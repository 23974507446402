//Import function
import React, {useEffect, useRef, useState} from "react"
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    fuzzySearchObjects,
    multiTermFuzzySearchObjects,
    objectIsEmpty,
    PopupModeEnum,
} from "edah_utils/dist"
import {t} from "i18next"
import {essiAddSignAgent, essiEditSignAgent} from "../../api/v1/ESSI"
import useToast from "../../hooks/useToast"
import {
    Dialog,
    DialogSizeEnums,
    DialogVariant,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Field,
    DateRangePicker,
    Search,
    AlertTypeEnum
} from "edah-component/dist";
import dayjs from "dayjs";

/**
 * 代簽人員維護-新增修改彈窗
 * @param show 是否顯示
 * @param dataRow {Object} 資料
 * @param doctors {Array<Object>} 醫生列表
 * @param mode {Number} 新增或編輯
 * @param closePopupOnClick {function} 關閉按鈕點擊事件
 * @return {JSX.Element}
 */
const AddEditSignAgentPopup = ({show, rowData, doctors, mode, closePopupOnClick, getQuerySignAgent}) => {
    /**
     * grid element 的預設type
     */
    const GridDefaultType = "grid grid-cols-2 gap-x-6 gap-y-5"

    /**
     * Toast
     */
    const showToast = useToast()

    /**
     * 預設資料
     */
    const defaultData = {
        // 代理人員主鍵
        "agnetPk": null,
        // 簽章人員編號
        "userNo": '',
        // 代理人員編號
        "agentUserNo": '',
        // 生效日期-起
        "effStartDatetime": null,
        // 生效日期-迄
        "effEndDatetime": null,
        // 備註
        "memo": null
    }

    //是否顯示彈窗
    const [showPopup, setShowPopup] = useState(false)

    // 是否顯示人員代號選項
    const [showUserDropdown, setShowUserDropdown] = useState(false)

    // 是否顯示代理人員代號選項
    const [showUserAgentDropdown, setShowUserAgentDropdown] = useState(false)

    //是否為新增模式
    const [isAddMode, setIsAddMode] = useState(false)

    //ref 用於指向人員代下拉菜單元素
    const dropdownUserRef = useRef(null)

    //ref 用於指向代理人員代號下拉菜單元素
    const dropdownAgentRef = useRef(null)

    //新增 / 修改資料
    const [newData, setNewData] = useState(null)

    // 人員代號選項
    const [userOption, setUserOption] = useState({userNo:'', userName: ''})

    // 代理人員代號選項
    const [agentUserOption, setAgentUserOption] = useState({userNo:'', userName: ''})

    /**
     * 處理人員代號選項點擊事件
     * @param option {Object} 人員代號
     * @return {void}
     */
    const handleUserNoOptionOnClick = (option) => {
        if (!objectIsEmpty(option)) {
            // 設定新資料
            setNewData({...newData, userNo: `${option.userNo} ${option.userName}`})
            // 設定人員代號
            setUserOption({userNo: option.userNo, userName: option.userName})
        }
    }

    /**
     * 處理代理人員代號選項點擊事件
     * @param option {Object} 人員代號
     * @return {void}
     */
    const handleAgentNoOptionOnClick = (option) => {
        if (!objectIsEmpty(option)) {
            // 設定新資料
            setNewData({...newData, agentUserNo: `${option.userNo} ${option.userName}`})
            // 設定代理人員代號
            setAgentUserOption({userNo: option.userNo, userName: option.userName})
        }
    }

    /**
     * 確定刪除點擊事件
     * @return {void}
     */
    const handleCloseButtonOnClick = () => {
        // 呼叫父層關閉彈窗
        closePopupOnClick()
        // 關閉彈窗
        setShowPopup(false)
    }

    /**
     * 儲存或編輯按鈕被點擊時
     * @return {void}
     */
    const handleSaveOrEditOnClick = () => {
        if (isAddMode) {
            // 新增代簽人員維護資料
            addSignAgent()
        } else {
            // 修改代簽人員維護資料
            editSignAgent()
        }
    }

    /**
     * 使用者代號選項改變時
     * @param value {String}
     * @return {void}
     */
    const handleUserNoOnChange = (value) => setNewData({...newData, userNo: value})

    /**
     * 代理人代號選項改變時
     * @param value {String}
     * @return {void}
     */
    const handleAgentUserNoOnChange = (value) => setNewData({...newData, agentUserNo: value})

    /**
     * 生效日期-起迄改變時
     * @param newDates {Array <Dayjs | null>}
     * @return {void}
     */
    const handleDateRangePickerOnChange = (newDates) => {
        // 取得日期陣列
        const [newStartDate, newEndDate] = newDates
        // 設定新資料
        setNewData({...newData, effStartDatetime: newStartDate ? `${newStartDate.format('YYYY-MM-DD')} 00:00:00` : '', effEndDatetime: newEndDate ? `${newEndDate.format('YYYY-MM-DD')} 00:00:00` : ''})
    }

    /**
     * 取得使用者選項過濾後列表
     * @return {Array<Object>}
     */
    const getFilterUserNoOptionList = () =>
        newData.userNo.indexOf(' ') >= 0 ?
            // 多個字串模糊搜尋
            multiTermFuzzySearchObjects(doctors, newData.userNo.split(' ')) :
            // 單個字串模糊搜尋
            fuzzySearchObjects(doctors, newData.userNo)

    /**
     * 取得代理人使用者選項過濾後列表
     * @return {Array<Object>}
     */
    const getFilterAgentUserNoOptionList = () =>
        newData.agentUserNo.indexOf(' ') >= 0 ?
            // 多個字串模糊搜尋
            multiTermFuzzySearchObjects(doctors, newData.agentUserNo.split(' ')) :
            // 單個字串模糊搜尋
            fuzzySearchObjects(doctors, newData.agentUserNo)

    /**
     * 處理API回應
     * @param res {Object} API回應
     * @return {void}
     */
    const handleApiResponse = (res) => {
        // 取得API回應
        if (res.err === ApiErrorStatusEnum.Success) {
            // 顯示成功訊息
            showToast({message: res.msg})
            // 呼叫父層關閉彈窗
            closePopupOnClick()
            // 取得所有代簽人員維護資料
            getQuerySignAgent()
        } else { // 顯示錯誤訊息
            showToast({type: AlertTypeEnum.Error, message: res.msg})
        }
    }

    /**
     * 新增代簽人員維護資料
     * @return {void}
     */
    const addSignAgent = () => {
        essiAddSignAgent([
            {
                ...newData,
                userNo: newData.userNo.split(' ')[0],
                agentUserNo: newData.agentUserNo.split(' ')[0]
            }
        ]).then(handleApiResponse)
    }

    /**
     * 修改代簽人員維護資料
     * @return {void}
     */
    const editSignAgent = () => {
        essiEditSignAgent([
            {
                ...newData,
                userNo: newData.userNo.split(' ')[0],
                agentUserNo: newData.agentUserNo.split(' ')[0]
            }
        ]).then(handleApiResponse)
    }

    /**
     * Dialog content UI fragment
     * @type {React.JSX.Element}
     */
    const DialogContent = (<>
        <div className="px-4 py-5">
            <div className={`${GridDefaultType}`}>
                {/*人員代號*/}
                <div className={`${GridDefaultType}`}>
                    <Field label={'人員代號'}>
                        <Search
                            value={userOption}
                            options={doctors || []}
                            getOptionLabel={(option) => `${option.userNo} ${option.userName}`}
                            onInputChange={(_e, value) => handleUserNoOnChange(value)}
                            onChange={(_e, value) => handleUserNoOptionOnClick(value)}
                        />
                    </Field>
                </div>
                {/*代理人員代號*/}
                <div className={`${GridDefaultType}`}>
                    <Field label={'代理人員代號'}>
                        <Search
                            value={agentUserOption}
                            options={doctors || []}
                            getOptionLabel={(option) => `${option.userNo} ${option.userName}`}
                            onInputChange={(_e, value) => handleAgentUserNoOnChange(value)}
                            onChange={(_e, value) => handleAgentNoOptionOnClick(value)}
                        />
                    </Field>
                </div>
                {/*代簽的起訖日期*/}
                <Field label={'代簽的起訖日期'}>
                    <DateRangePicker
                        value={
                            [newData?.effStartDatetime ? dayjs(newData.effStartDatetime) : null,
                                newData?.effEndDatetime ? dayjs(newData.effEndDatetime) : null]
                        }
                        onChange={handleDateRangePickerOnChange}
                    />

                </Field>
            </div>
        </div>
    </>)

    /**
     * Dialog footer content UI fragment
     * @type {React.JSX.Element}
     */
    const DialogFooterContent = (<>
        <Button
            color={ButtonColorEnum.Primary}
            size={ButtonSizeEnum.Medium}
            variant={ButtonVariantEnum.Contained}
            text={t('general.submit')}
            onClick={handleSaveOrEditOnClick}/>
    </>)

    /**
     * 監聽是否顯示彈窗Pros變化
     * @return {void}
     */
    useEffect(() => {
        // 設定是否顯示彈窗
        setShowPopup(show)
        // 設定是否為新增模式
        setIsAddMode(mode === PopupModeEnum.Add)
    }, [show])

    /**
     * 間接監聽rowData變化
     * @return {void}
     */
    useEffect(() => {
        const isAddMode = mode === PopupModeEnum.Add
        if (isAddMode) {
            setNewData(defaultData)
        } else {
            // 取得使用者資料
            const user = fuzzySearchObjects(doctors, rowData.userNo)[0]
            const agentUser = fuzzySearchObjects(doctors, rowData.agentUserNo)[0]
            setNewData({
                ...rowData,
                userNo: `${user.userNo} ${user.userName}`,
                agentUserNo: `${agentUser.userNo} ${agentUser.userName}`
            })
            // 設定人員代號和代理人員代號選項
            setUserOption(user)
            setAgentUserOption(agentUser)
        }
    }, [rowData])

    /**
     * 監聽 mode ，當新增模式時，重置所有表單資料外也清空人員代號和代理人員代號選項
     * @return {void}
     */
    useEffect(() => {
        // 監控 mode 的變化
        if (mode === PopupModeEnum.Add) {
            // 當切換到新增模式時，重置所有資料
            setNewData(defaultData)
            setUserOption({userNo: '', userName: ''})
            setAgentUserOption({userNo: '', userName: ''})
        }
    }, [mode])

    return showPopup ?
        <Dialog
            open={show}
            onClose={handleCloseButtonOnClick}
            title={"代替人員選擇"}
            variant={DialogVariant.CUSTOM}
            paperStyleProps={{width: DialogSizeEnums.MD, height: DialogSizeEnums.XS}}
            content={DialogContent}
            footerContent={DialogFooterContent}
        />
        : <></>
}
export default AddEditSignAgentPopup
