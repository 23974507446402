import React, { useEffect, useState } from 'react'
import { fuzzySearchObjects } from 'edah_utils/dist'
import {
    AdvancedDataGrid,
    Card,
    TextField,
    IconEnum,
    Pagination,
} from 'edah-component/dist'

/**
 * 進階搜尋表格
 * @param rows {Array} - 表格資料
 * @param columns {Array} - 表格欄位
 * @param height {number} - 表格高度
 * @param showCheckbox {boolean} - 是否顯示勾選框
 * @param getRowId {function} - 取得行ID
 * @param getRowHeight {function} - 取得行高度
 * @param onRowClick {function} - 行點擊事件
 * @param onSelectionChange {function} - 勾選框事件
 * @param onPageOnChange {function} - 分頁事件
 * @param pagination {Object} - 分頁資料
 * @param isShowPagination {boolean} - 是否顯示分頁
 * @return {JSX.Element}
 */
const AdvancedSearchTable = ({
    rows = [],
    columns = [],
    height = 400,
    showCheckbox = false,
    getRowId = undefined,
    getRowHeight = undefined,
    onRowClick = () => {},
    onSelectionChange = () => {},
    onPageOnChange = () => {},
    headerWrapperClassName = '',
    headerSlot = undefined,
    pagination = {
        totalPageSize: 0,
        page: 1,
        pageSize: 10,
        totalSize: 0,
    },
    isShowPagination = true,
    ...props
}) => {
    /**
     * 關鍵字
     */
    const [keyword, setKeyword] = useState('')
    /**
     * 過濾後的資料
     */
    const [filteredRows, setFilteredRows] = useState(rows)
    /**
     * 關鍵字變更事件
     * @param e {Object} - 事件
     * @return void
     */
    const handleKeywordChange = (e) => {
        const value = e.target.value
        setKeyword(value)
        const filteredDataList = fuzzySearchObjects(rows, value)
        setFilteredRows(filteredDataList)
    }
    /**
     * 初始化過濾後的資料
     * @return void
     */
    useEffect(() => {
        setFilteredRows(rows)
    }, [rows])

    return (
        <>
            <div className={`flex justify-between mb-2 ${headerWrapperClassName}`}>
                <TextField
                    value={keyword}
                    placeholder="進階搜尋"
                    startAdornment={IconEnum.Search}
                    onChange={handleKeywordChange}
                />
                {headerSlot}
            </div>
            <AdvancedDataGrid
                rows={filteredRows}
                height={height}
                columns={columns}
                onSelectionChange={onSelectionChange}
                showCheckbox={showCheckbox}
                onRowClick={onRowClick}
                getRowId={getRowId}
                {...props}
            />
            {isShowPagination && (
                <div className="flex justify-end mt-2">
                    <Pagination
                        totalPageSize={pagination.totalPageSize}
                        page={pagination.page}
                        pageSize={pagination.pageSize}
                        totalSize={pagination.totalSize}
                        onPageOnChange={onPageOnChange}
                        showFirstButton
                        showLastButton
                    />
                </div>
            )}
        </>
    )
}

export default AdvancedSearchTable
