import React, {useEffect, useMemo, useState} from "react"
import ListCard from "../../components/Regs/WeekMaintenance/ListCard"
import {t} from "i18next"
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    objectIsEmpty,
    PopupModeEnum,
    RegsWeekModeEnum,
    TimeslotEnum
} from "edah_utils/dist"
import {regsWeekQueryBaseData, regsWeekQueryByDivNo, regsWeekQueryByDoctorNo,} from "../../api/v1/Regs"
import {AddWeekMaintenancePopup} from "../../components/Regs/WeekMaintenance/Popup/AddWeekMaintenancePopup"
import useToast from "../../hooks/useToast"
import {AlertTypeEnum, Button, ButtonColorEnum, RadioGroup, Search, SizeEnum} from "edah-component/dist"

const WeekMaintenance = () => {
    // 計算總高度
    const totalHeight = window.innerHeight
    // 頂部區塊&日期區塊高度
    const defaultHeight = 120
    const topBlockHeight = 60
    const dateBlockHeight = 30
    // remain height
    const remainingHeight = totalHeight - defaultHeight - topBlockHeight - dateBlockHeight
    //計算時段高度
    const blockHeightPerSection = remainingHeight / 3

    // 預設周邊要插入資料
    const defaultWeekData = Array.from({length: 7}, (_, i) => i + 1).map((index) => {
            return {
                //星期幾的數值
                weekIndex: index,
                //星期幾
                weekName: t(`general.dateTime.weekRange.${index}`),
                // 早上
                morning: [],
                // 中午
                afternoon: [],
                // 晚上
                night: [],
            }
        }
    )

    // 切換科別或醫生
    const [filterMode, setFilterMode] = useState(RegsWeekModeEnum.Department)
    // 週班中查詢的相關參數初始值
    const [baseData, setBaseData] = useState(null)
    // 所有 醫師 list or 科別 list
    const [allDoctorOrDept, setAllDoctorOrDept] = useState(null)
    // 醫師 或 科別的下拉選單
    const [doctorOrDeptOptionList, setDoctorOrDeptOptionList] = useState([])
    // 給予ListCard選項使用
    const [listCardOptions, setListCardOptions] = useState(null)
    // 取得週班資料
    const [weekData, setWeekData] = useState(defaultWeekData)
    // 搜尋文字
    const [searchText, setSearchText] = useState("")
    // 避免API重複執行
    const [lock, setLock] = useState(false)
    //是否可以顯示新增週班視窗
    const [canShowAddWeekMaintenancePopup, setCanShowAddWeekMaintenancePopup] = useState(false)
    //第一次進來取得週班資料的flag
    const [hasFindWeekDataInInitial, setHasFindWeekDataInInitial] = useState(false)
    //當前右鍵點擊的區塊
    const [currentRightClick, setCurrentRightClick] = useState({
        week: null,
        timeslot: null,
    })
    //是否顯示新開診
    const [showAddAppointment, setShowAddAppointment] = useState(true)
    // Popup模式
    const [popupMode, setPopupMode] = useState(null)
    //當前選擇的資料
    const [selectedData, setSelectedData] = useState(null)
    // 判斷是否已進行過查詢
    const [isSearched, setIsSearched] = useState(false)
    //Toast Message Hooks
    const showToast = useToast()

    /**
     * 設定下拉選單選項
     * @param {Array} list 醫師或科別列表
     * @param {RegsWeekModeEnum} mode 醫師或科別模式
     */
    const setDropDownOptions = (list, mode) => {
        //添加ALL選項設定到開頭
        let updatedList = []
        if (+mode === RegsWeekModeEnum.Department) {
            updatedList = [{divNo: "ALL", divName: "ALL"}, ...list]
        } else {
            updatedList = [{userNo: "ALL", userName: "ALL"}, ...list]
        }
        setAllDoctorOrDept(updatedList)
    }
    /**
     * 模式改變時
     * @param mode {Number}
     * @return {void}
     */
    const handleModeOnChange = (mode) => {
        if (+mode === RegsWeekModeEnum.Department) {
            //將科別設定在下拉選單
            setDropDownOptions(baseData.divisionList, mode)
            //將醫生給予ListCard選項
            setListCardOptions(baseData.doctorList)
        } else {
            //將醫生設定在下拉選單
            setDropDownOptions(baseData.doctorList, mode)
            //將科別給予ListCard選項
            setListCardOptions(baseData.divisionList)
        }
        setIsSearched(false)
        //設定模式
        setFilterMode(mode)
        //將週班資料回復預設值
        setWeekData(defaultWeekData)

        // 重新設定新問診
        setCurrentRightClick({week: null, timeslot: null})

        const ele = document.querySelector('button.MuiAutocomplete-clearIndicator')
        if (ele) ele.click();
        //清空搜尋文字
        setSearchText('')
    }

    /**
     * 取得到搜尋的科別或醫生選項
     * @return {Array<Object>}
     */
    const getDoctorOrDeptOptions = () => {
        //取得搜尋文字
        const search = searchText
        if (arrayIsEmpty(allDoctorOrDept)) {
            return []
        }
        //取得科別或醫生選項
        return allDoctorOrDept.filter((item) => {
            //取得科別或醫生名稱
            const name = +filterMode === RegsWeekModeEnum.Department ? item?.divName : item?.userName
            //取得科別或醫生編號
            const no = +filterMode === RegsWeekModeEnum.Department ? item?.divNo : item?.userNo
            //如果搜尋文字存在
            if (search) {
                //搜尋文字存在空白代表使用者用選擇的
                return search.indexOf(' ') >= 0 ?
                    name.includes(search.split(' ')[1]) && no.includes(search.split(' ')[0]) :
                    name.includes(search) || no.includes(search)
            } else { //如果搜尋文字不存在
                //回傳全部
                return true
            }
        })
    }

    /**
     * 取得週班資料
     * @param filterText {Stinrg} 查詢的醫師或科別
     * @param mode {RegsWeekModeEnum} 查詢模式
     * @returns
     */
    const handleGetWeekData = (filterText, mode) => {
        const splitArray = filterText ? filterText.split(' ') : []
        if (!((filterText && splitArray.length >= 2) || filterText === "")) {
            return
        }
        parseWeekData(null)

        //是否科別模式
        const isDepartmentMode = +mode === RegsWeekModeEnum.Department
        //編號
        const no = filterText === "" ? "" : splitArray[0]
        //當前模式為科別
        if (isDepartmentMode) {
            regsWeekQueryByDivNo({divNo: no}).then(res => {
                //執行成功
                if (res.err === ApiErrorStatusEnum.Success) {
                    parseWeekData(res.data)
                }
            })
        } else {
            regsWeekQueryByDoctorNo({doctorNo: no}).then(res => {
                //執行成功
                if (res.err === ApiErrorStatusEnum.Success) {
                    parseWeekData(res.data)
                }
            })
        }
    }

    /**
     * 將資料還原成週班資料
     * @param result {Array<Object>}
     */
    const parseWeekData = (result) => {
        //如果沒有資料的話
        if (arrayIsEmpty(result)) {
            //將週班資料回復預設值
            setWeekData(defaultWeekData)
        } else { //如果有資料的話
            //先複製預設週班資料
            let tempWeekData = [...defaultWeekData]
            result.forEach(item => {
                //取得星期幾
                const week = parseInt(item.week)
                //取得時段
                const apn = item.apn
                //根據時段去設定週班資料
                switch (apn) {
                    case TimeslotEnum.Morning:
                        tempWeekData[week - 1].morning = [...item.regsWeekList]
                        break
                    case TimeslotEnum.Afternoon:
                        tempWeekData[week - 1].afternoon.push(...item.regsWeekList)
                        break
                    case TimeslotEnum.Night:
                        tempWeekData[week - 1].night.push(...item.regsWeekList)
                        break
                }
            })
            //設定週班資料
            setWeekData(tempWeekData)
        }
    }

    /**
     * 下拉選單值改變時
     * @param option {Object}
     */
    const handleDropdownOnChange = (option) => {
        let text = ''
        if (+filterMode === RegsWeekModeEnum.Department) {
            text = option?.divNo === "ALL" ? '' : option?.divNo && option?.divName ? `${option?.divNo} ${option?.divName}` : ''
        } else {
            text = option?.userNo === "ALL" ? '' : option?.userNo && option?.userName ? `${option?.userNo} ${option?.userName}` : ''
        }
        //設定搜尋文字
        setSearchText(text)
    }

    /**
     * 新增新開診時
     * @param week {Number} 週數
     * @param timeslot {Number} 時段
     */
    const handleOnAddNewAppointment = (week, timeslot) => {
        setShowAddAppointment(true)
        setCurrentRightClick({week: week, timeslot: timeslot})
    }

    /**
     * 顯示Popup
     * @param data {Object}
     * @param mode {Number}
     */
    const handleShowPopup = (data, mode = PopupModeEnum.Add) => {
        // 設定Popup模式
        setPopupMode(mode)
        //取得資料
        const newData = mode === PopupModeEnum.Modify ?
            {...data} :
            {
                //複製原先資料
                ...data,
                //設定日期
                //date: getDateOfWeek(currentRightClick.week),
                //當前選擇醫生
                doctor: +filterMode === RegsWeekModeEnum.Doctor ? (searchText ? searchText : null) : null,
                //當前選擇科別
                department: +filterMode === RegsWeekModeEnum.Department ? (searchText ? searchText : null) : null
            }

        // 設定選擇的資料
        setSelectedData(newData)
        // 顯示Popup
        setCanShowAddWeekMaintenancePopup(true)
    }

    /**
     * 關閉Popup
     * @return void
     */
    const handleCloseAddWeekMaintenancePopup = () => setCanShowAddWeekMaintenancePopup(false)

    /**
     * 新增成功事件
     * @param msg {String}
     */
    const handleCreateSuccess = (msg) => {
        //顯示toast
        showToast({message: `${msg} 新增成功`, type: AlertTypeEnum.Success})
        //重新取得週班資料
        handleGetWeekData(searchText, filterMode)
    }

    /**
     * 儲存成功事件
     */
    const handleSaveSuccess = (msg) => {
        //關閉popup
        setCanShowAddWeekMaintenancePopup(false)
        //顯示toast
        showToast({message: `${msg} 儲存成功`, type: AlertTypeEnum.Success})
        //重新取得週班資料
        handleGetWeekData(searchText, filterMode)
    }

    /**
     * 刪除成功事件
     */
    const handleDeleteSuccess = (msg) => {
        //關閉popup
        setCanShowAddWeekMaintenancePopup(false)
        //顯示toast
        showToast({message: `${msg} 刪除成功`, type: AlertTypeEnum.Success})
        //重新取得週班資料
        handleGetWeekData(searchText, filterMode)
    }

    /**
     * 取得醫師 科別下拉過濾選項Label
     * @param option {Object}
     * @return {string}
     */
    const getOptionDisplay = (option) => {
        // 當前模式為科別
        let text = ''
        if (+filterMode === RegsWeekModeEnum.Department) {
            text = option.divNo === "ALL" ? option.divName : `${option.divNo} ${option.divName}`
        } else {
            text = option.userNo === "ALL" ? option.userName : `${option.userNo} ${option.userName}`
        }

        return text
    }

    /**
     * 查詢周班
     * @return void
     */
    const handleQueryOnClick = () => {
        setIsSearched(true)
        //重新搜尋時隱藏新開診按鈕
        setShowAddAppointment(false)
        // 重置右鍵點擊狀態
        setCurrentRightClick({week: null, timeslot: null})
        handleGetWeekData(searchText, filterMode)
    }

    //第一次執行時先取得到資料
    useMemo(() => {
        //取得週班中查詢的相關參數初始值
        regsWeekQueryBaseData({}).then((res) => {
            //執行成功
            if (res.err === ApiErrorStatusEnum.Success && baseData === null) {
                //取得資料
                const data = res.data
                //設定資料
                setBaseData(data)
                //預設將科別設定在下拉選單
                setDropDownOptions(data.divisionList, RegsWeekModeEnum.Department)
                //預設將醫生給予ListCard選項
                setListCardOptions(data.doctorList)
            }
        })
    }, [])

    /**
     * 監聽搜尋文字改變時
     */
    useEffect(() => {
        setDoctorOrDeptOptionList(getDoctorOrDeptOptions())
    }, [searchText, filterMode, allDoctorOrDept])
    return (
        <div className="w-full">
            {/*頂部區塊*/}
            <div className="flex flex-row justify-start space-x-2 items-center w-full p-2">
                <div className="flex flex-row">
                    <RadioGroup
                        row
                        size={SizeEnum.Medium}
                        defaultValue={filterMode}
                        value={filterMode}
                        optionProps={{
                            options: [
                                // 科別
                                {label: t('general.appointmentDiv'), value: RegsWeekModeEnum.Department},
                                // 醫師
                                {label: t('general.doctor'), value: RegsWeekModeEnum.Doctor}
                            ]
                        }}
                        onChange={(e) => handleModeOnChange(e.target.value)}/>
                </div>
                <Search
                    onChange={(_e, value) => {
                        handleDropdownOnChange(value)
                    }}
                    disablePortal={false}
                    options={doctorOrDeptOptionList}
                    getOptionLabel={(option) => getOptionDisplay(option)}
                />
                <div>
                    <Button color={ButtonColorEnum.Primary} onClick={handleQueryOnClick}>
                        {t('general.query')}
                    </Button>
                </div>
            </div>
            {/*周班Content*/}
            <div className="flex flex-col">
                {/** 區塊 - 日期 */}
                <div className="w-full py-1 pl-[72px] pr-[16px]">
                    <div className="w-full min-w-[1457px] flex flex-row gap-2">
                        {weekData.map((week, index) => (
                            <div className="w-full min-w-[220px] flex justify-center" key={index}>
                                <p>{week.weekName}</p>
                            </div>
                        ))}
                    </div>
                </div>
                {/** 區塊 - 早 */}
                <div style={{height: blockHeightPerSection, gap: '5px'}}
                     className="w-full min-w-[1688px] bg-[#EBF8FF] flex flex-row gap-2 px-4 py-1">
                    <div className="w-12 h-[200px] flex-row justify-center items-center px-1 py-[52px]">
                        <div className="w-10 h-24 text-base">
                            <p className="w-12 text-center">
                                {t("general.dateTime.timeslot.short.morning")}
                            </p>
                            <p className="w-12 text-center">08:30</p>
                            <p className="w-12 text-center">~</p>
                            <p className="w-12 text-center">12:00</p>
                        </div>
                    </div>
                    {
                        weekData.map((week, index) => (
                            <ListCard
                                key={index}
                                week={week.weekIndex}
                                timeslot={TimeslotEnum.Morning}
                                options={listCardOptions}
                                mode={filterMode}
                                content={week["morning"]}
                                data={week.morning}
                                isAddAppointment={(currentRightClick.week === (index + 1) && currentRightClick.timeslot === TimeslotEnum.Morning) && showAddAppointment && isSearched}
                                onAddAppointment={handleOnAddNewAppointment}
                                showPopup={handleShowPopup}
                                baseData={baseData}
                                isSearched={isSearched}/>
                        ))
                    }
                </div>
                {/** 區塊 - 中 */}
                <div style={{height: blockHeightPerSection, gap: '5px'}}
                     className="w-full min-w-[1688px] bg-customYellow flex flex-row px-4 py-1">
                    <div className="w-12 h-[200px] flex-row justify-center items-center px-1 py-[52px]">
                        <div className="w-10 h-24 text-base">
                            <p className="w-12 text-center">
                                {t("general.dateTime.timeslot.short.afternoon")}
                            </p>
                            <p className="w-12 text-center">13:30</p>
                            <p className="w-12 text-center">~</p>
                            <p className="w-12 text-center">17:00</p>
                        </div>
                    </div>
                    {
                        weekData.map((week, index) => (
                            <ListCard
                                key={index}
                                week={week.weekIndex}
                                timeslot={TimeslotEnum.Afternoon}
                                options={listCardOptions}
                                mode={filterMode}
                                content={week["afternoon"]}
                                data={week.afternoon}
                                isAddAppointment={(currentRightClick.week === (index + 1) && currentRightClick.timeslot === TimeslotEnum.Afternoon) && showAddAppointment && isSearched}
                                onAddAppointment={handleOnAddNewAppointment}
                                showPopup={handleShowPopup}
                                baseData={baseData}
                                isSearched={isSearched}/>
                        ))
                    }
                </div>
                {/** 區塊 - 晚 */}
                <div style={{height: blockHeightPerSection, gap: '5px'}}
                     className="w-full min-w-[1688px] bg-[#FBF5FF] flex flex-row px-4 py-1">
                    <div className="w-12 h-[200px] flex-row justify-center items-center px-1 py-[52px]">
                        <div className="w-10 h-24 text-base">
                            <p className="w-12 text-center">
                                {t("general.dateTime.timeslot.short.night")}
                            </p>
                            <p className="w-12 text-center">18:00</p>
                            <p className="w-12 text-center">~</p>
                            <p className="w-12 text-center">21:00</p>
                        </div>
                    </div>
                    {
                        weekData.map((week, index) => (
                            <ListCard
                                key={index}
                                week={week.weekIndex}
                                timeslot={TimeslotEnum.Night}
                                options={listCardOptions}
                                mode={filterMode}
                                content={week["night"]}
                                data={week.night}
                                isAddAppointment={(currentRightClick.week === (index + 1) && currentRightClick.timeslot === TimeslotEnum.Night) && showAddAppointment && isSearched}
                                onAddAppointment={handleOnAddNewAppointment}
                                showPopup={handleShowPopup}
                                baseData={baseData}
                                isSearched={isSearched}/>
                        ))
                    }
                </div>
            </div>
            {canShowAddWeekMaintenancePopup &&
                // 新開診Popup
                <AddWeekMaintenancePopup
                    filterMode={filterMode}
                    mode={popupMode}
                    closePopup={handleCloseAddWeekMaintenancePopup}
                    baseData={baseData}
                    data={selectedData}
                    handleCreateSuccess={handleCreateSuccess}
                    handleSaveSuccess={handleSaveSuccess}
                    handleDeleteSuccess={handleDeleteSuccess}/>
            }
        </div>
    )
}

export default WeekMaintenance
