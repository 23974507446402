import React, {useContext, useState} from 'react'
import {stringIsEmpty, ApiErrorStatusEnum} from 'edah_utils/dist'
import {t} from 'i18next'
import {ReactComponent as Avatar} from '../../assets/images/ICCard/Avatar.svg'
import {
    Button,
    ButtonColorEnum,
    ButtonVariantEnum,
    ButtonSizeEnum,
    IconEnum,
    AlertTypeEnum
} from 'edah-component'
import VirtualICCard from '../ReadICCard/VirtualICCard'
import PhysicalICCard from '../ReadICCard/PhysicalICCard'
import {ReadICCardContext} from '../../context/ReadICCardContext'
import useICCard from '../../hooks/useICCard'
import {icUpdateData} from '../../api/v1/LocalService'
import useToast from "../../hooks/useToast";

const ReadICCard = () => {

    const {status, duration, isPhysical, isRead} = useContext(ReadICCardContext)

    const {readPhysicalICCard, readVHICCard} = useICCard()

    const showToast = useToast()

    /**
     * 重新讀取IC卡
     */
    const readICCard = () => {
        if (isRead) {
            isPhysical ? readPhysicalICCard() : readVHICCard()
        }
    }

    /**
     * IC卡更新
     */
    const updateICCard = () => {
        icUpdateData({}).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({message: 'IC卡更新成功', type: AlertTypeEnum.Success})
            } else {
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    return (
        <>
            {/*IC卡讀取*/}
            <div className="flex flex-row w-full h-[68px]">
                <div className="w-full h-full flex flex-row items-center justify-start">
                    <h2 className=" flex flex-row items-center justify-center text-[#2B6CB0] mr-4">
                        <Avatar className={'mr-2'}/> {t('general.patientInfo')}
                    </h2>
                    <div className="flex items-center justify-center h-11 mr-4">
                        <p className="text-[16px] text-[#3F3F46]">
                            {t('ICCard.cardReading', {
                                status: stringIsEmpty(status) ? '--' : status,
                            })}
                        </p>
                    </div>
                    <div className="flex items-center justify-center h-11 mr-4">
                        <p className="text-[16px] text-[#3F3F46]">
                            {t('ICCard.readingTime', {
                                duration: duration === 0 ? '--' : duration,
                            })}
                        </p>
                    </div>
                    <Button
                        sx={{marginRight: '1.5rem'}}
                        color={ButtonColorEnum.Secondary}
                        size={ButtonSizeEnum.Medium}
                        variant={ButtonVariantEnum.Outlined}
                        icon={IconEnum.Refresh}
                        onClick={readICCard}
                    >
                        {t('general.read')}
                    </Button>
                </div>
                <div className="w-full h-full flex flex-row items-center justify-start gap-6">
                    <PhysicalICCard/>
                    <VirtualICCard/>
                    <Button
                        color={ButtonColorEnum.Secondary}
                        size={ButtonSizeEnum.Medium}
                        variant={ButtonVariantEnum.Outlined}
                        text={t('ICCard.icCardUpdate')}
                        onClick={updateICCard}
                    />
                </div>
            </div>
        </>
    )
}

export default ReadICCard
