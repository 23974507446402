import React, {useEffect, useState} from "react"
import {Button, ButtonColorEnum,ButtonSizeEnum, ButtonVariantEnum, Field, Dialog, DialogVariant} from "edah-component";

/**
 * 退掛確認彈窗
 * @param show 是否顯示
 * @param closePopupButtonOnClick 關閉按鈕點擊事件
 * @param detailData 該筆詳細資料
 * @returns
 */
const SignInfoPopup = ({
   show = false,
   detailData,
   closePopupButtonOnClick
}) => {
    //grid element 的預設type
    const GridDefaultType = "grid grid-cols-2 gap-4"

    //顯示已簽radio(PopupModeEnum.Modify)或未簽radio(PopupModeEnum.Add)
    const [signRadio, setSignRadio] = useState([])

    //初始資料
    const [data, setData]=useState({})

    /**
    * 進階搜尋文字輸入事件
    * @param e {Event} 事件
    * @return {void}
    */
    const onAdvancedSearchTextOnInputChange = (e) => setAdvancedSearchText(e.target.value)

    /**
     * 進階搜尋文字鍵盤按鈕按下事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onAdvancedSearchTextOnInputKeyDown = (e) => {
        if (e.key === 'Enter') {
            //搜尋Patient
            advancedQueryPatient()
        }
    }

    /**
    * 頁碼變更事件
    * @param page {Number} 頁碼
    */
    const onPaginationPageOnChange = (page) => {
        setTimeout(() => setCurrentPage(page), 100)
    }

    /**
     * 每頁資料筆數變更事件
     * @param e {Event} 事件
     */
    const onPaginationPageSizeOnChange = (e) => {
        // 設定每頁資料筆數
        setPageSize(e.target.value)
    }

    /**
     * 上一頁點擊事件
     */
    const onPaginationPreviousOnClick = () => (currentPage - 1) > 1 ? setCurrentPage(currentPage - 1) : setCurrentPage(1)

    /**
     * 下一頁點擊事件
     */
    const onPaginationNextOnClick = () => (currentPage + 1) < totalPage ? setCurrentPage(currentPage + 1) : setCurrentPage(totalPage)


    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show)

    /**
     * 確定刪除點擊事件
     */
    const handleConfirmOnClick = () => {
        closePopupButtonOnClick()
    }

    /**
     * 取消刪除點擊事件
     */
    const handleCancelOnClick = () => {
        closePopupButtonOnClick()
    }

    /**
     * signRadio改變時
     * @param signRadio {Number}
     */
    const handleSignRadioOnChange = (signRadio) => {
        setSignRadio(signRadio)
    }

    /**
     * DialogContent
     * @returns {React.JSX.Element}
     * @constructor
     */
    const DialogContent =() => (
        <div className="my-4 mx-6">
            <div className="flex flex-col gap-2">
                <div>
                    <div className="flex-row flex w-full justify-end row-span-3 gap-2">
                        <Button
                            color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={'時戳驗證'}
                        />
                        <Button
                            color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} text={'驗證文件內容'}
                        />
                    </div>
                    <div className={'flex justify-around gap-2 py-4 px-8'}>
                        <div className="flex flex-col w-full justify-around gap-5">
                            <Field label={'患者姓名'}>{data.patientName}</Field>
                            <Field label={'患者病歷號'}>{data.chartNo}</Field>
                            <Field label={'門/急/住院日期'} labelWidth={'fit-content'}>{data.encounterDate}</Field>
                            <Field label={'建立人員'}>{data.createUserNo}</Field>
                            <Field label={'HCA憑證類別'}>{data.actionCode}</Field>
                            <Field label={'簽章日期時間'}>{data.signDatetime1}</Field>
                            <Field label={'簽章HCA卡號'}>{data.signUserNo1}</Field>
                            <Field label={'異動次數'}>{data.signPcName1}</Field>
                            <Field label={'敏感性類別'}>{data.sensitiveType}</Field>
                        </div>
                        <div className="flex flex-col w-full justify-start gap-5">
                            <Field label={'患者身分證號'}>{data.patientId}</Field>
                            <Field label={'患者來源'}>{data.inpOpd}</Field>
                            <Field label={'序號'}>{data.encounterDate}</Field>
                            <Field label={'傳輸狀態'}>{data.actionCode}</Field>
                            <Field label={'簽章演算法'}>{data.actionCode}</Field>
                            <Field label={'簽章人員'}>{data.signUserNo1}</Field>
                            <Field label={'簽章電腦位置'}>{data.signPcName1}</Field>
                            <Field label={'Trace ID'}>{data.traceId}</Field>
                            <Field label={'敏感性代號'}>{data.sensitiveCode}</Field>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )

    /**
     * 監聽是否顯示彈窗Pros變化
     * 開啟畫面下，radio為已簽章
     */
    useEffect(() => {
        setShowPopup(show)
        setData(detailData)
    }, [show])

    return (showPopup ?
            <>
                <Dialog
                    open={show}
                    title={'詳細內容'}
                    variant={DialogVariant.CUSTOM}
                    content={DialogContent()}
                    onClose={handleConfirmOnClick}
                />
        </> : <></>
    )
}

export default SignInfoPopup;
