import React, {memo, useEffect, useMemo, useRef, useState} from "react";
import {t} from "i18next";
import {
    Button, ButtonSizeEnum, ButtonVariantEnum, ColorEnum,
    Card, Field, AlertTypeEnum, HisTree, HisTreeTextAlignEnum, TabsEnum, Tabs, SizeEnum, TextArea,
    TextField, IconEnum, AdvancedDataGrid, DatePicker
} from "edah-component/dist/index";
import {ApiErrorStatusEnum, arrayIsEmpty, stringIsEmpty, time2String} from "edah_utils/dist";
import {cpoeQueryDoctorClinic, cpoeQueryOcisEncounterData, cpoeSaveAllOcisEncounterData} from "../../api/v1/Cpoe";
import useToast from "../../hooks/useToast";
import ClinicPatientList from "../../components/CPOE/ClinicPatientList";
import CpoeMain from "../../components/CPOE/CpoeMain";
import store from "../../redux/store"
import {toggleSideBar} from "../../redux/Slice/SideBarToggleSlice";
import {CpoeCardTitle} from "../../components/CPOE/CpoeCardTitle";
import {DATE_FORMAT, DATETIME_FORMAT} from "../../constants/common";
import dayjs from "dayjs";
/**
 * 時段
 * @type {{"": string, "1": string, "2": string, "3": string}}
 */
const apnList = {
    "1": '早',
    "2": '午',
    "3": '晚',
}
/**
 * EMR操作主畫面，通過看診清單進入cpoe操作畫面
 * @return {JSX.Element}
 */
const EmrMainV = ()=>{
    //Toast Message Hooks
    const showToast = useToast()
    //診間資訊相關資料
    const [clinicInfo, setClinicInfo] = useState({
        // 看診日期
        encounterDate: '' ,
        doctorNo: '2345',
        doctorName: '',
        clinicOpenDate: '',
    })
    // 看診日期(先開放可切換日期)
    const [encounterDate, setEncounterDate] = useState(time2String(new Date(),DATE_FORMAT))
    // 看診診間清單
    const [clinicDivList, setClinicDivList] = useState([])
    // 病人資料
    const [patientInfo, setPatientInfo] = useState({})
    // 是否顯示EMR主畫面
    const [isShowEmrView, setIsShowEmrView] = useState(false)

    // hisTree元件需要的欄位
    const commonItems = []
    // hisTree元件
    const [functionList,setFunctionList] = useState([
        { id: "AP-SS-1", name: 'IC卡功能',
            children:[{id: 'AP-SS-1-1', name: 'PACS'}]
        },
        { id: "AP-SS-2", name: '檢驗報告查詢',
            children:[
                {id: 'AP-SS-2-1', name: '檢查報告查詢'},
                {id: 'AP-SS-2-2', name: '就診紀錄查詢'},
                {id: 'AP-SS-2-3', name: '檢查報告查詢'},
                {id: 'AP-SS-2-4', name: '電子病歷查詢'},
                {id: 'AP-SS-2-5', name: '簽章病例查詢'},
                {id: 'AP-SS-2-6', name: '重大傷病申請'},
            ]},
        { id: "AP-SS-3", name: '用藥安全',
            children:[
                {id: 'AP-SS-3-1', name: '掛號'},{id: 'AP-SS-3-2', name: '預約住院'},
                {id: 'AP-SS-3-3', name: '診斷證明書'},{id: 'AP-SS-3-4', name: '同意書'},
                {id: 'AP-SS-3-5', name: '轉診'},{id: 'AP-SS-3-6', name: '約診單'},
                {id: 'AP-SS-3-7', name: '重印單據'},{id: 'AP-SS-3-8', name: '個人史'},
                {id: 'AP-SS-3-9', name: '醫病共享決策'}
            ]
        },
        { id: "AP-SS-4", name: '評估/療程紀錄'},
        { id: "AP-SS-5", name: '照會'},
        { id: "AP-SS-6", name: '論質計酬'},
        { id: "AP-SS-7", name: '癌症中心'},
        { id: "AP-SS-8", name: '調閱病歷'}
    ])


    /**
     * 獲取EMR VIEW
     * @return {JSX.Element}
     */
    const EMRView = ({patientInfo}) => {
        // 用於保存畫面資料api的患者信息參數
        const [ocisEncounter, setOcisEncounter] = useState(null)
        //當前選擇的tab
        const [currentTab, setCurrentTab] = useState('so')
        // 主訴
        const [subjective, setSubjective] = useState('')
        // 客訴
        const [objective, setObjective] = useState('')
        // 前期記錄
        const [previousRecord,setPreviousRecord] = useState(null)
        // 包含主訴，客訴，前期記錄等内容
        const [ocisEncSummary, setOcisEncSummary] = useState(null)
        // icd10CM
        const [icdCMList, setICdCMList] = useState([])
        // icdPCS
        const [icdPCSList, setICdPCSList] = useState([])
        // 生命體徵
        const [vitalSign, setVitalSign] = useState({
            height: '', //身高
            weight: '', //體重
            bmi: '',
            bsa: '',
            temperature: '', //體溫
            bloodSugar: '', //血糖
            pulse: '',//脈搏
            lPressure: '',//血壓-低點 舒張壓
            hPressure: '',//血壓-高點 收縮壓
        })
        // icd10 columns設置
        const columns = [
            {
                field: 'icdCmCode', width: 120, header: false,
                valueGetter: (value, row) => (row.recNo === 1 ? '主' : '次') + '('+ (row.icdCmCode || '') + ')'
            },
            {field: 'icd10NameEn',  width: 280 ,header: false,},
            {field: 'icdType', width: 64, header: false,}]
        // icd current tab
        const [icdCurrentTab, setICdCurrentTab] = useState('icdCM')
        // icd 分頁
        const icdTabItem =[
            { id: 'icdCM',title: t('ICD10-CM'),content: '' },
            { id: 'icdPCS',title: t('ICD10-PCS'),content: '' },
        ]

        //
        const cpoeMainRef = useRef()


        /**
         * 獲取EMR TAB分頁内容
         * @return {Object[]}
         */
        const getTabItem = ()=>{
            return [
                { id: 'so',title: t('SO'),content: getSoContent() },
                { id: 'clinic',title: t('就醫資訊'),content: '' },
                { id: 'allergy',title: t('過敏史'),content: '' },
                { id: 'ptInfo',title: t('病人資料'),content: '' }
            ]
        }

        /**
         * 獲取分頁tab-SO畫面内容
         */
        const getSoContent = ()=>{
            return (
                <div className="flex flex-row items-start gap-2">
                    <div className="flex-1 flex flex-col px-1 gap-2">
                        <div className="flex-1">
                            <div className="flex mb-2">
                                <CpoeCardTitle title={"Subjective(主訴)"}/>
                                <div className="flex-1 flex flex-row flex-wrap justify-end items-center gap-2">
                                    <Button
                                        sx={{fontSize: '1rem', padding: '0', minWidth: 'fit-content'}}
                                        color={ColorEnum.Primary} variant={ButtonVariantEnum.Text}
                                        size={ButtonSizeEnum.Large} icon={IconEnum.Edit}>
                                        {t('general.edit')}
                                    </Button>
                                </div>
                            </div>
                            <TextArea inputSize={SizeEnum.Fill} value={subjective}
                                      onChange={(e) => setSubjective(e.target.value)}/>
                        </div>
                        <div className="flex-1">
                            {/*title*/}
                            <div className="flex mb-2">
                                <CpoeCardTitle title={"Previous Record(前期紀錄)"}/>
                                <div className="flex-1 flex flex-row flex-wrap justify-end items-center gap-2">
                                    <Button
                                        sx={{fontSize: '1rem', padding: '0', minWidth: 'fit-content'}}
                                        color={ColorEnum.Primary} variant={ButtonVariantEnum.Text}
                                        size={ButtonSizeEnum.Large} icon={IconEnum.Edit}>
                                        {t('general.edit')}
                                    </Button>
                                </div>
                            </div>
                            <TextArea inputSize={SizeEnum.Fill} value={previousRecord}
                                      onChange={(e) => setPreviousRecord(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="flex-1">
                        <div className="flex mb-2">
                            <CpoeCardTitle title={"Objective(客述)"}/>
                            <div className="flex-1 flex justify-end  gap-2">
                                <Button sx={{fontSize: '1rem', padding: '0', paddingX: '8px', minWidth: 'fit-content'}}
                                        color={ColorEnum.Secondary} variant={ButtonVariantEnum.Contained}
                                        size={ButtonSizeEnum.Large}>
                                    {t('SO')}
                                </Button>
                                <Button
                                    sx={{fontSize: '1rem', padding: '0', minWidth: 'fit-content'}}
                                    color={ColorEnum.Primary} variant={ButtonVariantEnum.Text}
                                    size={ButtonSizeEnum.Large} icon={IconEnum.Edit}>
                                    {t('general.edit')}
                                </Button>
                            </div>
                        </div>
                        <TextArea inputSize={SizeEnum.Fill} rows={10} value={objective}
                                  onChange={(e) => setObjective(e.target.value)}
                        />

                    </div>
                </div>
            )
        }

        /**
         * 通過cpoe開單畫面帶入icd，主訴，客訴，前期記錄
         * @param encIcdCmParamList
         * @param subjective
         * @param objective
         * @param previousRecord
         */
        const setSOICDContent = ({encIcdCmParamList,subjective,objective,previousRecord})=>{
            !arrayIsEmpty(encIcdCmParamList) && setICdCMList(encIcdCmParamList)
            !stringIsEmpty(subjective) && setSubjective(subjective)
            !stringIsEmpty(objective) && setObjective(objective)
            !stringIsEmpty(previousRecord) && setPreviousRecord(previousRecord)
        }
        /**
         * 保存畫面資料
         * @param encounterStatus{String} 暫存傳"U"，存檔傳"F"
         * @return {void}
         */
        const handleSaveAllOcisEncounterData = (encounterStatus) => {
            // 調用cpoeMain獲取order内容
            const encounterOrderList = cpoeMainRef.current.getEncounterOrder()
            const saveData = {
                ocisEncounter: ocisEncounter,
                encounterOrderList: encounterOrderList,
                encIcdCmList: icdCMList,
                ocisEncSummary: {
                    ...ocisEncSummary,
                    subjective: subjective,
                    objective: objective,
                    previousRecord: previousRecord
                },
                encounterStatus: encounterStatus
            }
            saveAllOcisEncounterData(saveData)
        }

        /**
         * 獲取門診病人完整就醫資料
         * @returns {void}
         */
        const queryOcisEncounterData = () => {
            cpoeQueryOcisEncounterData({
                encounterId: patientInfo.encounterId
            }).then((res) =>{
                if(res.err === ApiErrorStatusEnum.Success){
                    setOcisEncounter(res.data.ocisEncounter)
                    // 設置畫面内容（主訴，客訴，icd，前期資料）
                    setICdCMList(res.data?.encIcdCmList || [])
                    const encSummary = res.data?.ocisEncSummary
                    setOcisEncSummary(encSummary)
                    setSubjective(encSummary?.subjective)
                    setObjective(encSummary?.objective)
                    setPreviousRecord(encSummary?.previousRecord)
                }else{
                    showToast({message: res.msg, type: AlertTypeEnum.Error})
                }
            })
        }

        /**
         * 病人門診+醫藥囑+ICD10+病情摘要 存檔
         * @param saveData {Object} 畫面資料
         */
        const saveAllOcisEncounterData =(saveData)=>{
            cpoeSaveAllOcisEncounterData(saveData).then((res) =>{
                if(res.err === ApiErrorStatusEnum.Success){
                    //保存成功后返回候診清單
                    setIsShowEmrView(false)
                }else{
                    showToast({message: res.msg, type: AlertTypeEnum.Error})
                }
            })
        }

        /**
         * 監聽病人信息變更
         */
        useEffect(()=>{
            //獲取門診病人完整就醫資料
            !stringIsEmpty(patientInfo?.encounterId) && queryOcisEncounterData()
        },[patientInfo])

        return (
            // 病人醫囑主畫面
            <div className="relative w-full bg-[#FAFAFA]">
                <div className="flex mb-2">
                    <CpoeCardTitle title={"病人醫囑主畫面"}/>
                    <div className="flex-1 flex flex-row flex-wrap justify-end items-center gap-2">
                        <Button
                            color={ColorEnum.Secondary} variant={ButtonVariantEnum.Contained}
                            size={ButtonSizeEnum.Medium}>
                            {t('此次存成套組')}
                        </Button>
                        <Button
                            color={ColorEnum.Secondary} variant={ButtonVariantEnum.Contained}
                            size={ButtonSizeEnum.Medium}>
                            {t('Ditto上次')}
                        </Button>
                        <Button
                            color={ColorEnum.Secondary} variant={ButtonVariantEnum.Contained}
                            size={ButtonSizeEnum.Medium}>
                            {t('選擇套組')}
                        </Button>
                        <Button
                            color={ColorEnum.Primary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium}
                            onClick={() => handleSaveAllOcisEncounterData('U')}>
                            {t('暫存')}
                        </Button>
                        <Button
                            color={ColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                            size={ButtonSizeEnum.Medium}
                            onClick={() => handleSaveAllOcisEncounterData('F')}>
                            {t('general.saveFile')}
                        </Button>
                    </div>
                </div>
                <div className="grid grid-cols-3 gap-2 mb-4">
                    <div className="col-span-2">
                        <Card>
                            <Tabs
                                items={getTabItem()}
                                variant={TabsEnum.INDICATOR_TAB}
                                activeTabId={currentTab}
                                onTabChange={(tab) => setCurrentTab(tab)}
                                tabsProps={{}}
                            />
                        </Card>
                    </div>
                    <div className="col-span-1 gap-2">
                        {/*icd*/}
                        <div className="relative">
                            <Tabs
                                items={icdTabItem}
                                variant={TabsEnum.INDICATOR_TAB}
                                activeTabId={icdCurrentTab}
                                onTabChange={(tab) => setICdCurrentTab(tab)}
                                tabsProps={{}}
                            />
                            <div className="flex justify-end absolute top-2.5 right-2.5">
                                <Button
                                    sx={{fontSize: '1rem', padding: '0', minWidth: 'fit-content'}}
                                    color={ColorEnum.Primary} variant={ButtonVariantEnum.Text}
                                    size={ButtonSizeEnum.Large} icon={IconEnum.Edit}>
                                    {t('general.edit')}
                                </Button>
                            </div>
                            {/*height={'200px'}---爲了展示先用3筆*/}
                            <AdvancedDataGrid
                                rows={icdCMList}
                                columns={columns}
                                disableRowSelectionOnClick={false}
                                disableColumnMenu={true}
                                disableColumnSorting={true}
                                height={'120px'}
                                columnHeaderHeight={0}
                                getRowHeight={(params) => 40}
                                getRowId={(row) => row.icdCmCode}
                            />
                        </div>
                        {/*生命體徵*/}
                        <Card title={"生命徵象"} sx={{'height': '200px', 'margin-top': '8px'}}>
                            <div className="mt-[-20px]">
                                <div className="flex flex-row justify-start items-start">
                                    <div className="flex flex-col gap-0">
                                        <Field labelWidth={80} items-center label="身高">
                                            <div className="flex items-center gap-2">
                                                <TextField label="" value={vitalSign.height}
                                                           inputWidth={SizeEnum.Tiny} size={SizeEnum.Small}
                                                           onChange={(e) => setVitalSign({
                                                               ...vitalSign,
                                                               height: e.target.value
                                                           })}
                                                />
                                                <div className="py-2">cm</div>
                                            </div>
                                        </Field>
                                        <Field labelWidth={80} label="體重">
                                            <div className="flex items-center gap-2">
                                                <TextField label="" value={vitalSign.weight}
                                                           inputWidth={SizeEnum.Tiny} size={SizeEnum.Small}
                                                           onChange={(e) => setVitalSign({
                                                               ...vitalSign,
                                                               weight: e.target.value
                                                           })}
                                                />
                                                <div className="py-2">kg</div>
                                            </div>
                                        </Field>
                                        <Field labelWidth={80} label="BMI">
                                            <div className="flex items-center gap-2">
                                                <div className="py-2">{vitalSign.bmi}</div>
                                            </div>
                                        </Field>
                                        <Field labelWidth={80} label="BSA">
                                            <div className="flex items-center gap-2">
                                                <div className="py-2">{vitalSign.bsa}</div>
                                            </div>
                                        </Field>
                                    </div>
                                    <div className="flex flex-col gap-1">
                                        <Field labelWidth={80} label="體溫">
                                            <div className="flex items-center gap-2">
                                                <TextField label="" value={vitalSign.temperature}
                                                           inputWidth={SizeEnum.Tiny} size={SizeEnum.Small}
                                                           onChange={(e) => setVitalSign({
                                                               ...vitalSign,
                                                               temperature: e.target.value
                                                           })}
                                                />
                                                <div className="py-2">°C</div>
                                            </div>
                                        </Field>
                                        <Field labelWidth={80} label="血糖">
                                            <div className="flex items-center gap-2">
                                                <TextField label="" value={vitalSign.bloodSugar}
                                                           inputWidth={SizeEnum.Tiny} size={SizeEnum.Small}
                                                           onChange={(e) => setVitalSign({
                                                               ...vitalSign,
                                                               bloodSugar: e.target.value
                                                           })}
                                                />
                                                <div className="py-2">mg/dL</div>
                                            </div>
                                        </Field>
                                        <Field labelWidth={80} label="脈搏">
                                            <div className="flex items-center gap-2">
                                                <TextField label="" value={vitalSign.pulse}
                                                           inputWidth={SizeEnum.Tiny} size={SizeEnum.Small}
                                                           onChange={(e) => setVitalSign({
                                                               ...vitalSign,
                                                               pulse: e.target.value
                                                           })}
                                                />
                                                <div className="py-2">次/分</div>
                                            </div>
                                        </Field>
                                    </div>
                                </div>
                                <Field labelWidth={80} label="血壓">
                                    <div className="flex gap-2 items-center">
                                        <TextField label="" value={vitalSign.lPressure} inputWidth={SizeEnum.Tiny}
                                                   size={SizeEnum.Small}
                                                   onChange={(e) => setVitalSign({
                                                       ...vitalSign,
                                                       lPressure: e.target.value
                                                   })}
                                        />
                                        <div className="py-2">/</div>
                                        <TextField label="" value={vitalSign.hPressure} inputWidth={SizeEnum.Tiny}
                                                   size={SizeEnum.Small}
                                                   onChange={(e) => setVitalSign({
                                                       ...vitalSign,
                                                       pulse: e.target.value
                                                   })}
                                        />
                                        <div className="py-2">mmHg</div>
                                    </div>
                                </Field>
                            </div>
                        </Card>
                    </div>
                </div>
                {/*cpoe主畫面*/}
                <CpoeMain patientInfo={patientInfo} inpOpd="O" ref={cpoeMainRef} bringSOICD={setSOICDContent}/>
            </div>
        )
    }

    /**
     * 回到病人清單
     */
    const handleReturnPtList = () => {
        setIsShowEmrView(false)
    }

    /**
     * 選擇項目
     * @param id {String}
     * @param name {String}
     * @param hasChildren {Boolean}
     * @return {void}
     */
    const handleSelectItem = (id, name, hasChildren) => {

    }

    /**
     * 取得醫師當天門診，剛進入和日期切換需呼叫
     * encounterDate=2024-07-18 00:00:00&doctorNo=1774
     * @returns {void}
     */
    const queryDoctorClinic = () => {
        cpoeQueryDoctorClinic({
            encounterDate: dayjs(encounterDate).format(DATETIME_FORMAT),//time2String(new Date(),DATETIME_FORMAT),
            doctorNo: clinicInfo.doctorNo
        }).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setClinicDivList(res.data)
                setClinicInfo(res.data.length > 0 ? res.data[0] : {})
            } else {
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 畫面初始化,第一次進來
     */
    useMemo(() => {
        // 自動收起左邊功能菜單
        store.dispatch(toggleSideBar({isOpen: false}))
    }, [])

    /**
     * 監聽encouterDate
     */
    useEffect(()=>{
        //查詢診間資訊
        queryDoctorClinic()
    },[encounterDate])

    return (
        <div className="w-full p-2 bg-[#FAFAFA] space-y-2.5 max-h-[calc(100vh-101px)] overflow-y-auto">
            {/*診間資訊，病人資訊*/}
            <div className="flex flex-row items-center justify-start gap-2">
                <div className="flex-1 grid grid-cols-2 gap-2">
                    <div className="col-span-1">
                        <Card title={"診間資訊"} sx={{flex: 1}}>
                            <div className="flex flex-row items-center justify-start gap-2">
                                <div className="flex-1 flex-col">
                                    <Field labelWidth={80} label={t('日期')}>
                                        <DatePicker size={SizeEnum.Small}
                                                    label=""
                                                    disableFuture
                                                    value={dayjs(encounterDate)}
                                                    onChange={(date) => setEncounterDate(dayjs(date).format(DATE_FORMAT))}/>
                                        {/*<p className="text-left">{time2String(clinicInfo.encounterDate, "yyyy-MM-DD")}</p>*/}
                                    </Field>
                                    <Field labelWidth={80} label={t('時段')}>
                                        <p className="text-left">{apnList[clinicInfo.apn] || ''}</p>
                                    </Field>
                                </div>
                                <div className="flex-1 flex-col">
                                    <Field labelWidth={80} label={t('診室名稱')}>
                                        <p className="text-left">{clinicInfo.titleDivName}</p>
                                    </Field>
                                    <Field labelWidth={80} label={t('看診醫師')}>
                                        <p className="text-left">{clinicInfo.doctorName}</p>
                                    </Field>
                                </div>
                                <div className="flex-1 flex-col">
                                    <Field isShowColon={false} labelWidth={80} label={clinicInfo.divName}><span></span>
                                    </Field>
                                    <Field labelWidth={80} label={t('開放日期')}>
                                        <p className="text-left">{clinicInfo.clinicOpenDate}</p>
                                    </Field>
                                </div>
                            </div>
                        </Card>
                    </div>
                    <div className="col-span-1">
                        <Card title={"病人資訊"} sx={{flex: 1}}>
                            <div className="flex flex-row items-center justify-start gap-2">
                                <div className="flex-1 flex-col">
                                    <Field labelWidth={80} label={t('病歷號')}>
                                        <p className="text-left">{patientInfo?.patientId || ''}</p>
                                    </Field>
                                    <Field labelWidth={80} label={t('姓名')}>
                                        <p className="text-left">{patientInfo?.patientName || ''}</p>
                                    </Field>
                                </div>
                                <div className="flex-1 flex-col">
                                    <Field labelWidth={80} label={t('性別')}>
                                        <p className="text-left">{patientInfo?.genderName || ''}</p>
                                    </Field>
                                    <Field labelWidth={80} label={t('生日')}>
                                        <p className="text-left">{time2String(patientInfo?.birthDate, "yyyy-MM-DD") || ''}</p>
                                    </Field>
                                </div>
                            </div>
                        </Card>
                    </div>
                </div>
                {isShowEmrView && (<Button
                    color={ColorEnum.Secondary} variant={ButtonVariantEnum.Contained}
                    size={ButtonSizeEnum.Medium} onClick={handleReturnPtList}>
                    {t('回病人清單')}
                </Button>)}
            </div>
                <div className="flex flex-wrap justify-start items-start gap-2">
                    {/*功能列表*/}
                    <Card title={"功能表"} sx={{width: '232px'}}>
                        <HisTree
                            menuTree={functionList}
                            textAlign={HisTreeTextAlignEnum.Left}
                            frequentlyUsedArray={commonItems}
                            onSelectNode={handleSelectItem}/>
                    </Card>
                    <div className="flex-1 w-0 overflow-auto">
                        {/*病人挂號清單   */}
                        {!isShowEmrView && (<ClinicPatientList
                            clinicList={clinicDivList}
                            clinicInfo={clinicInfo}
                            setClinicInfo={setClinicInfo}
                            setPatientInfo={setPatientInfo}
                            setIsShowEmrView={setIsShowEmrView}
                        />)}
                        {/*EMR主畫面*/}
                        {isShowEmrView && (
                            <EMRView patientInfo={patientInfo}/>
                        )}
                    </div>
                </div>

            </div>
            )
            }

            export default EmrMainV
