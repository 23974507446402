//Import icons
import SearchIcon from "../../assets/images/search-interface-symbol.png"

//Import css
import '../../components/TableList/tableList.scss'
import '../../components/ICCard/maintain.scss'

//Import function
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    createDateRange2String,
    fuzzySearchObjects,
    multiTermFuzzySearchObjects,
    objectIsEmpty,
    time2String
} from "edah_utils/dist"
import {Pagination} from "../../components/Pagination/Pagination"
import useOutsideClick from "../../hooks/useOutsideClick"
import React, {useEffect, useMemo, useRef, useState} from "react"
import {queryAllDoctor} from "../../api/v1/Menu"
import {t} from "i18next"
import {essiQueryEmroperLog} from "../../api/v1/ESSI"
import useToast from "../../hooks/useToast"
import {BaseInput} from "../../components/Input/BaseInput"
import {
    Select,
    SelectWidthEnum,
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    ButtonColorEnum,
    Search,
    DateRangePicker,
    Field,
    TextField,
    AlertTypeEnum
} from "edah-component/dist";
import dayjs from "dayjs"

/**
 * 操作記錄查詢
 * @return {JSX.Element}
 */
const EmrMasterLog = () => {
    /**
     * Table element 的預設type
     */
    const tableElementDefaultType = "px-[16px] border-r border-[#111111]/15 font-normal"

    /**
     * Show Toast
     */
    const showToast = useToast()

    // 建立時間
    const [startDate, setStartDate] = useState(null)

    // 終止時間
    const [endDate, setEndDate] = useState(null)

    //當前頁碼
    const [currentPage, setCurrentPage] = useState(1)

    //每頁資料筆數
    const [pageSize, setPageSize] = useState(10)

    //總資料筆數
    const [totalSize, setTotalSize] = useState(0)

    //總頁數
    const [totalPageSize, setTotalPageSize] = useState(0)

    // 初始資料 table
    const [data, setData] = useState([])

    // 初始資料 人員
    const [user, setUser] = useState('')

    //下拉選單 CRUD
    const [selectCrud, setSelectCrud] = useState("ALL")

    //病人ID
    const [patientId, setPatientId] = useState("")

    // 是否顯示人員代號選項
    const [showUserOptions, setShowUserOptions] = useState(false)

    // 所有人員代號選項
    const [users, setUsers] = useState([])

    //ref 用於指向人員代下拉菜單元素
    const dropdownUserRef = useRef(null)

    //進階搜尋文字
    const [advancedSearchText, setAdvancedSearchText] = useState("")

    //是否已經取得資料
    const hasFirstFetchedData = useRef(false)

    // 動作選項
    const selectCrudOptions = [
        {label: "全部", value: "ALL"},
        {label: "C - 新增", value: "C"},
        {label: "R - 簽章", value: "R"},
        {label: "U - 維護", value: "U"},
        {label: "D - 刪除", value: "D"},
    ]

    /**
     * 處理人員代號搜尋文字框焦點事件
     * @return {void}
     */
    const handleUserDropdownOnFocus = () => setShowUserOptions(true)

    /**
     * 處理人員代號選項點擊事件
     * @param option {Object} 人員代號
     * @return {void}
     */
    const handleUserOptionOnClick = (option) => {
        // 值不為空
        if (!objectIsEmpty(option)) {
            setUser(`${option.userNo} ${option.userName}`)
        }

        // 關閉建立人員下拉選單
        setShowUserOptions(false)
    }

    /**
     * 取得過濾後的人員代號選項
     * @return {Array<Object>}
     */
    const getFilterUser = () =>
        // 醫生是否有空格
        user.indexOf(' ') >= 0 ?
            // 多個字串模糊搜尋
            multiTermFuzzySearchObjects(users, user.split(' ')) :
            // 單個字串模糊搜尋
            fuzzySearchObjects(users, user)

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     * @return {void}
     */
    const onPaginationPageOnChange = (page) => setTimeout(() => setCurrentPage(page), 100)

    /**
     * 每頁資料筆數變更事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onPaginationPageSizeOnChange = (e) => setPageSize(e.target.value)

    /**
     * 上一頁點擊事件
     * @return {void}
     */
    const onPaginationPreviousOnClick = () => (currentPage - 1) > 1 ? setCurrentPage(currentPage - 1) : setCurrentPage(1)

    /**
     * 下一頁點擊事件
     * @return {void}
     */
    const onPaginationNextOnClick = () => (currentPage + 1) < totalPage ? setCurrentPage(currentPage + 1) : setCurrentPage(totalPage)

    /**
     * 時間起始事件
     * @return {void}
     */
    const handleStartDateOnChange = (e) => setStartDate(e.target.value)

    /**
     * 時間終止事件
     * @return {void}
     */
    const handleEndDateOnChange = (e) => setEndDate(e.target.value)

    /**
     * 日期範圍變動事件
     * @param newDates {Array<Dayjs | null>} 日期範圍 [開始日期, 結束日期]
     * @return {void}
     */
    const handleDateRangeOnChange = (newDates) => {
        const [newStartDate, newEndDate] = newDates
        setStartDate(newStartDate ? newStartDate.format('YYYY-MM-DD') : '')
        setEndDate(newEndDate ? newEndDate.format('YYYY-MM-DD') : '')
    }

    /**
     * 欄位下拉選單改變時-人員下拉選單
     * @param value {String} 事件
     * @return {void}
     */
    const handleUserDropdownOnChange = (value) => setUser(value)

    /**
     * 欄位下拉選單改變時-CRUD下拉
     * @param value {String} 事件
     * @return {void}
     */
    const handleSelectCrudOnChange = (value) => setSelectCrud(value)

    /**
     * 欄位改變時-病人ID
     * @param e {Event} 事件
     * @return {void}
     */
    const handlePatientIdOnChange = (e) => setPatientId(e.target.value)

    /**
     * 進階搜尋文字輸入事件
     * @param e {Event} 事件
     * @return {void}
     */
    const handleAdvancedSearchTextOnChange = (e) => setAdvancedSearchText(e.target.value)

     /**
    * 取得模糊匹配後的資料陣列
    * @return {Array<Object>}
    */
     const getFilterData = (data) => fuzzySearchObjects(data, advancedSearchText)

    /**
     * 查詢所有醫生
     * @return {void}
     */
    const getQueryAllDoctor = () => {
        queryAllDoctor().then(res => {
            //取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                const data = res.data
                //設定人員代號選項
                setUsers(data)
            }
        })
    }

    /**
     * 取得查詢操作記錄
     * @return {void}
     */
    const getQueryEmroPerLog = () => {
        essiQueryEmroperLog({
            // 起訖時間
            createStartDate: `${startDate} 00:00:00`,
            // 終止時間
            createEndDate: `${endDate} 23:59:59`,
            // 操作類型
            logOperCrud: selectCrud,
            //當前頁碼
            pageNum: currentPage,
            //每頁資料筆數
            pageSize: pageSize,
            // 病患ID not required
            pateientId: patientId,
            // logUserNo not required
            logUserNo: user
        }).then(res => {
            // API回傳成功
            if (res.err === ApiErrorStatusEnum.Success) {
                // 查無資料
                if (res.data.length === 0 || !res.data) {
                    // 顯示查無資料
                    showToast({type: AlertTypeEnum.Success, message: '查無資料'})
                }

                //設定資料
                setData(res.data)
                //設定總資料筆數
                setTotalSize(res.totalItemSize)
                //設定總頁數
                setTotalPageSize(res.totalPageSize)
            }
        })
    }

    /**
     * 取得查詢操作名稱
     * @param crud {String}
     * @return {*|string}
     */
    const getCrudLabel = (crud) => {
        const labelMap = {
            'C': '新增',
            'R': '簽章',
            'U': '維護',
            'D': '刪除',
        }
        return labelMap[crud] || ''
    }

    /**
     * 第一次進入時去新增測試資料 人員清單
     * @return {void}
     */
    useMemo(() => {
        //取得日期範圍
        const dateRange = createDateRange2String("","",0)
        // 設定開始時間
        setStartDate(dateRange[0])
        // 設定開始時間
        setEndDate(dateRange[1])
        // 查詢所有醫生
        getQueryAllDoctor()

    }, [])

    /**
     * 監聽當起始時間和結束時間改變時，重新取得查詢操作記錄
     */
    useEffect(() => {
        // 起訖時間不為空
        if (!!startDate && !!endDate) {
            // 是否為第一次取得資料
            if (!hasFirstFetchedData.current) {
                //取得查詢操作記錄
                getQueryEmroPerLog()
                // 設定已經取得資料
                hasFirstFetchedData.current = true
            }
        }
    }, [startDate, endDate])

    /**
     * 避免點擊建立人員選項時，因CSS點不到問題
     */
    useOutsideClick({
        ref: dropdownUserRef,
        handler: () => setShowUserOptions(false),
    })

    /**
     * 當頁碼或每頁筆數改變時
     * @return {void}
     */
    useEffect(() => {
        // 取得查詢操作記錄
        getQueryEmroPerLog()
    }, [currentPage, pageSize])

    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            <div className="flex items-center space-x-2 mb-2 text-left">
                {/* 日期範圍 */}
                <DateRangePicker
                    value={[
                        startDate? dayjs(startDate) : null,
                        endDate ? dayjs(endDate) : null,]}
                    onChange={handleDateRangeOnChange}
                    required
                />
                {/*操作人員*/}
                <Search
                    inputLabel={'操作人員'}
                    options={users}
                    getOptionLabel={(option)=>`${option.userNo} ${option.userName}`}
                    inputValue={user}
                    onInputChange={(_e,value)=>{handleUserDropdownOnChange((value))}}
                    onChange={(_e, value)=>handleUserOptionOnClick((value))}/>
                {/*病人ID*/}
                <TextField value={patientId} onChange={handlePatientIdOnChange} label={'病人ID'}/>
                {/*操作類型*/}
                <Select
                    width={SelectWidthEnum.Medium}
                    data={{
                        label: '操作類型',
                        options: selectCrudOptions.map((option)=>({
                            value: option.value,
                            label: option.label
                        }))
                    }}
                    value={selectCrud}
                    notched={true}
                    onChange={handleSelectCrudOnChange}/>
                {/*查詢按鈕*/}
                <Button color={ButtonColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                        size={ButtonSizeEnum.Medium} text={t('general.query')} onClick={getQueryEmroPerLog} />
            </div>
            <div className="py-2 px-2 bg-white border-[1px] border-[#D4D4D8] rounded-[6px]">
                <div className="flex flex-row items-center justify-between mb-2">
                    {/*進階搜尋*/}
                    <div>
                        <BaseInput inputMode="search" type="text" className="w-[320px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px]" type="text"
                               placeholder={t('general.advancedSearch')} value={advancedSearchText}
                               onChange={handleAdvancedSearchTextOnChange}/>
                    </div>
                </div>
                <div className='w-full overflow-x-auto overflow-y-auto min-h-[70vh] max-h-[70vh] xl:max-h-[70vh]'>
                    <table
                        className="table-fixed w-full text-left overflow-x-auto overflow-y-auto min-h-[70vh] max-h-[70vh] xl:max-h-[70vh]">
                        <thead>
                        <tr className="bg-[#e4e4e7] h-[56px] border-collapse text-lg text-[#18181b]">
                            <th className={`w-[200px] ${tableElementDefaultType}`}>操作時間 <br/> 操作人員</th>
                            <th className={`${tableElementDefaultType}`}>操作類型 <br/> 查詢條件</th>
                            <th className={`${tableElementDefaultType}`}>操作對象<br/> 查詢說明</th>
                            <th className={`w-[200px] ${tableElementDefaultType}`}>門/急/住<br/> 病人資訊</th>
                            <th className={`w-[200px] ${tableElementDefaultType}`}>備註</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            !arrayIsEmpty(getFilterData(data)) && getFilterData(data).map((data, index) =>
                                <tr
                                    className={`h-[40px] ${index % 2 === 0 ? '' : 'bg-[#f4f4f5]'} text-[#18181b]`}
                                    key={index}>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.logDatetime ? time2String(data.logDatetime,'YYYY-MM-DD hh:mm:ss') : ""}<br/>{data.logUserNo ? data.logUserNo : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.logOperCrud ? getCrudLabel(data.logOperCrud) : ""}<br/>{data.logOperCondition ? data.logOperCondition : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.loOperFunName ? data.loOperFunName : ""}<br/>{data.loOperFunName ? data.loOperFunName : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.patientId ? data.patientId : ""}<br/>{data.patientName ? data.patientName : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.logOtherDoc ? data.logOtherDoc : ""}
                                    </td>

                                </tr>
                            )
                        }
                        </tbody>
                    </table>
                </div>
                {/*分頁*/}
                <div className="flex justify-end w-full">
                    <Pagination totalPageSize={totalPageSize} currentPage={currentPage} pageSize={pageSize}
                                totalSize={totalSize}
                                onPageOnChange={(page) => onPaginationPageOnChange(page)}
                                onPreviousOnClick={onPaginationPreviousOnClick}
                                onNextOnClick={onPaginationNextOnClick}
                                onPageSizeChange={onPaginationPageSizeOnChange}/>
                </div>
            </div>
        </div>
    )
}
export default EmrMasterLog
