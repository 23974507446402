import React, {useEffect, useMemo, useRef, useState} from 'react';
import {t} from "i18next";
import SloganEditPopup from "../../components/Regs/MedicalMessageManagement/EditPopup";
import {ApiErrorStatusEnum, arrayIsEmpty, fuzzySearchObjects, objectIsEmpty, stringIsEmpty} from "edah_utils/dist";
import {DeletePopup} from "../../components/Popup/DeletePopup";
import {queryAllDivision, queryAllDoctor, queryJustNameByCategories} from "../../api/v1/Menu"
import {sloganQueryBySlognTypeAndDoctorNoAndDivNo, slognAddData, slognDelData, slognEditData} from '../../api/v1/Slogn';
import useToast from "../../hooks/useToast"
import useOutsideClick from '../../hooks/useOutsideClick';
import {
    AlertTypeEnum,
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Card,
    ColorEnum,
    IconEnum,
    Search,
    Select,
    Pagination,
    AdvancedDataGrid
} from 'edah-component/dist'
import {SearchTextField} from "../../components/SearchTextField/SearchTextField";

/**
 * 標語維護頁面
 * @return {JSX.Element}
 */
const MedicalMessageManagement = () => {
    // Show Toast
    const showToast = useToast()

    // 預設輸入欄資料
    const defaultQueryInputData = {
        category: '',
        doctorCode: '',
        departmentCode: ''
    }

    //控制新增編輯Popup
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    // 編輯標語
    const [editingSlogan, setEditingSlogan] = useState(null);

    // 查詢輸入欄位的資料 (顯示)
    const [displayQueryInputData, setDisplayQueryInputData] = useState(defaultQueryInputData)
    // 查詢輸入欄位的資料 (查詢後)
    const [queryInputData, setQueryInputData] = useState(defaultQueryInputData)
    //進階搜尋文字
    const [advSearchText, setAdvSearchText] = useState("")
    // 標語類別
    const [sloganType, setSloganType] = useState(null)
    // 醫師清單
    const [doctorList, setDoctorList] = useState(null)
    // 科別清單
    const [departmentList, setDepartmentList] = useState(null)
    // 是否顯示醫師下拉選單
    const [showDoctorDropDown, setShowDoctorDropDown] = useState(false)
    // 是否顯示科別下拉選單
    const [showDepartmentDropDown, setShowDepartmentDropDown] = useState(false)
    // 醫師下拉選項
    const [doctorOptionList, setDoctorOptionList] = useState(null)
    // 科別下拉選項
    const [departmentOptionList, setDepartmentOptionList] = useState(null)

    // 標語數據清單
    const [sloganData, setSloganData] = useState(null);
    // 標語數據進階搜尋清單
    const [advSearchSloganDataList, setAdvSearchSloganDataList] = useState(null)

    //控制刪除彈窗的顯示狀態
    const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

    //當前準備刪除的標語 ID
    const [deletingSloganId, setDeletingSloganId] = useState(null);

    //當前選中的標語
    const [selectedSlogan, setSelectedSlogan] = useState(null);
    // pagination控制變數
    const [paginationProps, setPaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    })
    //ref 用於指向醫師下拉菜單元素
    const dropdownDoctorRef = useRef(null)
    //ref 用於指向科別下拉菜單元素
    const dropdownDepartmentRef = useRef(null)
    useOutsideClick({
        ref: dropdownDoctorRef,
        handler: () => setShowDoctorDropDown(false),
    });
    useOutsideClick({
        ref: dropdownDepartmentRef,
        handler: () => setShowDepartmentDropDown(false),
    });

    // 表頭資料
    const columns = [
        {
            field: 'actions',
            headerName: '',
            width: 205,
            // 新增按鈕
            renderHeader: (params) => (
                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                    <Button
                        color={ColorEnum.Success}
                        variant={ButtonVariantEnum.Text}
                        size={ButtonSizeEnum.Large}
                        icon={IconEnum.Add}
                        onClick={handleAdd}  // 請確保在組件中定義 handleAdd 函數
                    >
                        {t('general.add')}
                    </Button>
                </div>
            ),
            // 操作按鈕
            renderCell: (params) => {
                const slogan = params.row;
                return (
                    <div
                        className={`flex space-x-2 justify-center h-full`}
                    >
                        <Button
                            sx={{marginRight: '0.75rem', whiteSpace: 'nowrap'}}
                            color={ColorEnum.Primary}
                            variant={ButtonVariantEnum.Text}
                            size={ButtonSizeEnum.Large}
                            icon={IconEnum.Edit}
                            onClick={(e) => {
                                e.stopPropagation(); // 防止事件冒泡
                                handleEdit(slogan);
                            }}
                        >
                            {t('general.edit')}
                        </Button>
                        <Button
                            sx={{whiteSpace: 'nowrap'}}
                            color={ColorEnum.Primary}
                            variant={ButtonVariantEnum.Text}
                            size={ButtonSizeEnum.Large}
                            icon={IconEnum.Delete}
                            onClick={(e) => {
                                e.stopPropagation(); // 防止事件冒泡
                                handleDelete(slogan.key);
                            }}
                        >
                            {t('general.delete')}
                        </Button>
                    </div>
                );
            },
        },
        {
            field: 'slognTypeName',
            headerName: t('Regs.MedicalMessageManagement.sloganCategory'),
            width: 205,
        },
        {
            field: 'slognName',
            headerName: t('Regs.MedicalMessageManagement.sloganName'),
            width: 205,
        },
        {
            field: 'doctorNo',
            headerName: t('Regs.MedicalMessageManagement.doctorCodeNum'),
            width: 205,
        },
        {
            field: 'doctorName',
            headerName: t('Regs.MedicalMessageManagement.doctorName'),
            width: 205,
        },
        {
            field: 'divNo',
            headerName: t('Regs.MedicalMessageManagement.departmentCodeNum'),
            width: 205,
        },
        {
            field: 'divName',
            headerName: t('Regs.MedicalMessageManagement.departmentName'),
            width: 205,
        },
        {
            field: 'apnName',
            headerName: t('Regs.general.timeSlot'),
            width: 205,
            renderCell: (params) => {
                const apnMap = { 1: '早', 2: '午', 3: '晚' };
                return apnMap[params.value] || params.value;
            },
        },
    ];

    /**
     * 處理 DataGrid 的行點擊事件
     * @param params {Object} - DataGrid 行點擊事件參數
     */
    const handleRowClick = (params) => {
        const clickedSlogan = params.row;
        handleSloganSelect(clickedSlogan);
    };

    /**
     * 處理標語選擇
     * @param slogan {object} - 被選中的標語對象
     */
    const handleSloganSelect = (slogan) => {
        const findedData = sloganData.find(item => item.key === slogan.key);
        setSelectedSlogan(prevSelected =>
            prevSelected && prevSelected.key === findedData.key ? null : findedData
        );
    };

    /**
     * 處理刪除標語的函數
     * @param key {number} 要刪除的標語的key
     * @return {void}
     */
    const handleDelete = (key) => {
        //找出要刪除的資料
        const findedData = sloganData.find(item => item.key === key)
        setDeletingSloganId(findedData.slognId);
        setIsDeletePopupOpen(true);
    };

    /**
     * 確認刪除標語的函數
     */
    const confirmDelete = () => {
        slognDelData({
            slognId: deletingSloganId
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得資料成功
            if (err === ApiErrorStatusEnum.Success) {
                // 顯示成功訊息
                showToast({message: '刪除成功', type: AlertTypeEnum.Success})

                setSelectedSlogan(null)
                setDeletingSloganId(null)

                //重新取標語明細
                querySlogn(queryInputData, 1, paginationProps.pageSize)
            } else { // 取得資料失敗
                setDeletingSloganId(null)
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
        setIsDeletePopupOpen(false);
    };

    /**
     * 取消刪除標語的函數
     */
    const cancelDelete = () => {
        setIsDeletePopupOpen(false);
        setDeletingSloganId(null);
    };

    /**
     * 標語類別的下拉選擇值變動時
     * @param value {string} 標語類別文字
     * @return {void}
     */
    const handleSelectCategoryOnChange = (value) => {
        setDisplayQueryInputData({...displayQueryInputData, category: value})
    }

    /**
     * 醫師代號方框的值變動時
     * @param e {Object} 事件
     * @return {void}
     */
    const handleInputDoctorNoOnChange = (e) => {
        setDisplayQueryInputData({...displayQueryInputData, doctorCode: e.target.value});
    }

    /**
     * 科別代號方框的值變動時
     * @param e {Object} 事件
     * @return {void}
     */
    const handleInputDepartmentNoOnChange = (e) => {
        setDisplayQueryInputData({...displayQueryInputData, departmentCode: e.target.value})
    }

    /**
     * 選取醫師下拉選項時
     * @param item {Object} 選取的選項
     * @return {void}
     */
    const handleDoctorOptionOnClick = (item) => {
        const value = item?.userNo && item?.userName ? `${item?.userNo} ${item?.userName}` : null

        setDisplayQueryInputData({
            ...displayQueryInputData,
            doctorCode: value
        })

        setShowDoctorDropDown(false)
    }

    /**
     * 選取科別下拉選項時
     * @param item {Object} 選取的選項
     * @return {void}
     */
    const handleDepartmentOptionOnClick = (item) => {
        const value = item?.divNo && item?.divName ? `${item?.divNo} ${item?.divName}` : null

        setDisplayQueryInputData({
            ...displayQueryInputData,
            departmentCode: value
        })

        setShowDepartmentDropDown(false)
    }

    /**
     * 處理編輯標語的函數
     * 設置當前編輯的標語並打開彈出窗口
     * @param slogan {object} 要編輯的標語對象
     * @return {void}
     */
    const handleEdit = (slogan) => {
        const findedData = sloganData.find(item => item.key === slogan.key)
        setEditingSlogan(findedData);
        setIsPopupOpen(true);
    };

    /**
     * 處理添加新標語的函數
     * 重置編輯狀態並打開彈出窗口
     * @return {void}
     */
    const handleAdd = () => {
        setEditingSlogan(null);
        setIsPopupOpen(true);
    };

    /**
     * 處理保存標語的函數
     * 根據是否為編輯模式來更新或添加標語
     * @param updatedSlogan {object} 更新後的標語對象
     * @return {void}
     */
    const handleSaveSlogan = (updatedSlogan) => {
        if (editingSlogan) {
            slognEditData({
                // 標語類識別碼
                slognId: editingSlogan.slognId,
                // 標語類別
                slognType: updatedSlogan.categoryNo,
                // 標語類別名稱
                slognName: updatedSlogan.name,
                // 醫師代碼
                doctorNo: !stringIsEmpty(updatedSlogan.doctorCode) ? updatedSlogan.doctorCode.split(" ")[0] : '',
                // 科別代碼
                divNo: !stringIsEmpty(updatedSlogan.departmentCode) ? updatedSlogan.departmentCode.split(" ")[0] : '',
                // 時段
                apn: stringIsEmpty(updatedSlogan.time) ? null : parseInt(updatedSlogan.time),
                // 標語內文
                slognContent: updatedSlogan.content,
                //版本鎖定
                lockVersion: editingSlogan.lockVersion
            }).then(res => {
                // 狀態 / 資料 / 訊息
                const {err, data, msg} = res
                // 取得資料成功
                if (err === ApiErrorStatusEnum.Success) {
                    // 顯示成功訊息
                    showToast({message: '儲存成功', type: AlertTypeEnum.Success})

                    setSelectedSlogan(null)
                    //重新取標語明細
                    querySlogn(queryInputData, 1, paginationProps.pageSize)

                    //關閉編輯彈窗
                    setIsPopupOpen(false);
                } else { // 取得資料失敗
                    // 顯示錯誤訊息
                    showToast({message: msg, type: AlertTypeEnum.Error})
                }
            })

        } else {
            let addData = {
                ...updatedSlogan
            }
            //01.單據，02.醫師_時段，03.科別，04.科別_時段，05.醫師_科別_時段
            if (addData.categoryNo !== '02' && addData.categoryNo !== '04' && addData.categoryNo !== '05' && addData.categoryNo !== '06') {
                addData.time = ''
            }

            if (addData.categoryNo !== '02' && addData.categoryNo !== '05' && addData.categoryNo !== '06') {
                addData.doctorCode = ''
            }

            if (addData.categoryNo !== '03' && addData.categoryNo !== '04' && addData.categoryNo !== '05' && addData.categoryNo !== '06') {
                addData.departmentCode = ''
            }
            slognAddData({
                // 標語類別
                slognType: addData.categoryNo,
                // 標語類別名稱
                slognName: addData.name,
                // 醫師代碼
                doctorNo: !stringIsEmpty(addData.doctorCode) ? addData.doctorCode.split(" ")[0] : '',
                // 科別代碼
                divNo: !stringIsEmpty(addData.departmentCode) ? addData.departmentCode.split(" ")[0] : '',
                // 時段
                apn: stringIsEmpty(addData.time) ? null : parseInt(addData.time),
                // 標語內文
                slognContent: addData.content
            }).then(res => {
                // 狀態 / 資料 / 訊息
                const {err, data, msg} = res
                // 取得資料成功
                if (err === ApiErrorStatusEnum.Success) {
                    // 顯示成功訊息
                    showToast({message: '新增成功', type: AlertTypeEnum.Success})

                    setSelectedSlogan(null)
                    //重新取標語明細
                    querySlogn(queryInputData, 1, paginationProps.pageSize)

                    //關閉新增彈窗
                    setIsPopupOpen(false);
                } else { // 取得資料失敗
                    // 顯示錯誤訊息
                    showToast({message: msg, type: AlertTypeEnum.Error})
                }
            })
        }
    };

    /**
     * 執行搜索
     * @return {void}
     */
    const handleSearch = () => {
        // 實現搜索邏輯
        querySlogn(displayQueryInputData, 1, paginationProps.pageSize)
    };

    /**
     * 取得標語類別Array資料
     * @return {void}
     */
    const getSloganTypeArray = () => {
        queryJustNameByCategories({
            // 標語類別
            categories: "SLOGN_TYPE"
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得資料成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定標語類別資料
                setSloganType(data)

                //初始設定 '全部'
                setDisplayQueryInputData({
                    ...displayQueryInputData,
                    category: '00'
                })
            } else { // 取得資料失敗
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得醫師下拉選項
     * @return {Array<Object>} 過濾後的醫師清單
     */
    const getDoctorOptionList = () => {
        const splitArray = displayQueryInputData.doctorCode ? displayQueryInputData.doctorCode.split(" ") : []
        if (splitArray.length > 2) {
            return []
        }

        if (splitArray.length === 2) {
            return doctorList.filter(doctor => doctor.userNo.includes(splitArray[0]) && doctor.userName.includes(splitArray[1]))
        } else {
            return fuzzySearchObjects(doctorList, displayQueryInputData.doctorCode)
        }
    }

    /**
     * 取得科別代碼下拉選項
     * @return {Array<Object>} 過濾後的科別清單
     */
    const getDepartmentOptionList = () => {
        const splitArray = displayQueryInputData.departmentCode ? displayQueryInputData.departmentCode.split(" ") : []
        if (splitArray.length > 2) {
            return []
        }

        if (splitArray.length === 2) {
            return departmentList.filter(department => department.divNo.includes(splitArray[0]) && department.divName.includes(splitArray[1]))
        } else {
            return fuzzySearchObjects(departmentList, displayQueryInputData.departmentCode)
        }
    }

    /**
     * 取得醫師清單
     * @return {void}
     */
    const getQueryAllDoctor = () => {
        queryAllDoctor({}).then(res => {
            //狀態/資料/訊息
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定醫師清單資料
                setDoctorList(data)
            } else {
                // 清空醫師清單
                setDoctorList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得科別清單
     * @return {void}
     */
    const getQueryAllDivision = () => {
        queryAllDivision({}).then(res => {
            //狀態/資料/訊息
            const {err, data, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定科別清單資料
                setDepartmentList(data)
            } else {
                // 清空科別清單
                setDepartmentList([])
                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 查詢標語資料
     * @param queryInputObj {Object} 查詢條件
     * @param page {Number} 第幾頁
     * @param pageSize {Number} 一頁幾筆資料
     * @return {void}
     */
    const querySlogn = (queryInputObj, page, pageSize) => {
        //把暫存的查詢條件寫入到真實的查詢條件
        setQueryInputData(queryInputObj)
        // 加入判斷，如果沒有輸入醫師代碼，則不帶入醫師代碼查詢條件
        const queryDoctorString = queryInputObj.doctorCode ? queryInputObj.doctorCode.trim() : ''
        // 加入判斷，如果沒有輸入科別代碼，則不帶入科別代碼查詢條件
        const queryDepartmentString = queryInputObj.departmentCode ? queryInputObj.departmentCode.trim() : ''
        sloganQueryBySlognTypeAndDoctorNoAndDivNo({
            // 標語類別
            slognType: queryInputObj.category === '00' ? '' : queryInputObj.category,
            // 醫師代碼
            doctorNo: queryDoctorString.indexOf(' ') > 0 ? queryDoctorString.split(' ')[0] : queryDoctorString,
            // 科別代碼
            divNo: queryDepartmentString.indexOf(' ') > 0 ? queryDepartmentString.split(' ')[0] : queryDepartmentString,
            pageNum: page,  //第幾頁
            pageSize: pageSize //一頁幾筆資料
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得資料成功
            if (err === ApiErrorStatusEnum.Success) {
                //在資料中加上key值
                const dataWithKeys = data.dataList ? data.dataList.map((item, index) => {
                    return {...item, key: index}
                }) : []

                setSloganData(dataWithKeys)

                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: data.totalItemSize,
                    totalPageSize: data.totalPageSize,
                    currentPage: page,
                    pageSize: pageSize
                })

            } else { // 取得資料失敗
                setSloganData([])
                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageSize: pageSize
                })

                // 顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }
    
    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     * @return {void}
     */
    const onPaginationPageOnChange = (page, pageSize) => {
        querySlogn(queryInputData, page, pageSize)
    }

    /**
     * 上一頁點擊事件
     * @return {void}
     */
    const onPaginationPreviousOnClick = () => {
        const page = (paginationProps.currentPage - 1) > 1 ? (paginationProps.currentPage - 1) : 1
        querySlogn(queryInputData, page, paginationProps.pageSize)
    }

    /**
     * 下一頁點擊事件
     * @return {void}
     */
    const onPaginationNextOnClick = () => {
        const page = (paginationProps.currentPage + 1) < paginationProps.totalPageSize ? (paginationProps.currentPage + 1) : paginationProps.totalPageSize
        querySlogn(queryInputData, page, paginationProps.pageSize)
    }

    /**
     * 每頁資料筆數變更事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onPaginationPageSizeOnChange = (e) => {
        // 設定每頁資料筆數
        querySlogn(queryInputData, 1, e.target.value)
    }

    /**
     * 進階搜尋文字改變時
     * @param e {Object} 事件 
     * @return {void}
     */
    const handleAdvanceSearchTextOnChange = (e) => setAdvSearchText(e.target.value)

    /**
     * 檢查資料是否被選擇
     * @param  data {Object} 要檢查的資料項
     * @return {Boolean}
     */
    const checkDataIsSelected = (data) => {
        if (objectIsEmpty(selectedSlogan)) {
            return false
        }

        return selectedSlogan.key === data.key;
    }

    /**
     * 取得標語進階搜尋結果清單
     * @return {void}
     */
    const getAdvanceSearchSloganData = () => {
        const tmpArray =
            arrayIsEmpty(sloganData) ? [] :
                sloganData.map((item) => (
                    {
                        //key
                        key: item.key, //這個不要過濾，不在顯示欄位內

                        //只copy要進階搜尋的欄位
                        //標語類別
                        slognTypeName: getSloganNameBySloganType(item.slognType),
                        //標語名稱
                        slognName: item.slognName,
                        //醫師代碼
                        doctorNo: item.doctorNo,
                        //醫師姓名
                        doctorName: item.doctorName,
                        //科別代碼
                        divNo: item.divNo,
                        //科別名稱
                        divName: item.divName,
                        //時段名稱
                        apnName: getApnNameByApnId(item.apn)
                    })
                )

        //開始模糊搜尋
        const findedData = fuzzySearchObjects(tmpArray, advSearchText, ["key"])
        setAdvSearchSloganDataList(findedData)

        if (!objectIsEmpty(selectedSlogan)) {
            //查找已被selected的資料是否在模糊搜尋結果的資料列內
            const haveSelected = arrayIsEmpty(findedData) ? false : findedData.some(item => item.key === selectedSlogan.key)
            if (!haveSelected) {
                //不存在的話將selected設為null
                setSelectedSlogan(null)
            }
        }
    }

    /**
     * 取得時段名稱
     * @param apnId {Number}  時段id
     * @return {String} 時段名稱
     */
    const getApnNameByApnId = (apnId) => {
        if (apnId === null) {
            return ''
        }
        switch (apnId) {
            case 1:
                return t("general.dateTime.timeslot.short.morning")
            case 2:
                return t("general.dateTime.timeslot.short.afternoon")
            case 3:
                return t("general.dateTime.timeslot.short.night")
        }
        return apnId.toString()
    }

    /**
     * 取得標語類別名稱
     * @param type {String} 時段id
     * @return {String} 標語類別名稱
     */
    const getSloganNameBySloganType = (type) => {
         //01.單據，02.醫師_時段，03.科別，04.科別_時段，05.醫師_科別_時段
        if (type !== '01' && type !== '02' && type !== '03' && type !== '04' && type !== '05' && type !== '06') {
            return ''
        }

        return t(`Regs.SloganTypeEnum.${type}`)
    }

    /**
     * 第一次執行時
     */
    useMemo(() => {
        if (arrayIsEmpty(sloganType)) {
            getSloganTypeArray()
        }

        if (arrayIsEmpty(doctorList)) {
            getQueryAllDoctor()
        }

        if (arrayIsEmpty(departmentList)) {
            getQueryAllDivision()
        }
    }, [])

    /**
     * 監聽advSearchText&sloganData
     */
    useEffect(() => {
        //取得進階查詢的資料
        getAdvanceSearchSloganData()
    }, [advSearchText, sloganData])

    /**
     * 監聽醫師代號輸入框變化
     */
    useEffect(() => {
        setDoctorOptionList(getDoctorOptionList())
    }, [displayQueryInputData.doctorCode, displayQueryInputData.departmentCode, doctorList])

    /**
     * 監聽科別代碼輸入框變化
     */
    useEffect(() => {
        setDepartmentOptionList(getDepartmentOptionList())
    }, [displayQueryInputData.departmentCode, displayQueryInputData.doctorCode, departmentList])

    return (
        <div className="p-4 bg-gray-100 w-full">
            {/* 搜索部分 */}
            <div className="mb-4 flex items-center space-x-4 text-left">
                {/*標語類別搜尋欄位*/}
                <Select
                    data={{
                        label: '標語類別',
                        options: [
                            {label: '全部', value: '00'},
                            ...(sloganType?.map((item) => ({
                                label: item.justnamedName,
                                value: item.justnamedNo
                            })) ?? [])]
                    }}
                    onChange={handleSelectCategoryOnChange}
                    value={displayQueryInputData.category}
                    displayEmpty={true}
                    notched = {true}
                />
                {/*醫師搜尋欄位*/}
                <Search
                    inputLabel={t('general.doctor')}
                    onChange={(_e, value) => {
                        handleDoctorOptionOnClick(value)
                    }}
                    onInputChange={(e) =>
                        handleInputDoctorNoOnChange(e)
                    }
                    disablePortal={false}
                    options={doctorOptionList || []}
                    getOptionLabel={(option) => `${option?.userNo} ${option?.userName}`}
                />
                {/*科別搜尋欄位*/}         
                <Search
                    inputLabel={t('general.department')}
                    onChange={(_e, value) => { 
                        handleDepartmentOptionOnClick(value) 
                    }}
                    onInputChange={(e) => 
                        handleInputDepartmentNoOnChange(e) 
                    }
                    disablePortal={false}
                    options={departmentOptionList || []}
                    getOptionLabel={(option) => `${option?.divNo} ${option?.divName}`}
                />
                {/* 查詢按鈕 */}
                <Button
                    sx={{marginRight: '0.5rem'}}
                    color={ColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                    size={ButtonSizeEnum.Medium}
                    onClick={handleSearch}>
                    {t('general.query')}
                </Button>
            </div>

            {/* 標語維護表格 */}
            <Card title={`${t('Regs.MedicalMessageManagement.sloganCategory')}`} sx={{ mb:1 }}>
                <div className="flex items-center mb-4">
                    {/* 進階搜尋輸入欄 */}
                    <SearchTextField
                        placeholder={t('general.advancedSearch')}
                        value={advSearchText}
                        onChange={(e) => handleAdvanceSearchTextOnChange(e)}
                    />
                </div>
                <AdvancedDataGrid
                    rows={advSearchSloganDataList || []}
                    columns={columns}
                    checkboxSelection={false}
                    disableRowSelectionOnClick={false}
                    disableColumnMenu={true}
                    onRowClick={handleRowClick}
                    rowClassName={(params) =>
                        selectedSlogan && selectedSlogan.key === params.row.key ? 'selected' : ''
                    }
                    height={'620px'}
                    getRowHeight={(params) => 56}
                />

                {/*分頁*/}
                <div className="flex justify-end w-full mt-1.5">
                    <Pagination
                        totalPageSize={paginationProps.totalPageSize}
                        page={paginationProps.currentPage}
                        pageSize={paginationProps.pageSize}
                        totalSize={paginationProps.totalItemSize}
                        onPageOnChange={(page, pageSize) => onPaginationPageOnChange(page, pageSize)}
                        showFirstButton
                        showLastButton
                    />
                </div>
            </Card>


            {/* 標語內文顯示 */}
            <Card title={`${t('Regs.MedicalMessageManagement.sloganContent')}`}>
                <p className="text-left min-h-[200px] max-h-[200px] overflow-y-auto">
                    {selectedSlogan ? selectedSlogan.slognContent : '請選擇一個標語以查看內容 '}
                </p>
            </Card>

            {/*新增編輯popup*/}
            <SloganEditPopup
                isOpen={isPopupOpen}
                sloganTypeList={sloganType}
                doctorList={doctorList}
                departmentList={departmentList}
                onClose={() => setIsPopupOpen(false)}
                onSave={handleSaveSlogan}
                editingSlogan={editingSlogan}/>

            {/*刪除popup*/}
            <DeletePopup
                content={t('sloganManagement.deleteConfirmation', '確定是否刪除該標語？')}
                show={isDeletePopupOpen}
                onYesButtonClick={confirmDelete}
                onNoButtonClick={cancelDelete}
                onClosePopupButtonClick={cancelDelete}
            />
        </div>
    );
};

export default MedicalMessageManagement;
