import {useEffect, useState, useRef, useMemo} from "react"
import {regsQueryActiveByCategoriesAndSearch} from "../../../api/v1/Regs";
import {ApiErrorStatusEnum} from "edah_utils/dist";
import {
    Dialog,
    DialogVariant,
    SizeEnum,
    Field,
    TextField,
    DatePicker,
    RadioGroup,
    Checkbox,
    Search,
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    ButtonColorEnum,
    AlertTypeEnum, DialogSizeEnums
} from "edah-component/dist";
import {t} from "i18next";
import dayjs from "dayjs";
import {time2String, stringIsEmpty} from "edah_utils/dist";
import useToast from "../../../hooks/useToast";

/**
 * 非初診彈窗
 * @param show
 * @param setShowNonFirstTimePatientEditPopup
 * @param saveEditPatient
 * @param patientData
 * @return {JSX.Element}
 */
const NonFirstTimePatientEditPopup = ({show = false,
                                          setShowNonFirstTimePatientEditPopup,
                                          saveEditPatient,
                                          patientData,
                                          baseData,
}) => {

    /**
     * 初始資料
     */
    const initialData = {
        patientName:'',
        birthDate:'',
        idNo:'',
        genderName:'',
        homePhoneNo:'',
        phoneNo:'',
        gender:'',
        idTypeNo:'',
        idTypeNoName:'',
        nationality:'',
        adrArea:'',
        adrAreaName:'',
        contactAddress:'',
        aborigineFlag: false,
        nativeptFlag: false
    }

    /**
     * Show Toast
     */
    const showToast = useToast();
    /**
     * 資料
     */
    const [data, setData] = useState(initialData)
    /**
     * 地區清單
     */
    const [areaAry, setAreaAry] = useState([])

    const [genderList, setGenderList] = useState([])
    /**
     * 打開提醒彈窗
     */
    const [showWarmingDialog, setShowWarmingDialog] = useState(false)
    /**
     * 彈窗訊息種類
     */
    const [warmingMessageType, setWarmingMessageType] = useState(0)

     /**
     * Input Refs
     */
     const patientNameRef = useRef(null);
     const birthDateRef = useRef(null);
     const idNoRef = useRef(null);
     const homephoneRef = useRef(null);
     const mobilePhoneRef = useRef(null);

    /**
     * 是否顯示彈窗
     * @return {void}
     */
    const handleCloseButtonOnClick = () => {
        // 關閉彈窗
        setShowNonFirstTimePatientEditPopup(false)
        // 把原本的資料帶回
        setData(patientData)
    }
    /**
     * 確認按鈕點擊事件
     * @return {void}
     */
    const handleConfirmButtonOnClick = () => {
        setShowNonFirstTimePatientEditPopup(false)
    }
    /**
     * 病人姓名變更事件
     * @param value {Object} 事件物件
     * @return {void}
     */
    const handlePatientNameOnChange=(value)=>{

        const newValue = (value.target.value || '')
        // 檢查第一個字符是否為數字
        if (newValue && /^\d/.test(newValue)) {
            setWarmingMessageType(0)
            setShowWarmingDialog(true)
            return;
        }

        setData({
            ...data,
            patientName: newValue
        })
    }

    /**
     * 病人性別變更事件
     * @param value {Object} 事件物件
     * @return {void}
     */
    const handlePatientGenderOnChange=(value)=>{
        const selectedGender = baseData?.genderList?.find(item => item.justnameNo === value.target.value);

        setData({
            ...data,
            genderName: selectedGender?.justnameName,
            gender: value.target.value
        })
    }

    /**
     * 病人身分證字號變更事件
     * @param value
     */
    const handleIdNoOnChange=(value)=>{
        setData({
            ...data,
            idNo: value.target.value
        })
    }

    /**
     * 地區選單點擊事件
     * @param option {Object} 點擊的選項
     * @return {void}
     */
    const handleAreaOptionOnClick = (option) => {
            setData({
                ...data,
                adrArea: option?.justnamedNo,
                adrAreaName: option?.justnamedName
            })

    }

    /**
     * 地址輸入變更事件
     * @param value {Object} 事件物件
     * @return {void}
     */
    const handleAddressInputOnChange=(value)=>{
        setData({
            ...data,
            contactAddress: value.target.value
        })
    }

    /**
     * 住家電話輸入變更事件
     * @param value {Object} 事件物件
     * @return {void}
     */
    const handleHomePhoneInputOnChange=(value)=>{
        setData({
            ...data,
            homePhoneNo: value.target.value
        })
    }

    /**
     * 手機輸入變更事件
     * @param value {Object} 事件物件
     * @return {void}
     */
    const handleMobilePhoneInputOnChange=(value)=>{
        setData({
            ...data,
            phoneNo: value.target.value
        })
    }

    /**
     * 是否為原住民變更事件
     * @param e {Event}
     * @param e {Object} 事件物件
     * @return {void}
     */
    const handleAborigineFlagOnChange = (e) => {        
        setData({
            ...data,
            aborigineFlag: e.target.checked
        })
    }

    /**
     * 是否為原鄉人變更事件
     * @param e {Object} 事件物件
     * @return {void}
     */
    const handleNativeptFlagOnChange = (e) => {
        setData({
            ...data,
            nativeptFlag: e.target.checked
        })
    }

    /**
     * 國籍變更事件
     * @param value {Object} 事件物件
     * @return {void}
     */
    const handleNationnalityOnChange=(value)=>{
        setData({
            ...data,
            nationality: value.target.value
        })
    }

    /**
     * 關閉姓名提示事件
     * return {void}
     */
    const handleCloseWarmingDialogOnClick = () => {
        setShowWarmingDialog(false)
    }

    /**
     * 存檔送出變更資料事件
     * @return {void}
     */
    const handleSavePatientInformationOnClick = ()=> {

        // 檢查必填欄位
        if (stringIsEmpty(data.patientName) || 
            stringIsEmpty(data.idNo) || 
            stringIsEmpty(data.birthDate) || 
            (stringIsEmpty(data.homePhoneNo) && stringIsEmpty(data.phoneNo))) {

            // 根據不同欄位顯示不同提示
            switch (true) {
                case stringIsEmpty(data.patientName):
                    showToast({message: "姓名欄位為必填欄位，不得為空", type: AlertTypeEnum.Warning})
                    patientNameRef.current?.focus();
                    break;
                case stringIsEmpty(data.idNo):
                    showToast({message: "身分證號欄位為必填欄位，不得為空", type: AlertTypeEnum.Warning})
                    idNoRef.current?.focus();
                    break;
                case stringIsEmpty(data.birthDate):
                    showToast({message: "出生日期欄位為必填欄位，不得為空", type: AlertTypeEnum.Warning})
                    birthDateRef.current?.focus();
                    break;
                case stringIsEmpty(data.homePhoneNo) && stringIsEmpty(data.phoneNo):
                    showToast({message: "住家號碼或手機號碼至少填寫一個", type: AlertTypeEnum.Warning})
                    homephoneRef.current?.focus();
                    break;
            }

            return;
        }

        //檢測手機號碼格式: 09開頭+後面8個數字
        const regex = /^09\d{8}$/;
        if (!stringIsEmpty(data.phoneNo) && !regex.test(data.phoneNo)) {
            showToast({message: "手機號碼格式不正確", type: AlertTypeEnum.Warning})
            return
        }

        // 呼叫父層的存檔事件
        saveEditPatient(
            {
            patientName:data.patientName,
            birthDate:time2String(data.birthDate, "YYYY-MM-DD 00:00:00"),
            idNo:data.idNo,
            genderName:data.genderName,
            gender: data.gender,
            homePhoneNo:data.homePhoneNo,
            phoneNo:data.phoneNo,
            adrArea:data.adrArea,
            contactAddress:data.contactAddress,
            nationality:data.nationality,
            aborigineFlag:data.aborigineFlag,
            nativeptFlag:data.nativeptFlag,
            idTypeNo:data.idTypeNo
            }
        )

        // 關閉彈窗
        setShowNonFirstTimePatientEditPopup(false)
    }


    /**
     * 計算年齡
     * @param birthdate {String} 出生日期
     * @return {String} 年齡
     */
    const calculateDetailedAge = (birthdate) => {
        const birthDate = new Date(birthdate)
        const today = new Date()

        let ageYears = today.getFullYear() - birthDate.getFullYear()
        let ageMonths = today.getMonth() - birthDate.getMonth()
        let ageDays = today.getDate() - birthDate.getDate();

        // 調整月份和年份
        if (ageDays < 0) {
            // 如果天數負數，從上個月減去一個月，並從該月加上該月的總天數
            ageMonths--;
            const previousMonth = new Date(today.getFullYear(), today.getMonth(), 0); // 取得上個月最後一天
            ageDays += previousMonth.getDate(); // 加上上個月的天數
        }

        if (ageMonths < 0) {
            // 負數月份轉為正，並從年份中減去1
            ageYears--;
            ageMonths += 12;
        }

        return `${ageYears}y${ageMonths}m${ageDays}d`;
    };

    /**
     * 計算地區選單的值
     */
    const areaValue = useMemo(() => {
        if (!data.adrArea) return null;

        return {
            justnamedNo: data.adrArea,
            justnamedName: data.adrAreaName
        };
    }, [data.adrArea, data.adrAreaName]);

    /**
     * DialogContent
     * @return {JSX.Element}
     */
    const DialogContent = ()=>(
        <>
            <div className={'flex flex-col gap-2'}>
                {/* 病歷號 */}
                <Field label={t('Regs.general.medicalRecordNumber')}>
                    {data.patientId || ''}
                </Field>
                {/* 姓名 */}
                <Field label={t('general.username')} isRequired>
                    <TextField inputRef={patientNameRef} value={data.patientName || ''} onChange={handlePatientNameOnChange} error={stringIsEmpty(data.patientName)} />
                </Field>
                {/* 性別 */}
                <Field label={t('general.gender.name')}>
                    <RadioGroup row={true} size={SizeEnum.Medium}
                                optionProps={{options:genderList}}
                                value={data.gender || ''}
                                onChange={handlePatientGenderOnChange}/>
                </Field>
                {/* 生日 */}
                <Field label={t('general.birthday')} isRequired>
                    <DatePicker label={null} inputRef={birthDateRef}
                              value={data.birthDate ? dayjs(data.birthDate) : null} 
                              onChange={(value) => {
                                setData({
                                    ...data,
                                    birthDate: value ? value.format('YYYY-MM-DD') : ''
                                })
                              }}/>
                </Field>
                {/* 年齡 */}
                <Field label={t('general.age')}>
                    {data.birthDate ? calculateDetailedAge(data.birthDate) : ''}
                </Field>
                {/* 身分證字號 */}
                <Field label={t('general.idNumber')} isRequired>
                    <TextField inputRef={idNoRef} value={data.idNo || ''} onChange={handleIdNoOnChange}  error={stringIsEmpty(data.idNo)}/>
                </Field>
                {/* 地址 */}
                <Field label={t('general.contactAddress')} childrenSx={{display:"flex", gap:'0.5rem'}}>
                    {/* 地區 */}
                    <Search
                        value={areaValue}
                        options={areaAry}
                        getOptionLabel={(option)=>`${option.justnamedNo} ${option.justnamedName}`}
                        onChange={(_e,value)=>handleAreaOptionOnClick(value)}
                    />
                    {/* 地址 */}
                    <TextField 
                        value={data.contactAddress || ''} 
                        onChange={handleAddressInputOnChange}
                    />
                </Field>
                {/* 家用電話號碼 */}
                <Field label={t('general.telephone')}>
                    <TextField
                        inputRef={homephoneRef} 
                        value={data.homePhoneNo || ''} 
                        error={stringIsEmpty(data.homePhoneNo) && stringIsEmpty(data.phoneNo)}
                        onChange={handleHomePhoneInputOnChange}
                    />
                </Field>
                {/* 手機號碼 */}
                <Field label={t('general.mobilePhone')}>
                    <TextField
                        inputRef={mobilePhoneRef} 
                        value={data.phoneNo || ''} 
                        error={stringIsEmpty(data.homePhoneNo) && stringIsEmpty(data.phoneNo)}
                        onChange={handleMobilePhoneInputOnChange}
                    />
                </Field>
                {/* 優待身份 */}
                <Field label={'優待身份'}>
                    {''}
                </Field>
                {/* 國籍 */}
                <Field label={'國籍'}>
                    <RadioGroup row={true} size={SizeEnum.Medium}
                                optionProps={{options:[{label:'本國',value:1},{label:'外國',value:2}]}}
                                value={data.nationality || ''}
                                onChange={handleNationnalityOnChange}
                    />
                </Field>
                {/* 原住民、原鄉人 */}
                <Field label={''} isShowColon={false}>
                    <Checkbox label={'原住民'} checked={Boolean(data.aborigineFlag)} onChange={handleAborigineFlagOnChange} />
                    <Checkbox label={'原鄉人'} checked={Boolean(data.nativeptFlag)}  onChange={handleNativeptFlagOnChange} />
                </Field>
            </div>
                
    </>)

    /**
     * 姓名提示訊息
     * @returns {string}
     */
    const nameWarmingMessage = ()=>{

        // 根據旗標顯示不同的姓名錯誤提示
        switch (warmingMessageType) {
            case 0:
                return '姓名第一個字不可為數字';
            case 1:
                return '姓名第一個字不可為空格';
            case 2:
                return '姓名第一個字不可為特殊符號';
            default:
                return '輸入資料有誤';
        }
    }

    /**
     * 監聽資料變更
     */
    useEffect(() => {
        if(patientData) {
            // 確保所有 initialData 的欄位都有值，如果 patientData 沒有提供就使用 initialData 的預設值
            const safeData = {
                ...initialData,  // 先放入所有預設值
                ...patientData   // 然後用後端資料覆蓋有的欄位
            };
            
            // 確保所有值都不是 null
            Object.keys(safeData).forEach(key => {
                if (safeData[key] === null) {
                    safeData[key] = '';
                }
            });
            // 設定資料
            setData(safeData);
        }
    },[patientData])

    /**
     * 監聽顯示彈窗，並取得區域碼查詢、性別
     */
    useEffect(() => {
        if(show) {
        regsQueryActiveByCategoriesAndSearch({
            categories: 'AREA',
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setAreaAry(res.data)
            }else {
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })}

        // 取得性別
        const transformedGenderList = baseData?.genderList?.map(item => ({
            label: item.justnameName,
            value: item.justnameNo
        })) || [];

        // 設定性別列表
        setGenderList(transformedGenderList);

    },[show])

    return (
        <>
            <Dialog
                open={show}
                title={'病歷簡易維護'}
                variant={DialogVariant.CUSTOM}
                paperStyleProps={{width: 'fit-content'}}
                onClose={handleCloseButtonOnClick}
                content={DialogContent()}
                onConfirm={handleConfirmButtonOnClick}
                footerContent={
                    <Button
                        color={ButtonColorEnum.Primary}
                        variant={ButtonVariantEnum.Contained}
                        size={ButtonSizeEnum.Medium}
                        onClick={handleSavePatientInformationOnClick}
                        text={t('general.submit')}
                    />

                }
            />
            {/*姓名提醒彈窗 */}
            <Dialog
                open={showWarmingDialog}
                title={'提醒'}
                variant={DialogVariant.REMIND}
                paperStyleProps={{width:DialogSizeEnums.SM}}
                content={nameWarmingMessage()}
                onClose={handleCloseWarmingDialogOnClick}
            />
        </>)
}

export default NonFirstTimePatientEditPopup