import React, {useState, useRef, useCallback, useEffect} from "react";
import {t} from "i18next"
import {
    PopupModeEnum,
    RegsMedicalTypeEnum,
    RegsWeekReservationLimitEnum,
    stringIsEmpty,
} from "edah_utils/dist"
import {
    Button,
    ColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    List
} from 'edah-component/dist'

/**
 * @param {Array} list 預約列表
 * @param {Number} timeslot 時段
 * @param {Number} week 週數
 * @param {Object} baseData 基本資料
 * @param {Boolean} isAddAppointment 是否顯示新增問診
 * @param {Function} onAddAppointment 新增問診函式
 * @param showPopup
 */
const ScheduleTimeBox = ({
                             list,
                             timeslot,
                             week,
                             baseData,
                             isAddAppointment,
                             onAddAppointment,
                             showPopup,
                             showTooltip,
                             isSearched,
                             globalTooltipInfo,
                             onTooltipShow,
                             onTooltipHide
                         }) => {

    // 控制 tooltip 的顯示
    const [activeTooltip, setActiveTooltip] = useState(null);
    const timeoutRef = useRef(null);// 儲存延遲操作的 Timeout

    // 清除所有 timeout
    const clearAllTimeouts = useCallback(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
            timeoutRef.current = null;
        }
    }, []);

    // 在組件卸載時清除所有 timeout
    useEffect(() => {
        return () => {
            clearAllTimeouts();
        };
    }, [clearAllTimeouts]);

    // 當 showTooltip 變更時，確保清除所有 tooltip
    useEffect(() => {
        if (!showTooltip) {
            clearAllTimeouts();
            setActiveTooltip(null);
        }
    }, [showTooltip, clearAllTimeouts]);

    // 處理滑鼠進入
    const handleMouseEnter = (index) => {
        if (isAddAppointment) return;
        clearAllTimeouts();
        setActiveTooltip(index);
        onTooltipShow(index);
    };

    // 處理滑鼠離開
    const handleMouseLeave = () => {
        clearAllTimeouts();
        setActiveTooltip(null);
        onTooltipHide();
    };

    // 處理整個 List 的滑鼠離開事件
    const handleListMouseLeave = () => {
        clearAllTimeouts();
        setActiveTooltip(null);
        onTooltipHide();
    };

    // 立即隱藏 Tooltip
    const hideTooltip = () => {
        clearAllTimeouts();
        setActiveTooltip(null);
        onTooltipHide();
    };

    /**
     * 雙擊Record時
     * @param record {Object} 預約資料
     */
    const handleOnDoubleClick = async (record) => {
        onTooltipHide(); // 立即隱藏 tooltip
        await new Promise(resolve => setTimeout(resolve, 200)); // 確保 tooltip 隱藏後再顯示 popup
        showPopup({
            // 診間ID
            clinicId: record.clinicId,
            // 預約最大看診人次
            maxPatient: record.limit,
            // 現場最大看診人次
            onsiteLimit: record.onsiteLimit,
            // 看診時間(人/分)
            consultationTime: record.slotMin,
            // 預約方式
            reservationMethod: parseInt(record.giveNumType),
            // 調病歷
            canGetMedicalRecords: record.sentChartFlag,
            // 給號方式
            numberGivingMethod: record.noType,
            // 預約限制
            reservationLimit: record.reserveLimitType,
            // 收費別
            chargeType: `${record.cashType} ${baseData.cashTypeList.find(cashType => cashType.cashType === `${record.cashType}`).cashName}`,
            // 診別
            medicalType: record.clinicType,
            // 開放網掛/App掛號
            webOrAppBooking: record.webapptFlag,
            // 網掛/App取消看診進度
            webOrAppCancel: record.showProcessFlag,
            // 網路預約期限
            onlineBookingDeadline: record.webapptMonth,
            // 看診項目
            mediItem: record.clinicMarkNo,
            // 診間地點
            clinicLocation: record.clinicLocation,
            // 注意事項
            notes: record.weekNotice,
            // 診間標語
            clinicSlogan: record.clinicSlogan,
            // 醫師
            doctor: `${record.doctorNo} ${baseData.doctorList.find(doctor => doctor.userNo === record.doctorNo).userName}`,
            // 科別
            department: `${record.divNo} ${baseData.divisionList.find(department => department.divNo === record.divNo).divName}`,
            // 診間名稱
            clinicName: record.clinicName,
            // 診室號
            clinicNo: record.clinicNo,
            // 時段
            timeslot: record.apn,
            // 當前日期
            date: record.encounterDate ? (record.encounterDate.split(' ')[0]).replaceAll('-', '/') : '',
            // 星期
            week: record.encounterDate ? new Date(record.encounterDate.split(' ')[0]).getDay() : '',
            // 代理醫師
            locumDoctor: stringIsEmpty(record.locumDoctor) ? record.locumDoctor : `${record.locumDoctor} ${baseData.doctorList.find(doctor => doctor.userNo === record.locumDoctor).userName}`,//record.locumDoctor ? `${record.locumDoctor}` : '',
            // 停診
            stopClinicFlag: record.stopClinicFlag,
            // 停診原因
            stopClinicReason: record.stopClinicReason,
            // 停診原因說明
            stopClinicDesc: record.stopClinicDesc,
            // 停代診公告
            stopClinicBulletinFlag: record.stopClinicBulletinFlag,
            // 假日展班
            holidayexclFlag: record.holidayexclFlag,
            // 建立時間
            createDatetime: record.createDatetime,
            // 建立者
            createUser: record.createUser,
            //修改時間
            modifyDatetime: record.modifyDatetime,
            //修改者
            modifyUser: record.modifyUser,

            weekId: record.weekId,
            // 鎖定版本
            lockVersion: record.lockVersion
        }, PopupModeEnum.Modify);
    }

    /**
     * 科別/診間顯示組件
     */
    const DepartmentDisplay = ({departmentName, clinicName}) => (
        <div>
            {`${departmentName}/${clinicName}`}
        </div>
    );


    /**
     * 取得tooltip內容
     * @param index
     */
    const renderTooltip = (index) => {
        return activeTooltip === index ? getRecordInfo(index) : null;
    };

    // 監聽彈窗顯示和關閉事件
    useEffect(() => {
        const handlePopupClose = () => {
            hideTooltip();
        };

        // 清理 tooltip
        if (isAddAppointment) {
            hideTooltip();
        }

        window.addEventListener('popupClose', handlePopupClose);
        return () => {
            window.removeEventListener('popupClose', handlePopupClose);
            clearTimeout(timeoutRef.current);
        };
    }, [isAddAppointment]);

    /**
     * 取得Record內容
     * @return {Object}
     */
    const renderRowContent = (index) => {
        const record = list[index];
        const {doctorContent, departmentName, clinicName} = getRecordContent(record);
        const isStopped = record.stopClinicFlag; // 判斷是否停診

        return {
            name: (
                <div
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                >
                    <DoctorDisplay
                        doctorContent={doctorContent}
                        isStopped={isStopped}/>
                </div>
            ),
            department: (
                <div
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={handleMouseLeave}
                >
                    <DepartmentDisplay
                        departmentName={departmentName}
                        clinicName={clinicName}/>
                </div>
            ),
            note: record.weekNotice ? <span>*備註</span> : null, // 當 weekNotice 不為空時顯示 *備註
            isStopped, // 傳遞停診狀態
        };
    };


    /**
     * 取得Record資訊
     */
    const getRecordInfo = (index) => {
        const record = list[index];
        // 預約最大看診人數
        const limit = record.limit
        // 現場最大看診人次
        const onSiteLimit = record.onsiteLimit
        // 看診時間(分/人)
        const singleViewMin = record.slotMin
        // 預約方式
        const giveNumType = record.giveNumType
        // 預約方式字串
        const giveNumTypeStr = t(`page.MonthMaintenance.ReservationMethod.${giveNumType}`)
        // 調病歷
        const sentChartFlag = record.sentChartFlag
        // 調病歷字串
        const sentChartFlagStr = sentChartFlag ? t('general.yes') : t('general.no')
        // 給號方式
        const noType = record.noType
        // 給號方式字串
        let noTypeStr = null;
        if (baseData && baseData.noTypeList) {
            noTypeStr = baseData.noTypeList.find(no => no.noType === noType)?.typeName;
        }
        // 預約限制類型
        const reserveLimitType = record.reserveLimitType
        // 取得預約限制類型字串
        const reserveLimitTypeStr = (type) => {
            //結果
            let result = ''
            switch (type) {
                //一般
                case RegsWeekReservationLimitEnum.Normal:
                    result = '一般'
                    break
                // 限醫生
                case RegsWeekReservationLimitEnum.DoctorOnly:
                    result = '限醫生'
                    break
                //限醫生本人
                case RegsWeekReservationLimitEnum.DoctorSelfOnly:
                    result = '限醫生本人'
                    break
            }
            return result
        }

        // 診別
        const medicalType = record.clinicType
        // 取得診別字串
        const medicalTypeStr = (type) => {
            let result = ''
            switch (type) {
                //門診
                case RegsMedicalTypeEnum.OutpatientClinic:
                    result = '門診'
                    break
                //外檢
                case RegsMedicalTypeEnum.MedicalCheckup:
                    result = '外檢'
                    break
                //預防保健
                case RegsMedicalTypeEnum.PreventiveHealthcare:
                    result = '預防保健'
                    break
            }

            return result
        }

        // 取得看診項目字串
        const medicalItemStr = (type) => {
            let result = ''
            switch (type) {
                //巡迴醫療
                case 'A':
                    result = '巡迴醫療'
                    break
                //視訊問診
                case 'B':
                    result = '視訊問診'
                    break
            }
            return result
        }
        // 開放網掛/APP
        const webAppFlag = record.webapptFlag
        // 開放網掛/APP字串
        const webAppFlagStr = webAppFlag ? t('general.yes') : t('general.no')
        // 網掛/App取消看診進度
        const showProcessFlag = record.showProcessFlag
        // 網掛/App取消看診進度字串
        const showProcessFlagStr = showProcessFlag ? t('general.yes') : t('general.no')
        // 網掛預約期限(月)
        const webApptMonth = record.webapptMonth
        // 網掛預約期限字串
        const webAppMonthStr = `${webApptMonth}${t('general.dateTime.month')}`
        // 看診項目
        const mediItem = record.clinicMarkNo
        // 診室地點
        const clinicLocation = record.clinicLocation
        // 取得診室地點字串
        const clinicLocationStr = clinicLocation ? clinicLocation : ''
        // 醫師
        const doctor = baseData?.doctorList?.find(doctor => doctor.userNo === record.doctorNo).userName
        //代班醫師
        const locumDoctor = baseData?.doctorList?.find(doctor => doctor.userNo === record.locumDoctor);
        // 取得代班醫師字串
        const locumDoctorStr = locumDoctor ? locumDoctor.userName : '';
        // 停診
        const stopClinicFlag = record.stopClinicFlag
        // 停診字串
        const stopClinicFlagStr = stopClinicFlag ? t('general.yes') : t('general.no')
        // 停代診公告
        const stopClinicBulletinFlag = record.stopClinicBulletinFlag
        // 停代診公告字串
        const stopClinicBulletinFlagStr = stopClinicBulletinFlag ? t('general.yes') : t('general.no')
        // 週班注意事項
        const weekRemark = record.weekNotice ? record.weekNotice : ''


        return (
            <>
                <p>預約最大看診人次:{limit}</p>
                <p>現場最大看診人次:{onSiteLimit} </p>
                <p className="line-clamp-3 text-wrap break-all">注意事項:{weekRemark} </p>
                <p>給號方式: {noTypeStr}</p>
                <p>預約方式: {giveNumTypeStr}</p>
                <p>代診醫師: {locumDoctorStr}</p>
                <p>原開診醫師: {doctor}</p>
                <p>停診: {stopClinicFlagStr}</p>
                <p>停代診公告: {stopClinicBulletinFlagStr}</p>
                <p>看診項目: {medicalItemStr(mediItem)}</p>
                <p>看診時間(分/人):{singleViewMin} </p>
                <p>預約限制: {reserveLimitTypeStr(reserveLimitType)}</p>
                <p>診別: {medicalTypeStr(medicalType)}</p>
                <p>網掛/APP取消看診進度: {showProcessFlagStr}</p>
                <p>網掛預約期限: {webAppMonthStr}</p>
                <p>{t("Regs.general.clinicLocation")}: {clinicLocationStr}</p>
                {/*<p>調病歷: {sentChartFlagStr}</p>*/}
                {/*<p>開放掛號/APP: {webAppFlagStr}</p>*/}
            </>
        )
    };
    /**
     * 取得Record內容
     * @param {Object} record - 單一預約紀錄
     * @return {Object}
     */
    const getRecordContent = (record) => {
        if (record !== null && baseData !== null) {
            let doctorContent = '';
            const department = baseData.divisionList.find(department => department.divNo === record.divNo) || {};
            const departmentName = department.divName ?? "Unknown";
            const clinicName = record.clinicName ?? "Unknown";

            if (!stringIsEmpty(record.locumDoctor)) {
                // 有代班醫生
                const locumDoctor = baseData.doctorList.find(doctor => doctor.userNo === record.locumDoctor) || {};
                const originalDoctor = baseData.doctorList.find(doctor => doctor.userNo === record.doctorNo) || {};
                doctorContent = `${locumDoctor.userName ?? "Unknown"} [代] 原:${originalDoctor.userName ?? "Unknown"}`;
            } else {
                const doctor = baseData.doctorList.find(doctor => doctor.userNo === record.doctorNo) || {};
                doctorContent = doctor.userName ?? "Unknown";
            }

            return {
                doctorContent,
                departmentName,
                clinicName,
                isStopped: record.stopClinicFlag  // 直接返回 stopClinicFlag 的值
            };
        } else {
            return {
                doctorContent: '',
                departmentName: '',
                clinicName: '',
                isStopped: false
            };
        }
    };

    /**
     * 醫生顯示組件
     */
    const DoctorDisplay = ({doctorContent, isStopped, reserveLimitType}) => {
        const getReservationLimitLabel = () => {
            switch (reserveLimitType) {
                case RegsWeekReservationLimitEnum.DoctorOnly:
                    return '[限醫]';
                case RegsWeekReservationLimitEnum.DoctorSelfOnly:
                    return '[限本]';
                default:
                    return null;
            }
        };

        const reservationLimitLabel = getReservationLimitLabel();

        return (
            <div>
                <span>{doctorContent}</span>
                {isStopped && <span style={{color: '#C53030'}}> [停]</span>}
                {reservationLimitLabel && <span style={{color: '#2B6CB0'}}> {reservationLimitLabel}</span>}
            </div>
        );
    };

    /**
     * 顯示編輯視窗
     * @param e {Event} 事件
     * @return {void}
     */
    const handleBlockOnContextMenu = (e) => {
        //防止右鍵點擊時觸發右鍵選單
        e.preventDefault()
        //右鍵點擊時
        if (e.nativeEvent.button === 2) {
            //透過父層函式新增問診
            onAddAppointment(week, timeslot)
        }
    }

    /**
     * 點擊新增問診時
     */
    const handleNewAppointmentOnClick = () => {
        showPopup({
            week: week + 1,
            timeslot: timeslot
        })
    }

    return (
        <div className="w-full bg-white rounded-lg flex flex-col items-center py-2 px-1 relative"
             onContextMenu={(e) => handleBlockOnContextMenu(e)}>
            <div className="flex flex-col justify-center items-center w-full h-full space-y-1.5">
                {isSearched ? (
                    <> {isAddAppointment && (
                        <Button
                            sx={{marginX: "1.5rem", paddingY: '0.25rem'}}
                            color={ColorEnum.Primary}
                            variant={ButtonVariantEnum.Contained}
                            size={ButtonSizeEnum.Large}
                            onContextMenu={(e) => e.preventDefault()}
                            onClick={handleNewAppointmentOnClick}>
                            {t('page.MonthMaintenance.NewAppointment')}
                        </Button>
                    )}
                        {list.length ? (
                            <div onMouseLeave={handleListMouseLeave}>
                                <List
                                    height={isAddAppointment ? 200 : 240}
                                    width={220}
                                    itemCount={list.length}
                                    itemSize={60}
                                    renderRowContent={renderRowContent}
                                    renderTooltip={renderTooltip}
                                    onDoubleClick={(index) => handleOnDoubleClick(list[index])}
                                />
                            </div>
                        ) : (
                            <div
                                className="flex flex-col  text-gray-500 tracking-wide text-center items-center justify-center">
                                <p>查無相關資料</p>
                                <p>請右鍵新增問診</p>
                            </div>
                        )}
                    </>
                ) : (
                    <div className="flex items-center justify-center text-gray-500 tracking-wide text-center">
                        <p>請先進行查詢</p>
                    </div>
                )}

            </div>
        </div>
    )
}
export default ScheduleTimeBox
