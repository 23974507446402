//Import css &icons
import '../../components/ICCard/PatientInfo.scss'
import '../../components/TableList/tableList.scss'

//Import function
import React from 'react'
import PatientInfo from '../../components/ICCard/PatientInfo'
import {SwitchContainer} from '../../components/ICCard/InfoPage/SwitchContainer'
import ReadICCard from '../../components/ICCard/ReadICCard'
import {ReadICCardContextProvider} from '../../context/ReadICCardContext'

/**
 * 病人IC卡資料查詢頁面
 * @return {JSX.Element}
 */
const InfoPage = () => {
    return (
        <ReadICCardContextProvider>
            <div className="w-full bg-[#FAFAFA] mb-3">
                <div className="p-3 space-y-3">
                    <ReadICCard/>
                    {/*病人資料*/}
                    <PatientInfo/>
                    <SwitchContainer/>
                </div>
            </div>
        </ReadICCardContextProvider>
    )
}
export default InfoPage
