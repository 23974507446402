import {useState} from 'react'
import PatientInfo from '../../components/ICCard/PatientInfo'
import WriteICCardSection from '../../components/ICCard/WriteICCardPage/WriteICCardSection'
import OrderDataSection from '../../components/ICCard/WriteICCardPage/OrderDataSection'
import {ReadICCardContextProvider} from '../../context/ReadICCardContext'
import ReadICCard from '../../components/ICCard/ReadICCard'

const WriteICCardPage = () => {


    return (
        <ReadICCardContextProvider>
            <div className="w-full bg-[#FAFAFA] space-y-3 p-3 h-[calc(100vh-98px)] overflow-y-auto">
                <ReadICCard/>
                <PatientInfo/>
                <WriteICCardSection/>
                <OrderDataSection/>
            </div>
        </ReadICCardContextProvider>
    )
}

export default WriteICCardPage
