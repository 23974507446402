import React, {useContext, useEffect, useState} from 'react'
import {t} from 'i18next'
import '../../components/ICCard/PatientInfo.scss'
import {
    ApiErrorStatusEnum,
    convertMinGuoToGregorian,
    objectIsEmpty,
} from 'edah_utils/dist'
import {icHisWriteEmergentTel, icHisWriteNewBorn} from '../../api/v1/LocalService'
import {
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    ButtonColorEnum,
    Field,
    GrayColorEnum,
    DatePicker,
    TextField,
    SizeEnum,
    Checkbox,
    Card,
    AlertTypeEnum,
} from 'edah-component/dist'
import dayjs from 'dayjs'
import useToast from '../../hooks/useToast'
import {ReadICCardContext} from '../../context/ReadICCardContext'
import {IconEnum} from 'edah-component'
import useICCard from '../../hooks/useICCard'

/**
 * 病人資料顯示區塊
 * @param refreshICCardInfo {function} 刷新IC卡資料
 */
const PatientInfo = () => {
    const {icCardData, isRead, isPhysical} = useContext(ReadICCardContext)
    // 控制是否可編輯日期及胎兒數字
    const [isEditable, setIsEditable] = useState(false)

    // 控制是否可編輯電話
    const [isPhoneEdit, setIsPhoneEdit] = useState(false)

    // 新生兒資料修改部分
    const [modifyChildData, setModifyChildData] = useState({})

    // 新生兒資料
    const [info, setInfo] = useState({})

    const [emergentTel, setEmergentTel] = useState('')

    const showToast = useToast()

    /**
     *  新生兒出生日期變更
     *  @param date {dayjs} 事件
     *  @return {void}
     */
    const handleChildBirthDateOnChange = (date) => {
        // 只有編輯模式才可以修改
        if (!isEditable) {
            return
        }

        // 設定新生兒出生日期
        setModifyChildData({
            childBirthDate: date,
            childBornNote: modifyChildData.childBornNote,
        })
    }

    /**
     * 新生兒胞胎註記數量變更
     * @param event {Event} 事件
     * @return {void}
     */
    const handleChildBornNoteOnChange = (event) => {
        // 只有編輯模式才可以修改
        if (!isEditable) {
            return
        }

        // 設定新生兒胞胎註記
        setModifyChildData({
            childBirthDate: modifyChildData.childBirthDate,
            childBornNote: event.target.value,
        })
    }

    /**
     * 新生兒Icon點擊事件
     * @return {void}
     */
    const handleChildIconOnClick = () => {
        // 取得新的狀態
        const newResult = !isEditable

        // 取得新生兒資料
        const newData = newResult
            ? {
                //新生兒出生日期
                childBirthDate: icCardData.childBirthDate,
                //新生兒胞胎註記
                childBornNote: icCardData.childBornNote,
            }
            : {}

        // 如果上一步是儲存狀態
        if (!newResult) {
            //比較值是否有變更
            const keys = Object.keys(modifyChildData)
            //是否有被變更過資料
            const isModify = keys.every(
                (key) => modifyChildData[key] === icCardData[key]
            )
            //有修改過則呼叫API
            if (!isModify) {
                icHisWriteNewBorn({
                    //身分證號
                    pPatientID: icCardData.idNo,
                    //生日(民國年月日)
                    pPatientBirthDate: icCardData.birthDate,
                    //新生兒出生日期(民國年月日)
                    pNewBornDate: modifyChildData.childBirthDate,
                    //新生兒胞胎註記
                    pNoOfDelivered: modifyChildData.childBornNote,
                }).then((res) => {
                    if (res.err === ApiErrorStatusEnum.Success) {
                        // 成功的話直接修改當前的資料
                        setInfo({
                            ...info,
                            childBirthDate: modifyChildData.childBirthDate,
                            childBornNote: modifyChildData.childBornNote,
                        })
                    } else {
                        showToast({
                            message: res.msg,
                            type: AlertTypeEnum.Error,
                        })
                    }
                })
            }
        }

        // 設定新狀態
        setIsEditable(newResult)
        // 設定新生兒資料
        setModifyChildData(newData)
    }

    const getBirthDate = () => {
        return isEditable
            ? dayjs(modifyChildData.childBirthDate)
            : dayjs(info.childBirthDate)
    }

    const handlePhoneChange = (event) => {
        setEmergentTel(event.target.value)
    }

    /**
     * 編輯緊急聯絡電話
     * @return {void}
     */
    const handlePhoneEdit = () => {
        const currentIsPhoneEdit = !isPhoneEdit
        setIsPhoneEdit(currentIsPhoneEdit)
        // 編輯
        if (currentIsPhoneEdit) {
            setEmergentTel(info.emergentTel)
        } else {
            // 結束編輯(儲存)
            if (emergentTel === info.emergentTel) {
                return
            }
            setInfo({
                ...info,
                emergentTel: emergentTel,
            })
            icHisWriteEmergentTel({
                pPatientID: icCardData.idNo,
                pPatientBirthDate: icCardData.birthDate,
                pEmergentTel: emergentTel,
            }).then((res) => {
                if (res.err === ApiErrorStatusEnum.Success) {
                    showToast({
                        message: res.msg,
                        type: AlertTypeEnum.Success,
                    })
                } else {
                    showToast({
                        message: res.msg,
                        type: AlertTypeEnum.Error,
                    })
                }
            })
        }
    }

    /**
     * 資料變更時監聽
     */
    useEffect(() => {
        setInfo(icCardData)
        setEmergentTel(icCardData.emergentTel)
    }, [icCardData])

    return (
        <div className="w-full">
            <Card childrenSX={{px: 1, py: 2}}>
                {isRead && !objectIsEmpty(info) ? (
                    <div className="flex flex-row w-full overflow-scroll">
                        {/*病人基本資料*/}
                        <div className="space-y-2">
                            <div className="flex flex-row items-center justify-between w-full">
                                <Field
                                    sx={{width: '15%'}}
                                    label="卡號"
                                >
                                    {info.icCardNo}
                                </Field>
                                <Field
                                    sx={{width: '20%'}}
                                    label="姓名"
                                >
                                    {info.patientName}
                                </Field>
                                <Field
                                    sx={{width: '15%'}}
                                    label="身分證號"
                                >
                                    {info.idNo}
                                </Field>
                                <Field
                                    sx={{width: '15%'}}
                                    label="性別"
                                >
                                    {info.gender === 'F'
                                        ? t('general.gender.female')
                                        : t('general.gender.male')}
                                </Field>
                                <Field
                                    sx={{width: '20%'}}
                                    label="生日"
                                >
                                    {dayjs(info.birthDate).format('YYYY-MM-DD')}
                                </Field>
                                <Field
                                    sx={{width: '20%'}}
                                    label="發卡日期"
                                >
                                    {dayjs(info.cardIssueDate).format('YYYY-MM-DD')}
                                </Field>
                            </div>
                            <div className="flex flex-row items-center justify-between w-full">
                                <Field
                                    sx={{width: '15%'}}
                                    label="卡片有效期限"
                                >
                                    {dayjs(info.cardExpirationDate).format(
                                        'YYYY-MM-DD'
                                    )}
                                </Field>
                                <Field
                                    sx={{width: '20%'}}
                                    childrenSx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'start',
                                    }}
                                    label="緊急聯絡電話"
                                >
                                    <div className="w-[70%]">
                                        {isPhoneEdit && isPhysical ? (
                                            <TextField
                                                inputWidth={SizeEnum.Fill}
                                                value={emergentTel}
                                                onChange={handlePhoneChange}
                                                readOnly={!isPhoneEdit}
                                            />
                                        ) : (
                                            info.emergentTel
                                        )}
                                    </div>

                                    {isPhysical && <Button
                                        text={isPhoneEdit ? '儲存' : '編輯'}
                                        variant={ButtonVariantEnum.Text}
                                        icon={
                                            isPhoneEdit
                                                ? IconEnum.Save
                                                : IconEnum.Edit
                                        }
                                        onClick={handlePhoneEdit}
                                    />}
                                </Field>
                                {/*卡片註銷註記*/}
                                <Field
                                    sx={{width: '15%'}}
                                    label="卡片註銷註記"
                                >
                                    正常卡
                                </Field>
                                {/*就醫可用次數*/}
                                <Field
                                    sx={{width: '15%'}}
                                    label="就醫可用次數"
                                >
                                    {info.availableTimesOfMed}
                                </Field>
                                {/*最後一次就醫序號*/}
                                <Field
                                    sx={{width: '20%'}}
                                    labelWidth={150}
                                    label="最後一次就醫序號"
                                >
                                    {info.clinicSeqNo}
                                </Field>
                                {/*保險對象身份註記*/}
                                <Field
                                    sx={{width: '20%'}}
                                    labelWidth={150}
                                    label="保險對象身份註記"
                                >
                                    {t(`ICCard.insurerNote.${info.insurerNote}`)}
                                </Field>
                            </div>
                            <div className="w-full flex flex-row items-center justify-start space-x-2">
                                <div
                                    className={`bg-[${GrayColorEnum.Gray100}] rounded p-1 flex items-center`}
                                >
                                    <Field
                                        sx={{width: '50%'}}
                                        label="新生兒出生日期"
                                        childrenSx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'start',
                                        }}
                                    >
                                        <DatePicker
                                            value={getBirthDate()}
                                            onChange={(e) =>
                                                handleChildBirthDateOnChange(e)
                                            } // 根據狀態設定是否只讀
                                            disabled={!isEditable}
                                        />
                                    </Field>
                                    <Field
                                        sx={{width: '35%'}}
                                        label="新生兒胞胎註記"
                                    >
                                        <TextField
                                            inputWidth={SizeEnum.Tiny}
                                            name="siblingCount"
                                            value={
                                                isEditable
                                                    ? modifyChildData.childBornNote
                                                    : info.childBornNote
                                            }
                                            onChange={(e) =>
                                                handleChildBornNoteOnChange(e)
                                            } // 根據狀態設定是否只讀
                                            disabled={!isEditable}
                                        />
                                    </Field>
                                    <div className="w-[15%]">
                                        {!isEditable ? (
                                            <Button
                                                color={ButtonColorEnum.Primary}
                                                icon={IconEnum.Edit}
                                                variant={ButtonVariantEnum.Text}
                                                text={t('general.edit')}
                                                onClick={handleChildIconOnClick}
                                            />
                                        ) : (
                                            <Button
                                                color={ButtonColorEnum.Primary}
                                                variant={ButtonVariantEnum.Text}
                                                icon={IconEnum.Save}
                                                text={t('general.save')}
                                                onClick={handleChildIconOnClick}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div
                                    className={`bg-[${GrayColorEnum.Gray100}] rounded p-1 flex items-center`}
                                >
                                    <Field
                                        labelWidth={80}
                                        label="病人註記"
                                    >
                                        <Checkbox
                                            label="同意器官捐贈"
                                            checked={info.organDonateFlag}
                                            disabled
                                        />
                                        <Checkbox
                                            label="同意安寧緩和醫療"
                                            checked={info.hospiceCareFlag}
                                            disabled
                                        />
                                        <Checkbox
                                            label="同意不施行心肺復甦手術"
                                            checked={info.notUseCPRFlag}
                                            disabled
                                        />
                                        <Checkbox
                                            label="同意不施行維生醫療"
                                            checked={
                                                info.notUseLifeSustainingMedicalFlag
                                            }
                                            disabled
                                        />
                                        <Checkbox
                                            label="同意預立醫療決定"
                                            checked={
                                                info.advanceMedicalDecisionFlag
                                            }
                                            disabled
                                        />
                                    </Field>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <h3 className="text-left text-[36px] font-normal text-[#9C9C9C] py-6">
                        {t('ICCard.readCardPlaceholder')}
                    </h3>
                )}
            </Card>
        </div>
    )
}

export default PatientInfo
