import React, {useEffect, useState} from "react";
import {ApiErrorStatusEnum} from "edah_utils/dist";
import {
    rsisAddCodeValue,
    rsisDelCodeValue,
    rsisEditCodeValue,
    rsisQueryCodeValueCodeType,
    rsisQueryCodeValueCodeTypeOption
} from "../../../api/v1/RSIS";
import useToast from "../../../hooks/useToast";
import {t} from "i18next"
import {
    AlertTypeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Select,
    Card,
    InlineEditDataGrid
} from "edah-component/dist"


/**
 * 基本資料設定
 * @returns {Element}
 * @constructor
 */
const BasicSetting = () => {
    // 類別
    const [codeType, setCodeType] = useState("");
    // 類別選項
    const [typeOptions, setTypeOptions] = useState([]);
    // 有效否
    const [effective, setEffective] = useState('ALL');
    // 有效否選項
    const [effectiveOptions, setEffectiveOptions] = useState([
        {
            label: "Y",
            value: 1,
        },
        {
            label: "N",
            value: 0,
        },
        {
            label: "ALL",
            value: 'ALL',
        },
    ]);
    // 表格內容
    const [rows, setRows] = useState([]);
    /**
     * Show Toast
     */
    const showToast = useToast()
    /**
     * 有效否改變
     * @param value {String} 值
     */
    const handleEffectiveChange = (value) => {
        setEffective(value);
    };

    /**
     * 類別改變
     * @param value {String} CodeType 值
     */
    const handleTypeChange = (value) => {
        setCodeType(value);
    };

    /**
     * 更新行
     * @param row {Object} row
     * @returns {boolean}
     */
    const updateRow = (row) => {
        // 使用 validateEffFlag 方法檢核有效否
        if (!validate(row)) {
            return false
        }

        const newRow = {
            // CODEVALUE_UUID
            codeValueUUID: row?.codevalueUUID || '',
            // 類別
            codeType: codeType || '',
            // 代號
            codeNo: row.codeNo,
            // 值1
            codeValue1: row.codeValue1,
            // 值2
            codeValue2: row.codeValue2,
            // 值3
            codeValue3: row.codeValue3,
            // 有效否(1/0)
            effFlag: row?.effFlag === "Y" || row?.effFlag === "y" ? 1 : 0,
            // 排列順序
            sortNo: row?.sortNo || ''
        }


        const result = (res) => {
            const {err, msg} = res
            //取得成功
            if (err === ApiErrorStatusEnum.Success) {
                showToast({message: '儲存成功', type: AlertTypeEnum.Success})
                return true
            } else {
                showToast({message: msg, type: AlertTypeEnum.Error})
                return false
            }
        }
        newRow?.codeValueUUID ? rsisEditCodeValue([newRow]).then(result) : rsisAddCodeValue(newRow).then(result)
    }


    /**
     * 檢核EffFlag是否存在
     * @param row {Object} row
     * @return {boolean}
     */
    const validate = (row) => {
        if (!row.effFlag) {
            showToast({message: '有效否沒有填寫，不允許存檔', type: AlertTypeEnum.Error});
            return false;
        }
        if (!row.codeNo) {
            showToast({message: '代號沒有填寫，不允許存檔', type: AlertTypeEnum.Error});
            return false;
        }
        return true;
    };


    /**
     * 刪除
     * @param deleteId {String} 刪除ID
     * @return {void}
     */
    const handleDeletePopup = (deleteId) => {
        rsisDelCodeValue({codevaluePk: deleteId}).then(async res => {
            const {msg, err} = res
            //執行成功
            if (err === ApiErrorStatusEnum.Success) {
                // 顯示訊息
                showToast({message: msg})
                await handleClickQuery()
                setShowDeletePopup(false)
            } else { //錯誤
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }


    /**
     *  基本資料設定 - 表格用的 header 與設定資料
     */
    const header = [
        {
            headerName: "代號",
            field: "codeNo",
            editable: true,
            flex: 1,
            type: 'string',
            minWidth: 220
        },
        {
            headerName: "值1",
            className: "",
            field: "codeValue1",
            type: 'string',
            editable: true,
            flex: 1,
            minWidth: 150
        },
        {
            headerName: "值2",
            field: "codeValue2",
            type: 'string',
            editable: true,
            flex: 1,
            minWidth: 100
        },
        {
            headerName: "值3",
            field: "codeValue3",
            type: 'string',
            editable: true,
            flex: 1,
            minWidth: 100
        },
        {
            headerName: "有效否",
            field: "effFlag",
            type: 'string',
            editable: true,
            flex: 1,
            minWidth: 100
        },
    ];

    /**
     * 查詢類別內容
     * @param type {String} 類別
     * @returns {Promise<void>}
     */
    const fetchQueryCodeValueCodeType = async (type) => {
        const fetchCodeType = codeType || type
        const res = await rsisQueryCodeValueCodeType({
            codeType: fetchCodeType,
            effFlag: effective === 'ALL' ? 'ALL' : effective === 1 ? 'Y' : 'N'
        });
        const data = res.data.map((item, index) => {
            return {
                ...item,
                effFlag: item.effFlag === 1 ? 'Y' : 'N',
                id: index + item.codeNo + item.codeType + item.codeValueUUID
            }
        })
        setRows(data);
    }

    /**
     * 查詢按鈕
     */
    const handleClickQuery = async () => {
        fetchQueryCodeValueCodeType()
    };

    /**
     * 初始化
     * @returns {Promise<void>}
     */
    const init = async () => {
        // 取得類別選項
        const result = await rsisQueryCodeValueCodeTypeOption();
        setTypeOptions(
            //過濾掉相同的value
            result.data
                .map((item) => ({
                    value: item.codeType,
                    label: item.codeType,
                    id: item.codeType
                }))
                .filter((item, index, self) =>
                    index === self.findIndex((t) => t.value === item.value)
                )
        );
        setCodeType(result.data[0].codeType)
        fetchQueryCodeValueCodeType(result.data[0].codeType)
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <Card>
            {/* 類別 */}
            <div className="flex justify-between p-2 items-center">
                <div className="flex items-center text-left">
                    <Select
                        data={{
                            label: '類別',
                            options: typeOptions.map((item) => ({
                                value: item.value,
                                label: item.label
                            }))
                        }}
                        value={codeType}
                        onChange={handleTypeChange}
                        notched={true}
                        displayEmpty = {true}
                    />
                    {/* 有效否 */}
                    <Select
                        data={{
                            label: '有效否',
                            options: effectiveOptions.map((item) => ({
                                value: item.value,
                                label: item.label
                            }))
                        }}
                        value={effective}
                        onChange={handleEffectiveChange}
                        notched={true}
                        displayEmpty = {true}
                    />

                    {/* 查詢 */}
                    <Button sx={{marginLeft: '1rem'}} color={ButtonColorEnum.Primary}
                            variant={ButtonVariantEnum.Contained} size={ButtonSizeEnum.Medium}
                            onClick={handleClickQuery}
                            text={t('general.query')}/>
                </div>
            </div>

            {/* 表格 */}
            <InlineEditDataGrid
                rows={rows || []}
                columns={header}
                checkboxSelection={false}
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
                disableDensitySelector
                disableRowSelectionOnClick
                height={'700px'}
                onSave={(row) => {
                   return updateRow(row)
                }}
                onDeleteRow={(row) => {
                    handleDeletePopup(row?.codevalueUUID)
                }}
            />
        </Card>
    );
};

export default BasicSetting;
