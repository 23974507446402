import {createSlice} from '@reduxjs/toolkit'

/**
 * sidebar是否展開的狀態管理
 */
export const sideBarToggle = createSlice({
    name: 'sideBarToggle',
    initialState: {
        //sideBar toggle
        isOpen: true,
        //menu toggle
        toggleMenuOpen: false,
    },
    reducers: {
        /**
         * 切換sidebar
         * @param state
         * @param action
         */
        toggleSideBar(state, action) {
            //若有傳值通過傳值控制
            const { isOpen } = action.payload

            state.isOpen = isOpen
            state.toggleMenuOpen = !isOpen
        },
    },
})
export const { toggleSideBar } = sideBarToggle.actions
export default sideBarToggle.reducer
