/**
 * Import Functions
 */
import React, {useEffect, useState} from "react"
import {
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Dialog,
    DialogVariant,
    DialogSizeEnums,
    Field,
    TextField } from "edah-component/dist";
import {t} from "i18next"

/**
 * 退掛確認彈窗
 * @param show 是否顯示
 * @param closePopupButtonOnClick 關閉按鈕點擊事件
 * @param savePopupButtonOnClick 確認按鈕點擊事件
 * @returns
 */
const PinCodePopup = ({
   show = false,
   closePopupButtonOnClick,
   savePopupButtonOnClick
}) => {

    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show)

    /**
     * 確定刪除點擊事件
     */
    const handleConfirmOnClick = () => {
        closePopupButtonOnClick()
    }

    /**
     * 確認PINCODE btn
     * */
    const handleConfirmSaveOnClick = () => {
        savePopupButtonOnClick()
    }

    /**
     * 取消刪除點擊事件
     */
    const handleCancelOnClick = () => {
        closePopupButtonOnClick()
    }

    /**
     * PinCode彈窗內容
     * @return {React.JSX.Element}
     */
    const PinCodeContentPopup = () => (
        <>
            <Field label={'Pin Code'} labelSx={{display: 'flex', justifyContent: 'flex-start'}}>
                <TextField sx={{width: '100%'}}/>
            </Field>
        </>
    )

    /**
     * PinCode彈窗頁尾
     * @return {React.JSX.Element}
     */
    const PinCodePopupFooter = () => (
        <Button sx={{marginLeft: '0.5rem'}}
                color={ButtonColorEnum.Primary}
                variant={ButtonVariantEnum.Contained}
                size={ButtonSizeEnum.Medium}
                text={t('general.submit')}
                onClick={handleConfirmSaveOnClick}/>
    )

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        setShowPopup(show)
    }, [show])

    return (
        <Dialog
            open={showPopup}
            title="請輸入PinCode"
            variant={DialogVariant.CUSTOM}
            content={PinCodeContentPopup()}
            onClose={handleConfirmOnClick}
            paperStyleProps={{width: DialogSizeEnums.SM}}
            isShowFooterDivider={true}
            footerContent={PinCodePopupFooter()}
        />
    )
}

export default PinCodePopup;