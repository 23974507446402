import React, {useEffect, useState} from "react";
import '../../components/TableList/tableList.scss'
import {ApiErrorStatusEnum, arrayIsEmpty, objectIsEmpty, stringIsEmpty,} from "edah_utils/dist"
import CvisListItem from "../../components/CVIS/CvisListItem";
import {cvisNotifyUnitAdapter, fuseObject, getCurrentFormattedDateTime} from "../../components/CVIS/utils";
import {Pagination} from "../../components/Pagination/Pagination";
import {
    cancelCvisNotifyrec,
    queryCvisNotifyobject,
    queryCvisNotifyrec,
    queryCvisNotifyrecById,
    queryCvisNotifyUnit,
    queryCvisRenotify,
    queryCvisResponse
} from "../../api/v1/Critical";

import {
    AlertTypeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Card,
    DateRangePicker,
    Field,
    Select,
    Tabs,
    TabsEnum,
    SelectWidthEnum,
    Checkbox,
    Dialog,
    DialogSizeEnums,
    DialogVariant
} from "edah-component/dist"
import {t} from "i18next"
import useToast from "../../hooks/useToast"
import dayjs from "dayjs";
import {SearchTextField} from "../../components/SearchTextField/SearchTextField"
import {Direction, ResizablePanel} from "edah-component";

/**
 * 危急值通報紀錄
 * @return {JSX.Element}
 */
const CriticalValuesRecords = () => {
    const showToast = useToast()
    // 危急值通報單位
    const [unit, setUnit] = useState([])
    // 預設查詢表單狀態
    const [cvisQueryData, setCvisQueryData] = useState({
        //通報單位
        cvisNotifyUnit: '',
        //通報日期起始
        cvisNotifyStartDate: dayjs().format('YYYY-MM-DD'),
        //通報日期結束
        cvisNotifyEndDate: dayjs().format('YYYY-MM-DD'),
        //取消狀態
        cvisCancelStatus: '1',
        //讀取狀態
        cvisReadStatus: '',
        //回覆狀態
        cvisResponseStatus: '',
        //通報類別
        cvisNotifyType: 'A' //危急值固定為A
    })
    // 危急值清單物件
    const [cvisNotifyObject, setcvisNotifyObject] = useState([])
    // 過濾後的危急值清單物件
    const [filteredItems, setFilteredItems] = useState([])
    // 通報清單編號
    const [cvisNotifyListId, setCvisNotifyListId] = useState("")
    // 進階搜尋字串
    const [queryString, setQueryString] = useState("")
    // 通報資料
    const [cvisNotifyData, setCvisNotifyData] = useState({})
    // 通報對象清單
    const [cvisNotifyObjectList, setCvisNotifyObjectList] = useState([])
    // 通報對象清單搜尋字串
    const [cvisNotifyObjectQueryString, setCvisNotifyObjectQueryString] = useState("")
    // 通報對象清單過濾結果
    const [filteredListItems, setFilteredListItems] = useState([])
    // 通報回覆紀錄
    const [cvisNotifyResponse, setCvisNotifyCvisResponse] = useState([])
    // 通報回覆紀錄搜尋字串
    const [cvisNotifyResponseQueryString, setCvisNotifyResponseQueryString] = useState("")
    // 通報回覆紀錄過濾結果
    const [filteredResponseItems, setFilteredResponseItems] = useState([])
    // 逾時回覆再通知紀錄
    const [cvisNotifyRenotify, setNotifyCvisRenotify] = useState([])
    // 逾時回覆再通知紀錄搜尋字串
    const [cvisNotifyRenotifyQueryString, setCvisNotifyRenotifyQueryString] = useState("")
    // 逾時回覆再通知紀錄過濾結果
    const [filteredRenotifyItems, setFilteredRenotifyItems] = useState([])
    // 切換基本資料 醫囑清單 檢核結果
    const [activeTab, setActiveTab] = useState('report');
    // pagination控制變數
    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
    })
    // 通報對象清單pagination
    const [notifyObjectPaginationProps, setNotifyObjectPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
    })
    // 回覆紀錄pagination
    const [responsePaginationProps, setResponsePaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
    })
    // 逾時回覆再通知pagination
    const [renotifyPaginationProps, setRenotifyPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
    })

    // 顯示取消通報對話框
    const [isShowCancelDialog, setIsShowCancelDialog] = useState(false)

    /**
     * 通報紀錄查詢按鈕事件
     * @return {void}
     */
    const handleQueryCvisNotifyrec = () => {
        if (cvisQueryData.cvisNotifyEndDate && cvisQueryData.cvisNotifyStartDate > cvisQueryData.cvisNotifyEndDate) {    // 檢查日期區間
            showToast({message: '起始日期不可大於結束日期', type: AlertTypeEnum.Error})
        } else if (stringIsEmpty(cvisQueryData.cvisNotifyEndDate) || stringIsEmpty(cvisQueryData.cvisNotifyStartDate)) { // 檢查日期是否為空
            showToast({message: '請輸入日期', type: AlertTypeEnum.Error})
        } else { // 日期區間正確
            queryCvisNotifyrec({
                "cvisNotifyUnit": cvisQueryData.cvisNotifyUnit,                               //Required 通報單位
                "cvisNotifyStartDate": `${cvisQueryData.cvisNotifyStartDate} 00:00:00`,       //Required 通報日期起始
                "cvisNotifyEndDate": `${cvisQueryData.cvisNotifyEndDate} 00:00:00`,           //Required 通報日期結束
                "cvisCancelStatus": cvisQueryData.cvisCancelStatus,                             //Option   取消狀態(空白：全部 1:未取消 2:已取消)
                "cvisReadStatus": cvisQueryData.cvisReadStatus,                                 //Option   讀取狀態(空白：全部 1:未讀 2:已讀)
                "cvisResponseStatus": cvisQueryData.cvisResponseStatus,                       //Option   回覆狀態(空白：全部 1:未回覆 2:已回覆)
                "cvisNotifyType": "A", //cvisQueryData.cvisNotifyType                         //Required 通報類別(A：危急值 B：感染管制)
                "pageNum": paginationProps.pageNum,                                                        //Required 當前頁數
                "pageSize": paginationProps.pageSize,                                                         //Required 每頁顯示筆數
            }).then(res => {
                // 錯誤狀態 / 資料 / 訊息
                const {err, data, msg} = res
                // 如果查詢成功
                if (err === ApiErrorStatusEnum.Success) {
                    // 取得通報資料 / 總筆數 / 總頁數
                    const {dataList, totalItemSize, totalPageSize} = data
                    setcvisNotifyObject(dataList)
                    setFilteredItems(dataList)
                    setCvisNotifyListId("0")
                    setPaginationProps({
                        ...paginationProps,
                        totalItemSize: totalItemSize,
                        totalPageSize: totalPageSize,
                    })
                } else { // 如果查詢失敗
                    // 重置通報資料
                    setcvisNotifyObject([])
                    // 重置過濾後的通報資料
                    setFilteredItems([])
                    // 重置通報清單編號
                    setCvisNotifyListId("")
                    // 重置分頁
                    setPaginationProps({
                        totalItemSize: 0,
                        totalPageSize: 0,
                        pageNum: 1,
                        pageSize: 10
                    })
                    showToast({message: msg, type: AlertTypeEnum.Error})
                }
            })
        }
    }

    /**
     * 危急值表格指定頁碼變動事件
     * @param page {number}
     * @return {void}
     */
    const onPaginationPageOnChange = (page) => {
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
    }

    /**
     * 危急值表格指定每頁筆數變動事件
     * @param e {Event}
     * @return {void}
     */
    const onPaginationPageSizeOnChange = (e) => {
        const newSize = e.target.value;
        setPaginationProps({
            ...paginationProps,
            pageSize: newSize
        });
    }

    /**
     * 危急值表格上一頁按鈕事件
     * @return {void}
     */
    const onPaginationPreviousOnClick = () => {
        const page = (paginationProps.pageNum - 1) > 1 ? (paginationProps.pageNum - 1) : 1;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
    }

    /**
     * 危急值表格下一頁按鈕事件
     * @return {void}
     */
    const onPaginationNextOnClick = () => {
        const page = (paginationProps.pageNum + 1) < paginationProps.totalPageSize ? (paginationProps.pageNum + 1) : paginationProps.totalPageSize;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
    }

    /**
     * 通報對象清單表格指定頁碼變動事件
     * @param page {Number}
     * @return {void}
     */
    const onNotifyPaginationPageOnChange = (page) => {
        setNotifyObjectPaginationProps({
            ...notifyObjectPaginationProps,
            pageNum: page
        });
    }

    /**
     * 通報對象清單表格指定每頁筆數變動事件
     * @param e {Event}
     * @return {void}
     */
    const onNotifyPaginationPageSizeOnChange = (e) => {
        const newSize = parseInt(e.target.value)
        setNotifyObjectPaginationProps({...notifyObjectPaginationProps, pageSize: newSize})
    }


    /**
     * 通報對象清單表格上一頁按鈕事件
     * @return {void}
     */
    const onNotifyPaginationPreviousOnClick = () => {
        const page = (notifyObjectPaginationProps.pageNum - 1) > 1 ? (notifyObjectPaginationProps.pageNum - 1) : 1;
        setNotifyObjectPaginationProps({
            ...notifyObjectPaginationProps,
            pageNum: page
        });
    }

    /**
     * 通報對象清單表格下一頁按鈕事件
     * @return {void}
     */
    const onNotifyPaginationNextOnClick = () => {
        const page = (notifyObjectPaginationProps.pageNum + 1) < notifyObjectPaginationProps.totalPageSize ? (notifyObjectPaginationProps.pageNum + 1) : notifyObjectPaginationProps.totalPageSize;
        setNotifyObjectPaginationProps({
            ...notifyObjectPaginationProps,
            pageNum: page
        });
    }

    /**
     * 回覆紀錄表格指定頁碼變動事件
     * @param page {number}
     * @return {void}
     */
    const onResponsePaginationPageOnChange = (page) =>
        setResponsePaginationProps({
            ...responsePaginationProps,
            pageNum: page
        })

    /**
     * 回覆紀錄表格指定每頁筆數變動事件
     * @param e {Event}
     * @return {void}
     */
    const onResponsePaginationPageSizeOnChange = (e) => {
        setResponsePaginationProps({...responsePaginationProps, pageSize: parseInt(e.target.value)})
    }

    /**
     * 回覆紀錄表格上一頁按鈕事件
     * @return {void}
     */
    const onResponsePaginationPreviousOnClick = () => {
        const page = (responsePaginationProps.pageNum - 1) > 1 ? (responsePaginationProps.pageNum - 1) : 1;
        setResponsePaginationProps({...responsePaginationProps, pageNum: page})
    }

    /**
     * 回覆紀錄表格下一頁按鈕事件
     * @return {void}
     */
    const onResponsePaginationNextOnClick = () => {
        const page = (responsePaginationProps.pageNum + 1) < responsePaginationProps.totalPageSize ? (responsePaginationProps.pageNum + 1) : responsePaginationProps.totalPageSize;
        setResponsePaginationProps({...responsePaginationProps, pageNum: page})
    }

    /**
     * 逾時回覆再通知紀錄表格指定頁碼變動事件
     * @param page {Number}
     * @return {void}
     */
    const onRenotifyPaginationPageOnChange = (page) =>
        setRenotifyPaginationProps({...renotifyPaginationProps, pageNum: page})

    /**
     * 逾時回覆再通知紀錄指定每頁筆數變動事件
     * @param e {Event}
     * @return {void}
     */
    const onRenotifyPaginationPageSizeOnChange = (e) =>
        setRenotifyPaginationProps({
            ...renotifyPaginationProps,
            pageSize: parseInt(e.target.value)
        })

    /**
     * 逾時回覆再通知紀錄上一頁按鈕事件
     * @return {void}
     */
    const onRenotifyPaginationPreviousOnClick = () => {
        const page = (renotifyPaginationProps.pageNum - 1) > 1 ? (renotifyPaginationProps.pageNum - 1) : 1;
        setRenotifyPaginationProps({
            ...renotifyPaginationProps,
            pageNum: page
        });
    }

    /**
     * 逾時回覆再通知紀錄下一頁按鈕事件
     * @return {void}
     */
    const onRenotifyPaginationNextOnClick = () => {
        const page = (renotifyPaginationProps.pageNum + 1) < renotifyPaginationProps.totalPageSize ? (renotifyPaginationProps.pageNum + 1) : renotifyPaginationProps.totalPageSize;
        setRenotifyPaginationProps({
            ...renotifyPaginationProps,
            pageNum: page
        })
    }

    /**
     * 危急值查詢按鈕事件
     * @return {void}
     */
    const handleOnQuery = () => handleQueryCvisNotifyrec()

    /**
     * 分頁切換事件
     * @param tab {String}
     * @return {void}
     */
    const handleTabClick = (tab) => setActiveTab(tab)

    /**
     * 通報單位選項更新
     * @param value {String}
     * @return {void}
     */
    const handleCvisNotifyUnitOnChange = (value) => {
        setCvisQueryData({
            ...cvisQueryData,
            cvisNotifyUnit: value
        })
    }

    /**
     * 通報日期起訖更新
     * @param {Array<Dayjs | null>} newDates 日期範圍 [開始日期, 結束日期]
     * @return {void}
     */
    const handleCvisNotifyDateOnChange = (newDates) => {
        const [startDate, endDate] = newDates;

        setCvisQueryData({
            ...cvisQueryData,
            cvisNotifyStartDate: startDate ? startDate.format('YYYY-MM-DD') : null,
            cvisNotifyEndDate: endDate ? endDate.format('YYYY-MM-DD') : null,
        });
    };

    /**
     * 取消狀態選項更新
     * @param value {String}
     * @return {void}
     */
    const handleCvisCancelStatusOnChange = (value) => {
        setCvisQueryData({
            ...cvisQueryData,
            cvisCancelStatus: value
        })
    }

    /**
     * 讀取狀態選項更新
     * @param value {Event}
     * @return {void}
     */
    const handleCvisReadStatusOnChange = (value) => {
        setCvisQueryData({
            ...cvisQueryData,
            cvisReadStatus: value
        })
    }

    /**
     * 回覆狀態選項更新
     * @param value {String}
     * @return {void}
     */
    const handleCvisResponseStatusOnChange = (value) => {
        setCvisQueryData({
            ...cvisQueryData,
            cvisResponseStatus: value
        })
    }

    /**
     * 取消通報按鈕事件
     * @return {void}
     */
    const handleCancelCvisNotifyrec = () => {
        cancelCvisNotifyrec({
            cvisNotifyId: cvisNotifyObject[cvisNotifyListId]?.cvisNotifyId,
            cvisCancelUser: localStorage.getItem("username"),
            cvisCancelDatetime: getCurrentFormattedDateTime()
        }).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                // 更新通報資料
                updateNotifyDataTable()
                showToast({message: '取消通報成功', type: AlertTypeEnum.Success})
            } else {
                showToast({message: '取消通報失敗', type: AlertTypeEnum.Error})
            }
        })
        // 關閉取消通報對話框
        setIsShowCancelDialog(false)
    }

    /**
     * 控制Query輸入框內的值變動事件
     * @param event {Event}
     * @return {void}
     */
    const handleQueryInputOnChange = (event) => {
        if (event.target.value === ' ')
            setQueryString('');
        else {
            setQueryString(event.target.value)
        }
    }

    /**
     * 通報對象清單搜尋框變動事件
     * @param event {Event}
     * @return {void}
     */
    const handleCvisNotifyObjectQueryOnChange = (event) => setCvisNotifyObjectQueryString(event.target.value)

    /**
     * 通報回覆紀錄搜尋框變動事件
     * @param event {Event}
     * @return {void}
     */
    const handleCvisNotifyResponseQueryOnChange = (event) => setCvisNotifyResponseQueryString(event.target.value)

    /**
     * 逾時回覆再通知紀錄搜尋框變動事件
     * @param event {Event}
     * @return {void}
     */
    const handleCvisNotifyRenotifyQueryOnChange = (event) => setCvisNotifyRenotifyQueryString(event.target.value)

    /**
     * 取消通報按鈕事件
     * @return {void}
     */
    const handleCancelCvisNotifyPopupOnClick = () => setIsShowCancelDialog(!isShowCancelDialog)

    /**
     * 根據流水號更新通報資料
     * @return {void}
     */
    const updateNotifyDataTable = () => {
        // 根據通報流水號取得通報資料
        queryCvisNotifyrecById({
            "cvisNotifyId": cvisNotifyObject[cvisNotifyListId].cvisNotifyId
        }).then(res => {
            // 錯誤狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 如果查詢成功
            if (err === ApiErrorStatusEnum.Success) {
                // 更新通報資料
                setCvisNotifyData(data)
            } else { // 如果查詢失敗
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得通報對象清單
     * @return {void}
     */
    const updateNotifyListTable = () => {
        // 根據通報流水號取得通報對象清單
        queryCvisNotifyobject({
            "cvisNotifyId": cvisNotifyObject[cvisNotifyListId]?.cvisNotifyId,
            "pageNum": notifyObjectPaginationProps.pageNum === 0 ? 1 : notifyObjectPaginationProps.pageNum,
            "pageSize": notifyObjectPaginationProps.pageSize,
        }).then(res => {
            // 錯誤狀態 / 資料
            const {err, data} = res
            // 如果查詢成功
            if (err === ApiErrorStatusEnum.Success) {
                // 資料 / 總筆數 / 總頁數
                const {dataList, totalItemSize, totalPageSize} = data
                // 更新通報對象清單
                setCvisNotifyObjectList(dataList)
                setFilteredListItems(dataList)
                setNotifyObjectPaginationProps({
                    ...notifyObjectPaginationProps,
                    pageNum: notifyObjectPaginationProps.pageNum === 0 ? 1 : notifyObjectPaginationProps.pageNum,
                    totalItemSize: totalItemSize,
                    totalPageSize: totalPageSize,
                })
            } else { // 如果查詢失敗
                // 重置通報對象清單
                setCvisNotifyObjectList([])
                // 重置過濾後的通報對象清單
                setFilteredListItems([])
                // 重置通報對象清單分頁
                setNotifyObjectPaginationProps({
                    ...notifyObjectPaginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageNum: 0,
                })
            }
        })
    }

    /**
     * 取得更新回覆紀錄
     * @return {void}
     */
    const updateResponseRecordTable = () => {
        // 根據通報流水號取得回覆紀錄
        queryCvisResponse({
            "cvisNotifyId": cvisNotifyObject[cvisNotifyListId]?.cvisNotifyId,
            "pageNum": responsePaginationProps.pageNum === 0 ? 1 : responsePaginationProps.pageNum,
            "pageSize": responsePaginationProps.pageSize,
        }).then(res => {
            // 錯誤狀態 / 資料
            const {err, data} = res
            // 如果查詢成功
            if (err === ApiErrorStatusEnum.Success) {
                // 資料 / 總筆數 / 總頁數
                const {dataList, totalItemSize, totalPageSize} = data
                // 更新回覆紀錄
                setCvisNotifyCvisResponse(dataList)
                // 更新過濾後的回覆紀錄
                setFilteredResponseItems(dataList)
                // 更新回覆紀錄分頁
                setResponsePaginationProps({
                    ...responsePaginationProps,
                    pageNum: responsePaginationProps.pageNum === 0 ? 1 : responsePaginationProps.pageNum,
                    totalItemSize: totalItemSize,
                    totalPageSize: totalPageSize,
                })
            } else { // 如果查詢失敗
                // 重置回覆紀錄
                setCvisNotifyCvisResponse([])
                // 重置過濾後的回覆紀錄
                setFilteredResponseItems([])
                // 重置回覆紀錄分頁
                setResponsePaginationProps({
                    ...responsePaginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageNum: 0,
                })
            }
        })
    }

    /**
     * 取得逾時回覆再通知紀錄
     * @return {void}
     */
    const updateCvisRenotifyRecordTable = () => {
        // 根據通報流水號取得逾時回覆再通知紀錄
        queryCvisRenotify({
            "cvisNotifyId": cvisNotifyObject[cvisNotifyListId]?.cvisNotifyId,
            "pageNum": renotifyPaginationProps.pageNum === 0 ? 1 : renotifyPaginationProps.pageNum,
            "pageSize": renotifyPaginationProps.pageSize,
        }).then(res => {
            // 錯誤狀態 / 資料
            const {err, data} = res
            // 如果查詢成功
            if (err === ApiErrorStatusEnum.Success) {
                // 資料 / 總筆數 / 總頁數
                const {dataList, totalItemSize, totalPageSize} = data
                // 更新逾時回覆再通知紀錄
                setNotifyCvisRenotify(dataList)
                // 更新過濾後的逾時回覆再通知紀錄
                setFilteredRenotifyItems(dataList)
                // 更新逾時回覆再通知紀錄分頁
                setRenotifyPaginationProps({
                    ...renotifyPaginationProps,
                    pageNum: renotifyPaginationProps.pageNum === 0 ? 1 : renotifyPaginationProps.pageNum,
                    totalItemSize: totalItemSize,
                    totalPageSize: totalPageSize,
                })
            } else { // 如果查詢失敗
                // 重置逾時回覆再通知紀錄
                setNotifyCvisRenotify([])
                // 重置過濾後的逾時回覆再通知紀錄
                setFilteredRenotifyItems([])
                // 重置逾時回覆再通知紀錄分頁
                setRenotifyPaginationProps({
                    ...renotifyPaginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageNum: 0,
                })
            }
        })
    }

    /**
     * 當通報紀錄被選取時觸發，更新索引值
     * @param index {String} 被選取的通報紀錄索引
     * @return {void}
     */
    const handleCvisListItemOnFocus = (index) => {
        if (cvisNotifyListId !== index) {
            setCvisNotifyListId(index)
        }
    }

    /**
     * 取得Tab內容
     * @return {Object[]}
     */
    const getTabContent = () => {
        return [
            {
                id: 'report',
                title: '通報資料',
                content: (
                    <div className="overflow-x-auto">
                        <div className="grid grid-cols-2">
                            <Field labelWidth={150} label="通報單位">
                                {!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].cvisNotifyUnit) ? cvisNotifyUnitAdapter(cvisNotifyObject[cvisNotifyListId].cvisNotifyUnit) : "無資料"}
                            </Field>
                            <Field label="通報編號">
                                <div className="w-[240px]">
                                    {!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].cvisNotifyId) ? cvisNotifyObject[cvisNotifyListId].cvisNotifyId : "無資料"}
                                </div>
                            </Field>
                        </div>
                        <div className="grid grid-cols-2">
                            <Field labelWidth={150} label="通報項目">
                                {!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].cvisNotifyItem) ? cvisNotifyData.cvisNotifyItem : "無資料"}
                            </Field>
                            <Field label="等級">
                                {!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].cvisNotifyClass) ? cvisNotifyData.cvisNotifyClass : "無資料"}
                            </Field>
                        </div>
                        <div className="grid grid-cols-2">
                            <Field labelWidth={150} label={t('general.patientNo')}>
                                {!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].patientId) ? cvisNotifyData.patientId : "無資料"}
                            </Field>
                            <Field label="姓名">
                                {!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].patientName) ? cvisNotifyData.patientName : "無資料"}
                            </Field>
                        </div>
                        <div className="grid grid-cols-2">
                            <Field labelWidth={150} label="門住">
                                {!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].inpOpdName) ? cvisNotifyObject[cvisNotifyListId].inpOpdName : "無資料"}
                            </Field>
                            <Field label="科別">
                                {!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].divisionName) ? cvisNotifyData.divisionName : "無資料"}
                            </Field>
                        </div>
                        <div className="grid grid-cols-2">
                            <Field labelWidth={150} label="通報日期時間">
                                {!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].cvisNotifyDatetime) ? cvisNotifyData.cvisNotifyDatetime : "無資料"}
                            </Field>
                            <Field label="通報人員">
                                {!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].cvisNotifyUser) ? `${cvisNotifyData.cvisNotifyUser} : ${cvisNotifyData.cvisNotifyUserName}` : "無資料"}
                            </Field>
                        </div>
                        <div className="grid grid-cols-2">
                            <Field labelWidth={150} label="通報對象">
                                {!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].cvisNotifyObject) ? `${cvisNotifyData.cvisNotifyObject} : ${cvisNotifyData.cvisNotifyObjectName}` : "無資料"}
                            </Field>
                            <Field label="通報電話">
                                {!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].userMobile) ? cvisNotifyData.userMobile : "無資料"}
                            </Field>
                        </div>
                        <div>
                            <Field labelWidth={150} label="通報內容">
                                {!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].cvisNotifyContent) ? cvisNotifyData.cvisNotifyContent : "無資料"}
                            </Field>
                        </div>
                        <div>
                            <Field labelWidth={150} isShowColon={false}>
                                <Checkbox label="取消通報"
                                          checked={!arrayIsEmpty(cvisNotifyObject) && cvisNotifyObject[cvisNotifyListId].cvisCancelFlag}/>
                            </Field>
                        </div>
                        <div className="grid grid-cols-2">
                            <Field labelWidth={150} label="取消通報日期時間">
                                {!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].cvisNotifyContent) ? cvisNotifyData.cvisCancelDatetime : "無資料"}
                            </Field>
                            <Field label="取消通報人員">
                                {!arrayIsEmpty(cvisNotifyObject) && !stringIsEmpty(cvisNotifyObject[cvisNotifyListId].cvisNotifyContent) ? cvisNotifyData.cvisCancelUser : "無資料"}
                            </Field>
                        </div>
                    </div>
                )
            },
            {
                id: 'list',
                title: '通報對象清單',
                content: (
                    <div>
                        <div className='flex justify-start'>
                            <SearchTextField value={cvisNotifyObjectQueryString}
                                             placeholder={t('general.advancedSearch')}
                                             onChange={(event) => handleCvisNotifyObjectQueryOnChange(event)}/>
                        </div>
                        <ul className="tableList CVRRightOneList mt-4">
                            <li className="title">
                                <div>類別名稱</div>
                                <div>通報對象</div>
                                <div>電話</div>
                                <div>通報時間</div>
                                <div>讀取時間</div>
                            </li>
                            {
                                !objectIsEmpty(cvisNotifyObject[cvisNotifyListId]) && filteredListItems.map((item, index) =>
                                    <CvisListItem index={index} type="CvisNotifyObjectList" item={item}/>
                                )
                            }
                        </ul>
                        <Pagination
                            totalPageSize={notifyObjectPaginationProps.totalPageSize}
                            pageSize={notifyObjectPaginationProps.pageSize}
                            totalSize={notifyObjectPaginationProps.totalItemSize}
                            currentPage={notifyObjectPaginationProps.pageNum}
                            onPageOnChange={onNotifyPaginationPageOnChange}
                            onPageSizeChange={onNotifyPaginationPageSizeOnChange}
                            onPrevPageOnClick={onNotifyPaginationPreviousOnClick}
                            onNextPageOnClick={onNotifyPaginationNextOnClick}/>
                    </div>
                )
            },
            {
                id: 'reply',
                title: '回覆紀錄',
                content: (
                    <div>
                        <div className='flex justify-start'>
                            <SearchTextField value={cvisNotifyResponseQueryString}
                                             placeholder={t('general.advancedSearch')}
                                             onChange={(event) => handleCvisNotifyResponseQueryOnChange(event)}/>
                        </div>
                        <ul className="tableList CVRRightTwoList mt-4">
                            <li className="title">
                                <div>{t('general.patientNo')}</div>
                                <div>姓名</div>
                                <div>主治醫師</div>
                                <div>回覆日期時間</div>
                                <div>回覆人員</div>
                                <div>處理狀態</div>
                                <div>處理說明</div>
                            </li>
                            {
                                !objectIsEmpty(cvisNotifyObject[cvisNotifyListId]) && filteredResponseItems.map((item, index) =>
                                    <CvisListItem index={index} type="CvisNotifyResponseList" item={item}/>
                                )
                            }
                        </ul>
                        <Pagination
                            totalPageSize={responsePaginationProps.totalPageSize}
                            pageSize={responsePaginationProps.pageSize}
                            totalSize={responsePaginationProps.totalItemSize}
                            currentPage={responsePaginationProps.pageNum}
                            onPageOnChange={onResponsePaginationPageOnChange}
                            onPageSizeChange={onResponsePaginationPageSizeOnChange}
                            onPrevPageOnClick={onResponsePaginationPreviousOnClick}
                            onNextPageOnClick={onResponsePaginationNextOnClick}/>
                    </div>
                )
            },
            {
                id: 'outOfTime',
                title: '逾時回覆再通知紀錄',
                content: (
                    <div>
                        <div className='flex justify-start'>
                            <SearchTextField
                                value={cvisNotifyRenotifyQueryString} placeholder={t('general.advancedSearch')}
                                onChange={(event) => handleCvisNotifyRenotifyQueryOnChange(event)}/>
                        </div>
                        <ul className="tableList CVRRightOneList mt-4 max-h-[800px]">
                            <li className="title">
                                <div>通報日期時間</div>
                                <div>單號</div>
                                <div>通報人員</div>
                                <div>通報對象</div>
                                <div>處理狀態</div>
                            </li>
                            {
                                !objectIsEmpty(cvisNotifyObject[cvisNotifyListId]) && filteredRenotifyItems.map((item, index) =>
                                    <CvisListItem index={index} type="CvisNotifyRenotifyList" item={item}/>
                                )
                            }
                        </ul>
                        <Pagination
                            totalPageSize={renotifyPaginationProps.totalPageSize}
                            pageSize={renotifyPaginationProps.pageSize}
                            totalSize={renotifyPaginationProps.totalItemSize}
                            currentPage={renotifyPaginationProps.pageNum}
                            onPageOnChange={onRenotifyPaginationPageOnChange}
                            onPageSizeChange={onRenotifyPaginationPageSizeOnChange}
                            onPrevPageOnClick={onRenotifyPaginationPreviousOnClick}
                            onNextPageOnClick={onRenotifyPaginationNextOnClick}/>
                    </div>
                )
            }
        ]
    }

    /**
     * DataGrid React 元件
     * @return {React.JSX.Element}
     */
    const DataGridContent = ()=>(
        <Card sx={{
            width: "100%",
            height: "100%",
            mr: 2,
            pt: 1,
            '.MuiCardContent-root': {height: '100%'}
        }}>
            <div className="btnGroup flex flex-row mb-4">
                <Button sx={{marginRight: '0.5rem'}} color={ButtonColorEnum.Secondary}
                        variant={ButtonVariantEnum.Outlined} size={ButtonSizeEnum.Medium}
                        onClick={handleCancelCvisNotifyPopupOnClick} text='取消通報'/>
            </div>
            {/* 進階搜尋 */}
            <div className="flex justify-start">
                <SearchTextField
                    value={queryString}
                    onChange={(event) => handleQueryInputOnChange(event)}
                    placeholder={t('general.advancedSearch')}/>
            </div>
            {/* 危急值紀錄列表 */}
            <ul className="tableList CVRTableList mt-4 h-[calc(100%-130px)]">
                <li className="title scale-1.1">
                    <div>通報單位</div>
                    <div>通報項目</div>
                    <div>等級</div>
                    <div>通報日期時間</div>
                    <div>{t('general.patientNo')}</div>
                    <div>姓名</div>
                    <div>回覆狀態</div>
                    <div>讀取</div>
                    <div>取消</div>
                    <div>門住</div>
                    <div>科別</div>
                    <div>通報對象</div>
                    <div>通報電話</div>
                    <div>通報編號</div>
                </li>
                {
                    !arrayIsEmpty(filteredItems) && filteredItems.map((item, index) =>
                        <CvisListItem index={index} type="CvisNotifyRecordList" item={item}
                                      handleItemOnClick={handleCvisListItemOnFocus}/>)
                }
            </ul>
            <Pagination
                totalPageSize={paginationProps.totalPageSize}
                pageSize={paginationProps.pageSize}
                totalSize={paginationProps.totalItemSize}
                currentPage={paginationProps.pageNum}
                onPageOnChange={onPaginationPageOnChange}
                onPageSizeChange={onPaginationPageSizeOnChange}
                onPrevPageOnClick={onPaginationPreviousOnClick}
                onNextPageOnClick={onPaginationNextOnClick}
            />
        </Card>
    )

    /**
     * Tab 區塊 React 元件
     * @return {React.JSX.Element}
     */
    const TabArea = ()=>(
        <Card sx={{width: "100%",minWidth:'800px', height: "100%"}}>
            {/*點擊按鈕切換下方內容*/}
            <Tabs
                variant={TabsEnum.INDICATOR_TAB}
                activeTabId={activeTab}
                items={getTabContent()}
                onTabChange={handleTabClick}
            />
        </Card>
    )

    /**
     * 面版 Handler 樣式元件
     * @returns {React.JSX.Element}
     */
    const LineComponent = ()=>(<span style={{width:'1px', height:'36px', border:'3px solid #D4D4D8'}}></span>)

    /**
     * Resizable panel Cards
     * @type {[{children: React.JSX.Element, defaultSize: number, minSize: number, style: {paddingBottom: string}},{children: React.JSX.Element, defaultSize: number, minSize: number, handle: {icon: React.JSX.Element, style: {padding: string}}, style: {paddingBottom: string}}]}
     */
    const HorizontalPanel = [
        {
            children: DataGridContent(),
            defaultSize: 50,
            minSize:30,
            style: {paddingBottom: '3px'}
        },
        {
            children: TabArea(),
            defaultSize: 50,
            minSize: 30,
            handle:{icon: LineComponent(),
                    style: {padding:'0.5rem'}
            },
            style: {paddingBottom: '3px'}
        }
    ]



    /**
     * 當前focus的list index更新時，更新通報資料、通報對象清單、回覆紀錄、逾時回覆再通知紀錄
     * @return {void}
     */
    useEffect(() => {
        if (cvisNotifyObject[cvisNotifyListId] !== undefined) {
            setCvisNotifyData(cvisNotifyObject[cvisNotifyListId])
            updateNotifyDataTable()
        }
        updateNotifyListTable()
        updateCvisRenotifyRecordTable()
        updateResponseRecordTable()
    }, [cvisNotifyListId])

    /**
     * 當進階搜尋字串及通報紀錄改變時觸發，更新過濾後的通報紀錄
     * @return {void}
     */
    useEffect(() => {
        if (queryString) {
            const result = fuseObject(cvisNotifyObject).search(queryString)
            setFilteredItems(result.map(item => item.item))
        } else {
            setFilteredItems(cvisNotifyObject)
        }
    }, [cvisNotifyObject, queryString])

    // 當進階搜尋字串及通報紀錄改變時觸發，更新過濾後的通報紀錄
    useEffect(() => {
        if (cvisNotifyObjectQueryString) {
            const result = fuseObject(cvisNotifyObjectList).search(cvisNotifyObjectQueryString)
            setFilteredListItems(result.map(item => item.item))
        } else {
            setFilteredListItems(cvisNotifyObjectList)
        }
    }, [cvisNotifyObjectList, cvisNotifyObjectQueryString])

    useEffect(() => {
        if (cvisNotifyResponseQueryString) {
            const result = fuseObject(cvisNotifyResponse).search(cvisNotifyResponseQueryString)
            setFilteredResponseItems(result.map(item => item.item))
        } else {
            setFilteredResponseItems(cvisNotifyResponse)
        }
    }, [cvisNotifyResponse, cvisNotifyResponseQueryString])

    // 當進階搜尋字串及通報紀錄改變時觸發，更新過濾後的通報紀錄
    useEffect(() => {
        if (cvisNotifyRenotifyQueryString) {
            const result = fuseObject(cvisNotifyRenotify).search(cvisNotifyRenotifyQueryString)
            setFilteredRenotifyItems(result.map(item => item.item))
        } else {
            setFilteredRenotifyItems(cvisNotifyRenotify)
        }
    }, [cvisNotifyRenotify, cvisNotifyRenotifyQueryString])

    // 取得通報單位選單
    useEffect(() => {
        queryCvisNotifyUnit({}).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setUnit(res.data)
                if (res.data.length > 0) {
                    setCvisQueryData({...cvisQueryData, cvisNotifyUnit: res.data[0].cvisNotifyUnitNo})
                }
            } else {
                showToast({message: '無法取得通報單位資料', type: AlertTypeEnum.Error})
            }
        })
    }, [])

    /**
     * 監聽notifyObjectPaginationProps變動
     * @return {void}
     */
    useEffect(() => updateNotifyListTable(), [notifyObjectPaginationProps.pageNum, notifyObjectPaginationProps.pageSize])

    /**
     * 監聽renotifyPaginationProps變動
     * @return {void}
     */
    useEffect(() => updateCvisRenotifyRecordTable(), [renotifyPaginationProps.pageNum, renotifyPaginationProps.pageSize])

    /**
     * 監聽responsePaginationProps變動
     * @return {void}
     */
    useEffect(() => updateResponseRecordTable(), [responsePaginationProps.pageNum, responsePaginationProps.pageSize])

    /**
     * 監聽paginationProps變動
     * @return {void}
     */
    useEffect(() => {
        if (!stringIsEmpty(cvisQueryData.cvisNotifyEndDate) || !stringIsEmpty(cvisQueryData.cvisNotifyStartDate)) {
            handleQueryCvisNotifyrec()
        }
    }, [paginationProps.pageNum, paginationProps.pageSize])

    return (
        <div className="w-full p-4 bg-[#FAFAFA] h-[calc(100vh-107px)]">
            {/* 篩選條件 */}
            <div className="filterGroup flex flex-row flex-wrap items-center justify-start">
                <div className="upload flex flex-row items-center justify-start mb-4 mr-4 text-left">
                    <Select
                        data={{
                            label: '通報單位',
                            options: unit?.map((item) => ({
                                value: item.cvisNotifyUnitNo,
                                label: item.cvisNotifyUnitName
                            })) || []
                        }}
                        value={cvisQueryData.cvisNotifyUnit}
                        showLabel={true} width={SelectWidthEnum.Medium} notched={true}
                        onChange={handleCvisNotifyUnitOnChange}/>
                </div>
                {/* 通報日期 */}
                <div className="date flex flex-row items-center justify-start mb-4 mr-4">
                    {/* 通報日期起訖 */}
                    <DateRangePicker
                        localeText={{
                            start: '通報開始日期',
                            end: '通報結束日期'
                        }}
                        value={[
                            cvisQueryData.cvisNotifyStartDate ? dayjs(cvisQueryData.cvisNotifyStartDate) : null,
                            cvisQueryData.cvisNotifyEndDate ? dayjs(cvisQueryData.cvisNotifyEndDate) : null
                        ]}
                        onChange={handleCvisNotifyDateOnChange}/>
                </div>
                {/* 取消狀態 */}
                <div className="upload flex flex-row items-center justify-start mb-4 mr-4 text-left">
                    <Select
                        data={{
                            label: '取消狀態',
                            options: [
                                {label: '全部', value: ''},
                                {label: '未取消', value: '1'},
                                {label: '已取消', value: '2'}
                            ]
                        }}
                        value={cvisQueryData.cvisCancelStatus}
                        width={SelectWidthEnum.Small} displayEmpty={true} notched={true}
                        onChange={handleCvisCancelStatusOnChange}/>
                </div>
                {/* 讀取狀態 */}
                <div className="upload flex flex-row items-center justify-start mb-4 mr-4 text-left">
                    <Select
                        data={{
                            label: '讀取狀態',
                            options: [
                                {label: '全部', value: ''},
                                {label: '未讀', value: '1'},
                                {label: '已讀', value: '2'}
                            ]
                        }}
                        value={cvisQueryData.cvisReadStatus}
                        width={SelectWidthEnum.Small} displayEmpty={true} notched={true}
                        onChange={handleCvisReadStatusOnChange}/>
                </div>
                {/* 回覆狀態 */}
                <div className="upload flex flex-row items-center justify-start mb-4 mr-4 text-left">
                    <Select
                        data={{
                            label: '回覆狀態',
                            options: [
                                {label: '全部', value: ''},
                                {label: '未回覆', value: '1'},
                                {label: '已回覆', value: '2'}
                            ]
                        }}
                        value={cvisQueryData.cvisResponseStatus}
                        width={SelectWidthEnum.Small} displayEmpty={true} notched={true}
                        onChange={handleCvisResponseStatusOnChange}/>
                </div>
                {/* 查詢按鈕 */}
                <div className="source flex flex-row items-center justify-start mb-4 mr-4">
                    <Button sx={{marginRight: '0.5rem'}} color={ButtonColorEnum.Primary}
                            variant={ButtonVariantEnum.Contained} size={ButtonSizeEnum.Medium}
                            onClick={handleOnQuery} text={t('general.query')}/>
                </div>
            </div>
            {/* 危急值內容 */}
            <div className="maintainContainer flex flex-row items-stretch justify-start h-[calc(100%-60px)]">
                <ResizablePanel
                    direction={Direction.Horizontal}
                    cards={HorizontalPanel}/>
            </div>
            {/* 取消通報彈窗 */}
            {isShowCancelDialog && (
                <Dialog
                    open={isShowCancelDialog}
                    title={'取消通報'}
                    content={'確定要取消通報嗎?'}
                    variant={DialogVariant.DECISION} 
                    onConfirm={handleCancelCvisNotifyrec}
                    onClose={handleCancelCvisNotifyPopupOnClick}
                    muiDialogContentProps={{style: {display: 'flex', alignItems: 'center'}}}
                    paperStyleProps={{width: DialogSizeEnums.SM, height: '250px'}}
                />
            )}
        </div>
    )
}
export default CriticalValuesRecords
