import React, {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from "react"
import {
    ApiErrorStatusEnum, arrayIsEmpty,
    objectIsEmpty
} from "edah_utils/dist"
import {t} from "i18next"
import useToast from "../../hooks/useToast"
import {
    AlertTypeEnum,
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Card,
    ColorEnum,
    TextField,
    Pagination,
    Tabs, TabsEnum,
    Divider,WidthEnum
} from 'edah-component/dist'
import {AdvancedDataGrid, Checkbox, Field, RadioGroup, Search, Select, SizeEnum} from "edah-component/dist/index";
import {CpoeOrderSearch} from "./CpoeOrderSearch";
import {ocisEncounterQueryOrder, ocisEnCounterQueryBaseData,cpoeBatchModifyQty,
    cpoeBatchModifyFreqNo,cpoeBatchModifyDay,cpoeAddOrder,
    cpoeModifyOrder,
    cpoeSaveEncounterOrder,
} from "../../api/v1/Cpoe"
import {CpoePopup} from "./Popup/CpoePopup";

/**
 * CPOE操作主畫面
 * @param patientInfo {Object} 選中的病人資料
 * @param inpOpd {String} 就診類型--門診:"O"、住院:"I"、急診:"E"、健檢:"H"
 * @param bringSOICD {Function} 設置so，icd
 * @param ref
 * @return {JSX.Element}
 */
let CpoeMain = ({
                  patientInfo,
                  inpOpd, bringSOICD
                }, ref) => {
    const [refillDay, setRefillDay] = useState(28)
    // 待新增的order
    const [orderAdded, setOrderAdded] = useState(null)
    // 途徑下拉清單
    const [methodinfoList, setMethodinfoList] = useState([])
    // 付款方式下拉
    const [paidTypeList,setPaidTypeList] = useState([])
    // 頻率下拉清單
    const [usefreqinfoList,setUsefreqinfoList] = useState([])
    // 服用方式
    const [usageNoList,setUsageNoList] = useState([])
    // 開單彈窗-醫師清單
    const [doctorList,setDoctorList] = useState([])
    // 開單彈窗-科別清單
    const [divList,setDivList] = useState([])

    // 用藥記錄
    const [encounterOrder, setEncounterOrder] = useState([])
    // 未變更前的用藥記錄
    const [originalEncounterOrder, setOriginalEncounterOrder] = useState(null)
    // pagination控制變數
    const [encounterOrderPaginationProps, setEncounterOrderPaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    })

    //Toast Message Hooks
    const showToast = useToast()
    // 是否打開cpoe開單彈窗
    const [showCpoePopup, setShowCpoePopup] = useState(false)

    // cpoe tabitem（全部/藥品/檢查/檢驗/治療/護理/其餘）
    const cpoeTabItem = [
        { id: 'all',title: t('全部'),content: '' },
        { id: 'med',title: t('藥品'),content: '' },
        { id: 'test',title: t('檢驗'),content: '' },
        { id: 'inspection',title: t('檢查'),content: '' },
        { id: 'treatment',title: t('治療'),content: '' },
        { id: 'nursing',title: t('護理'),content: '' },
        { id: 'other',title: t('其餘'),content: '' },
    ]
    //當前選擇的tab
    const [currentTab, setCurrentTab] = useState('all')
    // 用藥指示textfield
    const [medInstruction, setMedInstruction] = useState('')

    // datagrid選中的行
    const [selectionRow, setSelectionRow] = useState(null)

    /**
     * 自定義button header，批次修改次量，頻率，天數
     * @param params {GridColumnHeaderParams}
     * @returns {Element}
     * @constructor
     */
    const buttonHeaderRender = (params) =>{
        const field = params.field
        const handleSyncValue = ()=>{
            // 儅有選中行點擊才能批次修改
            if(selectionRow){
                batchModifyOrder(field)
            }
        }
        return (
            <Button color={ColorEnum.Info} variant={ButtonVariantEnum.Contained}
                         size={ButtonSizeEnum.Small} onClick={handleSyncValue}>
                {t(field === 'day' ? '天數' : (field === 'qty' ? '次量': '頻率'))}
            </Button>
        )
    }



    /**
     * 自定義datagrid編輯模式下元件
     * @param params {GridRenderEditCellParams}
     * @param type {String} 預設為string，TextInput模式
     // * @param onChange {Event}
     */
    function EditComponent(params, type){
        const {id, value,field, api} = params
        const handleChange = async (e) => {
            let newValue = e?.target?.value || e
            await api.setEditCellValue({ id, field, value: newValue  });
            const updatedRows = encounterOrder.map((order) =>
                order.orderId === params.row.orderId ? { ...order, [field]: newValue } : order
            );
            setEncounterOrder(updatedRows);
            // 去後端更新數據
            const updatedRow = updatedRows.find(order => order.orderId === params.row.orderId);
            if(field === 'qty' || field === 'day' || field === 'freqNo') {
                modifyOrder(updatedRow)
            }
        }
        if(type === 'select'){
            let optionList = []
            if(field === 'freqNo') optionList = usefreqinfoList
            if(field === 'methodNo') optionList = methodinfoList
            if(field === 'paidType') optionList = paidTypeList
            if(field === 'bhpDivNo') optionList = usageNoList

            return (
                <div className="inline-flex">
                    <Select showLable={false}
                            data={{
                                label: '',
                                options: optionList.map(item => ({
                                    label: item.name,
                                    value: item.no
                                }))
                            }}
                            value={value}
                            onChange={handleChange}
                    />
                </div>)
        }
        return (<TextField id={`text-${id}`}
            defaultValue={value}
            onChange={handleChange}
        />)

    }

    /**
     * 處理行更新
     * @param newRow
     // * @param field , field
     */
    const processRowUpdate = (newRow) => {
        const updatedRow = { ...newRow, isNew: false };
        setEncounterOrder(encounterOrder.map((row) => (row.orderId === updatedRow.orderId ? updatedRow : row)))
        // 儅修改次量/頻率/天數(field==qty/frepNo/day),call後端更新總量
        // if(field === 'qty' || field === 'day' || field === 'freqNo') {
        //     modifyOrder(updatedRow)
        // }
        return updatedRow;
    };

    //醫囑header
    const header = [
        { field: 'type', headerName: '', width:100, type: 'string'  },
        { field: 'deleteFlag', headerName: t('general.delete'), width:100,
            renderCell: (params)=> (<Checkbox checked={params.value} onChange={(event) => {
                // 更新datagrid行數據
                params.row[params.field] = event.target.checked;
                setEncounterOrder(encounterOrder.map((row) => (row.orderId === params.row.orderId ? params.row : row)))
            }}/>)
        },
        { field: 'isMed', headerName: '藥品', width:100 , type: 'string'  },
        { field: 'orderName', headerName:'醫囑明細', width:300,
            renderCell: (params)=> {
                return (<span className={params.row.deleteFlag ? 'line-through text-[#71717A]':''}>
                    {params.value}
                </span>)
            }
        },
        { field: 'qty', headerName:'次量', width:100,  editable: true, type: 'string',
            renderHeader: (params)=> buttonHeaderRender(params),
            renderEditCell: (params) => EditComponent(params)
        },
        { field: 'qtyUnit', headerName: '單位', width:100,  editable: false, type: 'string' },
        { field: 'freqNo', headerName: '頻率', width:100,  editable: true, type: 'select',
            renderHeader: (params)=> buttonHeaderRender(params),
            renderEditCell: (params)=> EditComponent(params,'select')
        },
        { field: 'bhpDivNo', headerName: '服用方法', width:100,  editable: true, type: 'select',
            renderEditCell: (params)=> EditComponent(params,'select')
        },
        { field: 'methodNo', headerName: '途徑', width:100,  editable: true, type: 'select',
            renderEditCell: (params)=> EditComponent(params,'select')
        },
        { field: 'day', headerName: '天數', width:100,  editable: true, type: 'string',
            renderHeader: (params)=> buttonHeaderRender(params),
            renderEditCell: EditComponent
        },
        { field: 'refillRxFlag', headerName: '慢箋', width:80,
            renderCell: (params)=> (<Checkbox  value={params.value}/>)
        },
        { field: 'tqty', headerName: '總量', width:100,  editable: false, type: 'string' },
        { field: 'tqtyUnit', headerName: '單位', width:100,  editable: false, type: 'string' },
        { field: 'paidType', headerName: '自費', width:100,  editable: true, type: 'select',
            renderEditCell: (params,type)=> EditComponent(params,'select')
        },
        { field: 'specimenNo', headerName: '檢體', width:100,  editable: false, type: 'string' },
        { field: 'positionNo', headerName: '部位', width:100,  editable: false, type: 'string' },
        { field: 'scheduleDateTime', headerName: '排程', width:100, },
        { field: 'orderInstruction', headerName: '', width:140,
            renderCell: ()=>(
                <div className="inline-flex justify-start items-center">
                    <Button color={ColorEnum.Primary} variant={ButtonVariantEnum.Text} size={ButtonSizeEnum.Small}>
                        {t('説明')}
                    </Button>
                    <Button color={ColorEnum.Primary} variant={ButtonVariantEnum.Text} size={ButtonSizeEnum.Small}>
                        {t('備注')}
                    </Button>
                </div>
            )},
    ]

    /**
     * datagrid選擇行
     */
    const handleRowSelectChange = (newSelectionModel) =>{
        if(newSelectionModel.length > 0){
            const findedData = encounterOrder.find(item => (item.orderId === newSelectionModel[0]))
            setSelectionRow(findedData)
        }else{
            setSelectionRow(null)
        }
    }

    /**
     * 切換tab，根據tabid去query
     * @param tab {String}
     */
    const handleTableOnChange = (tab) => {
        setCurrentTab(tab)
    }

    /**
     * 臨時method，待改
     * @return {void}
     */
    const handleSearchOnClick = () => {
    }

    /**
     * 開啓開單Ditto彈窗
     */
    const handleShowCpoePopupOnClick = () =>{
        setShowCpoePopup(true)
    }

    /**
     * 關閉開單Ditto彈窗
     * @return void
     */
    const handleCpoePopupOnClose = () => {
        setShowCpoePopup(false)
    };

    /**
     * 開單Ditto添加到cpoe畫面
     * @param addedOrder{Array} ---待新增的order
     * @param encIcdCmParamList{Array} ---帶入的icd10cm
     * @param subjective{String} ---主訴
     * @param objective{String} --客訴
     * @param previousRecord{String}
     * }
     * @return void
     */
    const handleCpoeBatchAdd = ({addedOrder=[],encIcdCmParamList=[],subjective='',objective='',previousRecord=''}) => {
        setShowCpoePopup(false);
        // 調用parent component的方法帶入資料
        bringSOICD({encIcdCmParamList,subjective,objective,previousRecord});
        // 新增order
        (!arrayIsEmpty(addedOrder)) && addOrder(addedOrder)

    };

    /**
     * 選擇醫藥囑資料並開單
     * @param value {Object}
     */
    const handleOrderOptionOnClick = (value) =>{
        if(!objectIsEmpty(value)){
            // setOrderAdded(value)
            //開單(添加到用藥記錄畫面最後一筆)
            addOrder([value])
            setOrderAdded(null)
        }
    }

    /**
     * 初始化取得相關下拉清單資訊
     * params：{
     *     encounterDate:2024-06-25 00:00:00,
     *     inpOpd: 'O'  ===門診:"O"、住院:"I"、急診:"E"、健檢:"H"
     * }
     */
    const queryBaseData = () =>{
        ocisEnCounterQueryBaseData({
            encounterDate: patientInfo.encounterDate,
            inpOpd: inpOpd
        }).then((res) =>{
            if (res.err === ApiErrorStatusEnum.Success) {
                setMethodinfoList(res.data?.methodinfoList)
                setUsefreqinfoList(res.data?.usefreqinfoList)
                setPaidTypeList(res.data?.paidTypeList)
                setUsageNoList(res.data?.usageNoList)
                setDoctorList(res.data?.doctorList)
                setDivList(res.data?.divList)
            }
        })
    }


    /**
     * 查詢醫囑清單
     * @param  encounterId{String} 查詢的文字
     * @return {void}
     */
    const queryEncounterOrder = (encounterId) => {
        ocisEncounterQueryOrder({
            encounterId: encounterId,
        }).then((res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                // 設置用藥記錄
                setEncounterOrder(res.data)
                // 保存原始用藥記錄（未異動前）
                setOriginalEncounterOrder(res.data)
            } else {
                const msg = `取得用藥記錄失敗: ${res.msg}`
                showToast({message: msg, type: AlertTypeEnum.Error})

                setEncounterOrder([])

            }
        }))
    }

    /**
     * 批量修改頻率/天數/次量
     * @param type {String}
     * @return {void}
     */
    const batchModifyOrder = (type) =>{
        // 根據類型去call不同的api type = day/qty/freqNo
        let batchApi = type === 'freqNo' ? cpoeBatchModifyFreqNo
            : type === 'qty' ? cpoeBatchModifyQty : cpoeBatchModifyDay
        batchApi({
            encounter: {...patientInfo},
            inpOpd: inpOpd,
            refillDay: refillDay,
            orderList: [...encounterOrder],
            baseOrder: {...selectionRow}
        }).then((res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setEncounterOrder(res.data.orderList)
            } else{
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        }))
    }

    /**
     * 修改次量、頻率、天數後call服務重算該醫囑相關資料，如總量
     * @param modifyData {Object}
     * @return {void}
     */
    const modifyOrder = (modifyData) =>{
        //處理api的傳值
        let data = {
            encounter: {...patientInfo},
            inpOpd: inpOpd,
            refillDay: refillDay,
            // 當前畫面orderList
            orderList: [...encounterOrder],
            // 醫囑更動前
            origOrder: originalEncounterOrder.find(order => order.orderId === modifyData.orderId),
            // 醫囑更動後
            order: {...modifyData}
        }
        cpoeModifyOrder(data).then((res) =>{
            if (res.err === ApiErrorStatusEnum.Success) {
                // 回傳orderList與order
                const modifyOrder = res.data.order
                setEncounterOrder(encounterOrder.map(order => order.orderId === modifyOrder.orderId ? modifyOrder : order))
            }else{
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 根據選擇的orderCode/orderName去query對應好的Encounterorder entity obj
     * @param addData{Array}
     * @return {void}
     */
    const addOrder = (addData) =>{
        //處理api的傳值
        let data = {
            encounter: {...patientInfo},
            inpOpd: inpOpd,
            // 畫面上所有醫囑
            orderList: [...encounterOrder],
            // 待新增的order
            addOrderList: addData
        }
        cpoeAddOrder(data).then(res =>{
            if (res.err === ApiErrorStatusEnum.Success) {
                // 待與後端確認
                // 會回傳：orderList  //畫面醫囑（後端說：需把orderList整個覆蓋上去）
                // infoOrderList  //需提示的醫囑
                // warnOrderList  //需警告的醫囑
                // replaceOrderList  //可替換的醫囑
                // addOrderList //待新增的order
                const addOrderList = res.data.addOrderList || []
                setEncounterOrder([...encounterOrder, ...addOrderList])
            } else{
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 將cpoe醫囑存檔
     * @return {void}
     */
    const saveEncounterOrder = () =>{
        //處理api的傳值
        let data = {
            ocisEncounter: {...patientInfo},
            // 畫面上所有醫囑
            encounterOrderList: [...encounterOrder],
        }
        cpoeSaveEncounterOrder(data).then(res =>{
            if (res.err === ApiErrorStatusEnum.Success) {
                // 儲存成功后refresh畫面
                queryEncounterOrder(patientInfo.encounterId)

            } else{
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 獲取cpoe醫囑内容方便存檔
     * @return {Array}
     */
    const getEncounterOrder = () =>{
        return encounterOrder
    }
    /**
     * 初始
     */
    useMemo(() => {
    }, [])

    /**
     * 可以傳遞給父元件的方法
     * @return {void}
     */
    useImperativeHandle(ref, ()=>({
        getEncounterOrder
    }))

    /**
     * 畫面初始化查詢基礎資料
     */
    useEffect(()=>{
        queryBaseData()
        queryEncounterOrder(patientInfo.encounterId)
    }, [patientInfo])


    // ===臨時 for test 全表格編輯======================
    const [rowModesModel, setRowModesModel] = useState({})
    const handleRowModesModelChange = (newRowModesModel) => {
        setRowModesModel(newRowModesModel);
    };
    const handleRowEditStop = (params, event) => {
        if (params.reason === 'rowFocusOut') {
            event.defaultMuiPrevented = true;
        }
    };

    // ============================================

    return (
        <>
            <div className="w-full  bg-[#FAFAFA] space-y-2.5 ">
            {/*CPOE模組 title={"CPOE"}*/}
                <Card >
                    <div className="flex flex-row items-center mb-2">
                        <span className="inline-block w-2 h-[22px] mr-2 bg-primary"></span>
                        <h6 className="text-primary font-medium text-[20px]">CPOE</h6>
                        {/*操作欄*/}
                        <div className="flex-1 ml-4 flex items-center justify-between">
                            <div className="flex items-center gap-2">
                                {/*提供醫囑全文檢索*/}
                                <CpoeOrderSearch
                                    value={orderAdded}
                                    inputLabel={t('提供醫囑全文檢索')}
                                    onChange={(_e, value) => handleOrderOptionOnClick(value)}
                                    getOptionLabel={(option) => `${option?.orderCode} ${option?.orderName}`}/>
                                <Tabs
                                    items={cpoeTabItem}
                                    variant={TabsEnum.INDICATOR_TAB}
                                    activeTabId={currentTab}
                                    onTabChange={handleTableOnChange}
                                    tabsProps={{}}
                                />
                            </div>
                            <div className="flex justify-end items-center border border-[#2b6cb0] rounded">
                                <Button color={ColorEnum.Primary} variant={ButtonVariantEnum.Text}
                                        size={ButtonSizeEnum.Medium}
                                        onClick={handleShowCpoePopupOnClick}>
                                    {t('開單')}
                                </Button>
                                <Divider orientation="vertical"/>
                                <Button color={ColorEnum.Primary} variant={ButtonVariantEnum.Text}
                                        size={ButtonSizeEnum.Medium}
                                        onClick={handleSearchOnClick}>
                                    {t('慢箋')}
                                </Button>
                                <Divider orientation="vertical"/>
                                <Button color={ColorEnum.Primary} variant={ButtonVariantEnum.Text}
                                        size={ButtonSizeEnum.Medium}
                                        onClick={handleSearchOnClick}>
                                    {t('自費同意書')}
                                </Button>
                            </div>
                        </div>
                    </div>
                    {/*用藥記錄*/}
                    <AdvancedDataGrid
                        rows={encounterOrder}
                        columns={header}
                        editMode="row"
                        checkboxSelection={false}
                        disableRowSelectionOnClick={false}
                        disableColumnMenu={true}
                        disableColumnSorting={true}
                        height={'450px'}
                        getRowHeight={(params) => 40}
                        columnHeaderHeight={40}
                        getRowId={(row) => row.orderId}
                        rowModesModel={rowModesModel}
                        onRowModesModelChange={handleRowModesModelChange}
                        onRowEditStop={handleRowEditStop}
                        processRowUpdate={processRowUpdate}
                        onSelectionChange={(newSelection) =>
                            handleRowSelectChange(newSelection)
                        }
                    />
                    {/*總筆數*/}
                    <div className="flex justify-end w-full my-2">
                        <Field labelWidth={80} label="總筆數" sx={{width: 'auto', mr: 2}}>
                            {encounterOrder?.length || 0}
                        </Field>
                    </div>
                    {/*用藥指示*/}
                    <Field labelWidth={80} label="用藥指示" sx={{width: 'auto'}}>
                        <TextField
                            value={medInstruction} inputWidth={SizeEnum.Fill}
                            onChange={(e) => setMedInstruction(e.target.value)}/>
                    </Field>
                </Card>
            </div>
            <CpoePopup show={showCpoePopup} patientInfo={patientInfo} divList={divList} doctorList={doctorList}
                       closePopupButtonOnClick={handleCpoePopupOnClose} onConfirm={handleCpoeBatchAdd}/>
        </>
    )
}

CpoeMain = forwardRef(CpoeMain)
export default CpoeMain
