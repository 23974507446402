import React, {useMemo, useState} from 'react'
import SideTab from '../Menu/SideTab'
import {getLocalStorage} from 'edah_utils/dist'
import {querySystemByRole} from '../../api/v1/Menu'
import {REFERRAL_SYSTEM_NO} from '../../constants/systemNo'
import {
    ADD_REFERRAL_NO,
    APPLY_DISCHARGE_SUMMARY_NO,
    APPROVE_DISCHARGE_SUMMARY_NO,
    EMERGENCY_HOSPITAL_MAINTAIN_NO,
    ER_QUALITY_VPN_UPLOAD_EXPORT_NO,
    LONG_TERM_CARE_CENTER_NO,
    MAINTAIN_NO,
    NO_REFERRAL_REPORT_NO,
    OTHERS_NO,
    PERMISSION_SETTING_NO,
    QUERY_PATIENT_VISIT_LIST_NO,
    REFERRAL_CODE_MAINTAIN_NO,
    REFERRAL_FORM_DOWNLOAD_NO,
    REFERRAL_FORM_UPLOAD_NO,
    REFERRAL_PLUGIN_UPLOAD_NO,
    REFERRAL_REPLY_LETTER,
    RURAL_HOSPITAL_NO,
    STRATEGIC_ALLIANCE_MAINTAIN_NO,
    TRAN_FOREIGN_LIST_NO,
    TRAN_IN_CASE_LIST_NO,
    TRAN_OUT_CASE_LIST_NO,
} from '../../constants/menuFuncNo'
import {HisTree, HisTreeTextAlignEnum, FREQUENTLY_NAME} from "edah-component/dist"

// Menu items
const staticMenuItems = [
    {
        "systemNo": "CVIS",
        "systemName": "危急值",
        "sortBy": null,
        "funList": [
            {
                "funNo": "CVIS-MA-01",
                "funTitle": "危急值通報紀錄",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "CVIS-MA-02",
                "funTitle": "感染管制通報記錄",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "CVIS-MA-03",
                "funTitle": "危急值逾時回覆查詢",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "CVIS-MA-04",
                "funTitle": "危急值通報閉環監控",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "CVIS-MO-01",
                "funTitle": "危急值通報",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "CVIS-MO-03",
                "funTitle": "A級危急值逾時回覆再通知",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "CVIS-MO-05",
                "funTitle": "待回覆提示",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                parentFunNo: 'CVIS-MO-05',
                "nodeFlag": "false"
            }
        ]
    },
    {
        "systemNo": "AP",
        "systemName": "掛號系統",
        "sortBy": null,
        "funList": [
            {
                funNo: 'AP-0001',
                funTitle: '掛號作業',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: null,
                nodeFlag: 'false',
            },
            {
                funNo: 'AP-0002',
                funTitle: '停代診聯絡作業',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: null,
                nodeFlag: 'false',
            },
            {
                funNo: 'AP-0004',
                funTitle: '醫囑預約掛號作業',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: null,
                nodeFlag: 'false',
            },
            // New Sidebar for 月班維護
            {
                funNo: 'AP-SS',
                funTitle: '排班作業',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: null,
                nodeFlag: 'true',
                funList: [
                    {
                        funNo: 'AP-SS-1',
                        funTitle: '週班維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: 'AP-SS-1',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: 'AP-SS-2',
                        funTitle: '月班維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: 'AP-SS-2',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: 'AP-SS-3',
                        funTitle: '班表批次異動',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: 'AP-SS-3',
                        nodeFlag: 'false',
                    },
                ],
            },
            {
                funNo: 'AP-SM',
                funTitle: '查詢作業',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: 'null',
                nodeFlag: 'true',
                funList: [
                    {
                        funNo: 'AP-SM-0004',
                        funTitle: '診間班表查詢',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: 'AP-SM-0004',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: 'AP-SM-0005',
                        funTitle: '掛號查詢',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: 'AP-SM-0005',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: 'AP-SM-0006',
                        funTitle: '列印作業',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: 'AP-SM-0006',
                        nodeFlag: 'false',
                    }
                ]
            }
            ,
            {
                funNo: 'AP-MM',
                funTitle: '維護作業',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: 'null',
                nodeFlag: 'true',
                funList: [
                    {
                        funNo: 'AP-MM-0001',
                        funTitle: '給號方式維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: 'AP-MM-0001',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: 'AP-MM-0002',
                        funTitle: '行事曆維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: 'AP-MM-0002',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: 'AP-MM-0003',
                        funTitle: '標語維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: 'AP-MM-0003',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: 'AP-MM-0004',
                        funTitle: '類別維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: 'AP-MM-0004',
                        nodeFlag: 'false',
                    }, {
                        funNo: 'AP-MM-0005',
                        funTitle: '科別維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: 'AP-MM-0005',
                        nodeFlag: 'false',
                    },
                ]
            }
        ],
    },
    {
        systemNo: 'BP',
        systemName: 'IC模組',
        sortBy: null,
        funList: [
            {
                funNo: 'BP-0001',
                funTitle: '主功能',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: 'null',
                nodeFlag: 'true',
                //測試用子層資料
                funList: [
                    {
                        funNo: 'BP-0001-1',
                        funTitle: '病人IC卡資料查詢',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: 'BP-0001-1',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: 'BP-0001-6',
                        funTitle: 'IC卡寫卡',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: 'BP-0001-6',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: 'BP-0001-2',
                        funTitle: 'IC卡寫卡資料維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: 'BP-0001-2',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: 'BP-0001-3',
                        funTitle: 'IC卡資料上傳資料查詢',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: 'BP-0001-3',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: 'BP-0001-4',
                        funTitle: '待寫卡清單',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: 'BP-0001-4',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: 'BP-0001-5',
                        funTitle: '健保醫療資訊雲端查詢',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: 'BP-0001-5',
                        nodeFlag: 'false',
                    },
                ],
            },
            {
                funNo: 'BP-0002',
                funTitle: '維護作業',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: 'null',
                nodeFlag: 'true',
                //測試用子層資料
                funList: [
                    {
                        funNo: 'BP-0002-1',
                        funTitle: '重大傷病資料維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: 'BP-0002-1',
                        nodeFlag: 'false',
                    },
                ],
            },
        ],
    },
    {
        "systemNo": "CP",
        "systemName": "CDSS系統",
        "sortBy": null,
        "funList": [
            {
                "funNo": "CP-0001",
                "funTitle": "維護作業",
                "funUrl": null,
                "iconPath": null,
                "sortBy": 1,
                "parentFunNo": "null",
                "nodeFlag": "true",
                //測試用子層資料
                "funList": [
                    {
                        "funNo": "CP-0001-1",
                        "funTitle": "CDSS Information維護",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": 1,
                        "parentFunNo": "CP-0001-1",
                        "nodeFlag": "false",

                    }
                ]
            }
        ]
    },
    {
        "systemNo": "ES",
        "systemName": "電子簽章",
        "sortBy": null,
        "funList": [
            {
                "funNo": "ES-0001",
                "funTitle": "主功能",
                "funUrl": null,
                "iconPath": null,
                "sortBy": 1,
                "parentFunNo": "null",
                "nodeFlag": "true",
                //測試用子層資料
                "funList": [
                    {
                        "funNo": "ES-0001-1",
                        "funTitle": "未簽文件查詢與簽章",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": 1,
                        "parentFunNo": "ES-0001-1",
                        "nodeFlag": "false",

                    },
                    {
                        "funNo": "ES-0001-2",
                        "funTitle": "電子病歷查詢及驗章",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": 1,
                        "parentFunNo": "ES-0001-2",
                        "nodeFlag": "false"
                    },
                    {
                        "funNo": "ES-0001-3",
                        "funTitle": "醫療機構卡簽章",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": 1,
                        "parentFunNo": "ES-0001-3",
                        "nodeFlag": "false"
                    },
                    {
                        "funNo": "ES-0001-4",
                        "funTitle": "操作記錄查詢",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": 1,
                        "parentFunNo": "ES-0001-4",
                        "nodeFlag": "false"
                    },
                    {
                        "funNo": "ES-0001-5",
                        "funTitle": "簽章率明細與查詢 ",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": 1,
                        "parentFunNo": "ES-0001-5",
                        "nodeFlag": "false"
                    },
                    {
                        "funNo": "ES-0001-6",
                        "funTitle": "24小時簽章率明細與查詢",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": 1,
                        "parentFunNo": "ES-0001-6",
                        "nodeFlag": "false"
                    },
                ]
            },
            {
                "funNo": "ES-0002",
                "funTitle": "維護作業",
                "funUrl": null,
                "iconPath": null,
                "sortBy": 1,
                "parentFunNo": "null",
                "nodeFlag": "true",
                //測試用子層資料
                "funList": [
                    {
                        "funNo": "ES-0002-1",
                        "funTitle": "電子表單類別維護 ",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": 1,
                        "parentFunNo": "ES-0002-1",
                        "nodeFlag": "false",
                    },
                    {
                        "funNo": "ES-0002-2",
                        "funTitle": "人員憑證維護",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": 1,
                        "parentFunNo": "ES-0002-2",
                        "nodeFlag": "false",
                    },
                    {
                        "funNo": "ES-0002-3",
                        "funTitle": "代簽人員維護",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": 1,
                        "parentFunNo": "ES-0002-3",
                        "nodeFlag": "false",
                    }
                ]
            },
        ]
    },
    {
        "systemNo": "HCIS",
        "systemName": "居家系統",
        "sortBy": null,
        "funList": [
            {
                "funNo": "HCIS-FN",
                "funTitle": "主功能",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "true",
                "funList": [
                    {
                        "funNo": "HCIS-FN-01",
                        "funTitle": "居家評估作業",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "HCIS-FN-00",
                        "nodeFlag": "false",
                        "funList": null
                    },
                    {
                        "funNo": "HCIS-FN-02",
                        "funTitle": "居家潛在個案維護(S1)",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "HCIS-FN-00",
                        "nodeFlag": "false",
                        "funList": null
                    }
                ]
            },
            {
                "funNo": "HCIS-MA",
                "funTitle": "維護作業",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "true",
                "funList": [
                    {
                        "funNo": "HCIS-MA-01",
                        "funTitle": "居家護理記錄項目維護",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "HCIS-MA-00",
                        "nodeFlag": "false",
                        "funList": null
                    },
                    {
                        "funNo": "HCIS-MA-02",
                        "funTitle": "居家個案已結案狀態維護",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "HCIS-MA-00",
                        "nodeFlag": "false",
                        "funList": null
                    }
                ]
            },
            {
                "funNo": "HCIS-MO",
                "funTitle": "上傳作業",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "true",
                "funList": [
                    {
                        "funNo": "HCIS-MO-01",
                        "funTitle": "居護個案基本資料API ",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "HCIS-MO-00",
                        "nodeFlag": "false",
                        "funList": null
                    },
                    {
                        "funNo": "HCIS-MO-02",
                        "funTitle": "居護全人評估API ",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "HCIS-MO-00",
                        "nodeFlag": "false",
                        "funList": null
                    },
                    {
                        "funNo": "HCIS-MO-03",
                        "funTitle": "居護需求摘要API",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "HCIS-MO-00",
                        "nodeFlag": "false",
                        "funList": null
                    },
                    {
                        "funNo": "HCIS-MO-04",
                        "funTitle": "居護照護計畫API",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "HCIS-MO-00",
                        "nodeFlag": "false",
                        "funList": null
                    },
                    {
                        "funNo": "HCIS-MO-05",
                        "funTitle": "居護照護紀錄API ",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "HCIS-MO-00",
                        "nodeFlag": "false",
                        "funList": null
                    },
                    {
                        "funNo": "HCIS-MO-06",
                        "funTitle": "居護共照紀錄API ",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "HCIS-MO-00",
                        "nodeFlag": "false",
                        "funList": null
                    },
                    {
                        "funNo": "HCIS-MO-07",
                        "funTitle": "居護人員緊急事件API ",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "HCIS-MO-00",
                        "nodeFlag": "false",
                        "funList": null
                    },
                    {
                        "funNo": "HCIS-MO-08",
                        "funTitle": "居護個案結案API ",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "HCIS-MO-00",
                        "nodeFlag": "false",
                        "funList": null
                    },
                    {
                        "funNo": "HCIS-MO-09",
                        "funTitle": "居護照護計畫結案API ",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "HCIS-MO-00",
                        "nodeFlag": "false",
                        "funList": null
                    }
                ]
            },
            {
                "funNo": "HCIS-QR",
                "funTitle": "列印作業",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "true",
                "funList": [
                    {
                        "funNo": "HCIS-QR-01",
                        "funTitle": "個案查詢報表 ",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "HCIS-QR-00",
                        "nodeFlag": "false",
                        "funList": null
                    },
                    {
                        "funNo": "HCIS-QR-02",
                        "funTitle": "再入院查詢報表 ",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "HCIS-QR-00",
                        "nodeFlag": "false",
                        "funList": null
                    },
                    {
                        "funNo": "HCIS-QR-03",
                        "funTitle": "急診使用查詢報表 ",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "HCIS-QR-00",
                        "nodeFlag": "false",
                        "funList": null
                    },
                    {
                        "funNo": "HCIS-QR-04",
                        "funTitle": "皮膚損傷匯出報表 ",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "HCIS-QR-00",
                        "nodeFlag": "false",
                        "funList": null
                    },
                    {
                        "funNo": "HCIS-QR-05",
                        "funTitle": "藥物到期查詢報表 ",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "HCIS-QR-00",
                        "nodeFlag": "false",
                        "funList": null
                    }
                ]
            }
        ]
    },
    {
        "systemNo": "WDIS",
        "systemName": "系統監控",
        "sortBy": null,
        "funList": [
            {
                "funNo": "WDIS-MA-01",
                "funTitle": "服務資料維護",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "WDIS-MA-02",
                "funTitle": "通知人員維護",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "WDIS-FN-02",
                "funTitle": "服務狀態查詢",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "WDIS-QR-01",
                "funTitle": "服務Log查詢",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "WDIS-QR-02",
                "funTitle": "系統服務Log查詢",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            }
        ]
    },
    {
        "systemNo": "RSIS",
        "systemName": "報備支援",
        "sortBy": null,
        "funList": [
            {
                "funNo": "RSIS-FN-01",
                "funTitle": "申請與查詢",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "RSIS-FN-02",
                "funTitle": "整批變更註銷或刪除",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "RSIS-FN-03",
                "funTitle": "整批匯入作業",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "RSIS-FN-04",
                "funTitle": "急件申請",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "RSIS-FN-05",
                "funTitle": "衛福部案號回饋作業",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "RSIS-FN-06",
                "funTitle": "個案管理",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            },
            {
                "funNo": "RSIS-MA",
                "funTitle": "維護作業",
                "funUrl": null,
                "iconPath": null,
                "sortBy": 1,
                "parentFunNo": null,
                "nodeFlag": "true",
                "funList": [
                    {
                        "funNo": "RSIS-MA-01",
                        "funTitle": "支援機構維護",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "RSIS-MA-00",
                        "nodeFlag": "false"
                    },
                    {
                        "funNo": "RSIS-MA-03",
                        "funTitle": "承辦部門人員維護",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "RSIS-MA-00",
                        "nodeFlag": "false"
                    },
                    {
                        "funNo": "RSIS-MA-04",
                        "funTitle": "病房代理人維護",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "RSIS-MA-00",
                        "nodeFlag": "false"
                    },
                    {
                        "funNo": "RSIS-MA-05",
                        "funTitle": "特殊權限設定",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "RSIS-MA-00",
                        "nodeFlag": "false"
                    },
                    {
                        "funNo": "RSIS-MA-06",
                        "funTitle": "警示信箱發送",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "RSIS-MA-00",
                        "nodeFlag": "false"
                    },
                    {
                        "funNo": "RSIS-MA-07",
                        "funTitle": "功能操作說明維護",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "RSIS-MA-00",
                        "nodeFlag": "false"
                    },
                    {
                        "funNo": "RSIS-MA-08",
                        "funTitle": "基本資料設定",
                        "funUrl": null,
                        "iconPath": null,
                        "sortBy": null,
                        "parentFunNo": "RSIS-MA-00",
                        "nodeFlag": "false"
                    },
                ]
            }
        ]
    },
    {
        systemNo: REFERRAL_SYSTEM_NO,
        systemName: '轉診系統',
        sortBy: null,
        funList: [
            {
                funNo: REFERRAL_FORM_DOWNLOAD_NO,
                funTitle: '電子轉診單_下載',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: '',
                nodeFlag: 'false',
            },
            {
                funNo: REFERRAL_FORM_UPLOAD_NO,
                funTitle: '電子轉診單_上傳',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: '',
                nodeFlag: 'false',
            },
            {
                funNo: REFERRAL_REPLY_LETTER,
                funTitle: '轉診回函',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: '',
                nodeFlag: 'false',
            },
            {
                funNo: APPLY_DISCHARGE_SUMMARY_NO,
                funTitle: '出院病摘_申請/列印',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: '',
                nodeFlag: 'false',
            },
            {
                funNo: APPROVE_DISCHARGE_SUMMARY_NO,
                funTitle: '出院病摘_核准',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: '',
                nodeFlag: 'false',
            },
            {
                funNo: TRAN_IN_CASE_LIST_NO,
                funTitle: '轉入單個管作業_清單',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: '',
                nodeFlag: 'false',
            },
            {
                funNo: TRAN_OUT_CASE_LIST_NO,
                funTitle: '轉出單個管作業_清單',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: '',
                nodeFlag: 'false',
            },
            {
                funNo: TRAN_FOREIGN_LIST_NO,
                funTitle: '病症暨失能診斷證明書_清單',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: '',
                nodeFlag: 'false',
            },
            {
                funNo: ADD_REFERRAL_NO,
                funTitle: '轉出轉入開立作業',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: '',
                nodeFlag: 'false',
            },
            {
                funNo: OTHERS_NO,
                funTitle: '其他作業',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: '',
                nodeFlag: 'true',
                funList: [
                    {
                        funNo: QUERY_PATIENT_VISIT_LIST_NO,
                        funTitle: '看診清單查詢',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: OTHERS_NO,
                        nodeFlag: 'false',
                    },
                    {
                        funNo: NO_REFERRAL_REPORT_NO,
                        funTitle: '不轉診原因報表',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: OTHERS_NO,
                        nodeFlag: 'false',
                    },
                    {
                        funNo: ER_QUALITY_VPN_UPLOAD_EXPORT_NO,
                        funTitle: '急診品質方案VPN上傳匯出作業',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: OTHERS_NO,
                        nodeFlag: 'false',
                    },
                    // {
                    //     funNo: MARS_REPORT_NO,
                    //     funTitle: 'MARS(VPN)報表',
                    //     funUrl: null,
                    //     iconPath: null,
                    //     sortBy: 1,
                    //     parentFunNo: OTHERS_NO,
                    //     nodeFlag: 'false',
                    // },
                    {
                        funNo: REFERRAL_PLUGIN_UPLOAD_NO,
                        funTitle: '轉診外掛單機上傳程式',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: OTHERS_NO,
                        nodeFlag: 'false',
                    },
                ]
            },
            {
                funNo: MAINTAIN_NO,
                funTitle: '維護作業',
                funUrl: null,
                iconPath: null,
                sortBy: 1,
                parentFunNo: '10011-SM-0000',
                nodeFlag: 'true',
                //測試用子層資料
                funList: [
                    {
                        funNo: PERMISSION_SETTING_NO,
                        funTitle: '權限設定(轉診外網)',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: REFERRAL_CODE_MAINTAIN_NO,
                        funTitle: '轉診代碼維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: EMERGENCY_HOSPITAL_MAINTAIN_NO,
                        funTitle: '急救責任醫院維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: STRATEGIC_ALLIANCE_MAINTAIN_NO,
                        funTitle: '策略聯盟維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: LONG_TERM_CARE_CENTER_NO,
                        funTitle: '長照中心維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '',
                        nodeFlag: 'false',
                    },
                    {
                        funNo: RURAL_HOSPITAL_NO,
                        funTitle: '離島偏鄉醫院維護',
                        funUrl: null,
                        iconPath: null,
                        sortBy: 1,
                        parentFunNo: '',
                        nodeFlag: 'false',
                    },
                ],
            },
        ],
    },
    // CRUD
    {
        "systemNo": "CRUD",
        "systemName": "CRUD",
        "sortBy": null,
        "funList": [
            {
                "funNo": "CRUD-SETTING-1",
                "funTitle": "設定清單",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            }
        ]
    },
    // EMR
    {
        "systemNo": "EMR",
        "systemName": "醫師工作站",
        "sortBy": null,
        "funList": [
            {
                "funNo": "EMR-MA-1",
                "funTitle": "門診醫囑",
                "funUrl": null,
                "iconPath": null,
                "sortBy": null,
                "parentFunNo": null,
                "nodeFlag": "false"
            }
        ]
    }
]

const commonItems = [
    {
        id: "AP-SS-1",
        name: '週班維護',
        description: '掛號作業 / 排班作業'
    },
    {
        id: "BP-0001-1",
        name: '病人IC卡資料查詢',
        description: 'IC模組 / 主功能'
    },
    {
        id: "CVIS-MO-05",
        name: '待回覆提示',
        description: '危急值'
    },

]

/**
 * 重新組裝結構
 * @param items {Array}
 * @return {Array}
 */
const buildTree = (items) => items.map((item) => {
    const node = {
        id: item.funNo,
        name: item.funTitle,
    }
    // 根據是否存在funlist來判斷是否有子節點
    if (item.funList && item.funList.length > 0) {
        node.children = buildTree(item.funList)
    }
    return node
})

/**
 * 側邊欄
 * @param Add {Function} 新增Tab
 * @param isOpen {Boolean} 是否開啟
 * @param toggleSidebar {Function} 切換側邊欄
 * @param activeTabId {String} 選擇的Tab
 * @return {JSX.Element}
 */
const Sidebar = ({Add, isOpen, toggleSidebar, activeTabId}) => {
    //Menu items
    const [items, setItems] = useState([])

    /**
     * 選擇項目
     * @param id {String}
     * @param name {String}
     * @param hasChildren {Boolean}
     * @return {void}
     */
    const handleSelectItem = (id, name, hasChildren) => {
        if (id.includes(FREQUENTLY_NAME)) {
            const result = id.split(FREQUENTLY_NAME + '-');
            if (result.length > 1 && !hasChildren) {
                Add(result[1], name);
            }
        } else if(!hasChildren){
            Add(id, name);
        }
    }

    //避免資料太多導致渲染效率差
    useMemo(() => {
        //取得當前使用者Role
        const role = getLocalStorage('role')
        //使用者Role存在的話
        if (role) {
            //將Role轉成Array
            const roleArray = JSON.parse(role)
            //目前API只能傳入一個角色
            const roleNos = roleArray[0].roleNo

            //將取得到的結果重新組裝新結構
            const data = staticMenuItems.map((system) => ({
                id: system.systemNo,
                name: system.systemName,
                children: buildTree(system.funList),
            }))

            //將結果配置給Items
            setItems(data)
            //取得角色Menu清單
            querySystemByRole({roleNos: roleNos}).then((res) => {
                if (res.err === 0) {
                    //     res.data.map(system => ({
                    //     id: system.systemNo,
                    //     name: system.systemName,
                    //     children: buildTree(system.funList),
                    // }))
                }
            })
        }
    }, [])

    return (
        <div className="relative">
            <SideTab isOpen={isOpen} toggleMenu={toggleSidebar}/>
            <div
                className={`${isOpen ? 'w-[232px]' : 'w-0'} bg-[#F4F4F5] shadow-md rounded-lg h-[calc(100vh-56px)] overflow-scroll`}>
                <div className="px-3 pt-3">
                    <HisTree
                        selectedNodeId={activeTabId}
                        menuTree={items}
                        textAlign={HisTreeTextAlignEnum.Left}
                        frequentlyUsedArray={commonItems}
                        onSelectNode={handleSelectItem}/>
                </div>
            </div>
        </div>
    )
}

export default Sidebar
