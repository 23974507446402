import React, {useEffect, useState} from "react"
import {Dialog, DialogSizeEnums, DialogVariant} from "edah-component/dist";

const DeleteRuleMessagePopup = ({ 
    show = false,
    closePopupButtonOnClick, 
    handleDeleteOnClick
}) => {
    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show);

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        // 設定是否顯示彈窗狀態
        setShowPopup(show)
    }, [show])

    return (
        showPopup ?
        <Dialog
            paperStyleProps={{width:DialogSizeEnums.SM}}
            open={showPopup}
            title={"提醒"}
            content={<p>是否確定刪除?</p>}
            variant={DialogVariant.DECISION}
            onClose={closePopupButtonOnClick}
            onConfirm={handleDeleteOnClick}
        />
        : <></>
    )
}

export default DeleteRuleMessagePopup;