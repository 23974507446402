import React, {useEffect, useState} from "react";
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    objectIsEmpty,
    stringIsEmpty,
    time2String,
    TimeslotEnum
} from "edah_utils/dist";
import {regsQueryFirstviewByField} from "../../../api/v1/RegsFirstView";
import {regsAddFirstviewToReg} from "../../../api/v1/Regs";
import {ShowGiveNumberPopup} from "../AppointmentSchedule/ShowGiveNumberPopup";
import {Pagination} from "../../Pagination/Pagination";
import {isCurrentDateTimeWithinRange} from "../utils";
import {t} from "i18next";
import useToast from "../../../hooks/useToast";
import {
    AlertTypeEnum,
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    ColorEnum,
    DatePicker,
    Dialog,
    DialogSizeEnums,
    DialogVariant,
    Field,
    RadioGroup,
    Select,
    SizeEnum,
    TextField
} from "edah-component/dist";
import {SearchTextField} from "../../SearchTextField/SearchTextField";
import dayjs from "dayjs";
import {addNewPatient} from "../../../api/v1/Mris";

/**
 * 初診病人預約名單彈窗
 * @param show {Boolean} 是否顯示彈窗
 * @param closePopupButtonOnClick {Function} 關閉事件
 * @param fvSearchOptionList {Array} 查詢欄位list
 * @param apnList {Array} 時段list
 * @param patientId {String} 病歷號
 * @param importAppointmentData {Object} 傳入的預約資料
 * @param triggerResetAppointment {Function} 重置預約資料
 * @returns
 */
const FirstVisitPatientAppointmentListPopup = ({
                                                   closePopupButtonOnClick,
                                                   show = false,
                                                   fvSearchOptionList,
                                                   apnList,
                                                   patientId,
                                                   importAppointmentData,
                                                   triggerResetAppointment,
                                               }) => {
    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show);
    //查詢欄位資料(已查詢後)
    const [queryInputData, setQueryInputData] = useState({
        //搜尋文字
        text: "",
        //進階搜尋文字
        advancedText: "",
        //預約日期
        encounterDate: "",
        //時段
        apn: null,
        //查詢欄位
        field: "",
    });
    //查詢欄位資料
    const [queryInputTmpData, setQueryInputTmpData] = useState({
        text: "",
        advancedText: "",
        encounterDate: "",
        apn: null,
        field: "",
    });

    /**
     * 預約名單資料
     */
    const [arrayData, setArrayData] = useState(null);
    // 病人資訊清單進階搜尋結果array data
    const [advSearchArrayData, setAdvSearchArrayData] = useState([]);
    /**
     * 查詢的欄位
     */
    const [searchField, setSearchField] = useState("");
    //是否顯示給診號彈窗
    const [showGiveNumberPopup, setShowGiveNumberPopup] = useState(false);
    //給給診號彈窗資料
    const [giveNumberData, setGiveNumberData] = useState({});
    // pagination控制變數
    const [paginationProps, setPaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    });
    //Toast Message Hooks
    const showToast = useToast();
    /**
     * Table element 的預設type
     */
    const tableElementDefaultType =
        "px-[16px] border-r border-[#111111]/15 font-normal";

    /**
     * 點擊查詢按鈕事件
     * @return {void}
     */
    const handleSearchOnClick = () => {
        /*    const queryInputObj = {
            encounterDate: time2String(filterEncounterDate, "YYYY-MM-DD 00:00:00"),
            apn: filterApn,
            search:searchText,
            field:searchField
        }*/

        searchFirstVisitAppointment(
            queryInputTmpData,
            1,
            paginationProps.pageSize
        );
    };

    /**
     * 搜尋文字輸入事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onSearchTextOnInputChange = (e) => {
        setQueryInputTmpData({
            ...queryInputTmpData,
            text: e.target.value,
        });
    };

    /**
     * 搜尋文字鍵盤按鈕按下事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onSearchTextOnInputKeyDown = (e) => {
        if (e.key === "Enter") {
        }
    };

    /**
     * 進階搜尋文字輸入事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onAdvancedSearchTextOnInputChange = (e) => {
        setQueryInputTmpData({
            ...queryInputTmpData,
            advancedText: e.target.value,
        });
    };

    /**
     * 進階搜尋文字鍵盤按鈕按下事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onAdvancedSearchTextOnInputKeyDown = (e) => {
        if (e.key === "Enter") {
        }
    };

    /**
     * 處理查詢預約日期變更
     * @param date {Dayjs | null} 選定的日期
     * @return {void}
     */
    const handleFilterEncounterDateOnChange = (date) => {
        if (!date || !date.isValid()) {
            return;
        }

        setQueryInputTmpData({
            ...queryInputTmpData,
            encounterDate: date.format('YYYY-MM-DD'), // 將選定日期格式化為 YYYY-MM-DD
        });
    };

    /**
     * 時段模式改變時
     * @param e {Event}
     * @return {void}
     */
    const handleApnModeOnChange = (e) => {
        const selectedApn = e.target.value;
        setQueryInputTmpData({
            ...queryInputTmpData,
            apn: parseInt(selectedApn),
        });
    };

    /**
     * 點擊確認報到按鈕事件
     * @param data {Object} 預約病歷
     * @return {void}
     */
    const handleCheckOnClick = async (data) => {
        if (stringIsEmpty(patientId)) {
            // 先新增初診預約存檔，取得病歷號後再執行初診報到
            addNewPatient({
                patientName: data.patientName,
                birthDate: stringIsEmpty(data.birthDate)
                    ? ""
                    : time2String(data.birthDate, "YYYY-MM-DD 00:00:00"),
                idNo: data.idNo,
                phoneNo: data.phoneNo,
                homePhoneNo: data.homePhoneNo
            }).then((res) => {
                if (res.err === ApiErrorStatusEnum.Success) {
                    const patientId = res.data.patientId
                    addFirstviewToReg(data, patientId)
                } else {
                    //失敗
                    let errMsg = "新增病歷失敗";
                    if (!stringIsEmpty(res.msg)) errMsg = errMsg + `: ${res.msg}`;
                    else if (res.statusCode !== null)
                        errMsg = errMsg + `: ${res.statusCode}`;
                    //顯示失敗訊息
                    showToast({message: errMsg, type: AlertTypeEnum.Error});
                }
            });
        } else {
            addFirstviewToReg(data, patientId)
        }
    };

    /**
     * 初診報到
     * @param data {Object} 病人資料
     * @param patientId {String} 病歷號
     */
    const addFirstviewToReg = (data, patientId) => {
        regsAddFirstviewToReg({
            patientId: patientId,
            firstregId: data.firstregId,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setGiveNumberData(res.data);
                //新增成功
                setTimeout(() => setShowGiveNumberPopup(true), 150);

                if (!stringIsEmpty(res.msg)) {
                    showToast({message: res.msg, type: AlertTypeEnum.Warning});
                }
            } else {
                showToast({message: res.msg, type: AlertTypeEnum.Warning});
            }
        });
    }

    /**
     * 關閉掛號完成的彈窗
     */
    const handleCloseGiveNumberPopup = () => {
        setGiveNumberData({});
        setShowGiveNumberPopup(false);

        triggerResetAppointment(true);

        closePopupButtonOnClick();
    };

    /**
     * 查詢初診預約
     * @param queryInputObj {Object} 查詢條件
     * @param page {Number} 查詢第幾頁
     * @param pageSize {Number} 每頁資料筆數
     */
    const searchFirstVisitAppointment = (queryInputObj, page, pageSize) => {
        //把暫存的查詢條件寫入到真實的查詢條件
        setQueryInputData(queryInputObj);

        regsQueryFirstviewByField({
            encounterDate: time2String(
                queryInputObj.encounterDate,
                "YYYY-MM-DD 00:00:00"
            ),
            apn: queryInputObj.apn.toString(),
            search: queryInputObj.text,
            field: queryInputObj.field,
            pageSize: pageSize,
            pageNum: page,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setArrayData(res.data.dataList);
                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                    currentPage: page,
                    pageSize: pageSize,
                });
            } else {
                setArrayData([]);
                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageSize: pageSize,
                });
            }
        });
    };

    /**
     * 欄位下拉選單改變時
     * @param value {String} 事件
     */
    const handleFieldOnChange = (value) => {
        setQueryInputTmpData({
            ...queryInputTmpData,
            field: value,
        });
    };

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     */
    const onPaginationPageOnChange = (page) => {
        searchFirstVisitAppointment(
            queryInputData,
            page,
            paginationProps.pageSize
        );
    };

    /**
     * 每頁資料筆數變更事件
     * @param e {Event} 事件
     */
    const onPaginationPageSizeOnChange = (e) => {
        // 設定每頁資料筆數
        searchFirstVisitAppointment(queryInputData, 1, e.target.value);
    };

    /**
     * 上一頁點擊事件
     */
    const onPaginationPreviousOnClick = () => {
        const page =
            paginationProps.currentPage - 1 > 1
                ? paginationProps.currentPage - 1
                : 1;
        searchFirstVisitAppointment(
            queryInputData,
            page,
            paginationProps.pageSize
        );
    };

    /**
     * 下一頁點擊事件
     */
    const onPaginationNextOnClick = () => {
        const page =
            paginationProps.currentPage + 1 < paginationProps.totalPageSize
                ? paginationProps.currentPage + 1
                : paginationProps.totalPageSize;
        searchFirstVisitAppointment(
            queryInputData,
            page,
            paginationProps.pageSize
        );
    };

    /**
     * 取得彈窗元件
     * @return {JSX.Element} 彈窗元件
     */
    const getContent = () => (
        <div className="px-4">
            <div className="flex items-center justify-start text-[16px] space-x-2 text-left py-2">
                {/*預約日期選擇*/}
                <DatePicker
                    label="預約日期"
                    disablePast
                    value={dayjs(queryInputTmpData.encounterDate)}
                    onChange={handleFilterEncounterDateOnChange}
                />
                {/*篩選條件下拉選單*/}
                <Select
                    data={{
                        label: "篩選條件",
                        options:
                            fvSearchOptionList?.map((item) => ({
                                label: item.justnameName,
                                value: item.justnameNo,
                            })) || [],
                    }}
                    value={queryInputTmpData.field}
                    onChange={handleFieldOnChange}
                    notched={true}
                    displayEmpty={true}
                />
                {/*篩選條件輸入框*/}
                <TextField
                    value={queryInputTmpData.text}
                    onChange={(e) => onSearchTextOnInputChange(e)}
                    onKeyDown={(e) => onSearchTextOnInputKeyDown(e)}/>
                {/*時段選擇*/}
                <RadioGroup
                    labelProps={{
                        text: '時段'
                    }}
                    row={true} size={SizeEnum.Medium}
                    value={queryInputTmpData.apn.toString()}
                    optionProps={{
                        options: apnList.map(item => ({
                            label: item.justnameName,
                            value: item.justnameNo
                        }))
                    }}
                    onChange={handleApnModeOnChange}/>
                {/*查詢按鈕*/}
                <Button
                    color={ColorEnum.Primary}
                    variant={ButtonVariantEnum.Contained}
                    size={ButtonSizeEnum.Medium}
                    onClick={handleSearchOnClick}>
                    {t('general.query')}
                </Button>
            </div>
            <div className="flex text-[20px]">
                病人IC卡基本資料：
            </div>
            <div className="flex space-x-2 py-1">
                <Button color={ColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                        size={ButtonSizeEnum.Medium}>
                    虛擬IC
                </Button>
                <Button color={ColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                        size={ButtonSizeEnum.Medium}>
                    實體IC
                </Button>
            </div>
            {/**中間 Data Grid */}
            <div className="px-2 py-2 mt-2 border border-[#d4d4d8] rounded-[6px] space-y-3">
                <div className="flex flex-row items-center">
                    {/*進階搜尋*/}
                    <SearchTextField
                        placeholder="進階搜尋"
                        value={queryInputTmpData.advancedText}
                        onChange={(e) =>
                            onAdvancedSearchTextOnInputChange(e)
                        }
                        onKeyDown={(e) =>
                            onAdvancedSearchTextOnInputKeyDown(e)
                        }
                    />
                </div>
                {/**Data Grid */}
                <div
                    className="border-collapse w-full overflow-x-auto overflow-y-auto min-h-[100px] max-h-[35vh] xl:max-h-[50vh]">
                    <table className="table-fixed w-full min-w-[1500px] text-left">
                        <thead>
                        <tr className="bg-[#e4e4e7] h-[56px] border-collapse text-lg text-[#18181b]">
                            <th className={`w-[127px] ${tableElementDefaultType}`}></th>
                            <th className={`w-[120px] ${tableElementDefaultType}`}>
                                看診日期
                            </th>
                            <th className={`w-[74px] ${tableElementDefaultType}`}>
                                {t("Regs.general.timeSlot")}
                            </th>
                            <th className={`w-[120px] ${tableElementDefaultType}`}>
                                {t("Regs.general.clinic")}
                            </th>
                            <th className={`w-[120px] ${tableElementDefaultType}`}>
                                {t("Regs.general.viewNumber")}
                            </th>
                            <th className={`w-[120px] ${tableElementDefaultType}`}>
                                {t("general.doctor")}
                            </th>
                            <th className={`w-[120px] ${tableElementDefaultType}`}>
                                {t("Regs.general.medicalRecordNumber")}
                            </th>
                            <th className={`w-[120px] ${tableElementDefaultType}`}>
                                {t("general.idNumber")}
                            </th>
                            <th className={`w-[120px] ${tableElementDefaultType}`}>
                                {t("general.username")}
                            </th>
                            <th className={`w-[120px] ${tableElementDefaultType}`}>
                                {t("general.birthday")}
                            </th>
                            <th className={`w-[120px] ${tableElementDefaultType}`}>
                                電話
                            </th>
                            <th className={`w-[120px] ${tableElementDefaultType}`}>
                                手機
                            </th>
                            <th className={`w-[120px] ${tableElementDefaultType}`}>
                                掛號人員
                            </th>
                            <th className={`w-[190px] ${tableElementDefaultType}`}>
                                掛號日期時間
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            !arrayIsEmpty(advSearchArrayData) &&
                            advSearchArrayData.map((data, index) => (
                                <tr
                                    className={`h-[40px] ${
                                        index % 2 === 0
                                            ? ""
                                            : "bg-[#f4f4f5]"
                                    } text-[#18181b]`}
                                    key={index}
                                >
                                    <td className={`${tableElementDefaultType}`}>
                                        <Button color={ColorEnum.Primary} variant={ButtonVariantEnum.Outlined}
                                                size={ButtonSizeEnum.Medium}
                                                disabled={
                                                    !isCurrentDateTimeWithinRange(
                                                        data.encounterDate,
                                                        parseInt(data.apn)
                                                    )
                                                }
                                                onClick={() =>
                                                    handleCheckOnClick(data)
                                                }>
                                            完成報到
                                        </Button>
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.encounterDate
                                            ? time2String(
                                                data.encounterDate,
                                                "YYYY-MM-DD"
                                            )
                                            : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.apn
                                            ? data.apn === 1
                                                ? t("general.dateTime.timeslot.short.morning")
                                                : data.apn === 2
                                                    ? t("general.dateTime.timeslot.short.afternoon")
                                                    : data.apn === 3
                                                        ? t("general.dateTime.timeslot.short.night")
                                                        : ""
                                            : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.clinicName
                                            ? data.clinicName
                                            : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.viewNo ? data.viewNo : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.doctorName
                                            ? data.doctorName
                                            : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>

                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.idNo ? data.idNo : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.patientName
                                            ? data.patientName
                                            : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.birthDate
                                            ? time2String(
                                                data.birthDate,
                                                "YYYY-MM-DD"
                                            )
                                            : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.homePhoneNo
                                            ? data.homePhoneNo
                                            : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.phoneNo
                                            ? data.phoneNo
                                            : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.createUserName
                                            ? data.createUserName
                                            : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.createDatetime
                                            ? data.createDatetime
                                            : ""}
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-end w-full">
                    <Pagination
                        totalPageSize={paginationProps.totalPageSize}
                        currentPage={paginationProps.currentPage}
                        pageSize={paginationProps.pageSize}
                        totalSize={paginationProps.totalItemSize}
                        onPageOnChange={(page) =>
                            onPaginationPageOnChange(page)
                        }
                        onPrevPageOnClick={onPaginationPreviousOnClick}
                        onNextPageOnClick={onPaginationNextOnClick}
                        onPageSizeChange={onPaginationPageSizeOnChange}
                    />
                </div>
            </div>
            {
                <ShowGiveNumberPopup
                    showPopup={showGiveNumberPopup}
                    data={giveNumberData}
                    handleClosePopup={handleCloseGiveNumberPopup}
                />
            }
        </div>
    );

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        // 設定是否顯示彈窗狀態
        if (show) {
            if (
                !objectIsEmpty(importAppointmentData) &&
                !objectIsEmpty(importAppointmentData.dataList)
            ) {
                //有預約資料傳入時
                //將預約資料寫入
                setArrayData(importAppointmentData.dataList);
                //設定分頁狀態
                setPaginationProps({
                    //當前頁碼
                    currentPage: importAppointmentData.currentPage,
                    //每頁資料筆數
                    pageSize: importAppointmentData.pageSize,
                    //總資料筆數
                    totalItemSize: importAppointmentData.totalItemSize,
                    //總頁碼
                    totalPageSize: importAppointmentData.totalPageSize,
                });
                //欄位狀態代入到顯示欄位上
                setQueryInputTmpData({
                    text: importAppointmentData.searchText,
                    advancedText: "",
                    encounterDate: time2String(
                        importAppointmentData.encounterDate,
                        "YYYY-MM-DD"
                    ),
                    apn: importAppointmentData.apn,
                    field: importAppointmentData.searchField,
                });
                //欄位狀態代入到真正的搜尋欄位上
                setQueryInputData({
                    text: importAppointmentData.searchText,
                    advancedText: "",
                    encounterDate: time2String(
                        importAppointmentData.encounterDate,
                        "YYYY-MM-DD"
                    ),
                    apn: importAppointmentData.apn,
                    field: importAppointmentData.searchField,
                });
            } else {
                const queryCondition = {
                    text: "",
                    advancedText: "",
                    encounterDate: time2String(
                        importAppointmentData.encounterDate,
                        "YYYY-MM-DD"
                    ),
                    apn: importAppointmentData.apn,
                    field: "ALL",
                };
                setQueryInputTmpData(queryCondition);
                searchFirstVisitAppointment(
                    queryCondition,
                    1,
                    paginationProps.pageSize
                );
            }
        } else {
            //重置輸入框
            const queryCondition = {
                text: "",
                advancedText: "",
                encounterDate: "",
                apn: TimeslotEnum.Morning, //'1',
                field: "ALL",
            };
            setQueryInputTmpData(queryCondition);
            setQueryInputData(queryCondition);
        }
        setShowPopup(show);
    }, [show]);

    /**監聽進階搜尋的文字或者arrayData的變化 */
    useEffect(() => {
        if (arrayData === null) {
            setAdvSearchArrayData(null);
        } else {
            const list = getAdvSearchPatientList();
            setAdvSearchArrayData(list);
        }
    }, [queryInputTmpData.advancedText, arrayData]);

    /**
     * 取得病人進階搜尋後的結果
     * @returns
     */
    const getAdvSearchPatientList = () => {
        //如果搜尋文字為空
        if (stringIsEmpty(queryInputTmpData.advancedText)) {
            return arrayData;
        } else {
            //如果搜尋文字不為空
            const lowerCaseSearchText =
                queryInputTmpData.advancedText.toLowerCase();
            return arrayData.filter((data) => {
                return (
                    (data.encounterDate !== null &&
                        time2String(data.encounterDate, "YYYYMMDD").includes(
                            lowerCaseSearchText
                        )) ||
                    (data.apn !== null &&
                        data.apn
                            .toString()
                            .toLowerCase()
                            .includes(lowerCaseSearchText)) ||
                    (data.clinicName !== null &&
                        data.clinicName
                            .toString()
                            .toLowerCase()
                            .includes(lowerCaseSearchText)) ||
                    (data.viewNo !== null &&
                        data.viewNo
                            .toString()
                            .toLowerCase()
                            .includes(lowerCaseSearchText)) ||
                    (data.doctorName !== null &&
                        data.doctorName
                            .toString()
                            .toLowerCase()
                            .includes(lowerCaseSearchText)) ||
                    (data.idNo !== null &&
                        data.idNo
                            .toString()
                            .toLowerCase()
                            .includes(lowerCaseSearchText)) ||
                    (data.patientName !== null &&
                        data.patientName
                            .toString()
                            .toLowerCase()
                            .includes(lowerCaseSearchText)) ||
                    (data.phonenumber !== null &&
                        data.phonenumber
                            .toString()
                            .toLowerCase()
                            .includes(lowerCaseSearchText)) ||
                    (data.birthDate !== null &&
                        time2String(data.birthDate, "YYYYMMDD").includes(
                            lowerCaseSearchText
                        )) ||
                    (data.createUserName !== null &&
                        data.createUserName
                            .toString()
                            .toLowerCase()
                            .includes(lowerCaseSearchText)) ||
                    (data.createDatetime !== null &&
                        time2String(data.createDatetime, "YYYYMMDD").includes(
                            lowerCaseSearchText
                        ))
                );
            });
        }
    };

    return (
        <Dialog
            open={show}
            title={"初診病人預約名單"}
            content={apnList && getContent()}
            variant={DialogVariant.EDIT}
            paperStyleProps={{width: DialogSizeEnums.XL}}
            onClose={closePopupButtonOnClick}
            muiDialogActionsProps={{sx: {display: "none"}}}
        />
    )
};

export default FirstVisitPatientAppointmentListPopup;
