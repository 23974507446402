import {
    AdvancedDataGrid,
    AlertTypeEnum,
    Pagination,
    RadioGroup,
    Search,
    Select,
    SizeEnum
} from "edah-component/dist/index";
import React, {useEffect, useState} from "react";
import {
    TextField,
    Button,
    ButtonColorEnum,
    ButtonVariantEnum,
    ButtonSizeEnum,
    IconEnum,
    WidthEnum, DialogVariant, Dialog, Field, DatePicker,
} from "edah-component";
import {ApiErrorStatusEnum, stringIsEmpty} from "edah_utils/dist";
import {curdDelete, curdMultiQuery, curdQueryStructure, curdSave} from "../../api/v1/CRUD";
import useToast from "../../hooks/useToast";
import {t} from "i18next"
import dayjs from "dayjs";

/**
 * 簡易CRUD模組
 * @param columns {Object} datagrid columns設置
 * @param rowId {String} datagrid唯一值标识
 * @param system {String} 系統名
 * @param tableName {String} table名
 */
const SimpleCRUD = ({
    columns,
    rowId,
    system,
    tableName
}) =>{
    //Toast Message Hooks
    const showToast = useToast()
    // dataGrid清單
    const [dataList,setDataList] = useState([]);
    // 查詢條件
    const [searchField, setSearchField] = useState(columns[0]?.headerName || '');
    // 搜尋框内容
    const [searchValue, setSearchValue] = useState("");
    // CRUD pagination控制變數
    const [crudPaginationProps, setCrudPaginationProps] =
        useState({
            //當前頁碼
            currentPage: 1,
            //每頁資料筆數
            pageSize: 10,
            //總資料筆數
            totalItemSize: 0,
            //總頁碼
            totalPageSize: 0,
        });
    // 新增編輯彈窗
    const [showPopup, setShowPopup] = useState(false);
    // 刪除確認彈窗
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [currentRow, setCurrentRow] = useState(null)
    const [editedData, setEditedData] = useState({})
    const [deleteRow, setDeleteRow] = useState({})
    // 新增的資料結構
    const [addEntity, setAddEntity] = useState({})


    /**
     * 操作列定義
     *
     */
    const actionColumn = {
        field: 'actions',
        renderHeader: () => (<Button
            sx={{ whiteSpace: 'nowrap' }}
            color='success'
            variant={ButtonVariantEnum.Text}
            size={ButtonSizeEnum.Large}
            icon={IconEnum.Add}
            text={t('general.add')}
            onClick={handleAddClick}
        /> ),
        width: 200,
        renderCell: (params) => (
            <>
                <Button
                    color={ButtonColorEnum.Primary}
                    size={ButtonSizeEnum.Medium}
                    icon={IconEnum.Edit}
                    text={t('general.edit')}
                    variant={ButtonVariantEnum.Text}
                    onClick={() => handleEditClick(params.row)}
                />
                <Button
                    color={ButtonColorEnum.Primary}
                    size={ButtonSizeEnum.Medium}
                    icon={IconEnum.Delete}
                    text={t('general.delete')}
                    variant={ButtonVariantEnum.Text}
                    onClick={() => handleDeleteClick(params.row)}
                />
            </>
        )
    }

    // 合併所有列，包括操作列
    const allColumns = [actionColumn, ...columns]

    /**
     * 查詢條件變更時
     * @param value {String}
     */
    const handleSearchFieldOnChange = (value) => {
        setSearchField(value);
    };

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     * @param pageSize {Number} 每頁幾筆
     */
    const onPaginationPageOnChange = (page, pageSize) => {
        queryCrudData(page, pageSize)
    };

    /**
     * 查詢資料
     */
    const handleQueryOnClick = ()=> {
        queryCrudData(crudPaginationProps.currentPage, crudPaginationProps.pageSize)
    }

    /**
     * 新增
     */
    const handleAddClick = ()=>{
        setEditedData({...addEntity})
        setCurrentRow(null)
        setShowPopup(true)
    }

    /**
     * 編輯
     */
    const handleEditClick= (row)=>{
        setCurrentRow(row)
        setEditedData(row)
        setShowPopup(true)
    }

    /**
     * 處理刪除按鈕點擊事件
     * @param row {any} - 被刪除的行數據
     */
    const handleDeleteClick = (row) => {
        setCurrentRow(row)
        setDeleteRow(row)
        setShowDeletePopup(true)
    }

    /**
     * 確認刪除
     */
    const handleDeleteConfirm =()=>{
        setShowDeletePopup(false)
        deleteRowData(deleteRow)
    }

    /**
     * 確認存檔按鈕
     */
    const handleConfirmOnClick = ()=>{
        setShowPopup(false)
        saveCrudData()
    }

    /**
     * 查詢 entity 結構
     */
    const queryStructure = ()=>{
        curdQueryStructure({
            tableName: tableName,
            system: system
        }).then(res =>{
            if(res.err === ApiErrorStatusEnum.Success){
                setAddEntity(res.data)
            }else{
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 查詢資料(like, equal)可排序
     * @param page {Number} 頁碼
     * @param pageSize {Number} 每頁幾筆
     * system=menu&tableName=UserInfo&pageNum=1&pageSize=100&likes=userName:陳,userNo:10&sorts=createDatetime:ASC
     */
    const queryCrudData = (page, pageSize)=>{
        let params = {
            system: system,
            tableName: tableName,
            likes: searchField + ':'+ searchValue,
            pageNum: page,
            pageSize: pageSize,
        }
        curdMultiQuery(params).then(res =>{
            if(res.err === ApiErrorStatusEnum.Success){
                //設置資料源
                setDataList(res.data)
                // 設置pagination
                setCrudPaginationProps({
                    totalItemSize: res.totalItemSize,
                    totalPageSize: res.totalPageSize,
                    currentPage: page,
                    pageSize: pageSize,
                })
            }else{
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 新增或儲存 table 資料
     */
    const saveCrudData = ()=>{
        curdSave({
            tableName: 'tw.com.hismax.his.entity.'+ system + '.'+ tableName,
            tableData: JSON.stringify([{...editedData}])
        }).then(res =>{
            if(res.err === ApiErrorStatusEnum.Success){
                //存儲成功后刷新畫面
                queryCrudData(crudPaginationProps.currentPage, crudPaginationProps.pageSize)
            }else{
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 刪除 table 資料
     * @param row {Object}
     */
    const deleteRowData = (row)=>{
        curdDelete({
            tableName: 'tw.com.hismax.his.entity.'+ system + '.'+ tableName,
            tableData: JSON.stringify([row])
        }).then(res =>{
            if(res.err === ApiErrorStatusEnum.Success){
                //刪除成功后刷新畫面
                queryCrudData(crudPaginationProps.currentPage, crudPaginationProps.pageSize)
            }else {
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取得新增/編輯內容
     * @return {JSX.Element}
     */
    const getContent = ()=>{
        /**
         * 獲取編輯元件
         * @param rowData
         * @param column
         * @returns {JSX.Element}
         */
        const getEditComponent = ({column})=> {
            (!column.editType) && (column.editType = 'string')
            switch (column.editType) {
                case 'string':
                case 'number':
                    return (
                        <TextField
                            key={column.field}
                            value={editedData?.[column.field] || ''}
                            inputWidth={SizeEnum.Fill}
                            onChange={(e) =>
                                setEditedData({
                                    ...editedData,
                                    [column.field]: e.target.value
                                })
                            }
                            fullWidth
                            margin='normal'
                        />
                    )
                case 'date':
                    return (
                        <DatePicker
                            label=''
                            size={SizeEnum.Medium}
                            value={editedData?.[column.field] ? dayjs(editedData[column.field]) : null}
                            onChange={(newDate) => {
                                const date = newDate?.format('YYYY-MM-DD')
                                setEditedData({
                                    ...editedData,
                                    [column.field]: date
                                })
                            }}
                        />
                    )
                case 'select':
                    return (
                        <Select
                            data={{
                                options: column?.valueOptions || [],
                            }}
                            value={editedData?.[column.field]}
                            onChange={(value)=>{
                                setEditedData({
                                    ...editedData,
                                    [column.field]: value
                                })
                            }}
                            showLabel={false}
                        />
                    )
                case 'search':
                    return (
                        <Search
                            freeSolo
                            value={column?.valueOptions.find(item => item.value === editedData?.[column.field])}
                            onChange={(_e, option) => {
                                setEditedData({
                                    ...editedData,
                                    [column.field]: option.value
                                })
                            }}
                            disablePortal={false}
                            options={column?.valueOptions}
                            getOptionLabel={(option) => `${option?.value} ${option?.label}`}
                        />
                    )
                case 'radio':
                    return (
                        <RadioGroup
                            value={editedData?.[column.field]}
                            optionProps={{
                                options: column?.valueOptions
                            }}
                            size={SizeEnum.Small}
                            onChange={(event)=>{
                                setEditedData({
                                    ...editedData,
                                    [column.field]: event.target.value
                                })
                            }}
                        />
                    )
            }
            return (<></>)
        }



        return (<div className="grid grid-cols-2 gap-4">
            {columns.map((column) => (
                <div className="col-span-1">
                    <Field labelWidth={120}  label={column.headerName} >
                        {getEditComponent({column})}
                    </Field>
                </div>
            ))}
        </div>)
    }

    /**
     * 畫面初始化獲取新增結構
     */
    useEffect(()=>{
        queryStructure()
    }, [])


    return (
        <div className="w-full p-2 bg-[#FAFAFA] space-y-2.5 max-h-[calc(100vh-101px)] overflow-y-auto">
            {/*搜尋欄*/}
            <div className="flex flex-row justify-start items-center gap-2">
                查詢條件
                <Select
                    data={{
                        label: "",
                        options:
                            columns?.map((column) => ({
                                value: column.field,
                                label: column.headerName,
                            })) || [],
                    }}
                    value={searchField}
                    width={WidthEnum.Large}
                    onChange={handleSearchFieldOnChange}
                    showLabel={false}
                />
                <TextField
                    inputWidth={SizeEnum.Large}
                    value={searchValue}
                    onChange={e => setSearchValue(e.target.value)}
                />
                {/*查詢按鈕*/}
                <Button color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium}
                        variant={ButtonVariantEnum.Contained} text={t('general.query')} onClick={handleQueryOnClick}/>
            </div>
            <AdvancedDataGrid
                rows={dataList}
                columns={allColumns}
                checkboxSelection={false}
                disableRowSelectionOnClick={false}
                disableColumnMenu={true}
                disableColumnSorting={true}
                height={'465px'}
                getRowHeight={(params) => 40}
                getRowId={(row) => row[rowId]}
            />
            <div className="flex justify-end">
                <Pagination
                    totalPageSize={crudPaginationProps.totalPageSize}
                    page={crudPaginationProps.currentPage}
                    pageSize={crudPaginationProps.pageSize}
                    totalSize={crudPaginationProps.totalItemSize}
                    onPageOnChange={(page, pageSize) =>
                        onPaginationPageOnChange(page, pageSize)
                    }
                    showFirstButton
                    showLastButton
                />
            </div>
            {/*刪除確認彈窗*/}
            <Dialog
                open={showDeletePopup}
                content='是否確定刪除?'
                paperStyleProps={{
                    padding: '0px',
                    width: '600px'
                }}
                title='刪除確認'
                variant={DialogVariant.DELETE}
                onClose={() => setShowDeletePopup(false)}
                onDelete={() => handleDeleteConfirm()}
            />
            {/*新增/編輯彈窗*/}
            <Dialog
                open={showPopup}
                title={currentRow? '編輯':'新增'}
                content={getContent()}
                variant={DialogVariant.CONFIRM}
                onClose={() => setShowPopup(false)}
                onConfirm={handleConfirmOnClick}
            />
        </div>
    );
}

export default SimpleCRUD;