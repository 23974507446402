// Import ICON
import SearchIcon from "../../assets/images/search-interface-symbol.png"

// Import function
import {
    ApiErrorStatusEnum,
    EssiCrlStatusEnum,
    PopupModeEnum,
    arrayIsEmpty,
    fuzzySearchObjects,
    multiTermFuzzySearchObjects,
    objectIsEmpty, stringIsEmpty,
} from "edah_utils/dist"
import React, {useEffect, useMemo, useRef, useState} from "react"
import HcaPopup from "./HcaPopup"
import useOutsideClick from "../../hooks/useOutsideClick"
import {queryAllDoctor} from "../../api/v1/Menu"
import {t} from "i18next"
import {essiAddUserCert, essiEditUserCert, essiReadHCA} from "../../api/v1/ESSI"
import useToast from "../../hooks/useToast"
import {AlertTypeEnum, Dialog, DialogSizeEnums, DialogVariant, Field, Search, Select, TextField, Button, ButtonSizeEnum, ButtonVariantEnum, ButtonColorEnum, DateRangePicker} from "edah-component/dist"
import dayjs from "dayjs"

/**
 * 人員憑證維護-新增 / 修改彈窗
 * @param show 是否顯示
 * @param data {Object} 資料
 * @param mode {Number} 新增或編輯
 * @param closePopupOnClick {function} 關閉按鈕點擊事件
 * @return {JSX.Element}
 */
const AddEditUserCerPopup = ({show = false, data, mode, closePopupOnClick}) => {
    /**
     * grid element 的預設type
     */
    const GridDefaultType = "grid grid-cols-2 gap-x-6 gap-y-2"

    /**
     * Show Toast
     */
    const showToast = useToast()

    /**
     * 預設資料
     */
    const defaultData = {
        // 卡片號碼
        "cardNumber": '',
        // 使用者代號
        "userNo": '',
        // 使用者名稱
        "userName": '',
        // HCA 卡別
        "cardType": '',
        // 存取碼,只用在自動簽章使用
        "accessCode": '',
        // HCA 憑證簽章類型 1 : SHA1 2 : SHA2
        "hcaCertType": '',
        // CA 憑證
        "certTypeCa": '',
        // 驗證簽章用(ID1)
        "certTypeId1": '',
        // 加解密用(ID2)
        "certTypeId2": '',
        // 憑證有效日期-起
        "effStartDate": '',
        // 憑證有效日期-迄
        "effEndDate": '',
        // 憑證有效否(N 正常/C 己廢止/S 停用)
        "crlStatus": ''
    }

    //是否為新增模式
    const [isAddMode, setIsAddMode] = useState(false)

    //新增或修改的資料
    const [newData, setNewData] = useState(mode === PopupModeEnum.Add ? defaultData : data)

    // 讀取HCA popup設定
    const [hcaPopup, setHcaPopup] = useState(false)

    // 是否顯示人員代號選項
    const [showUserDropdown, setShowUserDropdown] = useState(false)

    //ref 用於指向人員代下拉菜單元素
    const dropdownUserRef = useRef(null)

    //ref 用於指向pinCode元素
    const pinCodeRef = useRef(null)

    //是否顯示彈窗
    const [showPopup, setShowPopup] = useState(show)

    // 醫生選項
    const [doctorOptions, setDoctorOptions] = useState([])

    // HCA資料
    const [hcaData, setHcaData] = useState({})

    /**
     * 處理人員代號搜尋文字框焦點事件
     * @return {void}
     */
    const handleUserSearchTextOnFocus = () => setShowUserDropdown(true)

    /**
     * 處理人員代號選項點擊事件
     * @param option {Object} 人員代號
     * @return {void}
     */
    const handleUserOptionOnClick = (option) => {
        // 值不為空
        if (!objectIsEmpty(option)) {
            setNewData({...newData, userNo: `${option.userNo} ${option.userName}`})
        }
        // 關閉醫師下拉選單
        setShowUserDropdown(false)
    }

    /**
     * 取得 醫師選項列表
     * @return {Array<Object>}
     */
    const getFilterDoctorOptionList = () =>
        // 醫生是否有空格
        newData.userNo.indexOf(' ') >= 0 ?
            // 多個字串模糊搜尋
            multiTermFuzzySearchObjects(doctorOptions, newData.userNo.split(' ')) :
            // 單個字串模糊搜尋
            fuzzySearchObjects(doctorOptions, newData.userNo)

    /**
     * 確定刪除點擊事件
     * @return {void}
     */
    const handleConfirmOnClick = () => closePopupOnClick()

    /**
     * 儲存或編輯按鈕被點擊時
     * @return {void}
     */
    const handleSaveOrEditOnClick = () => {
        // 如果是新增模式
        if (isAddMode) {
            addUserCert()
        } else { // 如果是編輯模式
            editUserCert()
        }
    }

    /**
     * 關閉HCA視窗關閉事件
     * @return {void}
     */
    const handleHcaPopupOnClose = () => setHcaPopup(false)

    /**
     * 使用者改變時
     * @param value {String}
     * @return {void}
     */
    const handleUserOnChange = (value) => {
        setNewData({...newData, userNo: value})
    }

    /**
     * 憑證有效否欄位改變時
     * @param value {String}
     * @return {void}
     */
    const handleServerTypeOnChange = (value) => setNewData({...newData, crlFlag: value})

    /**
     * Pin Code欄位改變時
     * @return {void}
     */
    const handleAccessCodeOnChange = (e) => setNewData({...newData, accessCode: e.target.value})

    /**
     * 點擊讀取HCA卡時
     * 出現彈跳視窗>(如PIN CODE為空要求輸入，如PIN CODE有值將其帶入)要求輸入pin code>HCA API>顯示成功/失敗原因
     * 成功紀錄此筆certTypeCa、certTypeId1、certTypeId2
     * @param e {Event} 事件
     * @return {void}
     */
    const handleHcaButtonOnClick = (e) => {
        // 阻止預設行為
        e.preventDefault()
        // 如果PIN CODE為空要求輸入
        if (stringIsEmpty(newData.accessCode)) {
            // 顯示錯誤訊息
            showToast({message: '請輸入PIN CODE', type: AlertTypeEnum.Error})
            // 聚焦
            pinCodeRef.current.focus()
            return
        }

        // 顯示HCA Popup
        setHcaPopup(true)
    }

    /**
     * 處理API回傳結果
     * @param res {Object} API回傳結果
     * @return {void}
     */
    const handleApiResult = (res) => {
        // 顯示訊息
        const {err, msg} = res

        if (err === ApiErrorStatusEnum.Success) {
            // 顯示訊息
            showToast({message: msg})
        } else {
            // 顯示錯誤訊息
            showToast({message: msg, type: AlertTypeEnum.Error})
        }
    }

    /**
     *  新增人員憑證維護
     *  @return {void}
     */
    const addUserCert = () => {
        essiAddUserCert([newData]).then(handleApiResult)
    }

    /**
     * 修改人員憑證維護資料
     * @return {void}
     */
    const editUserCert = () => {
        essiEditUserCert([newData]).then(handleApiResult)
    }

    /**
     * 讀取HCA卡片
     * @return {void}
     */
    const readHCA = () => {
        essiReadHCA({}).then(res => {
            const {err, data} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 設定HCA資料
                setHcaData(data)
            }
        })
    }

    /**
     * 取得查詢醫生
     * @return {void}
     */
    const getQueryAllDoctor = () => {
        queryAllDoctor({}).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                // 設定醫生選項
                setDoctorOptions(res.data)
            }
        })
    }

    /**
     * 點擊外部區域時
     */
    useOutsideClick({
        ref: dropdownUserRef,
        handler: () => setShowUserDropdown(false)
    })

    /**
     * 第一次執行時
     * @return {void}
     */
    useMemo(() => getQueryAllDoctor(), [])

    /**
     * 監聽是否顯示彈窗Pros變化
     * @return {void}
     */
    useEffect(() => {
        // 設定是否顯示彈窗
        setShowPopup(show)
        // 設定是否為新增模式
        setIsAddMode(mode === PopupModeEnum.Add)
        // 設定資料
        setHcaData({})
    }, [show])

    return showPopup ?
        <>
        <Dialog
            open={show}
            onClose={closePopupOnClick}
            variant={DialogVariant.CUSTOM}
            title={'人員憑證維護'}
            paperStyleProps={{width:DialogSizeEnums.MD}}
            content={
            // Container
                <div className="px-4 py-5">
                    <div className={`${GridDefaultType}`}>
                        {/*員工代號*/}
                        <div className={`${GridDefaultType}`}>
                            <Field label={'員工代號'} labelWidth={'150px'}>
                                <Search
                                    inputValue={newData.userNo}
                                    options={doctorOptions}
                                    getOptionLabel={(option) => `${option.userNo} ${option.userName}`}
                                    onInputChange={(_e, value) => handleUserOnChange(value)}
                                    onChange={(_e, value) => handleUserOptionOnClick(value)}
                                />
                            </Field>
                        </div>
                        <div>
                            {/*憑證有效否*/}
                            <div className="flex flex-row justify-end mr-6">
                                <Field label={'憑證有效否'} labelWidth={'150px'}>
                                    <Select
                                        data={{
                                            options: Object.keys(EssiCrlStatusEnum).map(item => ({
                                                label: t(`ESSI.CertMaintenance.crlFlagEnum.${item}`),
                                                value: EssiCrlStatusEnum[item]
                                            })),
                                        }}
                                        value={newData.crlFlag || 'N'}
                                        onChange={handleServerTypeOnChange}
                                    />
                                </Field>
                            </div>
                        </div>
                        <div className={`${GridDefaultType}`}>
                            <Field label={'PIN CODE'} labelWidth={'150px'}>
                                <TextField
                                    type={'password'}
                                    value={newData.accessCode}
                                    inputRef={pinCodeRef}
                                    onChange={handleAccessCodeOnChange}
                                />
                            </Field>
                        </div>
                        <div className="flex flex-row justify-start">
                            <Button
                                color={ButtonColorEnum.Secondary}
                                variant={ButtonVariantEnum.Outlined}
                                size={ButtonSizeEnum.Medium}
                                text={'讀取HCA卡片'}
                                onClick={handleHcaButtonOnClick}
                            />
                        </div>
                        <div className={`${GridDefaultType}`}>
                            <Field label={'卡號'} labelWidth={'150px'}>
                                <TextField value={newData.cardNumber} disabled={true}/>
                            </Field>
                        </div>
                        <div></div>
                        <div className={`${GridDefaultType}`}>
                            <Field label={'HCA卡別'} labelWidth={'150px'}>
                                <TextField value={newData.cardType} disabled={true}/>
                            </Field>
                        </div>
                        <div></div>
                        <div className={`${GridDefaultType}`}>
                            {/*<p className="text-right mt-1">HCA憑證類別</p>*/}
                            {/*<input*/}
                            {/*    className={`px-[14px] w-[100%] border border-[#D4D4D8] h-[40px] rounded-[6px] mr-[30px]`}*/}
                            {/*    type="text" value={newData.hcaCertType} disabled={true}/>*/}
                            <Field label={'HCA憑證類別'} labelWidth={'150px'}>
                                <TextField value={newData.hcaCertType} disabled={true}/>
                            </Field>
                        </div>
                        <div></div>
                        <Field label={'HCA有效起訖日期'} labelWidth={'150px'}>
                            <DateRangePicker
                                value={
                                    [newData.effStartDate ? dayjs(newData.effStartDate) : null,
                                        newData.effEndDate ? dayjs(newData.effEndDate) : null]
                                }
                                localeText={{start: 'YYYY-MM-DD', end: 'YYYY-MM-DD'}}
                                onChange={() => {
                                }}
                                disabled/>
                        </Field>
                    </div>
                    <div></div>
                    <div className={`${GridDefaultType}`}>
                        <Field label={'HCA簽章憑證'} labelWidth={'150px'}>
                            <p className="mx-2 text-left mt-1">HCA簽章憑證</p>
                        </Field>
                    </div>
                </div>
            }
            footerContent={
                <>
                    {/*送出按鈕*/}
                    <Button
                        color={ButtonColorEnum.Primary}
                        variant={ButtonVariantEnum.Contained}
                        size={ButtonSizeEnum.Medium}
                        text={t('general.submit')}
                        onClick={handleSaveOrEditOnClick}/>
                    {/*取消按鈕*/}
                    <Button
                        color={ButtonColorEnum.Primary}
                        variant={ButtonVariantEnum.Outlined}
                        size={ButtonSizeEnum.Medium}
                        text={t('general.cancel')}
                        onClick={closePopupOnClick}
                    />

                </>
            }
        />
        {/*/!*HCA Popup*!/*/}
        <HcaPopup show={hcaPopup} closePopupButtonOnClick={handleHcaPopupOnClose} pinCode={newData.accessCode}/>
        </>
: <></>
}

export default AddEditUserCerPopup
