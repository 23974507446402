// Import function
import React, {useState} from "react"
import {enum2Array, ICCMMaintainPageActiveTabEnum, ICCMOperationModeEnum} from "edah_utils/dist"
import {t} from "i18next"
import {
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Card,
    IconEnum,
    Tabs,
    TabsEnum
} from "edah-component/dist"
import {InfoContent} from "./ActiveTab/InfoContent"
import {ListContent} from "./ActiveTab/ListContent"
import {ResultContent} from "./ActiveTab/ResultContent"

const tabList = enum2Array(ICCMMaintainPageActiveTabEnum)

/**
 * Active Tab
 * @return {JSX.Element}
 */
export const ActiveTab = () => {
    //當前點擊的Tab列舉
    const [currentActiveTab, setCurrentActiveTab] = useState(tabList[0].key)

    // 操作模式
    const [operationMode, SetOperationMode] = useState(ICCMOperationModeEnum.Edit)

    // 啟用編輯功能
    const [isEditable, setIsEditable] = useState(false);

    /**
     * 取得Tab Content
     * @return {Array}
     */
    const getTabItems = () => {
        return tabList.map((item) => {
            let content = <div></div>
            let title = ''
            switch (item.value) {
                case ICCMMaintainPageActiveTabEnum.BasicData:
                    title = '基本資料'
                    content = <InfoContent isEditable={isEditable}/>
                    break
                case ICCMMaintainPageActiveTabEnum.MedicalOrderList:
                    title = '醫囑清單'
                    content = <ListContent/>
                    break
                case  ICCMMaintainPageActiveTabEnum.CheckResult:
                    title = '檢核結果'
                    content = <ResultContent/>
                    break
            }
            return {
                id: item.key,
                title,
                content: content
            }
        })
    }

    const onTabChange = (id) => {
        setCurrentActiveTab(id)
    }

    /**
     * 取得新增/儲存按鈕區塊
     * @return {JSX.Element}
     */
    const getAddEditButtonSection = () =>
        currentActiveTab === 'BasicData' ?
            // 只顯示在基本資料Tab
            <div className="absolute right-0 top-4">
                {isEditable ?
                    <Button color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Large} variant={ButtonVariantEnum.Text}
                            icon={IconEnum.Save} text={t('general.save')} onClick={handleAddSaveButtonOnClick}/>
                    :
                    <Button color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Large} variant={ButtonVariantEnum.Text}
                            icon={IconEnum.Edit} text={t('general.edit')} onClick={handleAddSaveButtonOnClick}/>}
            </div>
            :
            null


    /**
     * 新增/儲存按鈕點擊時
     * @return {void}
     */
    const handleAddSaveButtonOnClick = () => {
        setIsEditable(!isEditable)
    }

    return (
        <Card>
            <div className="p-4 relative">
                <Tabs variant={TabsEnum.INDICATOR_TAB} activeTabId={currentActiveTab} items={getTabItems()}
                      onTabChange={onTabChange}/>
                {getAddEditButtonSection()}
            </div>
        </Card>
    )
}
