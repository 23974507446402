import {Card} from 'edah-component'
import AdvancedSearchTable from '../../Table/AdvancedSearchTable'

const OrderDataSection = () => {
    const columns = [
        {field: 'encDate', headerName: '就診日期時間', width: 150},
        {field: 'orderType', headerName: '醫令類別', width: 150},
        {field: 'orderCode', headerName: '診療項目代號', width: 150},
        {field: 'orderPart', headerName: '診療部位', width: 150},
        {field: 'orderUsage', headerName: '用法', width: 150},
        {field: 'orderDays', headerName: '天數', width: 150},
        {field: 'orderTotal', headerName: '總量', width: 150},
        {field: 'orderSign', headerName: '安全簽章', width: 150},
    ]
    return (
        <div className="w-full">
            <Card title="醫囑資料">
                <AdvancedSearchTable
                    columns={columns}
                    rows={[]}
                />
            </Card>
        </div>
    )
}

export default OrderDataSection
