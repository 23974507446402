import {
    Button,
    ButtonColorEnum,
    ButtonVariantEnum,
    DialogVariant,
    Select
} from 'edah-component'
import {Card} from 'edah-component'
import React, {useState} from 'react'
import {Dialog} from 'edah-component'
import {DialogSizeEnums} from 'edah-component'
import AdvancedSearchTable from '../../Table/AdvancedSearchTable'

const WriteICCardSection = () => {
    const [rows, setRows] = useState([])
    const [openDialog, setOpenDialog] = useState(false)
    const [errorCode, setErrorCode] = useState('')
    const columns = [
        {field: 'encounterDate', headerName: '就醫日期', width: 150},
        {field: 'origEncId', headerName: '', width: 150},
        {field: 'inpOpd', headerName: '門/住', width: 150},
        {field: 'icEncType', headerName: '就醫類別', width: 150},
        {field: 'icCardNo', headerName: '卡號', width: 150},
        {field: 'childBornNote', headerName: '新生兒註記', width: 150},
        {field: 'encSuppleNote', headerName: '補卡註記', width: 150},
        {
            field: 'occupationaltherapyQty',
            headerName: '預防保健項目',
            width: 150,
        },
        {
            field: 'vhcFlag',
            headerName: '寫入',
            width: 150,
            renderCell: (_params) => renderWriteDataField,
        },
        {field: 'icEncStatus', headerName: '狀態', width: 150},
        {field: 'icUploadDatetime', headerName: '寫卡日期時間', width: 150},
        {field: 'encSeqNo', headerName: '就醫序號', width: 150},
        {field: 'icEncId', headerName: '就醫識別碼', width: 150},
    ]
    /**
     * 使用異常碼寫入
     */
    const handleErrorCodeOnClick = () => {
        setOpenDialog(true)
    }
    /**
     * 使用異常碼寫入確認
     */
    const handleErrorCodeDialogOnConfirm = () => {
        // TODO: 使用異常碼寫入確認
    }
    /**
     * 寫入按鈕
     */
    const handleWriteICCardOnClick = () => {
        // TODO: 寫入
    }
    /**
     * 寫入/使用異常碼寫入 按鈕
     */
    const renderWriteDataField = () => {
        return (
            <>
                <Button
                    title="寫入"
                    onClick={handleWriteICCardOnClick}
                />
                <Button
                    title="使用異常碼寫入"
                    onClick={handleErrorCodeOnClick}
                    variant={ButtonVariantEnum.Success}
                    color={ButtonColorEnum.Primary}
                />
            </>
        )
    }

    return (
        <div className="w-full">
            <Card title="寫卡資料">
                <AdvancedSearchTable
                    height={400}
                    columns={columns}
                    rows={rows}
                />
                <Dialog
                    paperStyleProps={{width: DialogSizeEnums.SM}}
                    variant={DialogVariant.CONFIRM}
                    title="使用異常碼"
                    open={openDialog}
                    onClose={() => setOpenDialog(false)}
                    onConfirm={handleErrorCodeDialogOnConfirm}
                    content={
                        <div className="w-full flex justify-center">
                            <Select data={{options: []}} showLabel={false} value={errorCode}
                                    onChange={(value) => setErrorCode(value)}/>
                        </div>
                    }
                />
            </Card>
        </div>
    )
}

export default WriteICCardSection
