import {v1Api} from "../Http"
import {HttpMethodEnum} from "edah_utils/dist"

/**
 * 取得週班中查詢的相關參數初始值
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const hisGetBasicData_test = (params) => v1Api.request({
    url: '/regs/reg/hisGetBasicData_test',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 取得掛號基礎資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const regsQueryBaseData = (params) => v1Api.request({
    url: '/regs/reg/queryRegBaseData',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 依照日期與APN查詢診室(包含醫生與科別清單)
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const regsQueryBasicDataClinicRes = (params) => v1Api.request({
    url: '/regs/reg/queryResRegsBasicDataClinic',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢當日班表(日期必填,其他選填)
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const regsQueryClinicOfTheDay = (params) => v1Api.request({
    url: '/regs/regsclinic/queryRegsClinicOfTheDay',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 新增預約資料
 * @param data {Object} params
 * @return {AxiosPromise}
 */
const regsAddReg = (data) => v1Api.request({
    url: '/regs/reg/addRegsReg',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 查詢掛號紀錄
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const regsQueryRegHistory = (params) => v1Api.request({
    url: '/regs/reg/queryRegHistory',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 初診預約完成掛號
 * @param data {Object} params
 * @return {AxiosPromise}
 */
const regsAddFirstviewToReg = (data) => v1Api.request({
    url: '/regs/reg/addFirstviewToReg',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 退掛
 * @param data {Object} params
 * @return {AxiosPromise}
 */
const regsCancelRegSave = (data) => v1Api.request({
    url: '/regs/reg/cancelRegSave',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 取得週班中查詢的相關參數初始值
 * @param params params
 * @returns {AxiosPromise}
 */
const regsWeekQueryBaseData = (params) => v1Api.request({
    url: '/regs/regsweek/queryRegsweekBaseData',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 依科別查詢周班表
 * @param params params
 * @returns {AxiosPromise}
 */
const regsWeekQueryByDivNo = (params) => v1Api.request({
    url: '/regs/regsweek/queryRegsweekByDivNo',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 依醫師查詢周班表
 * @param params params
 * @returns {AxiosPromise}
 */
const regsWeekQueryByDoctorNo = (params) => v1Api.request({
    url: '/regs/regsweek/queryRegsweekByDoctorNo',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 儲存醫師週班
 * @param data data
 * @returns {AxiosPromise}
 */
const addRegsWeek = (data) => v1Api.request({
    url: '/regs/regsweek/addRegsweek',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 刪除醫師週班
 * @param params params
 * @returns {AxiosPromise}
 */
const delRegsWeek = (params) => v1Api.request({
    url: '/regs/regsweek/delRegsweek',
    method: HttpMethodEnum.DELETE,
    params
})

/**
 * 更新醫師週班
 * @param data data
 * @returns {AxiosPromise}
 */
const editRegsWeek = (data) => v1Api.request({
    url: '/regs/regsweek/editRegsweek',
    method: HttpMethodEnum.PATCH,
    data
})

/**
 * 取得就醫資訊
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const regsQueryMedicalInfo = (params) => v1Api.request({
    url: '/regs/reg/queryMedicalInfo',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 掛號查詢
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const regsQueryReg = (params) => v1Api.request({
    url: '/regs/reg/queryReg',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢診室類別清單
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const regsQueryClinicTypeList = (params) => v1Api.request({
    url: 'regs/reg/queryClinicTypeList',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢診室類別清單
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const regsReportPappt = (params) => v1Api.request({
    url: 'regs/print/Pappt',
    method: HttpMethodEnum.GET,
    params
})

/**
 * getFile
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const regsReportGetFile = (params) => v1Api.request({
    url: 'report/getFile',
    method: HttpMethodEnum.GET,
    params,
    responseType: 'blob'
})

/**
 * 異動診室時確認有無提醒
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const regsCheckAlertWhenChangeClinicBeforeReg = (params) => v1Api.request({
    url: '/regs/reg/checkAlertWhenChangeClinicBeforeReg',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢該病人，是否在同日期、時段、醫師、科別的診已有掛號(排除已退掛)
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const regsCheckDuplicateClinicByPatientAndTimeAndDocAndDiv = (params) => v1Api.request({
    url: '/regs/reg/checkDuplicateClinicByPatientAndTimeAndDocAndDiv',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 存檔前檢查周班是否有重複 同星期&時段&醫師
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const regsCheckForWeekDuplicate = (params) => v1Api.request({
    url: '/regs/regsweek/checkForWeekDuplicate',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 展班前確認報備支援
 * @param data {Object} params
 * @return {AxiosPromise}
 */
const regsCheckBeforeAddClinic = (data) => v1Api.request({
    url: '/regs/regsweek/checkBeforeAddClinic',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 查詢該診掛號病患清單
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const regsQueryRegListByClinicId = (params) => v1Api.request({
    url: '/regs/reg/queryRegListByClinicId',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢有效類別清單(可搜尋代號與內容)(查詢郵地區號+縣市區名)
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const regsQueryActiveByCategoriesAndSearch = (params) => v1Api.request({
    url: '/install/justnamed/queryActiveByCategoriesAndSearch',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 依照醫師跟科別查詢診室使用者預設值(資料庫沒有設定會回傳null)
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const regsQueryResClinicUserSettingDefValue = (params) => v1Api.request({
    url: '/regs/regsclinic/queryResClinicUserSettingDefValue',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 依照醫師查詢診室使用者設定中的科別清單
 * @param params {object}
 * @return {AxiosPromise}
 */
const regsQueryDivListByClinicUserSetting = (params) => v1Api.request({
    url: '/regs/regsclinic/queryDivListByClinicUserSetting',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 依照科別查詢診室使用者設定中的醫師清單
 * @param params {object}
 * @return {AxiosPromise}
 */
const regsQueryDoctorListByClinicUserSetting = (params) => v1Api.request({
    url: '/regs/regsclinic/queryDoctorListByClinicUserSetting',
    method: HttpMethodEnum.GET,
    params
})

export {
    regsWeekQueryBaseData,
    regsWeekQueryByDivNo,
    regsWeekQueryByDoctorNo,
    addRegsWeek,
    delRegsWeek,
    editRegsWeek,
    hisGetBasicData_test,
    regsQueryBaseData,
    regsQueryBasicDataClinicRes,
    regsQueryClinicOfTheDay,
    regsAddReg,
    regsQueryRegHistory,
    regsQueryMedicalInfo,
    regsAddFirstviewToReg,
    regsCancelRegSave,
    regsQueryReg,
    regsQueryClinicTypeList,
    regsReportPappt,
    regsReportGetFile,
    regsCheckAlertWhenChangeClinicBeforeReg,
    regsCheckDuplicateClinicByPatientAndTimeAndDocAndDiv,
    regsCheckForWeekDuplicate,
    regsCheckBeforeAddClinic,
    regsQueryRegListByClinicId,
    regsQueryActiveByCategoriesAndSearch,
    regsQueryResClinicUserSettingDefValue,
    regsQueryDivListByClinicUserSetting,
    regsQueryDoctorListByClinicUserSetting
}
