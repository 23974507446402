import { useContext } from 'react'
import { ReadICCardContext } from '../context/ReadICCardContext'
import { icHisGetHisRegisterBasic } from '../api/v1/LocalService'
import { vHChisGetRegisterBasic } from '../api/v1/ICCM'
import { ApiErrorStatusEnum } from 'edah_utils/dist'
import { AlertTypeEnum } from 'edah-component'
import { t } from 'i18next'

/**
 * 讀取IC卡
 * @return {Object} 回傳物件
 */
const useICCard = () => {
    const { setStatus, setIcCardData, setIsPhysical, setIsRead, setDuration } =
        useContext(ReadICCardContext)

    /**
     * 讀取IC卡基本資料
     * @return {void}
     */
    const readPhysicalICCard = () => {
        //讀取IC卡基本資料
        icHisGetHisRegisterBasic({}).then((res) => {
            //運行時間存在
            if (res.runTime) {
                //設定讀取時間, 以秒為單位
                setDuration(res.runTime / 1000)
            }

            //是否讀取成功
            const apiStatus = res.err === ApiErrorStatusEnum.Success
            //設定狀態
            setStatus(apiStatus ? t('general.success') : t('general.failed'))
            //設定資料
            setIcCardData(apiStatus ? { ...res.data, isPhysical: true } : {})
            //設定是否為實體卡
            setIsPhysical(true)
            //設定是否讀取
            setIsRead(apiStatus)
        })
    }
  
    /**
     * 成功取得IC卡資料
     * @param data {Object} 資料
     * @param isPhysical {Boolean} 是否為實體卡
     */
    const successToGetVHICCard = (data, isPhysical) => {
        setIcCardData(data)
        // 設定為虛擬卡
        setIsPhysical(isPhysical)
        //設定是否讀取
        setIsRead(true)
    }

    /**
     * 讀取虛擬健保卡
     */
    const readVHICCard = () => {
        vHChisGetRegisterBasic({}).then((res) => {
            // 錯誤 / 訊息 / 資料
            const {err, msg, data} = res
            if (err === ApiErrorStatusEnum.Success) {
                successToGetVHICCard(data, false)
            } else {
                //顯示錯誤訊息
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        })
    }


    return { readPhysicalICCard, successToGetVHICCard, readVHICCard }
}
export default useICCard
