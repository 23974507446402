import React, {useEffect, useState} from "react"
import { access } from "fs-extra";
import {Dialog, DialogSizeEnums, DialogVariant} from "edah-component/dist";


/**
 * 退掛確認彈窗
 * @param show 是否顯示
 * @param closePopupButtonOnClick 關閉按鈕點擊事件
 * @param pinCode pincode值，無則跳出輸入視窗
 * @returns
 */
const PdfJsonPopup = ({
   show = false,
   closePopupButtonOnClick,
   pinCode
}) => {
    /*讀取HCA卡btn
    * 出現彈跳視窗>(如PIN CODE為空要求輸入，如PIN CODE有值將其帶入)要求輸入pin code>HCA API>顯示成功/失敗原因
    * 成功紀錄此筆certTypeCa、certTypeId1、certTypeId2
    * mode=0請輸入pincode mode=1將pincode傳入API並讀取 mode=2讀取成功失敗訊息
    **/

    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show)

    /**
     * mode=0請輸入pincode mode=1將pincode傳入API並讀取 mode=2讀取成功失敗訊息
     */
    const [mode, setMode] = useState(1)

    /**
     * 確定刪除點擊事件
     */
    const handleConfirmOnClick = () => {
        closePopupButtonOnClick()
    }

    /**
     * 取消刪除點擊事件
     */
    const handleCancelOnClick = () => {
        closePopupButtonOnClick()
    }

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        setShowPopup(show)
        setMode(pinCode? 1 : 0)
    }, [show])

    return showPopup ?
        <Dialog
            open={show}
            title={'讀取HCA卡片'}
            content={<div>{pinCode}</div>}
            variant={DialogVariant.CUSTOM}
            paperStyleProps={{ width:DialogSizeEnums.SM }}
            footerContent={
                <div className="text-sky-700 text-lg font-normal cursor-pointer"
                     onClick={handleConfirmOnClick}>關閉
                </div>
            }
        />
    : <></>
}

export default PdfJsonPopup;