/**
 * import functions
 */
import React, {useEffect, useState} from "react"
import ProgressBarChart from "../Chart/ProgressBarChart";
import {Dialog, DialogSizeEnums, DialogVariant} from "edah-component/dist/index";

/**
 * 退掛確認彈窗
 * @param show 是否顯示
 * @param closePopupButtonOnClick 關閉按鈕點擊事件
 * @returns
 */
const SignLoadingPopup = ({
   show = false,
   closePopupButtonOnClick
}) => {

    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show)

    /**
     * 確定刪除點擊事件
     */
    const handleConfirmOnClick = () => {
        closePopupButtonOnClick()
    }

    /**
     * 取消刪除點擊事件
     */
    const handleCancelOnClick = () => {
        closePopupButtonOnClick()
    }

    // test ProgressBarChart Data
    const progressPercentage = 25;

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {
        setShowPopup(show)
    }, [show])

    return (
        <Dialog
            open={show}
            title="簽章進度"
            variant={DialogVariant.CUSTOM}
            content={
                <div className="flex flex-row p-5">
                    <ProgressBarChart className="w-full " percentage={progressPercentage} color={'#2B6CB0'}/>
                </div>}
            onClose={handleConfirmOnClick}
            paperStyleProps={{width: DialogSizeEnums.SM}}
        />)
}

export default SignLoadingPopup;