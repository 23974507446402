import {createContext, useState} from 'react'

export const ReadICCardContext = createContext({})

export const ReadICCardContextProvider = (props) => {
    /**
     * 是否讀取
     */
    const [isRead, setIsRead] = useState(false)
    /**
     * ic卡資料
     */
    const [icCardData, setIcCardData] = useState({})
    /**
     * 是否為實體卡
     */
    const [isPhysical, setIsPhysical] = useState(true)
    /**
     * 讀取時間
     */
    const [duration, setDuration] = useState(0)
    /**
     * 讀取狀態
     */
    const [status, setStatus] = useState('')

    return (
        <ReadICCardContext.Provider
            // 記得提供 context 給 Provider
            value={{
                isRead: isRead,
                setIsRead: setIsRead,
                icCardData: icCardData,
                setIcCardData: setIcCardData,
                isPhysical: isPhysical,
                setIsPhysical: setIsPhysical,
                duration: duration,
                setDuration: setDuration,
                status: status,
                setStatus: setStatus,
            }}
        >
            {props.children}
        </ReadICCardContext.Provider>
    )
}
