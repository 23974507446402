// Import function
import {
    ApiErrorStatusEnum,
    fuzzySearchObjects,
    IccmInfoPageTypeEnum,
    stringIsEmpty,
    enum2Array,
} from 'edah_utils/dist'
import {t} from 'i18next'
import {useContext, useState} from 'react'
import {NoHpcSection} from './ActiveSection/NoHpcSection'
import {HasHpcSection} from './ActiveSection/HasHpcSection'
import {CheckPregnantSection} from './ActiveSection/CheckPregnantSection'
import {AllergyMedicineSection} from './ActiveSection/AllergyMedicineSection'
import {PreventiveHealthInfoSection} from './ActiveSection/PreventiveHealthInfoSection'
import {MedicalOrderSection} from './ActiveSection/MedicalOrderSection'
import {LongTermPrescriptionSection} from './ActiveSection/LongTermPrescriptionSection'
import {PrescriptionNoteSection} from './ActiveSection/PrescriptionNoteSection'
import {VaccinationInfoSection} from './ActiveSection/VaccinationInfoSection'
import {
    icHisGetInoculateData,
    icHisGetRegisterPregnant,
    icHisGetRegisterPrevent,
    icHisGetTreatmentNeedHPC,
    icHisGetTreatmentNoNeedHPC,
    icHisReadPrescriptHVE,
    icHisReadPrescriptLongTerm,
    icHisReadPrescriptMain,
} from '../../../api/v1/LocalService'
import {
    vhcHisGetTreatmentNoNeedHPC,
    vhcHisGetTreatmentNeedHPC,
    vhcHisReadPrescriptMain,
    vhcHisReadPrescriptLongTerm,
    vhcHisReadPrescriptHVE,
} from '../../../api/v1/ICCM'
import useToast from '../../../hooks/useToast'
import {
    Field,
    SizeEnum,
    TextField,
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    ButtonColorEnum,
    IconEnum,
    Tabs,
    TabsEnum,
    AlertTypeEnum,
    Card,
} from 'edah-component/dist'
import {ReadICCardContext} from '../../../context/ReadICCardContext'

/**
 * Tab資料類型
 */
const iccmInfoPageTypes = enum2Array(IccmInfoPageTypeEnum)

/**
 * 最大筆數
 */
const MAX_COUNT = {
    //門診處方箋
    prescriptionNote: 60,
    //長期處方箋
    longTermPrescription: 30,
    //重要醫令
    medicalOrder: 10,
}

/**
 *  Switch Container
 *  @return {JSX.Element}
 */
export const SwitchContainer = () => {
    const {icCardData, isRead} = useContext(ReadICCardContext)
    //資料
    const [data, setData] = useState([])

    //當前選取的Tab index
    const [currentActiveTab, setCurrentActiveTab] = useState(
        iccmInfoPageTypes[0].key
    )

    //當前讀取狀態
    const [readStatus, setReadStatus] = useState('')

    //讀取時間
    const [readDuration, setReadDuration] = useState(0)

    //搜尋文字
    const [searchText, setSearchText] = useState('')

    //Toast Message Hooks
    const showToast = useToast()

    //預設範圍筆數
    const defaultCountRange = {
        //欲讀取的起始資料
        iStartPos: '',
        //欲讀取的終止資料
        iEndPos: '',
    }

    //範圍筆數
    const [countRange, setCountRange] = useState(defaultCountRange)

    /**
     * Tab點擊時切換
     * @param activeSection {Number} activeSection
     * @return {void}
     */
    const onTabChange = (activeSection) => {
        //設定當前選取的Tab index
        setCurrentActiveTab(activeSection)
        //清空搜尋文字
        setSearchText('')
        //清空讀取狀態
        setReadStatus('')
        //清空讀取時間
        setReadDuration(0)
        //清空範圍筆數
        setCountRange(defaultCountRange)
        //清空資料
        setData([])
    }

    /**
     * 搜尋文字改變時
     * @param e {Event} e
     * @return {void}
     */
    const handleSearchTextOnChange = (e) => {
        setSearchText(e.target.value)
    }

    /**
     * 取得模糊匹配後的資料陣列
     * @return {Array<Object>}
     */
    const getFilterData = (oriData) => {
        return fuzzySearchObjects(oriData, searchText)
    }

    /**
     * 起始資料改變時
     * @param e {Event} event
     * @return {void}
     */
    /**
     * 起始資料改變時
     * @param e {Event} event
     * @return {void}
     */
    const handleStartPosOnChange = (e) => {
        // 取得輸入值
        const value = e.target.value
        const newValue = value === '' ? '' : parseInt(value, 10)

        // 如果輸入不是數字，直接返回
        if (value !== '' && isNaN(newValue)) {
            return
        }

        // 檢查是否符合以下條件：
        // 1. 允許空值
        // 2. 不超過最大筆數限制
        // 3. 小於終止位置（如果有終止位置）
        if (
            newValue === '' ||
            (newValue < MAX_COUNT[currentActiveTab] &&
                (!countRange.iEndPos || newValue < countRange.iEndPos))
        ) {
            setCountRange({...countRange, iStartPos: newValue})
        }
    }

    /**
     * 終止資料改變時
     * @param e {Event} event
     * @return {void}
     */
    const handleEndPosOnChange = (e) => {
        // 取得新值並轉換為數字
        const value = e.target.value
        const newValue = value === '' ? '' : parseInt(value, 10)
        // 如果輸入不是數字，直接返回
        if (value !== '' && isNaN(newValue)) {
            return
        }
        // 檢查是否符合以下條件：
        // 1. 允許空值
        // 2. 不超過最大筆數限制
        if (newValue === '' || newValue <= MAX_COUNT[currentActiveTab]) {
            setCountRange({...countRange, iEndPos: newValue})
        }
    }

    /**
     *  取得控制是否顯示範圍筆數
     *  @return {JSX.Element}
     */
    const getRangeCountSectionElement = () => {
        switch (IccmInfoPageTypeEnum[currentActiveTab]) {
            case IccmInfoPageTypeEnum.prescriptionNote:
            case IccmInfoPageTypeEnum.longTermPrescription:
            case IccmInfoPageTypeEnum.medicalOrder:
                return (
                    <div className="flex flex-row items-center justify-start px-4">
                        <Field label="筆數範圍">
                            <div className="flex flex-row items-center">
                                <TextField inputWidth={SizeEnum.Small} value={countRange.iStartPos}
                                           onChange={(e) => handleStartPosOnChange(e)}/>
                                <span className="px-2"> ~ </span>
                                <TextField inputWidth={SizeEnum.Small} value={countRange.iEndPos}
                                           onChange={(e) => handleEndPosOnChange(e)}/>
                            </div>
                        </Field>
                    </div>
                )
            default:
                return null
        }
    }

    /**
     * 控制過敏藥物不顯示讀取內容
     * @return {JSX.Element}
     */
    const getReadContentSectionElement = () => {
        switch (currentActiveTab) {
            case IccmInfoPageTypeEnum.allergyMedicine:
                return null
            default:
                return (
                    <div className="flex flex-row">
                        {/*卡片讀取*/}
                        <div className='flex items-center justify-center mr-4'>
                            <p className='text-[16px] text-[#3F3F46]'>{t('ICCard.cardReading', {status: stringIsEmpty(readStatus) ? '--' : readStatus})}</p>
                        </div>
                        {/*讀取花費時間*/}
                        <div className='flex items-center justify-center h-11 mr-4'>
                            <p className='text-[16px] text-[#3F3F46]'>{t('ICCard.readingTime', {duration: readDuration === 0 ? '--' : readDuration})}：</p>
                        </div>
                        {/*讀取按鈕*/}
                        <Button color={ButtonColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                                size={ButtonSizeEnum.Medium} icon={IconEnum.Refresh}
                                text={t('general.read')}
                                sx={{
                                    alignSelf: 'start',
                                    marginTop: '0.25rem',
                                    marginRight: '1.5rem',
                                }}
                                onClick={handleReadButtonOnClick}/>
                    </div>
                )
        }
    }

    /**
     * 取得區塊
     * @return {JSX.Element}
     */
    const getSection = () => {
        switch (IccmInfoPageTypeEnum[currentActiveTab]) {
            //就醫資料不需HPC卡
            case IccmInfoPageTypeEnum.noHpc:
                return <NoHpcSection data={getFilterData(data)}/>
            //就醫資料需HPC卡
            case IccmInfoPageTypeEnum.hasHpc:
                return (
                    <HasHpcSection
                        severeIllness={data.severeIllness}
                        treatment={data.treatment}
                        getFilterDataFunc={getFilterData}
                    />
                )
            //就醫資料門診處方箋
            case IccmInfoPageTypeEnum.prescriptionNote:
                return <PrescriptionNoteSection data={getFilterData(data)}/>
            //就醫資料住院處方箋
            case IccmInfoPageTypeEnum.longTermPrescription:
                return (
                    <LongTermPrescriptionSection data={getFilterData(data)}/>
                )
            //重要醫令
            case IccmInfoPageTypeEnum.medicalOrder:
                return <MedicalOrderSection data={getFilterData(data)}/>
            //預防保健掛號作業
            case IccmInfoPageTypeEnum.preventiveHealthInfo:
                return (
                    <PreventiveHealthInfoSection data={getFilterData(data)}/>
                )
            //孕婦產前檢查掛號作業
            case IccmInfoPageTypeEnum.checkPregnant:
                return <CheckPregnantSection data={data}/>
            //過敏藥物
            case IccmInfoPageTypeEnum.allergyMedicine:
                return (
                    <AllergyMedicineSection
                        info={{
                            //身分證
                            pPatientID: icCardData.idNo,
                            //生日
                            pPatientBirthDate: icCardData.birthDate,
                            //是否為實體IC
                            isPhysical: icCardData.isPhysical,
                        }}
                    />
                )
            //預防接種資料
            case IccmInfoPageTypeEnum.vaccinationInfo:
                return <VaccinationInfoSection data={data}/>
        }
    }

    /**
     * 取得顯示進階搜尋區塊
     * @return {JSX.Element}
     */
    const getAdvancedSearchSectionElement = () => {
        switch (IccmInfoPageTypeEnum[currentActiveTab]) {
            case IccmInfoPageTypeEnum.noHpc:
            case IccmInfoPageTypeEnum.hasHpc:
            case IccmInfoPageTypeEnum.medicalOrder:
            case IccmInfoPageTypeEnum.prescriptionNote:
            case IccmInfoPageTypeEnum.longTermPrescription:
                return (
                    <div>
                        <TextField placeholder="進階搜尋" value={searchText}
                                   onChange={(e) => handleSearchTextOnChange(e)}/>
                    </div>
                )
            default:
                return null
        }
    }

    /**
     * 讀取按鈕點擊時
     * @return {void}
     */
    const handleReadButtonOnClick = () => {
        switch (IccmInfoPageTypeEnum[currentActiveTab]) {
            //就醫資料不需HPC卡
            case IccmInfoPageTypeEnum.noHpc:
                getIcHisGetTreatmentNoNeedHPC()
                break
            //就醫資料需HPC卡
            case IccmInfoPageTypeEnum.hasHpc:
                getIcHisGetTreatmentNeedHPC()
                break
            //就醫資料門診處方箋
            case IccmInfoPageTypeEnum.prescriptionNote:
                getIcHisReadPrescriptMain()
                break
            //就醫資料住院處方箋
            case IccmInfoPageTypeEnum.longTermPrescription:
                getIcHisReadPrescriptLongTerm()
                break
            //就醫資料重要醫令
            case IccmInfoPageTypeEnum.medicalOrder:
                getIcHisReadPrescriptHVE()
                break
            //預防保健掛號作業
            case IccmInfoPageTypeEnum.preventiveHealthInfo:
                getIcHisGetRegisterPrevent()
                break
            //孕婦產前檢查掛號作業
            case IccmInfoPageTypeEnum.checkPregnant:
                getIcHisGetRegisterPregnant()
                break
            //預防接種資料
            case IccmInfoPageTypeEnum.vaccinationInfo:
                getIcHisGetInoculateData()
                break
        }
    }

    const tabItems = () => {
        return iccmInfoPageTypes.map((item, index) => {
            let disable = false
            if (
                icCardData.isPhysical === false &&
                (item.value === IccmInfoPageTypeEnum.preventiveHealthInfo ||
                    item.value === IccmInfoPageTypeEnum.checkPregnant ||
                    item.value === IccmInfoPageTypeEnum.vaccinationInfo)
            ) {
                //虛擬卡時，沒有 預防保健、孕婦產前檢查、預防接種資料
                disable = true
            }
            return {
                id: item.key,
                title: t(`ICCard.activeSection.${index}`),
                content: (
                    <div>
                        <div className="flex flex-row w-full py-2 mb-4">
                            <h3 className="flex items-center justify-center text-[20px] mr-4 pl-2 text-[#2B6CB0] border-l-8 border-[#2B6CB0]">
                                <span>
                                    {t(`ICCard.activeSection.${index}`)}
                                </span>
                            </h3>
                            {/*控制是否顯示範圍筆數*/}
                            {getRangeCountSectionElement()}
                            {/*控制過敏藥物不顯示讀取內容*/}
                            {getReadContentSectionElement()}
                            {/*控制是否顯示進階搜尋*/}
                            {getAdvancedSearchSectionElement()}
                        </div>
                        {/*區塊*/}
                        {getSection()}
                    </div>
                ),
                props: {
                    disabled: disable,
                },
            }
        })
    }

    /**
     * 處理API回應
     * @param res {Object} res
     * @return {void}
     */
    const handleApiResponse = (res) => {
        // API狀態
        const apiStatus = res.err === ApiErrorStatusEnum.Success
        // API回傳結果
        const result = res
        //設定讀取狀態
        setReadStatus(apiStatus ? t('general.success') : t('general.failed'))
        //設定讀取時間
        setReadDuration(res.runTime / 1000)
        //API狀態為成功
        if (apiStatus) {
            //設定資料
            setData(result.data.treatment)
        } else {
            // 顯示錯誤訊息
            showToast({message: res.msg, type: AlertTypeEnum.Error})
        }
    }

    /**
     * 處理API回應2
     * @param res {Object} res
     * @return {void}
     */
    const handleApiResponse2 = (res) => {
        // API狀態
        const apiStatus = res.err === ApiErrorStatusEnum.Success
        // API回傳結果
        const result = res
        //設定讀取狀態
        setReadStatus(apiStatus ? t('general.success') : t('general.failed'))
        //是否有執行時間
        const hasRunTime = !!res.runTime
        //設定讀取時間
        setReadDuration(hasRunTime ? res.runTime / 1000 : 0)

        //API狀態為成功
        if (apiStatus) {
            //設定資料
            setData(result.data)
        } else {
            // 顯示錯誤訊息
            showToast({message: res.msg, type: AlertTypeEnum.Error})
        }
    }

    /**
     * 取得IC卡歷次就醫不需HPC
     * @return {void}
     */
    const getIcHisGetTreatmentNoNeedHPC = () => {
        if (icCardData.isPhysical) {
            icHisGetTreatmentNoNeedHPC({}).then(handleApiResponse)
        } else {
            vhcHisGetTreatmentNoNeedHPC({}).then(handleApiResponse)
        }
    }

    /**
     * 取得IC卡歷次就醫需HPC
     * @return {void}
     */
    const getIcHisGetTreatmentNeedHPC = () => {
        if (icCardData.isPhysical) {
            icHisGetTreatmentNeedHPC({}).then(handleApiResponse2)
        } else {
            vhcHisGetTreatmentNeedHPC({}).then(handleApiResponse2)
        }
    }

    /**
     * 讀取就醫資料-門診處方箋
     * @return {void}
     */
    const getIcHisReadPrescriptMain = () => {
        if (countRange.iEndPos < countRange.iStartPos) {
            showToast({message: '結束筆數不得小於起始筆數', type: AlertTypeEnum.Error})
            return
        }
        if (icCardData.isPhysical) {
            icHisReadPrescriptMain({
                //欲讀取的起始資料
                iStartPos: countRange.iStartPos,
                //欲讀取的起始資料(該欄位不可超過60)
                iEndPos: countRange.iEndPos,
            }).then(handleApiResponse2)
        } else {
            vhcHisReadPrescriptMain({
                //欲讀取的起始資料
                iStartPos: countRange.iStartPos,
                //欲讀取的起始資料(該欄位不可超過60)
                iEndPos: countRange.iEndPos,
            }).then(handleApiResponse2)
        }
    }

    /**
     * 讀取就醫資料-長期處方箋
     * @return {void}
     */
    const getIcHisReadPrescriptLongTerm = () => {
        if (countRange.iEndPos < countRange.iStartPos) {
            showToast({message: '結束筆數不得小於起始筆數', type: AlertTypeEnum.Error})
            return
        }
        if (icCardData.isPhysical) {
            icHisReadPrescriptLongTerm({
                //欲讀取的起始資料
                iStartPos: countRange.iStartPos,
                //欲讀取的起始資料(該欄位不可超過30)
                iEndPos: countRange.iEndPos
            }).then(handleApiResponse2)
        } else {
            vhcHisReadPrescriptLongTerm({
                //欲讀取的起始資料
                iStartPos: countRange.iStartPos,
                //欲讀取的起始資料(該欄位不可超過30)
                iEndPos: countRange.iEndPos
            }).then(handleApiResponse2)
        }
    }

    /**
     * 讀取就醫資料-重要醫令
     * @return {void}
     */
    const getIcHisReadPrescriptHVE = () => {
        if (countRange.iEndPos < countRange.iStartPos) {
            showToast({message: '結束筆數不得小於起始筆數', type: AlertTypeEnum.Error})
            return
        }
        if (icCardData.isPhysical) {
            icHisReadPrescriptHVE({
                //欲讀取的起始資料
                iStartPos: countRange.iStartPos,
                //欲讀取的起始資料(該欄位不可超過10)
                iEndPos: countRange.iEndPos
            }).then(handleApiResponse2)
        } else {
            vhcHisReadPrescriptHVE({
                //欲讀取的起始資料
                iStartPos: countRange.iStartPos,
                //欲讀取的起始資料(該欄位不可超過10)
                iEndPos: countRange.iEndPos
            }).then(handleApiResponse2)
        }
    }

    /**
     * 預防保健掛號作業
     * @return {void}
     */
    const getIcHisGetRegisterPrevent = () => {
        icHisGetRegisterPrevent({}).then(handleApiResponse2)
    }

    /**
     * 孕婦產前檢查掛號作業
     * @return {void}
     */
    const getIcHisGetRegisterPregnant = () => {
        icHisGetRegisterPregnant({}).then(handleApiResponse2)
    }

    /**
     * 預防接種資料
     * @return {void}
     */
    const getIcHisGetInoculateData = () => {
        icHisGetInoculateData({}).then(handleApiResponse2)
    }

    return (
        isRead && (
            <Card sx={{p: 1}}>
                <Tabs variant={TabsEnum.WORKSPACE_TAB} activeTabId={currentActiveTab}
                      items={tabItems()} onTabChange={onTabChange}/>
            </Card>
        )
    )
}
