import React from "react";

/**
 * 自定義CardTitle
 * @param title {String}
 * @param operationBar {JSX.Element}
 * @return {JSX.Element}
 */
export const CpoeCardTitle = ({
    title,
    operationBar
}) =>{

    /**
     * 獲取操作欄
     * @return
     */
    const getOpetationBar = ()=>{
        if(operationBar){
            return operationBar
        }
        return (<></>)
    }

    return (
        <div className="flex flex-row items-center">
            {/*標題欄*/}
            <div className="flex gap-2 items-center justify-start">
                <span className="inline-block w-2 h-[22px] bg-primary"></span>
                <h6 className="text-primary font-medium text-[20px]">{title}</h6>
            </div>
            <div className="flex-1 flex justify-end items-center">
                {getOpetationBar()}
            </div>

        </div>
    )
}