import React, {useEffect, useState} from 'react'
import {PopupModeEnum, RegsWeekModeEnum} from "edah_utils/dist"
import {Button, ColorEnum, ButtonSizeEnum, ButtonVariantEnum} from 'edah-component/dist'
import {List} from "edah-component/dist/index";

/**
 * 列表卡片
 * @param data {Array<Object>}
 * @param mode {RegsWeekModeEnum}
 * @param options {Array<Object>}
 * @param week {number} 星期幾
 * @param timeslot {number}
 * @param isAddAppointment {Boolean} 是否顯示新增問診
 * @param onAddAppointment {Function} 新增問診函式
 * @param showPopup
 * @param baseData {Object} 基礎資料
 * @param isSearched {Boolean} 是否已查詢過
 */
const ListCard = ({
                      data,
                      mode,
                      options,
                      week,
                      timeslot,
                      isAddAppointment,
                      onAddAppointment,
                      showPopup,
                      baseData,
                      isSearched
                  }) => {
    // 列表資料
    const [list, setList] = useState(data)

    /**
     * 顯示編輯視窗
     * @param e {Event} 事件
     * @return {void}
     */
    const handleBlockOnContextMenu = (e) => {
        //防止右鍵點擊時觸發右鍵選單
        e.preventDefault()
        //右鍵點擊時
        if (e.nativeEvent.button === 2) {
            //透過父層函式新增問診
            onAddAppointment(week, timeslot)
        }
    }

    /**
     * 點擊新增問診時
     */
    const handleNewAppointmentOnClick = () => {
        showPopup({
            //星期幾
            week: week,
            //時段
            timeslot: timeslot
        })
    }

    /**
     * 當滑鼠右鍵點擊新問診時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleNewAppointmentOnContextMenu = (e) => e.preventDefault()

    /**
     * 渲染週班List的內容
     * @param index
     * @returns {{name: React.JSX.Element, department: React.JSX.Element}}
     */
    const renderRowContent = (index) => {
        const record = list[index]
        const doctorName = baseData.doctorList.find(item => item.userNo === record.doctorNo)?.userName || ''
        const divName = baseData.divisionList.find(item => item.divNo === record.divNo)?.divName || ''
        return {
            name: <div>{doctorName}</div>,
            department: <p>{`${divName}/${record.clinicName}`}</p>,
            note: record.weekNotice ? <span>*備註</span> : null, // 當 weekNotice 不為空時顯示 *備註
        }
    }

    /**
     * 點擊Record時
     */
    const handleOnDoubleClick = (item) => {
        // 顯示編輯視窗
        showPopup({
            //
            weekId: item.weekId,
            // 預約最大看診人次
            maxPatient: item.limit,
            // 現場最大看診人次
            onSiteNumber: item.onsiteLimit,
            // 看診時間(人/分)
            consultationTime: item.slotMin,
            // 預約方式
            reservationMethod: parseInt(item.giveNumType),
            // 調病歷
            canGetMedicalRecords: item.sentChartFlag,
            // 給號方式
            numberGivingMethod: item.noType,
            // 診間開始展班日期
            effectiveDate: item.effectiveDate ? item.effectiveDate.split(' ')[0] : null,
            // 收費別
            chargeType: item.cashType !== null ? `${item.cashType} ${baseData.cashTypeList.find(cashType => cashType.cashType === `${item.cashType}`).cashName}` : '',
            // 預約限制
            reservationLimit: item.reserveLimitType,
            // 診別
            medicalType: item.clinicType,
            // 展班月數
            exhibitionMonth: item.tranMonth,
            // 假日展班
            holidayExhibition: item.holidayexclFlag,
            // 開放網掛/App掛號
            webOrAppBooking: item.webapptFlag,
            // 開放網掛/App取消看診進度
            webOrAppCancel: item.showProcessFlag,
            //網路預約期限
            onlineBookingDeadline: item.webapptMonth,
            //看診項目
            consultationItems: item.clinicMarkNo,
            //診間地點
            clinicLocation: item.clinicLocation,
            //注意事項
            notes: item.weekNotice,
            //診間標語
            clinicSlogan: item.clinicSlogan,
            //醫生或科別
            doctorOrDepartment: +mode === RegsWeekModeEnum.Department ? item.doctorNo : item.divNo,
            //診室名稱
            clinicName: item.clinicName,
            //時段
            timeslot: item.apn,
            //星期幾
            week: item.week,
            // 醫師
            doctor: `${item.doctorNo} ${baseData.doctorList.find(doctor => doctor.userNo === item.doctorNo).userName}`,
            // 科別
            department: `${item.divNo} ${baseData.divisionList.find(department => department.divNo === item.divNo).divName}`,

            lockVersion: item.lockVersion

        }, PopupModeEnum.Modify)
    }


    /**
     * 監聽當前模式改變時重新設定列表
     */
    useEffect(() => {
        //重新清空列表
        setList(data || [])
    }, [mode])

    /**
     * 當列表資料改變時更新列表
     */
    useEffect(() => {
        setList(data)
    }, [data])

    return (
        <div
            className='w-full h-full bg-white rounded-lg flex flex-col items-center justify-center py-2 px-1'
            onContextMenu={(e) => handleBlockOnContextMenu(e)}>
            {isSearched ? (<>
                {
                    isAddAppointment && (
                        <Button sx={{margin: "0.5rem", paddingY: '0.25rem'}}
                                color={ColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                                size={ButtonSizeEnum.Large}
                                onContextMenu={(e) => handleNewAppointmentOnContextMenu(e)}
                                onClick={handleNewAppointmentOnClick}>{"新開診"}</Button>
                    )
                }
                {list.length ?
                    <List
                    height={isAddAppointment ? 200 : 240}  // 根據 isAddAppointment 動態設定高度
                    width={220}
                    itemCount={list.length}
                    itemSize={60}
                    renderRowContent={renderRowContent}
                    onDoubleClick={(index) => handleOnDoubleClick(list[index])}
                    /> : <div className="text-gray-500 tracking-wide">
                    <p>查無相關資料</p>
                    <p>請右鍵新開診</p>
                </div>}
            </>) : <p className="text-gray-500 tracking-wide">請先進行查詢</p>}

        </div>
    )
}
export default ListCard
