//Import Icons
import {ReactComponent as PdfJsonIcon} from '../../assets/images/ESSI/pdf-json.svg'
import SearchIcon from "../../assets/images/search-interface-symbol.png"

//Import css
import '../../components/TableList/tableList.scss'
import '../../components/ICCard/maintain.scss'

//Import function
import {Pagination} from "../../components/Pagination/Pagination"
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    createDateRange2String,
    fuzzySearchObjects,
    generateEnums,
    getLocalStorage,
    multiTermFuzzySearchObjects,
    objectIsEmpty,
    stringIsEmpty
} from "edah_utils/dist"
import PdfJsonPopup from "../../components/ESSI/PdfJsonPopup"
import PinCodePopup from "../../components/ESSI/PinCodePopup"
import useOutsideClick from "../../hooks/useOutsideClick"
import React, {Fragment, useEffect, useMemo, useRef, useState} from "react"
import {queryAllDoctor} from "../../api/v1/Menu"
import {t} from "i18next"
import {essiQueryTransRecByOrg} from "../../api/v1/ESSI"
import {BaseInput} from "../../components/Input/BaseInput"
import {
    Select,
    SelectWidthEnum,
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    ButtonColorEnum,
    IconEnum,
    Search,
    DateRangePicker,
    Checkbox,
    ColorEnum
} from "edah-component/dist"
import dayjs from "dayjs"



/**
 * 醫療機構卡簽章
 * @return {JSX.Element}
 */
const Unsign2Page = () => {
    /**
     * 機構卡簽章狀態
     */
    const InstitutionCardEnums = generateEnums({
        /**
         * 機構卡未簽
         */
        NotSigned: {
            key: 0,
            value: "機構卡未簽"
        },
        /**
         * 機構卡已簽
         */
        Signed: {
            key: 1,
            value: "機構卡已簽"
        },
        /**
         * 全部狀態
         */
        All: {
            key: 9,
            value: "全部狀態"
        },
    })

    /**
     * Table element 的預設type
     */
    const tableElementDefaultType = "px-[16px] border-r border-[#111111]/15 font-normal"

    // 起始時間
    const [startDate, setStartDate] = useState(null)

    // 終止時間
    const [endDate, setEndDate] = useState(null)

    //當前頁碼
    const [currentPage, setCurrentPage] = useState(1)

    //每頁資料筆數
    const [pageSize, setPageSize] = useState(10)

    //總資料筆數
    const [totalSize, setTotalSize] = useState(0)

    // 是否選擇全部
    const [selectedAll, setSelectedAll] = useState(false)

    // 已選擇資料
    const [selectedData, setSelectedData] = useState([])

    // 初始資料
    const [data, setData] = useState([])

    // 建立人員
    const [user, setUser] = useState("")

    // 所有人員代號選項
    const [users, setUsers] = useState([])

    // 是否顯示人員代號選項
    const [showUserOptions, setShowUserOptions] = useState(false)

    // 該筆點選資料
    const [rowData, setRowData] = useState([])

    //是否顯示PinCode彈窗
    const [showPinCodePopup, setShowPinCodePopup] = useState(false)

    //是否顯示PDF/JSON彈窗
    const [showPdfJsonPopup, setShowPdfJsonPopup] = useState(false)

    // PDF/JSON文件內容
    const [docContent, setDocContent] = useState(false)

    //是否顯示簽章進度
    const [showSignLoading, setShowSignLoading] = useState(false)

    //進階搜尋文字
    const [advancedSearchText, setAdvancedSearchText] = useState("")

    //ref 用於指向人員代下拉菜單元素
    const dropdownUserRef = useRef(null)

    // 機構卡簽章狀態
    const [institutionCardType, setInstitutionCardType] = useState(InstitutionCardEnums.All.key)

    // 當前使用者
    const [currentUserNo, setCurrentUserNo] = useState("")

    //分院類型
    const [branchType, setBranchType] = useState({})

    //是否已經取得資料
    const hasFirstFetchedData = useRef(false)

    //當前分院
    const [currentCampus, setCurrentCampus] = useState("")

    /**
     * 處理機構卡簽章狀態改變
     * @param value {String}
     * return {void}
     */
    const handleInstitutionCardOnChange = (value) => {
        setInstitutionCardType(value)
    }

    /**
     * 分院類型點擊時
     * @param key {String} key
     * @return {void}
     */
    const handleBranchTypeOnClick = (key,) => {
        // 設定分院類型
        setBranchType(prevBranchType => ({
            // 舊的分院類型
            ...prevBranchType,
            [key]: !prevBranchType[key],
        }))
    }

    /**
     * 欄位下拉選單改變時-人員下拉選單
     * @param value {String}
     * @return {void}
     */
    const handleUserOnChange = (value) => setUser(value)

    /**
     * 處理人員代號搜尋文字框焦點事件
     * @return {void}
     */
    const handleUserDropdownOnFocus = () => setShowUserOptions(true)

    /**
     * 處理人員代號選項點擊事件
     * @param option {Object} 人員代號
     * @return {void}
     */
    const handleUserOptionOnClick = (option) => {
        // 值不為空
        if (!objectIsEmpty(option)) {
            setUser(`${option.userNo} ${option.userName}`)
        }

        // 關閉建立人員下拉選單
        setShowUserOptions(false)
    }

    /**
     * 進階搜尋文字輸入事件
     * @param e {Event} 事件
     * @return {void}
     */
    const handleAdvancedSearchTextOnChange = (e) => setAdvancedSearchText(e.target.value)

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     * @return {void}
     */
    const onPaginationPageOnChange = (page) => setTimeout(() => setCurrentPage(page), 100)

    /**
     * 每頁資料筆數變更事件
     * @param e {Event} 事件
     * @return {void}
     */
    const onPaginationPageSizeOnChange = (e) => setPageSize(e.target.value)

    /**
     * 上一頁點擊事件
     * @return {void}
     */
    const onPaginationPreviousOnClick = () => (currentPage - 1) > 1 ? setCurrentPage(currentPage - 1) : setCurrentPage(1)

    /**
     * 下一頁點擊事件
     * @return {void}
     */
    const onPaginationNextOnClick = () => (currentPage + 1) < totalPage ? setCurrentPage(currentPage + 1) : setCurrentPage(totalPage)

    /**
     * 時間開始改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleStartDateOnChange = (e) => setStartDate(e.target.value)

    /**
     * 時間開始改變時
     * @param e {Event} 事件
     * @return {void}
     */
    const handleEndDateOnChange = (e) => setEndDate(e.target.value)

    /**
     * 日期範圍變動事件
     * @param newDates {Array<Dayjs | null>} 日期範圍 [開始日期, 結束日期]
     * @return {void}
     */
    const handleDateRangeOnChange = (newDates) => {
        const [newStartDate, newEndDate] = newDates
        setStartDate(newStartDate ? newStartDate.format('YYYY-MM-DD') : '')
        setEndDate(newEndDate ? newEndDate.format('YYYY-MM-DD') : '')
    }

    /**
     * 點選PDF JSON按鈕事件
     * @return {void}
     */
    const handlePdfJsonOnClick = () => setShowPdfJsonPopup(true)

    /**
     * 關閉PDF JSON彈窗
     * @return {void}
     */
    const handleCloseCancelPdfJsonPopup = () => setShowPdfJsonPopup(false)

    /**
     * 簽章btn點擊時
     * @return {void}
     */
    const handleSignButtonOnClick = () => {
        // 判斷ESSI_USERCERT.ACCESSCODE是否為空，為空請輸入pincode
        setShowPinCodePopup(true)
    }

    /**
     * 關閉PinCode popup
     * @return {void}
     */
    const handlePinCodePopupOnClose = () => setShowPinCodePopup(false)

    /**
     * 確認PinCode popup
     * @return {void}
     */
    const handlePinCodePopupOnSave = () => {
        // 關閉pinCode popup
        setShowPinCodePopup(false)
        // 顯示sign Loading popup
        setShowSignLoading(true)
    }

    /**
     * 關閉sign Loading popup 簽章進度
     * @return {void}
     */
    const handleSignLoadingPopupOnClose = () => setShowSignLoading(false)

    /**
     * 全選事件觸發時
     * @return {void}
     */
    const handleSelectedAllOnClick = () => {
        //更改值後是否為全選
        const isSelectedAll = !selectedAll
        //取得當前的資料
        const arrayData = [...data]
        //將所有資料的selected改為true
        setData(arrayData.map((data) => ({...data, selected: !selectedAll})))
        //設定是否全選
        setSelectedAll(isSelectedAll)
    }

    /**
     * 個別點擊選取事件
     * @param index {Number}
     * @return {void}
     */
    const handleSelectedOnClick = (index) => {
        //新的資料
        const newData = data.map((d, i) => i === index ? {...d, selected: !d.selected} : d)
        //設定新的資料
        setData(newData)
        //是否全選
        const isSelectedAll = newData.every(d => d.selected)
        //設定是否全選
        setSelectedAll(isSelectedAll)
    }

    /**
     * 當搜尋按鈕點擊時
     * @return {void}
     */
    const handleSearchButtonOnClick = () => getQueryTransRecByOrg()

    /**
     * 取得所有醫生
     * @return {void}
     */
    const getQueryAllDoctor = () => {
        queryAllDoctor({}).then(res => {
            //取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                const data = res.data
                //設定人員代號選項
                setUsers(data)
            }
        })
    }

    /**
     * 查詢未簽醫療機構卡
     * @return {void}
     */
    const getQueryTransRecByOrg = () => {
        // 取得分院
        const zones = Object.keys(branchType).filter(type => branchType[type] === true)

        essiQueryTransRecByOrg({
            //院區
            zone: zones.join(','),
            //當前使用者編號
            createUser: user,
            // 頁數
            pageNum: currentPage,
            // 每頁資料筆數
            pageSize: pageSize,
            // 建立時間
            createStartDate: `${startDate} 00:00:00`,
            // 結束時間
            createEndDate: `${endDate} 23:59:59`,
            // 機構卡簽章狀態
            signStatus: institutionCardType,
        }).then(res => {
            // 取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                // 設定資料
                setData(res.data)
            }
        })
    }

    /**
     * 取得過濾後的人員代號選項
     * @return {Array<Object>}
     */
    const getFilterUser = () =>
        // 醫生是否有空格
        user.indexOf(' ') >= 0 ?
            // 多個字串模糊搜尋
            multiTermFuzzySearchObjects(users, user.split(' ')) :
            // 單個字串模糊搜尋
            fuzzySearchObjects(users, user)

    /**
     * 第一次進入時去新增測試資料
     * @return {void}
     */
    useMemo(() => {
        //分院
        const campus = getLocalStorage('campus')
        // 設定當前分院
        setCurrentCampus(campus)
        //取得所有分院Object
        const allBranch = t('campus.shortName', {returnObjects: true})
        // 動態根據分院數量設定分院類型
        const initialBranchType = Object.keys(allBranch).reduce((acc, key) => {
            // 設定分院類型
            acc[key] = key === campus
            // 回傳分院類型
            return acc
        }, {})
        //設定分院類型
        setBranchType(initialBranchType)
        //取得當前使用者編號
        const userNo = getLocalStorage('userno')
        // 設定當前使用者編號
        setCurrentUserNo(userNo)
        //取得日期範圍
        const dateRange = createDateRange2String("","",2)
        // 設定開始時間
        setStartDate(dateRange[0])
        // 設定開始時間
        setEndDate(dateRange[1])
        // 取得所有醫生
        getQueryAllDoctor()
    }, [])

    /**
     * 當前頁碼改變時
     * @return {void}
     */
    useEffect(() => {
        // 當前使用者編號 / 起始時間 / 結束時間不為空
        if (!stringIsEmpty(currentUserNo) && !stringIsEmpty(startDate) && !stringIsEmpty(endDate)) {
            // 是否為第一次取得資料
            if (!hasFirstFetchedData.current) {
                // 查詢未簽醫療機構卡
                getQueryTransRecByOrg()
                // 設定為已取得資料
                hasFirstFetchedData.current = true
            }
        }
    }, [currentUserNo, startDate, endDate])

    /**
     * 避免點擊建立人員選項時，因CSS點不到問題
     */
    useOutsideClick({
        ref: dropdownUserRef,
        handler: () => setShowUserOptions(false),
    })

    return (
        <div className="w-full p-4 bg-[#FAFAFA]">
            <div className="filterGroup flex flex-row flex-wrap items-center justify-between">
                <div className="flex items-center space-x-2 mb-2 text-left">
                    {/*建立人員*/}
                    <Search
                        inputLabel={'建立人員'}
                        options={users}
                        getOptionLabel={(option)=>`${option.userNo} ${option.userName}`}
                        inputValue={user}
                        onInputChange={(_e,value)=>handleUserOnChange(value)}
                        onChange={(_e,value)=>{handleUserOptionOnClick(value)}}/>
                    {/*開始日期起*/}
                    <DateRangePicker
                        value={[
                            startDate? dayjs(startDate) : null,
                            endDate ? dayjs(endDate) : null,]}
                        onChange={handleDateRangeOnChange}
                        required
                    />
                    <Select
                        width={SelectWidthEnum.Medium}
                        data={{
                            label: '全部狀態',
                            options: [
                                // 全部狀態
                                {value: InstitutionCardEnums.All.key, label: InstitutionCardEnums.All.value},
                                // 機構卡已簽
                                {value: InstitutionCardEnums.Signed.key, label: InstitutionCardEnums.Signed.value},
                                // 機構卡未簽
                                {
                                    value: InstitutionCardEnums.NotSigned.key,
                                    label: InstitutionCardEnums.NotSigned.value
                                },
                            ]
                        }}
                        value={institutionCardType}
                        notched={true}
                        onChange={handleInstitutionCardOnChange}/>
                    {/*分院選項*/}
                        <div className="flex flex-row items-center">
                            {
                                !arrayIsEmpty(Object.keys(branchType)) && Object.keys(branchType).map((key, index) =>
                                    <Fragment key={index}>
                                        <Checkbox color={ColorEnum.Success}
                                                  label={t(`campus.shortName.${key}`)}
                                                  checked={branchType[key]}
                                                  onChange={() => handleBranchTypeOnClick(key)}
                                        />

                                    </Fragment>
                                )
                            }
                        </div>
                    {/*查詢按鈕*/}
                    <Button color={ButtonColorEnum.Primary} size={ButtonSizeEnum.Medium}
                            variant={ButtonVariantEnum.Contained} text={t('general.query')}
                            onClick={handleSearchButtonOnClick}/>
                </div>
                {/*簽章按鈕*/}
                <div className="source flex flex-row items-end justify-end mb-2 mr-4">
                    <Button color={ButtonColorEnum.Warning} size={ButtonSizeEnum.Medium}
                            variant={ButtonVariantEnum.Contained} text={'簽章'}
                            onClick={handleSignButtonOnClick}/>
                </div>
            </div>
            <div className="py-2 px-2 bg-white border-[1px] border-[#D4D4D8] rounded-[6px]">
                <div className="flex flex-row items-center justify-between mb-2">
                    {/*進階搜尋*/}
                    <div>
                        <BaseInput inputMode="search"
                                   className="w-[320px] h-10 pl-4 border-[1px] border-[#D4D4D8] rounded-[6px]"
                                   type="text" value={advancedSearchText} placeholder={t('general.advancedSearch')}
                                   onChange={handleAdvancedSearchTextOnChange}/>
                    </div>
                    <div>
                        <span>已選取 {selectedData.length} 筆</span>
                    </div>
                </div>
                <div className=' w-full overflow-x-auto overflow-y-auto min-h-[100px] max-h-[35vh] xl:max-h-[65vh]'>
                    <table
                        className="table-fixed w-full text-left overflow-x-auto overflow-y-auto min-h-[100px] max-h-[35vh] xl:max-h-[50vh]">
                        <thead>
                        <tr className="bg-[#e4e4e7] h-[56px] border-collapse text-lg text-[#18181b]">
                            <th className={`w-[70px] ${tableElementDefaultType}`}>
                                <Checkbox checked={selectedAll} onClick={handleSelectedAllOnClick} />
                            </th>
                            <th className={`${tableElementDefaultType}`}>操作</th>
                            <th className={`${tableElementDefaultType}`}>單據類別 <br/> 表單抬頭</th>
                            <th className={`${tableElementDefaultType}`}>門/急/住 <br/> 病人資訊</th>
                            <th className={`${tableElementDefaultType}`}>狀態</th>
                            <th className={`w-[250px] ${tableElementDefaultType}`}>表單代號<br/> 建立人員/日期</th>
                            <th className={`${tableElementDefaultType}`}>處理狀態<br/>說明</th>
                            <th className={`${tableElementDefaultType}`}>內容<br/>處理明細</th>
                            <th className={`${tableElementDefaultType}`}>Trace Id</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            data && data.map((data, index) =>
                                <tr className={`h-[40px] ${index % 2 === 0 ? '' : 'bg-[#f4f4f5]'} text-[#18181b]`}
                                    key={index}>
                                    <td className={`${tableElementDefaultType}`}
                                        onClick={() => handleSelectedOnClick(index)}>
                                        <input
                                            className="bg-green-400 checked:bg-green-600 text-green-600 w-[20px] h-[20px]"
                                            type="checkbox" style={{accentColor: "#38A169"}} checked={data.selected}/>
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        <Button sx={{marginLeft: '0.5rem'}}
                                                color={ButtonColorEnum.Primary}
                                                variant={ButtonVariantEnum.Text}
                                                size={ButtonSizeEnum.Large}
                                                text={'閱讀'}
                                                icon={IconEnum.View}
                                                onClick={handlePdfJsonOnClick} />
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.emrRpttype ? data.emrRpttype : ""}<br/>{data.emrTitle ? data.emrTitle : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.inpOpd ? data.inpOpd : ""}<br/>{data.patientName ? data.patientName : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.transStatus ? data.transStatus : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.formCode ? data.formCode : ""}<br/>{data.createUser ? data.createUser : ""}{data.createDatetime ? data.createDatetime : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.fixStatue ? data.fixStatue : ""}<br/>{data.memo ? data.memo : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.contentType ? data.contentType : ""}<br/>{data.transMemo ? data.transMemo : ""}
                                    </td>
                                    <td className={`${tableElementDefaultType}`}>
                                        {data.transDocId ? data.transDocId : ""}
                                    </td>
                                </tr>
                            )
                        }
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-end w-full">
                    <Pagination totalPageSize={totalSize} currentPage={currentPage} pageSize={pageSize}
                                totalSize={totalSize}
                                onPageOnChange={(page) => onPaginationPageOnChange(page)}
                                onPreviousOnClick={onPaginationPreviousOnClick}
                                onNextOnClick={onPaginationNextOnClick}
                                onPageSizeChange={onPaginationPageSizeOnChange}/>
                </div>
            </div>
            {/*PdfJsonPopup*/}
            <PdfJsonPopup
                show={showPdfJsonPopup}
                closePopupButtonOnClick={handleCloseCancelPdfJsonPopup}
                docContent={docContent}
                rowData={rowData}/>
            {/*PinCodePopup*/}
            <PinCodePopup
                show={showPinCodePopup}
                closePopupButtonOnClick={handlePinCodePopupOnClose}
                savePopupButtonOnClick={handlePinCodePopupOnSave}/>
        </div>
    )
}

export default Unsign2Page
