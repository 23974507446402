import React, {useEffect, useRef, useState} from "react";
import {ReactComponent as HeaderMark} from "../assets/images/icons/EDH-logo.svg";
// import { ReactComponent as SettingIcon } from "../assets/images/icons/settings.svg";
// import { ReactComponent as ClockIcon } from "../assets/images/icons/clock.svg";
import {ReactComponent as UserIcon} from "../assets/images/icons/user.svg";
import {ReactComponent as LogoutIcon} from "../assets/images/icons/logout.svg";
// import NaviButton from "../components/Sidebar/NaviButton";
import {t} from "i18next"
import {cleanLocalStorage, getLocalStorage, time2String} from "edah_utils/dist";
import NotificationsMenu from "../components/Notifications/NotificationsMenu";
import {fetchNotificationData, fetchSetNotificationData} from "../api/api";
import {logout} from "../api/v1/Users";
import {useNavigate} from "react-router-dom";

const HeaderLayout = ({openNotifitionItemModal, openNotifitionViewMoreModal}) => {
    //當前院區
    const currentCampus = getLocalStorage('campus')

    useEffect(() => {
        const username = getLocalStorage('username')
        setUsername(username)
    }, [])

    const navigate = useNavigate()
    const [notificationsData, steNotificationsData] = useState([]);
    const [currentDateTime, setCurrentDateTime] = useState(
        time2String(null, "YYYY-MM-DD hh:mm A")
    );
    //使用者名稱
    const [username, setUsername] = useState(null);
    const [showUserMenu, setShowUserMenu] = useState(false); // user block status
    const submittingNotificationStatus = useRef(false); //notification data發送狀態

    /**
     * 登出
     */
    const handleLogout = async () => {
        //呼叫Logout API
        logout({}).then(result => {
            //成功登出
            if (result.err === 0) {
                cleanLocalStorage()
                navigate('/login')
            }
        })
    }

    useEffect(() => {
        const totalData = fetchNotificationData();
        if (totalData !== null) {
            steNotificationsData(totalData);
        }

        //設一個計時器，用來更新時間
        const intervalId = setInterval(() => {
            const newDateTime = time2String(null, "YYYY-MM-DD hh:mm A");
            setCurrentDateTime(newDateTime);
        }, 1000);

        //設一個計時器(每兩分鐘)，用來作假notification的假資料
        const intervalGetNotificationId = setInterval(() => {
            const currTime = time2String(null, "YYYY-MM-DD HH:mm:ss");
            const randomMsgIndex = Math.floor(Math.random() * 5);

            let msg, detail, message_type

            if (randomMsgIndex === 0) {
                message_type = 0;
                msg = "用藥指示變更";
                detail = "患者 李先生 變更用藥天數為七日七日七日七日七日七日";
            } else if (randomMsgIndex === 1) {
                message_type = 1;
                msg = "處置變更";
                detail = "病歷號A123,施打Epinephrine 1 mg 並增加食鹽水";
            } else if (randomMsgIndex === 2) {
                message_type = 0;
                msg = "用藥指示變更";
                detail = "患者 林小姐 處方籤 B002 變更為 A113";
            } else if (randomMsgIndex === 3) {
                message_type = 1;
                msg = "處置變更";
                detail = "病歷號B007,施打Epinephrine 1 mg..... 並增加teeeeeeeeest";
            } else {
                message_type = 0;
                msg = "用藥指示變更";
                detail = "患者 鄭先生 處方籤 B015 變更為 A231";
            }

            const newNotification = {
                message_type: message_type,
                message: msg,
                detail: detail,
                receivedTime: currTime,
                read: false,
            };

            submittingNotificationStatus.current = true;
            steNotificationsData((prevDatas) => [newNotification, ...prevDatas]);
        }, 30 * 1000);

        // 在組件卸載時清除定時器
        return () => {
            clearInterval(intervalId);
            clearInterval(intervalGetNotificationId);
        };
    }, []); // 請注意，傳遞一個空數組作為依賴項，以確保只在組件掛載時啟動定時器

    useEffect(() => {
        if (!submittingNotificationStatus.current) {
            return;
        }
        fetchSetNotificationData(notificationsData);
        submittingNotificationStatus.current = false;
    }, [notificationsData]);

    return (
        <header className="flex justify-between relative bg-[#2B6CB0] h-12">
            <div className="flex items-center ml-[64px]">
                <div className="flex justify-center items-center w-9 h-9 rounded-md ml-2">
                    <HeaderMark id="header-mark-icon"/>
                </div>

                {/*院區名稱*/}
                <div className="ml-3 px-4 py-1 bg-[#FFF5F5] rounded-full border-2 border-[#FEB2B2]">
                    <h1 className="font-bold text-[#C53030] text-lg">
                        {t(`campus.${currentCampus}`)}
                    </h1>
                </div>
            </div>

            <div className="flex items-center justify-end mr-2.5">
                {/* important notification */}
                <NotificationsMenu
                    openNotifitionItemModal={openNotifitionItemModal}
                    openViewMoreModal={openNotifitionViewMoreModal}
                    data={notificationsData}
                    setData={steNotificationsData}
                    submittingNotificationStatus={submittingNotificationStatus}
                    type={"important"}
                />
                {/* vertical divider */}
                <div className="w-px h-6 m-1.5 bg-[#111111]/25"></div>
                {/* notification */}
                <NotificationsMenu
                    openNotifitionItemModal={openNotifitionItemModal}
                    data={notificationsData}
                    setData={steNotificationsData}
                    openViewMoreModal={openNotifitionViewMoreModal}
                    submittingNotificationStatus={submittingNotificationStatus}
                />
                {/* vertical divider */}
                <div className="w-px h-6 m-1.5 bg-[#111111]/25"></div>
                {/*使用者*/}
                <div className="flex flex-row items-center justify-center h-full relative group hover:cursor-pointer z-0"
                     onClick={() => setShowUserMenu(!showUserMenu)}
                >
                    <div className="flex flex-row items-center justify-center mr-2 text-white">
                        <UserIcon/>
                        <p className="text-sm">{username}</p>
                        <p className="text-sm ml-1">{t("department.ip")}</p>
                    </div>

                    {/*使用者登入操作介面*/}
                    {showUserMenu && (
                        <div className="absolute top-10 right-0 w-[200px] px-8 py-4 bg-white rounded-[8px] shadow-lg">
                            <ul>
                                <li className="flex items-center justify-start mb-4">
                                    <p className="text-sm">{t("general.modifyPassword")}</p>
                                </li>
                                <li className="flex items-center justify-start">
                                    <p className="text-sm" onClick={handleLogout}>{t("general.logout")}</p>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>

                {/* vertical divider */}
                <div className="w-px h-6 m-1.5 bg-[#111111]/25"></div>

                {/*時間顯示*/}
                {/* <div className="header-time-container flex items-center justify-end mr-7 w-[240px]">
          <ClockIcon />
          <div className="ml-1 mr-2 text-center text-white text-sm">
            {t("general.systemTime")}
          </div>
          <div className="text-center text-white text-sm">
            {currentDateTime}
          </div>
        </div> */}
                <div className="flex flex-row items-center justify-center mr-2 text-white hover:cursor-pointer">
                    <LogoutIcon/>
                    <p className="text-sm" onClick={handleLogout}>
                        {t('general.logout')}
                    </p>
                </div>
                {/* vertical divider */}
                {/* <div className="w-px h-6 m-1.5 bg-[#111111]/25	"></div> */}
            </div>
        </header>
    );
};

export default HeaderLayout;
