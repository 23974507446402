import React, { useState } from 'react'
import {
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
} from 'edah-component'
import { t } from 'i18next'
import VirtualHealthInsuranceCardPopup from '../Appointment/Popup/VirtualHealthInsuranceCardPopup'
import useICCard from '../../hooks/useICCard'

/**
 * 虛擬健保卡讀取按鈕
 * @param {string} title 按鈕文字
 * @param {ButtonVariantEnum} buttonVariant 按鈕樣式
 * @param {ButtonColorEnum} buttonColor 按鈕顏色
 * @return {JSX.Element}
 */
const VirtualICCard = ({
    title = t('ICCard.virtualHealthInsuranceCard'),
    buttonVariant = ButtonVariantEnum.Outlined,
    buttonColor = ButtonColorEnum.Secondary,
}) => {
    // 成功取得虛擬健保卡資料
    const { successToGetVHICard } = useICCard()

    // 用來控制 VHICard 是否顯示的狀態
    const [showVHICard, setShowVHICard] = useState(false)

    /**
     * 打開 VHICard
     * @return {void}
     */
    const openVHICard = () => {
        setShowVHICard(true)
    }
    /**
     * 關閉 VHICard
     * @return {void}
     */
    const closeVHICard = () => {
        setShowVHICard(false)
    }

    return (
        <>
            <Button
                color={buttonColor}
                size={ButtonSizeEnum.Medium}
                variant={buttonVariant}
                text={title}
                onClick={openVHICard}
            />

            {/*虛擬健保卡*/}
            <VirtualHealthInsuranceCardPopup
                show={showVHICard}
                closePopupButtonOnClick={closeVHICard}
                successToGetIcCard={successToGetVHICard}
            />
        </>
    )
}

export default VirtualICCard
