import React, {useEffect, useState} from "react"
import {Dialog, DialogVariant, List } from "edah-component/dist";
import {Pagination} from "../Pagination/Pagination"
import {PopupModeEnum} from "edah_utils/dist"

/**
 * 退掛確認彈窗
 * @param show 是否顯示
 * @param closePopupButtonOnClick 關閉按鈕點擊事件
 * @param versionInfoData 版次data
 * @returns
 */
const VersionInfoPopup = ({
                              show = false,
                              versionInfoData,
                              closePopupButtonOnClick
                          }) => {
    //Table element 的預設type
    const tableElementDefaultType = "px-[16px] border-r border-[#111111]/15 font-normal"

    //初始資料
    const [data, setData] = useState([])

    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show)

    /**
     * 確定刪除點擊事件
     */
    const handleConfirmOnClick = () => {
        closePopupButtonOnClick()
    }

    /**
     * 取消刪除點擊事件
     */
    const handleCancelOnClick = () => {
        closePopupButtonOnClick()
    }

    const DialogContent = ()=> (
        <div>
            <div className="grid grid-cols-3">
                <div className="">
                    {/* table */}
                    <div className=" col-span-5  m-4 bg-white border-[#D4D4D8] rounded-[6px]">
                        <div
                            className=' w-full overflow-x-auto overflow-y-auto min-h-[100px] max-h-[35vh] xl:max-h-[60vh]'>
                            <table
                                className="table-fixed w-full text-left overflow-x-auto overflow-y-auto min-h-[100px] max-h-[35vh] xl:max-h-[50vh]">
                                <thead>
                                <tr className=" h-[50px] border-collapse text-lg text-[#18181b]">
                                    <th className={` ${tableElementDefaultType}`}>版本變更時間</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    data && data.map((data, index) => (
                                        <tr
                                            className={`h-[40px] ${index % 2 === 0 ? '' : 'bg-[#f4f4f5]'} text-[#18181b]`}
                                            key={index}>
                                            <td className={`${tableElementDefaultType}`}>{data.modifyDatetime ? data.modifyDatetime : ""}</td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>
                <div>
                    {data.content}
                </div>
            </div>
        </div>
    )

    /**
     * 監聽是否顯示彈窗Pros變化
     * 開啟畫面下，radio為已簽章
     */
    useEffect(() => {
        setShowPopup(show)
        setData(versionInfoData)
    }, [show])

    return (showPopup ?
            <>
                {/*<div
                    className="absolute top-0 left-0 bg-[rgba(0,0,0,0.5)] w-screen h-screen z-80 min-w-screen min-h-screen items-center justify-center flex text-globalFont"
                >
                    <div
                        className="flex w-[50%] bg-[#ffffff] rounded-md text-lg relative flex flex-col"
                        onClick={(e) => e.stopPropagation()}>
                        *Title
                        <div
                            className="h-[60px] w-full px-6 py-4 bg-white rounded-tl-lg rounded-tr-lg border-b border-neutral-900 border-opacity-20 flex justify-between items-center gap-2.5">
                            <div className="text-black text-xl font-normal">
                                修改之版本紀錄
                            </div>
                            <div className="text-sky-700 text-lg font-normal cursor-pointer"
                                 onClick={handleConfirmOnClick}>關閉
                            </div>
                        </div>
                        *Container
                        <div>
                            <div className="grid grid-cols-3">
                                <div className="">
                                     table
                                    <div className=" col-span-5  m-4 bg-white border-[#D4D4D8] rounded-[6px]">
                                        <div
                                            className=' w-full overflow-x-auto overflow-y-auto min-h-[100px] max-h-[35vh] xl:max-h-[60vh]'>
                                            <table
                                                className="table-fixed w-full text-left overflow-x-auto overflow-y-auto min-h-[100px] max-h-[35vh] xl:max-h-[50vh]">
                                                <thead>
                                                <tr className=" h-[50px] border-collapse text-lg text-[#18181b]">
                                                    <th className={` ${tableElementDefaultType}`}>版本變更時間</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    data && data.map((data, index) => (
                                                        <tr
                                                            className={`h-[40px] ${index % 2 === 0 ? '' : 'bg-[#f4f4f5]'} text-[#18181b]`}
                                                            key={index}>
                                                            <td className={`${tableElementDefaultType}`}>{data.createDatetime ? data.createDatetime : ""}</td>
                                                        </tr>
                                                    ))
                                                }
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>

                                </div>
                                <div>
                                    PDF
                                </div>
                            </div>
                        </div>
                    </div>
                </div>*/}
                <Dialog
                    open={show}
                    title={'修改之版本紀錄'}
                    variant={DialogVariant.CUSTOM}
                    onClose={handleConfirmOnClick}
                    content={DialogContent()}
                />

            </>
         : <></>
    )
}

export default VersionInfoPopup;
