import React, {useEffect, useState} from "react";
import {ApiErrorStatusEnum, arrayIsEmpty, objectIsEmpty, stringIsEmpty} from "edah_utils/dist"
import CvisListItem from "../../components/CVIS/CvisListItem";
import {cvisNotifyUnitAdapter, fuseObject, getCurrentFormattedDateTime} from "../../components/CVIS/utils";
import {Pagination} from "../../components/Pagination/Pagination";
import {
    cancelCvisNotifyrec,
    queryCvisNotifyobject,
    queryCvisNotifyrec,
    queryCvisNotifyrecById,
    queryCvisNotifyUnit
} from "../../api/v1/Critical";
import {
    AlertTypeEnum,
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Card,
    Checkbox,
    DateRangePicker,
    Field,
    Select,
    Tabs,
    TabsEnum,
    Dialog,
    DialogSizeEnums,
    DialogVariant
} from "edah-component/dist";
import {t} from "i18next"
import useToast from "../../hooks/useToast"
import {SearchTextField} from "../../components/SearchTextField/SearchTextField"
import dayjs from "dayjs";

/**
 * 感染管制通報記錄 => 主頁面
 * @return {JSX.Element}
 */
const InflectionControlRecords = () => {
    const showToast = useToast()
    // 通報單位
    const [unit, setUnit] = useState([])
    // 感染管制通報查詢條件
    const [icrQueryData, setIcrQueryData] = useState({
        //通報單位
        cvisNotifyUnit: '',
        //通報日期起始
        cvisNotifyStartDate: dayjs().format('YYYY-MM-DD'),
        //通報日期結束
        cvisNotifyEndDate: dayjs().format('YYYY-MM-DD'),
        //取消狀態
        cvisCancelStuts: '1',
        //讀取狀態
        cvisReadStuts: '',
        //回覆狀態
        cvisResponseStatus: '',
        //通報類別
        cvisNotifyType: 'B' //感染管制固定為B
    })
    // 感染管制通報紀錄物件
    const [inflectionControlRecordObject, setInflectionControlRecordObject] = useState([])
    // 過濾後的感染管制通報紀錄物件
    const [filteredItems, setFilteredItems] = useState([])
    // 進階搜尋字串
    const [queryString, setQueryString] = useState('')
    // 感染管制通報紀錄索引
    const [inflectionControlRecordListId, setInflectionControlRecordListId] = useState("0")
    // 通報資料
    const [inflectionControlRecordData, setInflectionControlRecordData] = useState({})
    // 感染管制通報對象清單
    const [inflectionControlRecordList, setInflectionControlRecordList] = useState([])
    // 通報對象清單進階搜尋字串
    const [queryListString, setQueryListString] = useState('')
    // 過濾後的感染管制通報對象清單
    const [filteredListItems, setFilteredListItems] = useState([])
    // pagination控制變數
    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
    })
    // 通報對象清單 pagination控制變數
    const [paginationListProps, setPaginationListProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
    })
    // 切換基本資料 醫囑清單 檢核結果
    const [activeTab, setActiveTab] = useState('report');

    // 顯示取消通報視窗
    const [isShowCancelDialog, setIsShowCancelDialog] = useState(false)

    /**
     * 點擊Tab事件
     * @param tab {String} 訊息
     * @return {void}
     */
    const handleTabClick = (tab) => setActiveTab(tab)

    /**
     * 通報單位值改變事件
     * @param value {String}
     * @return {void}
     */
    const handleCvisNotifyUnitOnChange = (value) => setIcrQueryData({...icrQueryData, cvisNotifyUnit: value})

    /**
     * 通報日期起訖更新
     * @param {Array<Dayjs | null>} newDates 日期範圍 [開始日期, 結束日期]
     * @return {void}
     */
    const handleCvisNotifyDateOnChange = (newDates) => {
        // 取得日期範圍
        const [startDate, endDate] = newDates;
        // 更新通報日期起訖
        setIcrQueryData({
            ...icrQueryData,
            cvisNotifyStartDate: startDate ? startDate.format('YYYY-MM-DD') : null,
            cvisNotifyEndDate: endDate ? endDate.format('YYYY-MM-DD') : null,
        });
    }

    /**
     * 取消狀態改變事件
     * @param value {string}
     * @return {void}
     */
    const handleCvisCancelStutsOnChange = (value) => setIcrQueryData({...icrQueryData, cvisCancelStuts: value})

    /**
     * 讀取狀態值改變事件
     * @param value {string}
     * @return {void}
     */
    const handleCvisReadStutsOnChange = (value) => setIcrQueryData({...icrQueryData, cvisReadStuts: value})

    /**
     * 回覆狀態值改變事件
     * @param value {String}
     * @return {void}
     */
    const handleCvisResponseStatusOnChange = (value) => setIcrQueryData({...icrQueryData, cvisResponseStatus: value})

    /**
     * 感染管制通報紀錄查詢按鈕事件
     * @return {void}
     */
    const handleOnQuery = () => handleQueryInflectionControl(paginationListProps.pageNum, paginationListProps.pageSize)

    /**
     * 感染管制通報紀錄表格指定頁碼變動事件
     * @param page {Number}
     * @return {void}
     */
    const onPaginationPageOnChange = (page) => {
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryInflectionControl(page, paginationProps.pageSize);
    }

    /**
     * 感染管制通報紀錄表格指定每頁筆數變動事件
     * @param e {Event}
     * @return {void}
     */
    const onPaginationPageSizeOnChange = (e) => {
        const newSize = e.target.value;
        setPaginationProps({
            ...paginationProps,
            pageSize: newSize
        });
        handleQueryInflectionControl(paginationProps.pageNum, newSize);
    }

    /**
     * 感染管制通報紀錄表格上一頁按鈕事件
     * @return {void}
     */
    const onPaginationPreviousOnClick = () => {
        const page = (paginationProps.pageNum - 1) > 1 ? (paginationProps.pageNum - 1) : 1;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryInflectionControl(page, paginationProps.pageSize);
    }

    /**
     * 感染管制通報紀錄表格下一頁按鈕事件
     * @return {void}
     */
    const onPaginationNextOnClick = () => {
        const page = (paginationProps.pageNum + 1) < paginationProps.totalPageSize ? (paginationProps.pageNum + 1) : paginationProps.totalPageSize;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        handleQueryInflectionControl(page, paginationProps.pageSize);
    }

    /**
     * 通報對象清單表格指定頁碼變動事件
     * @param page {number}
     * @return {void}
     */
    const onNotifyPaginationPageOnChange = (page) => {
        setPaginationListProps({
            ...paginationListProps,
            pageNum: page
        });
        updateInflectionRecordListTable(page, paginationProps.pageSize)
    }

    /**
     * 通報對象清單表格指定每頁筆數變動事件
     * @param e {Event}
     * @return {void}
     */
    const onNotifyPaginationPageSizeOnChange = (e) => {
        const newSize = e.target.value;
        setPaginationProps({
            ...paginationProps,
            pageSize: newSize
        });
        updateInflectionRecordListTable(paginationProps.pageNum, newSize);
    }

    /**
     * 通報對象清單表格上一頁按鈕事件
     * @return {void}
     */
    const onNotifyPaginationPreviousOnClick = () => {
        const page = (paginationProps.pageNum - 1) > 1 ? (paginationProps.pageNum - 1) : 1;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        updateInflectionRecordListTable(page, paginationProps.pageSize);
    }

    /**
     * 通報對象清單表格下一頁按鈕事件
     * @return {void}
     */
    const onNotifyPaginationNextOnClick = () => {
        const page = (paginationProps.pageNum + 1) < paginationProps.totalPageSize ? (paginationProps.pageNum + 1) : paginationProps.totalPageSize;
        setPaginationProps({
            ...paginationProps,
            pageNum: page
        });
        updateInflectionRecordListTable(page, paginationProps.pageSize);
    }


    /**
     * 感染管制通報紀錄查詢按鈕事件
     * @param page {number} 頁碼
     * @param pageSize {number} 每頁筆數
     * @return {void}
     */
    const handleQueryInflectionControl = (page, pageSize) => {
        if (icrQueryData.cvisNotifyEndDate && icrQueryData.cvisNotifyStartDate > icrQueryData.cvisNotifyEndDate) {
            showToast({message: '結束日期不可早於起始日期', type: AlertTypeEnum.Error})
        } else if (icrQueryData.cvisNotifyEndDate === "" || icrQueryData.cvisNotifyStartDate === "") {
            showToast({message: '請輸入日期', type: AlertTypeEnum.Error})
        } else {
            queryCvisNotifyrec({
                "cvisNotifyUnit": icrQueryData.cvisNotifyUnit,                               //Required 通報單位
                "cvisNotifyStartDate": `${icrQueryData.cvisNotifyStartDate} 00:00:00`,       //Required 通報日期起始
                "cvisNotifyEndDate": `${icrQueryData.cvisNotifyEndDate} 00:00:00`,           //Required 通報日期結束
                "cvisCancelStuts": icrQueryData.cvisCancelStuts,                         //Option   取消狀態(空白：全部 1:未取消 2:已取消)
                "cvisReadStuts": icrQueryData.cvisReadStuts,                                 //Option   讀取狀態(空白：全部 1:未讀 2:已讀)
                "cvisResponseStatus": icrQueryData.cvisResponseStatus,                       //Option   回覆狀態(空白：全部 1:未回覆 2:已回覆)
                "cvisNotifyType": "B",//icrQueryData.cvisNotifyType                                //Required 通報類別(A：危急值 B：感染管制)
                "pageNum": page,
                "pageSize": pageSize
            }).then(res => {
                // 錯誤狀態 / 資料 / 訊息
                const {err, data, msg} = res

                if (err === ApiErrorStatusEnum.Success) {
                    // 資料 / 總筆數 / 總頁數
                    const {dataList, totalItemSize, totalPageSize} = data
                    // 更新感染管制通報紀錄物件
                    setInflectionControlRecordObject(dataList)
                    // 更新過濾後的感染管制通報紀錄物件
                    setFilteredItems(dataList)
                    // 更新感染管制通報紀錄索引
                    setInflectionControlRecordListId("0")
                    // 更新感染管制通報紀錄分頁
                    setPaginationProps({
                        ...paginationProps,
                        totalItemSize: totalItemSize,
                        totalPageSize: totalPageSize,
                        pageNum: page,
                        pageSize: pageSize
                    })
                } else {
                    // 重置通報資料
                    setInflectionControlRecordObject([])
                    // 重置過濾後的通報資料
                    setFilteredItems([])
                    // 重置通報紀錄分頁
                    setPaginationProps({
                        pageNum: 1,
                        pageSize: 10,
                        totalItemSize: 0,
                        totalPageSize: 0,
                    })
                    showToast({message: msg, type: AlertTypeEnum.Error})
                }
            })
        }
    }

    /**
     * 更新通報資料
     * @return {void}
     */
    const updateInflectionRecordTable = () => {
        // 根據通報流水號取得通報資料
        queryCvisNotifyrecById({
            "cvisNotifyId": inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyId
        }).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                // 更新通報資料
                setInflectionControlRecordData(res.data)
            } else {
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 取消通報按鈕事件
     * @return {void}
     */
    const handleCancelCvisNotify = () => {
        cancelCvisNotifyrec({
            cvisNotifyId: inflectionControlRecordObject[inflectionControlRecordListId]?.cvisNotifyId,
            cvisCancelUser: localStorage.getItem("username"),
            cvisCancelDatetime: getCurrentFormattedDateTime()
        }).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                // 更新通報資料
                updateInflectionRecordTable()
                showToast({message: '取消通報成功', type: AlertTypeEnum.Success})
            } else {
                showToast({message: '取消通報失敗', type: AlertTypeEnum.Error})
            }
        })
        // 關閉取消通報對話框
        setIsShowCancelDialog(false)
    }

    /**
     * 取消通報按鈕事件
     * @return {void}
     */
    const handleCancelCvisNotifyPopupOnClick = () => setIsShowCancelDialog(!isShowCancelDialog)

    /**
     * 進階搜尋字串改變事件
     * @param e {event}
     * @return {void}
     */
    const handleQueryInputOnChange = (e) => {
        setQueryString(e.target.value)
    }

    /**
     * 進階搜尋字串按下Enter事件
     * @param event {event}
     * @return {void}
     */
    const handleTableQuerySearchInputKeyDown = (event) => {
        if (event.key === 'Enter') {
            // 進行過濾
            if (queryString) {
                const result = fuseObject(inflectionControlRecordObject).search(queryString)
                setFilteredItems(result.map(item => item.item))
            } else {
                setFilteredItems(inflectionControlRecordObject)
            }
        }
    }

    /**
     * 通報對象清單進階搜尋字串改變事件
     * @param event {event}
     * @return {void}
     */
    const handleQueryListInputOnChange = (event) => {
        setQueryListString(event.target.value)
    }

    /**
     * 通報對象清單進階搜尋字串按下Enter事件
     * @param event {event}
     * @return {void}
     */
    const handleTableListQuerySearchInputKeyDown = (event) => {
        if (event.key === 'Enter') {
            // 進行過濾
            if (queryListString) {
                const result = fuseObject(inflectionControlRecordList).search(queryListString)
                setFilteredListItems(result.map(item => item.item))
            } else {
                setFilteredListItems(inflectionControlRecordList)
            }
        }
    }

    /**
     * 更新通報對象清單
     * @param page {Number} 頁碼
     * @param pageSize {Number} 每頁筆數
     * @return {void}
     */
    const updateInflectionRecordListTable = (page, pageSize) => {
        queryCvisNotifyobject({
            "cvisNotifyId": inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyId,
            "pageNum": page,
            "pageSize": pageSize
        }).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const data = res.data.dataList
                // 更新通報對象清單
                setInflectionControlRecordList(data)
                setFilteredListItems(data)
                setPaginationListProps({
                    ...paginationListProps,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                    pageNum: page,
                    pageSize: pageSize
                })
            } else {
                // 重置通報對象清單
                setInflectionControlRecordList([])
                // 重置過濾後的通報對象清單
                setFilteredListItems([])
                // 重置通報對象清單分頁
                setPaginationListProps({
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageNum: 1,
                    pageSize: 10,
                })
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 當通報紀錄被選取時觸發，取得通報資料、通報對象清單
     * @param index {String} 被選取的通報紀錄索引
     * @return {void}
     */
    const handleListItemOnFocus = (index) => {
        setInflectionControlRecordListId(index)
        updateInflectionRecordListTable(paginationListProps.pageNum, paginationListProps.pageSize)
    }

    /**
     * 取得分頁頁籤內容
     * @return {Object[]}
     */
    const getTabsContent = () => {
        return [
            {
                id: 'report',
                title: '通報資料',
                content: (
                    <div>
                        {
                            !objectIsEmpty(inflectionControlRecordObject[inflectionControlRecordListId]) && (
                                <div className="space-y-2">
                                    <div className="grid grid-cols-2" key={0}>
                                        <Field labelWidth={150} label="通報單位">
                                            {!arrayIsEmpty(inflectionControlRecordObject) && !stringIsEmpty(inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyUnit) ? cvisNotifyUnitAdapter(inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyUnit) : "無資料"}
                                        </Field>
                                        <Field label="通報編號">
                                            {!arrayIsEmpty(inflectionControlRecordObject) && inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyId}
                                        </Field>
                                    </div>
                                    <div className="grid grid-cols-2" key={1}>
                                        <Field labelWidth={150} label="通報項目">
                                            {!arrayIsEmpty(inflectionControlRecordObject) && inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyItem}
                                        </Field>
                                        <Field label="等級">
                                            {!arrayIsEmpty(inflectionControlRecordObject) && inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyClass}
                                        </Field>
                                    </div>
                                    <div className="grid grid-cols-2" key={2}>
                                        <Field labelWidth={150} label={t('general.patientNo')}>
                                            {!arrayIsEmpty(inflectionControlRecordObject) && inflectionControlRecordObject[inflectionControlRecordListId].patientId}
                                        </Field>
                                        {/*姓名*/}
                                        <Field label={t('general.username')}>
                                            {!arrayIsEmpty(inflectionControlRecordObject) && inflectionControlRecordObject[inflectionControlRecordListId].patientName}
                                        </Field>
                                    </div>
                                    <div className="grid grid-cols-2" key={3}>
                                        <Field labelWidth={150} label="門住">
                                            {!arrayIsEmpty(inflectionControlRecordObject) && inflectionControlRecordObject[inflectionControlRecordListId].inpOpdName}
                                        </Field>
                                        <Field label="科別">
                                            {!arrayIsEmpty(inflectionControlRecordObject) && !stringIsEmpty(inflectionControlRecordObject[inflectionControlRecordListId].divisionName) ? inflectionControlRecordObject[inflectionControlRecordListId].divisionName : "無資料"}
                                        </Field>
                                    </div>
                                    <div className="grid grid-cols-2" key={4}>
                                        <Field labelWidth={150} label="通報日期時間">
                                            {!arrayIsEmpty(inflectionControlRecordObject) && inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyDatetime}
                                        </Field>
                                        <Field label="通報人員">
                                            {!arrayIsEmpty(inflectionControlRecordObject) && !stringIsEmpty(inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyUser) ? `${inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyUser} : ${inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyUserName}` : "無資料"}
                                        </Field>
                                    </div>
                                    <div className="grid grid-cols-2" key={5}>
                                        <Field labelWidth={150} label="通報對象">
                                            {!arrayIsEmpty(inflectionControlRecordObject) && !stringIsEmpty(inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyObjectName) ? `${inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyObject} : ${inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyObjectName}` : "無資料"}
                                        </Field>
                                        <Field label="通報電話">
                                            {!arrayIsEmpty(inflectionControlRecordObject) && !stringIsEmpty(inflectionControlRecordObject[inflectionControlRecordListId].userMobile) ? inflectionControlRecordObject[inflectionControlRecordListId].userMobile : "無資料"}
                                        </Field>
                                    </div>
                                    <div key={6}>
                                        <Field labelWidth={150} label="通報內容">
                                            {!arrayIsEmpty(inflectionControlRecordObject) && inflectionControlRecordObject[inflectionControlRecordListId].cvisNotifyContent}
                                        </Field>
                                    </div>
                                    <div key={7}>
                                        <Field labelWidth={150} isShowColon={false}>
                                            <Checkbox
                                                label="取消通報"
                                                onChange={() => {
                                                }}
                                                checked={!arrayIsEmpty(inflectionControlRecordObject) && inflectionControlRecordObject[inflectionControlRecordListId].cvisCancelFlag === true}
                                            />
                                        </Field>
                                    </div>
                                    <div className="grid grid-cols-2" key={8}>
                                        <Field labelWidth={150} label="取消通報日期時間">
                                            {!arrayIsEmpty(inflectionControlRecordObject) && inflectionControlRecordObject[inflectionControlRecordListId].cvisCancelDatetime}
                                        </Field>
                                        <Field label="取消通報人員">
                                            {!arrayIsEmpty(inflectionControlRecordObject) && inflectionControlRecordObject[inflectionControlRecordListId].cvisCancelUser}
                                        </Field>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                )
            },
            {
                id: 'list',
                title: '通報對象清單',
                content: (
                    <div>
                        <div className='flex justify-start'>
                            <SearchTextField
                                value={queryListString}
                                onChange={(event) => handleQueryListInputOnChange(event)}
                                onKeyDown={handleTableListQuerySearchInputKeyDown}
                                placeholder={t('general.advancedSearch')}
                            />
                        </div>
                        <ul className="tableList CVRRightOneList mt-4 relative max-h-[800px]">
                            <li className="title" key={0}>
                                <div>類別名稱</div>
                                <div>通報對象</div>
                                <div>電話</div>
                                <div>通報時間</div>
                                <div>讀取時間</div>
                            </li>
                            {
                                filteredListItems.map((item, index) =>
                                    <CvisListItem index={index}
                                                  type="CvisNotifyObjectList"
                                                  item={item}/>
                                )
                            }
                        </ul>
                        <Pagination
                            totalPageSize={paginationListProps.totalPageSize}
                            pageSize={paginationListProps.pageSize}
                            totalSize={paginationListProps.totalItemSize}
                            currentPage={paginationListProps.pageNum}
                            onPageOnChange={onNotifyPaginationPageOnChange}
                            onPageSizeChange={onNotifyPaginationPageSizeOnChange}
                            onPrevPageOnClick={onNotifyPaginationPreviousOnClick}
                            onNextPageOnClick={onNotifyPaginationNextOnClick}
                        />
                    </div>
                )
            }
        ]
    }

    /**
     * 當當前focus的list index更新時，更新通報資料、通報對象清單
     * @return {void}
     */
    useEffect(() => {
        if (inflectionControlRecordObject[inflectionControlRecordListId] !== undefined) {
            setInflectionControlRecordData(inflectionControlRecordObject[inflectionControlRecordListId])
            updateInflectionRecordListTable(paginationListProps.pageNum, paginationListProps.pageSize)
        }
    }, [inflectionControlRecordListId])

    // 當進階搜尋字串及通報紀錄改變時觸發，更新過濾後的通報紀錄
    useEffect(() => {
        if (queryString) {
            const result = fuseObject(inflectionControlRecordObject).search(queryString)
            setFilteredItems(result.map(item => item.item))
        } else {
            setFilteredItems(inflectionControlRecordObject)
        }
    }, [queryString, inflectionControlRecordObject])

    // 當進階搜尋字串及通報對象清單改變時觸發，更新過濾後的通報對象清單
    useEffect(() => {
        if (queryListString) {
            const result = fuseObject(inflectionControlRecordObject).search(queryString)
            setFilteredListItems(result.map(item => item.item))
        } else {
            setFilteredListItems(inflectionControlRecordList)
        }
    }, [queryListString, inflectionControlRecordList])

    // 初始化通報單位
    useEffect(() => {
        queryCvisNotifyUnit({}).then(res => {
            if (res.err === ApiErrorStatusEnum.Success) {
                // 更新通報單位
                setUnit(res.data)
                if (res.data.length > 0) {
                    setIcrQueryData({...icrQueryData, cvisNotifyUnit: res.data[0].cvisNotifyUnitNo})
                }
            } else { // 通報單位查詢失敗
                showToast({message: '通報單位查詢失敗', type: AlertTypeEnum.Error})
            }
        })
    }, [])

    return (
        <div className="w-full p-4 bg-[#FAFAFA] h-[calc(100vh-107px)]">
            <div className="filterGroup flex flex-row flex-wrap items-center justify-start">
                {/* 通報單位 */}
                <div className="upload flex flex-row items-center justify-start mb-4 mr-4 text-left">
                    <Select
                        data={{
                            label: "通報單位",
                            options: unit?.map((item) => ({
                                value: item.cvisNotifyUnitNo,
                                label: item.cvisNotifyUnitName,
                            })),
                        }}
                        value={icrQueryData.cvisNotifyUnit}
                        onChange={handleCvisNotifyUnitOnChange}
                        width="Medium"
                        displayEmpty={true}
                        notched={true}
                    />
                </div>
                {/* 通報日期 */}
                <div className="date flex flex-row items-center justify-start mb-4 mr-4">
                    {/* 通報日期起訖 */}
                    <DateRangePicker
                        localeText={{
                            start: '通報開始日期',
                            end: '通報結束日期'
                        }}
                        value={[
                            icrQueryData.cvisNotifyStartDate ? dayjs(icrQueryData.cvisNotifyStartDate) : null,
                            icrQueryData.cvisNotifyEndDate ? dayjs(icrQueryData.cvisNotifyEndDate) : null
                        ]}
                        onChange={handleCvisNotifyDateOnChange}
                    />
                </div>
                {/* 取消狀態 */}
                <div className="upload flex flex-row items-center justify-start mb-4 mr-4 text-left">
                    <Select
                        data={{
                            label: "取消狀態",
                            options: [
                                {label: "全部", value: ""},
                                {label: "未取消", value: "1"},
                                {label: "已取消", value: "2"},
                            ],
                        }}
                        value={icrQueryData.cvisCancelStuts}
                        onChange={handleCvisCancelStutsOnChange}
                        displayEmpty={true}
                        width="Small"
                        notched={true}
                    />
                </div>
                {/* 讀取狀態 */}
                <div className="upload flex flex-row items-center justify-start mb-4 mr-4 text-left">
                    <Select
                        data={{
                            label: "讀取狀態",
                            options: [
                                {label: "全部", value: ""},
                                {label: "未讀", value: "1"},
                                {label: "已讀", value: "2"},
                            ],
                        }}
                        value={icrQueryData.cvisReadStuts}
                        onChange={handleCvisReadStutsOnChange}
                        displayEmpty={true}
                        width="Small"
                        notched={true}
                    />
                </div>
                {/* 回覆狀態 */}
                <div className="upload flex flex-row items-center justify-start mb-4 mr-4 text-left">
                    <Select
                        data={{
                            label: "回覆狀態",
                            options: [
                                {label: "全部", value: ""},
                                {label: "未回覆", value: "1"},
                                {label: "已回覆", value: "2"},
                            ],
                        }}
                        value={icrQueryData.cvisResponseStatus}
                        onChange={handleCvisResponseStatusOnChange}
                        displayEmpty={true}
                        width="Small"
                        notched={true}/>
                </div>
                {/* 查詢按鈕 */}
                <div className="source flex flex-row items-center justify-start mb-4 mr-4">
                    <Button sx={{marginRight: '0.5rem'}} color={ButtonColorEnum.Primary}
                            variant={ButtonVariantEnum.Contained} size={ButtonSizeEnum.Medium}
                            onClick={handleOnQuery} text={t('general.query')}/>
                </div>
            </div>
            {/* 感染管制紀錄通報內容 */}
            <div className="maintainContainer flex flex-row items-stretch justify-start h-[calc(100%-60px)]">
                <Card sx={{
                    width: "800px",
                    height: "100%",
                    mr: 2,
                    pt: 1,
                    '.MuiCardContent-root': {height: '100%'}
                }}>
                    {/* 取消通報按鈕 */}
                    <div className="btnGroup flex flex-row mb-4">
                        <Button sx={{marginRight: '0.5rem'}} color={ButtonColorEnum.Secondary}
                                variant={ButtonVariantEnum.Outlined} size={ButtonSizeEnum.Medium}
                                onClick={handleCancelCvisNotifyPopupOnClick} text='取消通報'/>
                    </div>
                    {/* 進階搜尋 */}
                    <div className='flex justify-start'>
                        <SearchTextField
                            value={queryString}
                            onChange={(event) => handleQueryInputOnChange(event)}
                            onKeyDown={handleTableQuerySearchInputKeyDown}
                            placeholder={t('general.advancedSearch')}/>
                    </div>
                    {/* 感染管制紀錄列表 */}
                    <ul className="tableList ICRTableList mt-4 relative h-[calc(100%-130px)]">
                        <li className="title scale-1.1" key={0}>
                            <div>通報單位</div>
                            <div>通報項目</div>
                            <div>等級</div>
                            <div>通報日期時間</div>
                            <div>{t('general.patientNo')}</div>
                            <div>姓名</div>
                            <div>讀取</div>
                            <div>取消</div>
                            <div>門住</div>
                            <div>科別</div>
                            <div>通報對象</div>
                            <div>通報電話</div>
                            <div>通報編號</div>
                        </li>
                        {
                            !objectIsEmpty(inflectionControlRecordObject[inflectionControlRecordListId]) && filteredItems.map((item, index) =>               // 列表項目組件
                                <CvisListItem index={index} type="InflectionControlRecordList" item={item}
                                              handleItemOnClick={handleListItemOnFocus}/>
                            )
                        }
                    </ul>
                    <Pagination
                        totalPageSize={paginationProps.totalPageSize}
                        pageSize={paginationProps.pageSize}
                        totalSize={paginationProps.totalItemSize}
                        currentPage={paginationProps.pageNum}
                        onPageOnChange={onPaginationPageOnChange}
                        onPageSizeChange={onPaginationPageSizeOnChange}
                        onPrevPageOnClick={onPaginationPreviousOnClick}
                        onNextPageOnClick={onPaginationNextOnClick}/>
                </Card>
                <Card sx={{width: "800px", height: "initial"}}>
                    {/*點擊按鈕切換下方內容*/}
                    <Tabs
                        variant={TabsEnum.INDICATOR_TAB}
                        activeTabId={activeTab}
                        items={getTabsContent()}
                        onTabChange={handleTabClick}
                    />
                </Card>
            </div>
            {/* 取消通報確認視窗 */}
            {isShowCancelDialog && 
            <Dialog
                open={isShowCancelDialog}
                title={'取消通報'}
                variant={DialogVariant.DECISION}
                onClose={() => setIsShowCancelDialog(false)}
                content={'是否確定取消通報?'}
                onConfirm={handleCancelCvisNotify}
                muiDialogContentProps={{style: {display: 'flex', alignItems: 'center'}}}
                paperStyleProps={{width: DialogSizeEnums.SM, height: '250px'}}
            />}
        </div>
    )
}
export default InflectionControlRecords
