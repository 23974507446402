import {v1Api} from "../Http"
import {HttpMethodEnum} from "edah_utils/dist"

/**
 * 依權限查詢人員憑證維護
 * 人員憑證維護>依權限查詢人員憑證維護
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const essiQueryUserCert = (params) => v1Api.request({
    url: '/essi/usercert/queryUserCert',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 讀取 HCA 卡片
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const essiReadHCA = (params) => v1Api.request({
    url: '/essi/usercert/queryUsercert/HCA',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 新增人員憑證維護
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const essiAddUserCert = (data) => v1Api.request({
    url: '/essi/usercert/addUserCert',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 修改人員憑證維護資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const essiEditUserCert = (data) => v1Api.request({
    url: '/essi/usercert/editUserCert',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 刪除人員憑證維護資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const essiDelUserCert = (data) => v1Api.request({
    url: '/essi/usercert/delUserCert',
    method: HttpMethodEnum.DELETE,
    data
})

/**
 * 取得所有代簽人員維護資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const essiQuerySignAgent = (params) => v1Api.request({
    url: '/essi/signagent/querySignAgent',
    method: HttpMethodEnum.GET,
    params
})

/**
 * 新增代簽人員維護資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const essiAddSignAgent = (data) => v1Api.request({
    url: '/essi/signagent/addSignAgent',
    method: HttpMethodEnum.POST,
    data
})

/**
 * 修改代簽人員維護資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const essiEditSignAgent = (data) => v1Api.request({
    url: '/essi/signagent/editSignAgent',
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 刪除代簽人員維護資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const essiDelSignAgent = (data) => v1Api.request({
    url: `/essi/signagent/delSignAgent/${data.userNo}/${data.agentUserNo}`,
    method: HttpMethodEnum.DELETE,
    data
})

/**
 * 查詢電子病歷查詢及驗章
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const essiQueryEmrMaster = (params) => v1Api.request({
    url: `/essi/emrmaster/queryEmrmaster/${params.createStartDate}/${params.createEndDate}`,
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢該筆電子病歷及驗章的 PDF 內容
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const essiQueryEmrMasterByPDF = (params) => v1Api.request({
    url: `/essi/emrmaster/queryEmrmasterlog/pdf/${params.emrDocId}/${params.batchNo + 1}`,
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢該筆電子病歷內容與驗章
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const essiQueryEmrMasterDetail = (params) => v1Api.request({
    url: `/essi/emrmaster/queryEmrmasterlog/detail/${params.emrDocId}/${params.batchNo}`,
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢該筆電子病歷驗章的修改次數版本紀錄
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const essiQueryEmrMasterChangeTimes = (params) => v1Api.request({
    url: `/essi/emrmaster/queryEmrmaster/changestimes/${params.emrDocId}/${params.batchNo}`,
    method: HttpMethodEnum.GET,
    params
})

/**
 * 取得所有電子表單類別維護資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const essiQueryEmrType = (params) => v1Api.request({
    url: `/essi/emrtype/queryEmrType`,
    method: HttpMethodEnum.GET,
    params
})

/**
 * 新增該筆電子表單類別維護資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const essiAddEmrType = (data) => v1Api.request({
    url: `/essi/emrtype/addEmrType`,
    method: HttpMethodEnum.POST,
    data
})

/**
 * 修改該筆電子表單類別維護資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const essiEditEmrType = (data) => v1Api.request({
    url: `/essi/emrtype/editEmrType`,
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 刪除該筆電子表單類別維護資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const essiDelEmrType = (data) => v1Api.request({
    url: `/essi/emrtype/delEmrType`,
    method: HttpMethodEnum.DELETE,
    data
})

/**
 * 查詢操作記錄
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const essiQueryEmroperLog = (params) => v1Api.request({
    url: `/essi/emroperlog/queryEmroperlog/${params.createStartDate}/${params.createEndDate}/${params.logOperCrud}`,
    method: HttpMethodEnum.GET,
    params
})

/**
 * 預設查詢 24 小時未簽文件查詢及簽章
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const essiQueryTransRecByUserNo = (params) => v1Api.request({
    url: `/essi/transrec/queryTransrec/${params.createUserNo}`,
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢未簽文件查詢及簽章
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const essiQueryTransRec = (params) => v1Api.request({
    url: `/essi/transrec/queryTransrec`,
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢該筆未簽文件查詢及簽章之 PDF 或 JSON 文件
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const essiQueryTransRecByFile = (params) => v1Api.request({
    url: `/essi/transrec/queryTransrec/jsonorpdf/${params.transrecId}`,
    method: HttpMethodEnum.GET,
    params
})

/**
 * 刪除該筆電子表單類別維護資料
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const essiEditTransRec = (data) => v1Api.request({
    url: `/essi/transrec/editTransrec/${data.loginUserNo}?cardNo=${data.cardNo}&accessCode=${data.accessCode}`,
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 查詢未簽文件簽章進度
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const essiQueryTransRecByProcess = (params) => v1Api.request({
    url: `/essi/transrec/queryTransrec/process/${params.processId}/${params.loginUserNo}`,
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢未簽醫療機構卡
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const essiQueryTransRecByOrg = (params) => v1Api.request({
    url: `/essi/transrec/queryTransrec/org/${params.createStartDate}/${params.createEndDate}/${params.signStatus}/${params.zone}`,
    method: HttpMethodEnum.GET,
    params
})

/**
 * 簽章醫療機構卡
 * @param data {Object} data
 * @return {AxiosPromise}
 */
const essiQueryTransRecByOrgSign = (data) => v1Api.request({
    url: `/essi/transrec/queryTransrec/org/${data.loginUserNo}?accessCode=${data.accessCode}&cardNo=${data.cardNo}`,
    method: HttpMethodEnum.PUT,
    data
})

/**
 * 查詢簽章率明細
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const essiSignatureRate = (params) => v1Api.request({
    url: `/essi/transrec/queryTransrec/signaturerate/${params.createStartDate}/${params.createEndDate}`,
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢該日簽章率明細的未簽章及已簽章
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const essiSignatureRateDetail = (params) => v1Api.request({
    url: `/essi/transrec/queryTransrec/signaturerate/detail/${params.createDate}`,
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢 24 小時簽章率明細
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const essi24SignatureRate = (params) => v1Api.request({
    url: `/essi/transrec/queryTransrec/24signaturerate/${params.createStartDate}/${params.createEndDate}`,
    method: HttpMethodEnum.GET,
    params
})

/**
 * 查詢該日 24 小時簽章率明細的未簽章及已簽章查
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const essi24SignatureRateDetail = (params) => v1Api.request({
    url: `/essi/transrec/queryTransrec/24signaturerate/detail/${params.createDate}`,
    method: HttpMethodEnum.GET,
    params
})

/**
 * 外部系統新增電子簽章資料
 * @param params {Object} params
 * @return {AxiosPromise}
 */
const essiAddTransRec = (params) => v1Api.request({
    url: `/essi/transrec/addTransrec`,
    method: HttpMethodEnum.GET,
    params
})

// 人員憑證維護
// /**
//  * 人員憑證維護>依權限查詢人員憑證維護
//  * @param params {Object} params
//  * @return {AxiosPromise}
//  */
// const essiQueryUserCert = (params) => v1Api.request({
//     url: '/essi/usercert/queryUserCert',
//     method: HttpMethodEnum.GET,
//     params
// })
//
// /**
//  * 人員憑證維護>新增人員憑證維護
//  * @param data {Object} data
//  * @return {AxiosPromise}
//  */
// const essiAddUserCert = (data) => v1Api.request({
//     url: '/essi/usercert/addUserCert',
//     method: HttpMethodEnum.POST,
//     data
// })
//
// /**
//  * 人員憑證維護>修改人員憑證維護資料
//  * @param data {Object} data
//  * @return {AxiosPromise}
//  */
// const essiEditUserCert = (data) => v1Api.request({
//     url: '/essi/usercert/editUserCert',
//     method: HttpMethodEnum.PUT,
//     data
// })
//
// /**
//  * 人員憑證維護>刪除人員憑證維護資料
//  * @param data {Object} data
//  * @return {AxiosPromise}
//  */
// const essiDelUserCert = (data) => v1Api.request({
//     url: '/essi/usercert/delUserCert',
//     method: HttpMethodEnum.DELETE,
//     data
// })
//
// // 代簽人員維護
// /**
//  * 待簽人員維護>取得所有代簽人員維護資料
//  * @param params {Object} params
//  * @return {AxiosPromise}
//  */
// const essiQuerySignAgent = (params) => v1Api.request({
//     url: '/essi/signagent/querySignAgent',
//     method: HttpMethodEnum.GET,
//     params
// })
//
// /**
//  * 待簽人員維護>新增代簽人員維護資料
//  * @param data {Object} data
//  * @return {AxiosPromise}
//  */
// const essiAddSignAgent = (data) => v1Api.request({
//     url: '/essi/signagent/addSignAgent',
//     method: HttpMethodEnum.POST,
//     data
// })
//
// /**
//  * 待簽人員維護>修改代簽人員維護資料
//  * @param data {Object} data
//  * @return {AxiosPromise}
//  */
// const essiEditSignAgent = (data) => v1Api.request({
//     url: '/essi/signagent/editSignAgent',
//     method: HttpMethodEnum.PUT,
//     data
// })
//
// /**
//  * 待簽人員維護>刪除待簽維護資料
//  * @param data {Object} data
//  * @return {AxiosPromise}
//  */
// const essiDelSignAgent = (data) => v1Api.request({
//     url: `/essi/signagent/delSignAgent/${data.userNo}/${data.agentUserNo}`,
//     method: HttpMethodEnum.DELETE,
// })
//
// //
// /**電子表單類別維護>
//  * 取得所有電子表單類別維護資料
//  * @param params {Object} params
//  * @return {AxiosPromise}
//  */
// const essiQueryEmrType = (params) => v1Api.request({
//     url: '/essi/emrtype/queryEmrType',
//     method: HttpMethodEnum.GET,
//     params
// })
//
// /**
//  * 電子表單類別維護>新增該筆電子表單類別維護資料
//  * @param data {Object} data
//  * @return {AxiosPromise}
//  */
// const essiAddEmrType = (data) => v1Api.request({
//     url: '/essi/emrtype/addEmrType',
//     method: HttpMethodEnum.POST,
//     data
// })
//
// /**
//  * 電子表單類別維護>修改該筆電子表單類別維護資料
//  * @param data {Object} data
//  * @return {AxiosPromise}
//  */
// const essiEditEmrType = (data) => v1Api.request({
//     url: '/essi/emrtype/editEmrType',
//     method: HttpMethodEnum.PUT,
//     data
// })
//
// /**
//  * 電子表單類別維護>刪除該筆電子表單類別維護資料
//  * @param data {Object} data
//  * @return {AxiosPromise}
//  */
// const essiDelEmrType = (data) => v1Api.request({
//     url: '/essi/emrtype/delEmrType',
//     method: HttpMethodEnum.DELETE,
//     data
// })
//
// // 操作紀錄
// /**
//  * 操作紀錄>查詢操作記錄
//  * @param params {Object} params
//  * @return {AxiosPromise}
//  */
// const essiQueryEmroperlog = (params) => v1Api.request({
//     url: `/essi/emroperlog/queryEmroperlog/${params.createStartDate}/${params.createEndDate}/${params.logOperCrud}`,
//     method: HttpMethodEnum.GET,
//     params
// })
//
// // 未簽文件查詢及簽章
// /** 未簽文件查詢及簽章>預設查詢 24 小時未簽文件查詢及簽章
//  * @param params {Object} params
//  * @return {AxiosPromise}
//  */
// const essiQueryTransrecUnsign1 = (params) => v1Api.request({
//     // /essi/transrec/queryTransrec/:craeteUserNo?pageNum=1&pageSize=10
//     url: `/essi/transrec/queryTransrec/${params.craeteUserNo}`,
//     method: HttpMethodEnum.GET,
//     params
// })
//
// /**
//  * 未簽文件查詢及簽章>查詢未簽文件查詢及簽章
//  * @param params {Object} params
//  * @return {AxiosPromise}
//  */
// const essiQueryTransrecUnsign1choose = (params) => v1Api.request({
//     // /essi/transrec/queryTransrec/?createUserNo=&createStartDatetime=&createEndDatetime=
//     url: '/essi/transrec/queryTransrec/',
//     method: HttpMethodEnum.GET,
//     params
// })
//
// /**
//  * 未簽文件查詢及簽章>查詢該筆未簽文件查詢及簽章之 PDF 或 JSON 文件
//  * @param data {Object} data
//  * @return {AxiosPromise}
//  */
// const essiQueryTransrecUnsign1Pdf = (data) => v1Api.request({
//     // /essi/transrec/queryTransrec/jsonorpdf/:transrecId
//     url: '/essi/transrec/queryTransrec/jsonorpdf/' + data.transrecId,
//     method: HttpMethodEnum.GET,
// })
//
// // 簽章率明細
// /**簽章率明細>查詢簽章率明細
//  * @param params {Object} params
//  * @return {AxiosPromise}
//  */
// const essiQuerySignaturerate = (params) => v1Api.request({
//     // /essi/transrec/queryTransrec/signaturerate/:createStartDate/:createEndDate?createUserNo=
//     url: `/essi/transrec/queryTransrec/signaturerate/${params.createStartDate}/${params.createEndDate}`,
//     method: HttpMethodEnum.GET,
//     params
// })


export {
    // // UserCert 人員憑證
    // essiQueryUserCert,
    // essiAddUserCert,
    // essiEditUserCert,
    // essiDelUserCert,
    // // SignAgent 代簽
    // essiQuerySignAgent,
    // essiAddSignAgent,
    // essiEditSignAgent,
    // essiDelSignAgent,
    // // EmrType 電子表單類別
    // essiQueryEmrType,
    // essiAddEmrType,
    // essiEditEmrType,
    // essiDelEmrType,
    // // 操作紀錄
    // essiQueryEmroperlog,
    // // 個人未簽
    // essiQueryTransrecUnsign1,
    // essiQueryTransrecUnsign1choose,
    // // PDF/JSON
    // essiQueryTransrecUnsign1Pdf,
    // // 簽章明細
    // essiQuerySignaturerate,
    essiQueryUserCert,
    essiReadHCA,
    essiAddUserCert,
    essiEditUserCert,
    essiDelUserCert,
    essiQuerySignAgent,
    essiAddSignAgent,
    essiEditSignAgent,
    essiDelSignAgent,
    essiQueryEmrMaster,
    essiQueryEmrMasterByPDF,
    essiQueryEmrMasterDetail,
    essiQueryEmrMasterChangeTimes,
    essiQueryEmrType,
    essiAddEmrType,
    essiEditEmrType,
    essiDelEmrType,
    essiQueryEmroperLog,
    essiQueryTransRecByUserNo,
    essiQueryTransRec,
    essiQueryTransRecByFile,
    essiEditTransRec,
    essiQueryTransRecByProcess,
    essiQueryTransRecByOrg,
    essiQueryTransRecByOrgSign,
    essiSignatureRate,
    essiSignatureRateDetail,
    essi24SignatureRate,
    essi24SignatureRateDetail,
    essiAddTransRec,
}
