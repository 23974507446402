import React, {useEffect, useState} from "react"
import AppointmentPopup from "../../components/Regs/MonthMaintenance/AppointmentPopup"
import {
    regsMonthClinicBaseData,
    regsQueryMonthClinicByDivNo,
    regsQueryMonthClinicByDoctorNo
} from "../../api/v1/RegsMonth"
import {ApiErrorStatusEnum, PopupModeEnum, RegsWeekModeEnum, time2String, TimeslotEnum} from "edah_utils/dist"
import {t} from "i18next"
import ScheduleTimeBox from "../../components/Regs/MonthMaintenance/ScheduleTimeTable/ScheduleTimeBox"
import {
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    ColorEnum,
    DatePicker,
    RadioGroup,
    Search,
    Select,
    SizeEnum
} from 'edah-component/dist'
import dayjs from "dayjs"

// 時段配置
const TIME_SLOTS = [
    {
        key: 'morning',
        type: TimeslotEnum.Morning,
        bgColor: 'bg-[#EBF8FF]',
        label: 'general.dateTime.timeslot.short.morning',
        startTime: '08:30',
        endTime: '12:00'
    },
    {
        key: 'afternoon',
        type: TimeslotEnum.Afternoon,
        bgColor: 'bg-customYellow',
        label: 'general.dateTime.timeslot.short.afternoon',
        startTime: '13:30',
        endTime: '17:00'
    },
    {
        key: 'night',
        type: TimeslotEnum.Night,
        bgColor: 'bg-[#FBF5FF]',
        label: 'general.dateTime.timeslot.short.night',
        startTime: '18:00',
        endTime: '21:00'
    }
]

// 時段標題組件
const TimeSlotTitle = ({label, startTime, endTime}) => (
    <div className="w-12 flex justify-center items-center px-1">
        <div className="text-base">
            <p className="text-center">{t(label)}</p>
            <p className="text-center">{startTime}</p>
            <p className="text-center">~</p>
            <p className="text-center">{endTime}</p>
        </div>
    </div>
)

/**
 * 月班維護
 * @return {JSX.Element}
 */
const MonthMaintenance = () => {
    // 計算總高度
    const totalHeight = window.innerHeight
    // 頂部區塊&日期區塊高度
    const defaultHeight = 120
    const topBlockHeight = 60
    const dateBlockHeight = 30
    // remain height
    const remainingHeight = totalHeight - defaultHeight - topBlockHeight - dateBlockHeight
    //計算時段高度
    const blockHeightPerSection = remainingHeight / 3

    //當前週數
    const [currentDate, setCurrentDate] = useState(null)
    //Viewing Filter -> 醫師 or 科別
    const [filterMode, setFilterMode] = useState(RegsWeekModeEnum.Department)
    //搜尋文字
    const [searchText, setSearchText] = useState("")
    // 所有 醫師 list/科別 list
    const [allDoctorOrDept, setAllDoctorOrDept] = useState([])
    // 醫師/科別的下拉選單
    const [doctorOrDeptOptionList, setDoctorOrDeptOptionList] = useState([])
    // 是否顯示Popup
    const [showPopup, setShowPopup] = useState(false)
    // Popup模式
    const [popupMode, setPopupMode] = useState(null)
    // 基礎資料
    const [baseData, setBaseData] = useState(null)
    // 修改初始狀態，月班資料預設為空
    const [data, setData] = useState(null) // null 表示尚未查詢
    // 是否顯示新問診按鈕
    const [showAddAppointment, setShowAddAppointment] = useState(true)
    //當前右鍵點擊的區塊
    const [currentRightClick, setCurrentRightClick] = useState({
        week: null,
        timeslot: null,
    })
    //當前選擇的資料
    const [selectedData, setSelectedData] = useState(null)
    // 標記是否進行過查詢
    const [isSearched, setIsSearched] = useState(false)
    // 控制 tooltip 的顯示狀態
    const [showTooltip, setShowTooltip] = useState(true)
    // 控制當前活動的 tooltip
    const [activeTooltipInfo, setActiveTooltipInfo] = useState({
        week: null,
        timeslot: null,
        index: null
    });
    // 控制全局 tooltip 的顯示狀態
    const [globalTooltipInfo, setGlobalTooltipInfo] = useState({
        slotKey: null,
        weekIndex: null,
        listIndex: null
    });


    /**
     * 預設周邊要插入資料
     * @return {Array<Object>}
     */
    const defaultWeekData = () => Array.from({length: 7}, (_, i) => i + 1).map(
        (index) => {
            return {
                //星期幾的數值
                weekIndex: index,
                // 早上
                morning: [],
                // 中午
                afternoon: [],
                // 晚上
                night: [],
            }
        }
    )


    /**
     * 改變日期時
     * @param newDate {Dayjs | null} - 選擇的日期或 null
     * @return {void}
     */
    const handleDateOnChange = (newDate) => {
        // 如果 newDate 為 null , 使用 currentDate 或預設日期
        const selectedDate = newDate ? newDate.format('YYYY-MM-DD') : currentDate;

        // 設定當前日期
        setCurrentDate(selectedDate);

        // 重新設定新問診
        setCurrentRightClick({week: null, timeslot: null});
    };

    /**
     * 模式改變時
     * @param mode {Number}
     * @return {void}
     */
    const handleModeOnChange = (mode) => {
        const newMode = parseInt(mode)
        //設定模式
        setFilterMode(newMode)
        //是否科別模式
        const isDepartmentMode = newMode === RegsWeekModeEnum.Department
        //根據科別取得下拉選項
        const options = isDepartmentMode ? baseData.divisionList : baseData.doctorList
        //設定下拉選項
        setAllDoctorOrDept(options)
        // 重新設定新問診
        setCurrentRightClick({week: null, timeslot: null})
        const ele = document.querySelector('button.MuiAutocomplete-clearIndicator')
        if (ele) ele.click();
        //清空搜尋文字
        setSearchText('')
        setIsSearched(false)
        //設定模式
        setFilterMode(mode)
        //將週班資料回復預設值
        setData(defaultWeekData)
    }

    /**
     * 取得過濾選項
     * @param option {Object}
     * @return {string}
     */
    const getFilterOptionContent = (option) => +filterMode === RegsWeekModeEnum.Department ?
        //科別代號 科別名稱
        `${option.divNo} ${option.divName}` :
        //醫師代號 醫師名稱
        `${option.userNo} ${option.userName}`

    /**
     * 取得到搜尋的科別或醫生選項
     * @return {Array<Object>}
     */
    const getDoctorOrDeptOptions = () => {
        //搜尋文字為空時
        if (searchText === '') {
            return allDoctorOrDept
        } else { //搜尋文字有數據時
            return allDoctorOrDept.filter(option => {
                //是否科別模式
                const isDepartmentMode = +filterMode === RegsWeekModeEnum.Department
                //編號
                const noID = isDepartmentMode ? option.divNo : option.userNo
                //名稱
                const name = isDepartmentMode ? option.divName : option.userName
                //是否有空白
                const hasSpace = searchText.indexOf(' ') >= 0
                //回傳是否符合條件
                return hasSpace ?
                    //有空白時
                    (noID.includes(searchText.split(' ')[0]) && name.includes(searchText.split(' ')[1])) :
                    //無空白時
                    (noID.includes(searchText) || name.includes(searchText))
            })
        }
    }

    /**
     * 選擇過濾選項時
     * @param option {Object}
     * @return void
     */
    const handleFilterOptionOnClick = (option) => {
        //是否科別模式
        const isDepartmentMode = +filterMode === RegsWeekModeEnum.Department

        const div = option?.divNo && option?.divName ? `${option?.divNo} ${option?.divName}` : ''
        const user = option?.userNo && option?.userName ? `${option?.userNo} ${option?.userName}` : ''

        //設定搜尋文字
        setSearchText(isDepartmentMode ? div : user)
    }

    /**
     * 處理查詢按鈕點擊事件
     */
    const handleSearchOnClick = () => {
        setData([]); // 清空資料並顯示查詢中的狀態
        setIsSearched(true); // 設定為已查詢
        setShowAddAppointment(false); // 隱藏新開診按鈕
        setCurrentRightClick({week: null, timeslot: null}); // 重置右鍵點擊狀態
        getMonthData(); // 執行資料查詢
    };

    /**
     * 取得月班資料
     * @return {void}
     */
    const getMonthData = () => {
        //是否科別模式
        const isDepartmentMode = +filterMode === RegsWeekModeEnum.Department
        //編號
        const no = searchText ? searchText.split(' ')[0] : null

        // 月班模式
        if (isDepartmentMode) {
            //依科別查詢月班表
            regsQueryMonthClinicByDivNo({
                encounterDate: `${currentDate} 00:00:00`,
                divNo: no
            }).then(res => {
                if (res.err === ApiErrorStatusEnum.Success) {
                    //設定資料
                    parserMonthData(res.data)
                }
            })
        } else { // 醫師模式
            //依醫師查詢月班表
            regsQueryMonthClinicByDoctorNo({
                encounterDate: `${currentDate} 00:00:00`,
                doctorNo: no
            }).then(res => {
                if (res.err === ApiErrorStatusEnum.Success) {
                    //設定資料
                    parserMonthData(res.data)
                }
            })
        }
    }

    /**
     * 解析月班資料
     * @param data {Array<Object>}
     * @return {void}
     */
    const parserMonthData = (data) => {
        //設定資料
        const newData = [...defaultWeekData()]

        data.forEach(item => {
            //取得星期幾
            let week = parseInt(item.week)
            if (week === 0) week = 7
            //取得時段
            const apn = item.apn
            //根據時段去設定週班資料
            switch (apn) {
                case TimeslotEnum.Morning:
                    newData[week - 1].morning.push(...item.regsClinicList)
                    break
                case TimeslotEnum.Afternoon:
                    newData[week - 1].afternoon.push(...item.regsClinicList)
                    break
                case TimeslotEnum.Night:
                    newData[week - 1].night.push(...item.regsClinicList)
                    break
            }
        })
        //設定資料
        setData(newData)
    }


    /**
     * 取得本週日期
     * @return {Array<Object>}
     */
    const getWeekDates = () => {
        //避免第一次進來時沒有日期報錯
        if (!currentDate) {
            return []
        }

        const date = new Date(currentDate);
        const dayOfWeek = date.getDay(); // 0 (Sunday) to 6 (Saturday)
        const startDate = new Date(date);
        // 設定當前日期為星期日的情況，使其成為星期天 (0) 以符合 ISO 8601，其中星期一是1
        startDate.setDate(date.getDate() + (dayOfWeek === 0 ? -6 : 1 - dayOfWeek)); // Start from Monday

        // 保存該週的所有日期
        const weekDates = [];
        // 生成該週的所有日期 (從星期一到星期日)
        for (let i = 0; i < 7; i++) {
            const current = new Date(startDate);
            current.setDate(startDate.getDate() + i);
            // 格式化日期或者直接使用 Date 物件
            weekDates.push(`${time2String(current, "MM/DD")}(${t(`general.dateTime.weekRange.short.${(i + 1)}`)})`); // Convert to YYYY-MM-DD format
        }

        return weekDates
    }

    /**
     * 點擊上一週或下一週時
     * @param isIncrement {Boolean} 是否增加
     * @return {void}
     */
    const handleCurrentWeekOnClick = (isIncrement = true) => {

        const date = new Date(currentDate);
        const newWeekDate = new Date(date);
        if (isIncrement)
            newWeekDate.setDate(date.getDate() + 7);
        else
            newWeekDate.setDate(date.getDate() - 7);

        setCurrentDate(time2String(newWeekDate, "YYYY-MM-DD"))

        // 重新設定新問診
        setCurrentRightClick({week: null, timeslot: null})
    }

    /**
     * 取得週的指定星期幾的日期
     * @param dayOfWeek {Number} 星期幾 (0: 星期日, 1: 星期一, ..., 6: 星期六)
     * @return {String}
     */
    function getDateOfWeek(dayOfWeek) {
        // 如果當前日期不存在，則返回空字串
        if (!currentDate) {
            return ''
        }
        const date = new Date(currentDate);
        const days = date.getDay(); // 0 (Sunday) to 6 (Saturday)
        const startDate = new Date(date);
        startDate.setDate(date.getDate() + (days === 0 ? -6 : 1 - days)); // Start from Monday

        const targetDate = new Date(startDate)
        targetDate.setDate(startDate.getDate() + dayOfWeek)

        // 格式化日期為YYYY-MM-DD
        return `${targetDate.getFullYear()}/${(targetDate.getMonth() + 1).toString().padStart(2, '0')}/${targetDate.getDate().toString().padStart(2, '0')}`
    }

    /**
     * 新增新開診時
     * @param week {Number} 週數
     * @param timeslot {Number} 時段
     */
    const handleOnAddNewAppointment = (week, timeslot) => {
        setShowAddAppointment(true)
        setCurrentRightClick({week: week, timeslot: timeslot})
    }

    /**
     * 顯示Popup
     * @param data {Object}
     * @param mode {Number}
     * @return {void}
     */
    const handleShowPopup = (data, mode = PopupModeEnum.Add) => {
        // 設定Popup模式
        setPopupMode(mode)
        //取得資料
        const newData = mode === PopupModeEnum.Modify ?
            {...data} :
            {
                //複製原先資料
                ...data,
                //設定日期
                date: getDateOfWeek(currentRightClick.week),
                //當前選擇醫生
                doctor: +filterMode === RegsWeekModeEnum.Doctor ? (searchText ? searchText : null) : null,
                //當前選擇科別
                department: +filterMode === RegsWeekModeEnum.Department ? (searchText ? searchText : null) : null
            }
        // 設定選擇的資料
        setSelectedData(newData)
        // 顯示Popup
        setShowPopup(true)
        // 當開啟 popup 時，關閉所有 tooltip
        setShowTooltip(false)
    }

    /**
     * 關閉 Popup
     */
    const handleClosePopup = () => {
        setShowPopup(false)
        setPopupMode(null)
        setSelectedData(null)
        // 當關閉 popup 時，允許顯示 tooltip
        setShowTooltip(true)
    }

    /**
     * 處理 tooltip 的顯示
     */
    const handleTooltipShow = (slotKey, weekIndex, listIndex) => {
        setGlobalTooltipInfo({
            slotKey,
            weekIndex,
            listIndex
        });
    };

    /**
     * 處理 tooltip 的隱藏
     */
    const handleTooltipHide = () => {
        setGlobalTooltipInfo({
            slotKey: null,
            weekIndex: null,
            listIndex: null
        });
    };

    /**
     * 取得月班資料
     * @return {void}
     */
    const handleGetMonthData = () => {
        // 關閉Popup
        setShowPopup(false)
        // 重新設定新問診
        setCurrentRightClick({week: null, timeslot: null})
        // 取得月班資料
        getMonthData()
    }

    /**
     * 第一次進來時取得基礎資料
     * @return {void}
     */
    useEffect(() => {
        setData(defaultWeekData()); // 設定初始空白週資料
        setCurrentDate(time2String(new Date(), "yyyy-MM-DD")); // 設定當前日期
        // 取得基礎資料
        regsMonthClinicBaseData({}).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setBaseData(res.data);
                setAllDoctorOrDept(res.data.divisionList);
            }
        });
    }, []);

    /**
     * 當日期改變時
     * @return {void}
     */
    useEffect(() => {
        if (!!currentDate) {
            //取得月班資料
            getMonthData()
        }
    }, [currentDate])

    /**
     * 搜尋文字改變時
     * @return {void}
     */
    useEffect(() => {
        setDoctorOrDeptOptionList(getDoctorOrDeptOptions());
    }, [searchText, allDoctorOrDept]);

    return (
        <div className="w-full">
            <div className="w-full flex flex-col bg-[#FAFAFA]  overflow-y-hidden">
                {/*頂部區塊*/}
                <div className="flex flex-row items-center space-x-2 px-2 pt-1"
                     style={{height: topBlockHeight}}>
                    <div className="text-left">
                        <Select
                            data={{
                                label: '搜尋條件',
                                options: [
                                    {value: '屏東義大醫院', label: '屏東義大醫院'},
                                ]
                            }}
                            value="屏東義大醫院"
                            showLabel={false}
                        />
                    </div>
                    <Button
                        color={ColorEnum.Secondary}
                        variant={ButtonVariantEnum.Outlined}
                        size={ButtonSizeEnum.Medium}
                        onClick={() => handleCurrentWeekOnClick(false)}>
                        {t('general.dateTime.lastWeek')}
                    </Button>
                    <DatePicker
                        label=""
                        value={dayjs(currentDate)}
                        displayWeekNumber
                        onChange={handleDateOnChange}
                    />
                    <Button
                        color={ColorEnum.Secondary}
                        variant={ButtonVariantEnum.Outlined}
                        size={ButtonSizeEnum.Medium}
                        onClick={() => handleCurrentWeekOnClick()}>
                        {t('general.dateTime.nextWeek')}
                    </Button>
                    <RadioGroup
                        row
                        defaultValue={filterMode}
                        value={filterMode}
                        optionProps={{
                            options: [
                                {label: t('general.appointmentDiv'), value: RegsWeekModeEnum.Department},
                                {label: t('general.doctor'), value: RegsWeekModeEnum.Doctor}
                            ]
                        }}
                        size={SizeEnum.Medium}
                        onChange={(e) => handleModeOnChange(e.target.value)}
                    />
                    {doctorOrDeptOptionList && (
                        <Search
                            disablePortal={false}
                            options={doctorOrDeptOptionList}
                            getOptionLabel={(option) => getFilterOptionContent(option)}
                            onChange={(_, value) => handleFilterOptionOnClick(value)}
                        />
                    )}
                    {/*查詢*/}
                    <Button
                        color={ColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                        size={ButtonSizeEnum.Medium}
                        onClick={handleSearchOnClick}>
                        {t('general.query')}
                    </Button>
                </div>

                {/*月班Content*/}
                <div className="flex flex-col bg-white">
                    {/**日期區 */}
                    <div className="w-full pl-[72px] pr-[16px]"
                         style={{height: dateBlockHeight}}>
                        <div className="w-full flex flex-row">
                            {getWeekDates().map((date, index) => (
                                <div className="flex-1 flex justify-center" key={index}>
                                    <p>{date}</p>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/**時段區塊 */}
                    <div className="flex flex-col">
                        {TIME_SLOTS.map((slot) => (
                            <div
                                key={slot.key}
                                className={`flex ${slot.bgColor} px-1 py-3`}
                                style={{height: blockHeightPerSection, gap: '5px'}}
                            >
                                <TimeSlotTitle
                                    label={slot.label}
                                    startTime={slot.startTime}
                                    endTime={slot.endTime}
                                />
                                <div className="flex-1 grid grid-cols-7 gap-1">
                                    {Array.from({ length: 7 }, (_, weekIndex) => (
                                        <ScheduleTimeBox
                                            key={`${slot.key}-${weekIndex}`}
                                            list={data ? data[weekIndex]?.[slot.key] || [] : []}
                                            timeslot={slot.type}
                                            week={weekIndex}
                                            baseData={baseData}
                                            isAddAppointment={currentRightClick.week === weekIndex && currentRightClick.timeslot === slot.type}
                                            onAddAppointment={handleOnAddNewAppointment}
                                            showPopup={handleShowPopup}
                                            showTooltip={showTooltip}
                                            isSearched={isSearched}
                                            onTooltipShow={(index) => {setShowTooltip(true)}}
                                            onTooltipHide={() => {setShowTooltip(false)}}
                                        />
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/*月班Popup*/}
                {showPopup && (
                    <AppointmentPopup
                        mode={popupMode}
                        filterMode={filterMode}
                        baseData={baseData}
                        data={selectedData}
                        closePopup={handleClosePopup}
                        getMonthData={handleGetMonthData}
                    />
                )}
            </div>
        </div>
    )
}

export default MonthMaintenance
