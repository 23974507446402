import React, {useEffect, useState} from "react"
import {t} from "i18next"
import {regsQueryStopClinicRegList} from "../../../api/v1/RegsStopClinic"
import {
    AlertTypeEnum,
    Dialog,
    DialogSizeEnums,
    DialogVariant,
    DateRangePicker,
    AdvancedDataGrid
} from "edah-component/dist"
import {Button, ButtonSizeEnum, ButtonVariantEnum, ColorEnum} from "edah-component/dist";
import dayjs from "dayjs";
import useToast from "../../../hooks/useToast";
import {ApiErrorStatusEnum} from "edah_utils/dist";

const StopClinicListPromptPopup = ({show = false, closePopupButtonOnClick}) => {
    //Toast Message Hooks
    const showToast = useToast()
    // 停代診清單
    const [advSearchDataList, setAdvSearchDataList] = useState([])
    // 日期範圍
    const [dateRange, setDateRange] = useState([])
    // 停代診清單表頭
    const header = [
        {
            field: 'encounterDate', headerName: t("Regs.general.clinicDate"), width: 150, flex: 1.5,
            valueFormatter: (params) => {
                return dayjs(params).format('YYYY-MM-DD');
            }
        },
        {field: 'apnName', headerName: t("Regs.general.timeSlot"), width: 80, flex: 0.8},
        {
            field: 'doctorName', headerName: t("general.doctor"), width: 130, flex: 1.3,renderCell: (params) => {
                const { locumDoctorName, stopClinicFlag, doctorName } = params.row;
                if (locumDoctorName) {
                    return `${locumDoctorName}[代]`;
                }
                if (stopClinicFlag) {
                    return (
                        <span>
                        {doctorName}<span style={{ color: '#C53030' }}>[停]</span>
                    </span>
                    );
                }
                return doctorName;
            }
        },
        {field: 'divName', headerName: t("general.department"), width: 130, flex: 1.3},
        {field: 'clinicName', headerName: '診室名稱', width: 160, flex: 1.6},
        {field: 'regCount', headerName: '掛號人次', type: 'number', width: 185, flex: 1.85},
    ]

    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show)

    /**
     * 彈窗關閉處理
     */
    const handleDialogClose = () => {
        closePopupButtonOnClick()
        // 清除日期資料
        setDateRange([])
        // 清除搜尋資料
        setAdvSearchDataList([])

    }

    /**
     * 查詢按鈕點擊事件
     * @return {void}
     */
    const handleSearchOnClick = async () => {
        if (!dateRange[0] || !dateRange[1]) {
            showToast({message: "請選擇日期區間", type: AlertTypeEnum.Error});
            return;
        }

        // 計算日期差異
        const diffInDays = dateRange[1].diff(dateRange[0], 'day');
        if (diffInDays > 31) {
            showToast({message: "日期區間限一個月", type: AlertTypeEnum.Error});
            return;
        }

        regsQueryStopClinicRegList({
            startDate: dateRange[0].format('YYYY-MM-DD 00:00:00'),
            endDate: dateRange[1].format('YYYY-MM-DD 00:00:00')
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setAdvSearchDataList(res.data || []);
            } else {
                const msg = `查詢失敗: ${res.msg}`;
                showToast({message: msg, type: AlertTypeEnum.Error});
            }
        });
    }

    /**
     * 日期範圍變更事件
     * @param newValue {Array} 日期範圍 [開始日期, 結束日期]
     */
    const handleDateRangeOnChange = (newValue) => {
        setDateRange(newValue);
    }


    /**
     * 取得Content
     * @return {JSX.Element}
     */
    const getContent = () => (
        <>
            <div className="flex flex-row py-2 space-x-2 justify-start items-center">
                {/* 選擇日期區間 */}
                <DateRangePicker
                    value={dateRange}
                    required
                    onChange={handleDateRangeOnChange}
                />
                {/*查詢按鈕*/}
                <Button
                    color={ColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                    size={ButtonSizeEnum.Medium}
                    onClick={handleSearchOnClick}>
                    {t('general.query')}
                </Button>
            </div>
            {/*停代診明細 Table*/}
            <AdvancedDataGrid
                rows={advSearchDataList || []}
                columns={header}
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
                disableDensitySelector
                height={'60vh'}
                autoWidth
                hideFooter
                getRowHeight={(params) => 45}
            />
        </>
    )

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {

        setShowPopup(show)
    }, [show])

    return (
        <Dialog
            open={showPopup}
            title={'停代診清單'}
            variant={DialogVariant.CUSTOM}
            paperStyleProps={{width: DialogSizeEnums.MD}}
            content={getContent()}
            onClose={handleDialogClose}
            muiDialogContentProps={{dividers: true}}/>
    )
}

export default StopClinicListPromptPopup
