import React from "react";
import "react-tooltip/dist/react-tooltip.css";

//Import Icons
import {ReactComponent as IcCardModuleIcon} from "../assets/images/icons/ic_card_module.svg";
import {ReactComponent as IcCardTodoListIcon} from "../assets/images/icons/ic_card_todo_list.svg";

//Import Pages
import ICCardInfo from "../pages/IC Card/InfoPage";
import ICCardMaintain from "../pages/IC Card/MaintainPage";
import ICCardUploadSearch from "../pages/IC Card/UploadSearchPage";
import ICCardTodoList from "../pages/IC Card/TodoListPage";
import Demo from "../pages/Demo/Demo";
import LoginPage from "../pages/Login/LoginPage";
import Appointment from "../pages/Appointment/Appointment.jsx";
import AppointmentList from "../components/Appointment/AppointmentList.jsx";
import ScheduleBatchesUpdate from "../pages/Regs/ScheduleBatchesUpdate.jsx";
import Registration from "../pages/Appointment/Registration";
import MonthMaintenance from "../pages/Regs/MonthMaintenance.jsx";
import WeekMaintenance from "../pages/Regs/WeekMaintenance.jsx";
import StopClinic from "../pages/Regs/StopClinic.jsx";
import CalendarMaintenance from "../pages/Regs/CalendarMaintenance.jsx";
import ClinicScheduleSearch from "../pages/Regs/ClinicScheduleSearch.jsx";
import TypeMaintenance from "../pages/Regs/TypeMaintenance";
import DoctorInformSearch from "../pages/Regs/DoctorInformSearch.jsx";
import RegistrationSearch from "../pages/Regs/RegistrationSearch.jsx";
import DoctorOrderAppointmentRegistration from "../pages/Regs/DoctorOrderAppointmentRegistration.jsx";
import MainInjury from "../pages/IC Card/System Maintenance/MainInjury";
// 轉診系統
import {
    ADD_REFERRAL_NO,
    APPLY_DISCHARGE_SUMMARY_NO,
    APPROVE_DISCHARGE_SUMMARY_NO,
    EMERGENCY_HOSPITAL_MAINTAIN_NO,
    ER_QUALITY_VPN_UPLOAD_EXPORT_NO,
    LONG_TERM_CARE_CENTER_NO,
    NO_REFERRAL_REPORT_NO,
    PERMISSION_SETTING_NO,
    QUERY_PATIENT_VISIT_LIST_NO,
    REFERRAL_CODE_MAINTAIN_NO,
    REFERRAL_FORM_DOWNLOAD_NO,
    REFERRAL_FORM_UPLOAD_NO,
    REFERRAL_PLUGIN_UPLOAD_NO,
    REFERRAL_REPLY_LETTER,
    RURAL_HOSPITAL_NO,
    STRATEGIC_ALLIANCE_MAINTAIN_NO,
    TRAN_FOREIGN_LIST_NO,
    TRAN_IN_CASE_LIST_NO,
    TRAN_OUT_CASE_LIST_NO
} from "../constants/menuFuncNo.js";
// 電子轉診單_下載
import ReferralFormDownload from "../pages/ReferralSystem/ReferralFormDownload";
// 電子轉診單_上傳
import ReferralFormUpload from "../pages/ReferralSystem/ReferralFormUpload";
// 轉診回函
import ReferralReplyLetter from "../pages/ReferralSystem/ReferralReplyLetter";
// 出院病摘_申請/列印
import ApplyDischargeSummary from "../pages/ReferralSystem/ApplyDischargeSummary";
// 出院病摘_核准
import ApproveDischargeSummary from "../pages/ReferralSystem/ApproveDischargeSummary"
// 轉出轉入單開立作業
import AddReferralForm from "../pages/ReferralSystem/AddReferralForm.jsx";
// 轉入單個管作業_清單
import TranInCaseList from "../pages/ReferralSystem/TranInCaseList";
// 轉出單個管作業
import TranOutCaseList from "../pages/ReferralSystem/TranOutCaseList.jsx";
// 病症暨失能診斷證明書_清單
import DisabilityCertificateList from "../pages/ReferralSystem/DisabilityCertificateList.jsx";
// 不轉診原因報表
import NoReferralReport from "../pages/ReferralSystem/NoReferralReport";
// 急診品質方案VPN上傳匯出作業
import ErQualityVpnUploadExport from '../pages/ReferralSystem/ErQualityVpnUploadExport'
// MARS(VPN)報表
// 權限設定
import PermissionSetting from "../pages/ReferralSystem/PermissionSetting";
// 轉診代碼維護
import ReferralCodeMaintain from "../pages/ReferralSystem/ReferralCodeMaintain";
// 急診責任醫院維護
import EmergencyHospitalMaintain from "../pages/ReferralSystem/EmergencyHospitalMaintain";
// 策略聯盟維護
import StrategicAllianceMaintain from "../pages/ReferralSystem/StrategicAllianceMaintain";
// 離島偏鄉醫院維護
import RuralHospitalMaintain from "../pages/ReferralSystem/RuralHospitalMaintain";

import CDSSInformationMaintain from "../pages/CDSS/InformationMaintain.jsx";
import BasicDataMaintenance from "../pages/SystemMonitoring/BasicDataMaintenance";
import NotificationPersonnelMaintenance from "../pages/SystemMonitoring/NotificationPersonnelMaintenance";
import ServiceStatusInquiry from "../pages/SystemMonitoring/ServiceStatusInquiry";
import ServiceLogInquiry from "../pages/SystemMonitoring/ServiceLogInquiry";
import ServiceLog2Inquiry from "../pages/SystemMonitoring/ServiceLog2Inquiry";

//危急值回復待回覆清單彈窗
//A級危急值逾時回覆再通知-A版
import CrisisAlertTimeoutReplyAssignmentAPopup from "../pages/CVIS/CrisisAlertTimeoutReplyAssignmentAPopup.jsx";
//危急值逾時回覆查詢
import CrisisAlertTimeoutReplyQuery from "../pages/CVIS/CrisisAlertTimeoutReplyQuery.jsx";
//危急值逾時閉環監控
import CrisisAlertClosureMonitor from "../pages/CVIS/CrisisAlertClosureMonitor.jsx";
//危急值通報紀錄
import CriticalValuesRecords from "../pages/CVIS/CriticalValuesRecords";
// 感染管制通報紀錄
import InflectionControlRecords from "../pages/CVIS/InflectionControlRecords";
// 待回覆提示
import PendingReplyNotification from "../pages/CVIS/PendingReplyNotification";
// 危急值通報
import CriticalValueAlert from "../pages/CVIS/CriticalValueAlert";

//電子簽章
import UnsignedDocumentsQueryPage from "../pages/ESSI/UnsignedDocumentsQueryPage";
import Unsign2Page from "../pages/ESSI/Unsign2Page";
import EmrMaster from "../pages/ESSI/EmrMaster";
import EmrOperLog from "../pages/ESSI/EmrOperLog";
import SignRate from "../pages/ESSI/SignRate";
import HoursSignRate from "../pages/ESSI/HoursSignRate.jsx";
import EmrTypeMaintenance from "../pages/ESSI/EmrTypeMaintenance";
import UserCertMaintenance from "../pages/ESSI/UserCertMaintenance";
import SignAgentMaintenance from "../pages/ESSI/SignAgentMaintenance";
import ReferralPluginUpload from "../pages/ReferralSystem/ReferralPluginUpload.jsx";
import LongTermCareCenter from "../pages/ReferralSystem/LongTermCareCenter";

//報備支援
import EntityRelationPage from "../pages/RSIS/EntityRelationPage.jsx";
import CaseManagement from "../pages/RSIS/CaseManagement.jsx";
import QueryPatientVisitList from "../pages/ReferralSystem/QueryPatientVisitList.jsx";
import MOHWgovFeedbackLayout from "../pages/RSIS/MOHWgovFeedbackLayout.jsx";
import SupportOrgMaintainLayout from "../pages/RSIS/SupportOrgMaintainLayout.jsx";
import LogOffDeleteBatchesLayout from "../pages/RSIS/LogOffDeleteBatchesLayout.jsx";
import ImportResourcesLayout from "../pages/RSIS/ImportResourcesLayout.jsx";
import UrgentApplicationLayout from "../pages/RSIS/UrgentApplicationLayout.jsx";
import FunctionOperateMaintainLayout from "../pages/RSIS/FunctionOperateMaintainLayout.jsx";
import SpecialPermissionSettingLayout from "../pages/RSIS/SpecialPermissionSettingLayout.jsx";
import SupportDivMaintainLayout from "../pages/RSIS/SupportDivMaintainLayout.jsx";
import WardAgentMaintainLayout from "../pages/RSIS/WardAgentMaintainLayout.jsx";
import ContactPersonMaintainLayout from "../pages/RSIS/ContactPersonMaintainLayout.jsx";
import BasicSetting from "../pages/RSIS/BasicSetting/BasicSetting";

//居家護理
import HomeAssessmentOperations from "../pages/HomeHealthCare/HomeAssessmentOperations";
import HomePotentialCase from "../pages/HomeHealthCare/HomePotentialCase";
import HomePotentialManagement from "../pages/HomeHealthCare/HomePotentialManagement";
import ReadmissionReport from "../pages/HomeHealthCare/ReadmissionReport";
import EmergencyUseReport from "../pages/HomeHealthCare/EmergencyUseReport";
import SkinDamageReport from "../pages/HomeHealthCare/SkinDamageReport";
import HomeHealthCareInfoAPI from "../pages/HomeHealthCare/HomeHealthCareInfoAPI";
import DrugExpirationReport from "../pages/HomeHealthCare/DrugExpirationReport";
import CaseQueryReport from "../pages/HomeHealthCare/CaseQueryReport";
import CaseCloseStatusMaintenance from "../pages/HomeHealthCare/CaseCloseStatusMaintenance";
import Cloudenc from "../pages/IC Card/Cloudenc";
import HomeCareRecordMaintenance from "../pages/HomeHealthCare/HomeCareRecordMaintenance";
import QueueNumberManagement from "../pages/Regs/QueueNumberManagement";
import MedicalMessageManagement from "../pages/Regs/MedicalMessageManagement";
import DepartmentMaintence from "../pages/Regs/DepartmentMaintenance";
import MailListMaintainLayout from "../pages/RSIS/MailListMaintainLayout";
import PrintOperation from "../pages/Regs/PrintOperation";

//CURD
import CRUDSettingPage from "../pages/CRUD/CRUDSettingPage"

//EMR
import EmrMainV from "../pages/CPOE/EmrMainV";
import WriteICCardPage from "../pages/IC Card/WriteICCardPage.jsx";

const routes = [
    {
        id: "BP-0001-1",
        path: "/ICCard/info",
        tip: "病人IC卡資料查詢",
        element: <ICCardInfo/>,
    },
    {
        id: "AP-SS-1",
        path: "/WeekMaintenance/WeekMaintenance",
        tip: "週班維護",
        element: <WeekMaintenance/>,
    },
    {
        id: "BP-0001-2",
        element: <ICCardMaintain/>,
        path: "/ICCard/maintain",
        tip: "IC卡寫卡資料維護",
    },
    {
        id: "BP-0001-6",
        element: <WriteICCardPage/>,
        path: "/ICCard/writeICCard",
        tip: "IC卡寫卡",
    },
    {
        id: "BP-0001-3",
        element: <ICCardUploadSearch/>,
        path: "/ICCard/uploadSearch",
        tip: "IC資料上傳資料查詢",
    },
    {
        id: "BP-0001-4",
        element: <ICCardTodoList/>,
        path: "/ICCard/todoList",
        tip: "待寫卡清單",
    },
    {
        id: "BP-0001-5",
        element: <Cloudenc/>,
        path: "/ICCard/cloudenc",
        tip: "健保醫療資訊雲端查詢",
    },
    {
        id: "BP-0002-1",
        element: <MainInjury/>,
        path: "/ICCard/mainInjury",
        icon: IcCardModuleIcon,
        tip: "重大傷病資料維護",
    },
    {
        id: "demo",
        element: <Demo/>,
        path: "/",
        icon: IcCardTodoListIcon,
        tip: "展示用頁面",
    },
    {
        element: <LoginPage/>,
        path: "/login",
    },
    {
        path: "/appointment",
        element: <Appointment/>,
        id: "toyahane",
        tip: "toyahane",
        children: [
            {
                path: "appointment-list",
                element: <AppointmentList/>,
            },
        ],
    },
    {
        path: "/appointment",
        element: <Appointment/>,
        id: "AP-0001",
        tip: "掛號作業",
    },
    {
        path: "/stopClinic",
        element: <StopClinic/>,
        id: "AP-0002",
        tip: "停代診聯絡作業"
    },
    {
        path: "/doctorOrderAppointmentRegistration",
        element: <DoctorOrderAppointmentRegistration/>,
        id: "AP-0004",
        tip: "醫囑預約掛號作業"
    },
    {
        path: "/queueNumberManagement",
        element: <QueueNumberManagement/>,
        id: "AP-MM-0001",
        tip: "給號方式維護"
    },
    {
        path: "/medicalMessageManagement",
        element: <MedicalMessageManagement/>,
        id: "AP-MM-0003",
        tip: "標語維護"
    },
    {
        path: "/departmentMaintence",
        element: <DepartmentMaintence/>,
        id: "AP-MM-0005",
        tip: "科別維護"
    },
    {
        path: "/calendarMaintenance",
        element: <CalendarMaintenance/>,
        id: "AP-MM-0002",
        tip: "行事曆維護"
    },
    {
        path: "/clinicScheduleSearch",
        element: <ClinicScheduleSearch/>,
        id: "AP-SM-0004",
        tip: "診間班表查詢"
    },
    {
        path: "/registrationSearch",
        element: <RegistrationSearch/>,
        id: "AP-SM-0005",
        tip: "掛號查詢"
    },
    {
        path: "/printOperation",
        element: <PrintOperation/>,
        id: "AP-SM-0006",
        tip: "列印作業"
    },
    {
        path: "/scheduleBatchesUpdate",
        element: <ScheduleBatchesUpdate/>,
        id: "AP-SS-3",
        tip: "班表批次異動",
    },
    {
        path: "/monthMaintenance",
        element: <MonthMaintenance/>,
        id: "AP-SS-2",
        tip: "月班維護",
    },
    {
        path: "/typeMaintenance",
        element: <TypeMaintenance/>,
        id: "AP-MM-0004",
    },
    {
        id: "common-3-1-1",
        path: "/registration",
        element: <Registration/>,

    },
    {
        path: "/CDSSInformationMaintain",
        element: <CDSSInformationMaintain/>,
        id: 'CP-0001-1',
        tip: 'CDSS Information維護'
    },
    {
        id: "CVIS-MA-01",
        path: "/criticalValuesRecords",
        element: <CriticalValuesRecords/>,
        tip: "危急值通報紀錄",
    },
    {
        id: "CVIS-MA-02",
        path: "/inflectionControlRecords",
        element: <InflectionControlRecords/>,
        tip: "感染管制通報紀錄",
    },
    {
        id: "CVIS-MO-05",
        path: "/pendingReplyNotification",
        element: <PendingReplyNotification/>,
        tip: "待回覆提示",
    },
    {
        id: "crisisAlertTimeoutReplyAssignmentAPopup",
        path: "/crisisAlertTimeoutReplyAssignmentAPopup",
        element: <CrisisAlertTimeoutReplyAssignmentAPopup/>,
        tip: "A級危急值逾時回覆再通知-A版",
    },
    {
        id: "CVIS-MA-03",
        path: "/crisisAlertTimeoutReplyQuery",
        element: <CrisisAlertTimeoutReplyQuery/>,
        tip: "危急值逾時回覆查詢",
    },
    {
        id: "CVIS-MA-04",
        path: "/crisisAlertClosureMonitor",
        element: <CrisisAlertClosureMonitor/>,
        tip: "危急值閉環監控",
    },
    {
        id: "CVIS-MO-01",
        path: "/criticalValueAlert",
        element: <CriticalValueAlert/>,
        tip: "危急值通報",
    },
    {
        id: "CVIS-MO-03",
        path: "/CrisisAlertTimeoutReplyAssignmentAPopup",
        element: <CrisisAlertTimeoutReplyAssignmentAPopup/>,
        tip: "A級危急值逾時回覆再通知",
    },
    {
        id: REFERRAL_FORM_DOWNLOAD_NO,
        path: "/referral/form-download",
        element: <ReferralFormDownload/>,
        tip: "電子轉診單_下載",
    },
    {
        id: REFERRAL_FORM_UPLOAD_NO,
        path: "/referral/form-upload",
        element: <ReferralFormUpload/>,
        tip: "電子轉診單_上傳",
    },
    {
        id: REFERRAL_REPLY_LETTER,
        path: "/referral/reply-letter",
        element: <ReferralReplyLetter/>,
        tip: "轉診回函",
    },
    {
        id: APPLY_DISCHARGE_SUMMARY_NO,
        path: "/referral/apply-summary",
        element: <ApplyDischargeSummary/>,
        tip: "出院病摘_申請/列印",
    },
    {
        id: APPROVE_DISCHARGE_SUMMARY_NO,
        path: "/referral/apply-summary",
        element: <ApproveDischargeSummary/>,
        tip: "出院病摘_核准",
    },
    {
        id: TRAN_IN_CASE_LIST_NO,
        path: "/referral/tran-in-list",
        element: <TranInCaseList/>,
        tip: "轉入單個管作業_清單",
    },
    {
        id: TRAN_OUT_CASE_LIST_NO,
        path: "/referral/tran-out-list",
        element: <TranOutCaseList/>,
        tip: "轉出單個管作業_清單",
    },
    {
        id: TRAN_FOREIGN_LIST_NO,
        path: "/referral/tran-foreign-list",
        element: <DisabilityCertificateList/>,
        tip: "病症暨失能診斷證明書_清單",
    },
    {
        id: ADD_REFERRAL_NO,
        path: "/referral/add-referral",
        element: <AddReferralForm/>,
        tip: "轉出轉入單開立作業",
    },
    {
        id: QUERY_PATIENT_VISIT_LIST_NO,
        path: "/referral/patient-visit-list",
        element: <QueryPatientVisitList/>,
        tip: "看診清單查詢",
    },
    {
        id: NO_REFERRAL_REPORT_NO,
        path: "/referral/no-referral-report",
        element: <NoReferralReport/>,
        tip: "不轉診原因報表",
    },
    {
        id: ER_QUALITY_VPN_UPLOAD_EXPORT_NO,
        path: "/referral/er-quality-vpn-upload",
        element: <ErQualityVpnUploadExport/>,
        tip: "急診品質方案VPN上傳匯出作業",
    },
    // {
    //     id: MARS_REPORT_NO,
    //     path: "/referral/mars-install-report",
    //     element: <MarsReport />,

    // },
    {
        id: REFERRAL_PLUGIN_UPLOAD_NO,
        path: "/referral/referral-plugin-upload",
        element: <ReferralPluginUpload/>,

    },
    {
        id: RURAL_HOSPITAL_NO,
        path: "/referral/referral-plugin-upload",
        element: <RuralHospitalMaintain/>,

    },
    {
        id: "ES-0001-1",
        path: "/Unsign1Page",
        element: <UnsignedDocumentsQueryPage/>,
        tip: "未簽文件查詢與簽章",
    },
    {
        id: "ES-0001-2",
        path: "/EmrMaster",
        element: <EmrMaster/>,
        tip: "電子病歷查詢及驗章",
    },
    {
        id: "ES-0001-3",
        path: "/Unsign2Page",
        element: <Unsign2Page/>,
        tip: "醫療機構卡簽章",
    },
    {
        id: "ES-0001-4",
        path: "/EmrOperLog",
        element: <EmrOperLog/>,
        tip: "操作紀錄查詢",
    },
    {
        id: "ES-0001-5",
        path: "/SignRate",
        element: <SignRate/>,
        tip: "簽章率明細與查詢",
    },
    {
        id: "ES-0001-6",
        path: "/HoursSignRate",
        element: <HoursSignRate/>,
        tip: "24小時簽章率明細",
    },
    {
        id: "ES-0002-1",
        path: "/EmrTypeMaintenance",
        element: <EmrTypeMaintenance/>,
        tip: "電子表單類別維護",
    },
    {
        id: "ES-0002-2",
        path: "/UserCertMaintenance",
        element: <UserCertMaintenance/>,
        tip: "人員憑證維護",
    },
    {
        id: "ES-0002-3",
        path: "/SignAgentMaintenance",
        element: <SignAgentMaintenance/>,
        tip: "代簽人員維護",
    },
    {
        id: "WDIS-MA-01",
        path: "/basicDataMaintenance",
        element: <BasicDataMaintenance/>,
        tip: "服務資料維護"
    },
    {
        id: "WDIS-MA-02",
        path: "/notificationPersonnelMaintenance",
        element: <NotificationPersonnelMaintenance/>,
        tip: "通知人員維護"
    },
    {
        id: "WDIS-FN-02",
        path: "/serviceStatusInquiry",
        element: <ServiceStatusInquiry/>,
        tip: "服務狀態查詢"
    },
    {
        id: "WDIS-QR-01",
        path: "/serviceLogInquiry",
        element: <ServiceLogInquiry/>,
        tip: "服務Log查詢"
    },
    {
        id: "WDIS-QR-02",
        path: "/serviceLog2Inquiry",
        element: <ServiceLog2Inquiry/>,
        tip: "系統服務Log查詢"
    },
    {
        id: PERMISSION_SETTING_NO,
        path: "/referral/permission-setting",
        element: <PermissionSetting/>,
    },
    {
        id: REFERRAL_CODE_MAINTAIN_NO,
        path: "/referral/referral-code-maintain",
        element: <ReferralCodeMaintain/>,
    },
    {
        id: EMERGENCY_HOSPITAL_MAINTAIN_NO,
        path: "/referral/emergency-hospital-maintain",
        element: <EmergencyHospitalMaintain/>,
    },
    {
        id: STRATEGIC_ALLIANCE_MAINTAIN_NO,
        path: "/referral/strategic-alliance-maintain",
        element: <StrategicAllianceMaintain/>,
    },
    {
        id: LONG_TERM_CARE_CENTER_NO,
        path: "/referral/long-term-care-center",
        element: <LongTermCareCenter/>,
    },
    {
        id: "RSIS-FN-01",
        path: "/EntityRelationLayout",
        element: <EntityRelationPage/>,
        tip: "申請與查詢"
    },
    {
        id: "RSIS-FN-02",
        path: "/LogOffDeleteBatchesLayout",
        element: <LogOffDeleteBatchesLayout/>,
        tip: "整批變更註銷或刪除"
    },
    {
        id: "RSIS-FN-03",
        path: "/ImportResourcesLayout",
        element: <ImportResourcesLayout/>,
        tip: "匯入報備資源資料"
    },
    {
        id: "RSIS-FN-04",
        path: "/UrgentApplicationLayout",
        element: <UrgentApplicationLayout/>,
        tip: "急件申請"
    },
    {
        id: "RSIS-FN-05",
        path: "/MOHWgovFeedbackLayout",
        element: <MOHWgovFeedbackLayout/>,
        tip: "衛福部案號回饋作業"
    },
    {
        id: "RSIS-FN-06",
        path: "/CaseManagement",
        element: <CaseManagement/>,
        tip: "個案管理"
    },
    {
        id: "RSIS-MA-01",
        path: "/SupportOrgMaintainLayout",
        element: <SupportOrgMaintainLayout/>,
        tip: "支援機構維護"
    },
    {
        id: "RSIS-MA-01",
        path: "/SupportDivMaintainLayout",
        element: <SupportDivMaintainLayout/>,
        tip: "支援科別維護"
    },
    {
        id: "RSIS-MA-03",
        path: "/ContactPersonMaintainLayout",
        element: <ContactPersonMaintainLayout/>,
        tip: "承辦部門人員維護"
    },
    {
        id: "RSIS-MA-06",
        path: "/MailListMaintainLayout",
        element: <MailListMaintainLayout/>,
        tip: "Mail發送人員名單維護"
    },
    {
        id: "RSIS-MA-04",
        path: "/WardAgentMaintainLayout",
        element: <WardAgentMaintainLayout/>,
        tip: "病房代理人維護"
    },
    {
        id: "RSIS-MA-05",
        path: "/SpecialPermissionSettingLayout",
        element: <SpecialPermissionSettingLayout/>,
        tip: "特殊權限設定"
    },
    {
        id: "RSIS-MA-07",
        path: "/FunctionOperateMaintainLayout",
        element: <FunctionOperateMaintainLayout/>,
        tip: "功能操作維護"
    },
    {
        id: "RSIS-MA-08",
        path: "/BasicSetting",
        element: <BasicSetting/>,
        tip: "基本資料設定"
    },
    //居家護理
    {
        id: "HCIS-FN-01",
        path: "/homeAssessmentOperation",
        element: <HomeAssessmentOperations/>,
        tip: "居家評估作業＿收案清單"
    },
    {
        id: "HCIS-FN-02",
        path: "/homePotentialManagement",
        element: <HomePotentialManagement/>,
        tip: "居家潛在個案維護_收案清單"
    },
    {
        id: "HCIS-FN-09",
        path: "/homePotentialCase",
        element: <HomePotentialCase/>,
        tip: "居家潛在個案維護(S1)"
    },
    {
        id: "HCIS-QR-02",
        path: "/readmissionReport",
        element: <ReadmissionReport/>,
        tip: "再入院查詢報表"
    },
    {
        id: "HCIS-QR-03",
        path: "/emergencyUseReport",
        element: <EmergencyUseReport/>,
        tip: "急診使用查詢報表"
    },
    {
        id: "HCIS-QR-04",
        path: "/skinDamageReport",
        element: <SkinDamageReport/>,
        tip: "皮膚損傷匯出報表"
    },
    {
        id: "HCIS-QR-05",
        path: "/drugExpirationReport",
        element: <DrugExpirationReport/>,
        tip: "藥物到期查詢報表"
    },
    {
        id: "HCIS-QR-01",
        path: "/caseQueryReport",
        element: <CaseQueryReport/>,
        tip: "個案查詢報表_新收案"
    },
    {
        id: "HCIS-MO-01",
        path: "/homeHealthCareInfoAPI",
        element: <HomeHealthCareInfoAPI/>,
        tip: "居護個案基本資料API"
    },
    {
        id: "HCIS-MA-01",
        path: "/homeCareRecordMaintenance",
        element: <HomeCareRecordMaintenance/>,
        tip: "居家護理記錄項目維護"
    },
    {
        id: "HCIS-MA-02",
        path: "/caseCloseStatusMaintenance",
        element: <CaseCloseStatusMaintenance/>,
        tip: "居家個案已結案狀態維護"
    },
    //CRUD
    {
        id: "CRUD-SETTING-1",
        path: "/CRUDSettingPage",
        element: <CRUDSettingPage/>,
        tip: "程式清單"
    },
    //EMR
    {
        id: "EMR-MA-1",
        path: "/EmrMainLayout",
        element: <EmrMainV/>,
        tip: "門診醫囑"
    }
];

export default routes;
