import React, {useEffect, useState} from "react";
import {ApiErrorStatusEnum, arrayIsEmpty, objectIsEmpty, stringIsEmpty, time2String,} from "edah_utils/dist";
import {
    Dialog, DialogSizeEnums,
    DialogVariant,
    Select,
    AdvancedDataGrid,Divider
} from "edah-component/dist";
import {AlertTypeEnum, Checkbox, Tabs, TabsEnum} from "edah-component/dist/index";
import {cpoeQueryDittoOrders} from "../../../api/v1/Cpoe";
import useToast from "../../../hooks/useToast";
import {DATE_FORMAT} from "../../../constants/common";

/**
 *  CPOE開單彈出視窗
 *  @param closePopupButtonOnClick {Function} 關閉按鈕點擊事件
 *  @param onConfirm {Function} 確認按鈕點擊事件  --傳值{addedOrder=[],encIcdCmParamList=[],subjective='',objective='',previousRecord=''}
 *  @param show {Boolean} 是否顯示彈窗
 *  @param patientInfo {Object} 患者資訊
 *  @param doctorList {Array} 醫師清單
 *  @param divList {Array} 科別清單
 *  @return {JSX.Element} CPOE開單彈出視窗
 */
export const CpoePopup = ({
                               closePopupButtonOnClick,
                               show = false,
                               patientInfo,
                               doctorList,
                               divList,
                               onConfirm,
                           }) => {
    // 切換用藥記錄
    const [activeTab, setActiveTab] = useState('medRecord');
    // 切換 門/急/住
    const [activeEncounterType, setActiveEncounterType] = useState('OP');
    // 搜尋條件-科別
    const [divNo, setDivNo] = useState(patientInfo?.divNo); //'62'
    // 搜尋條件-醫師
    const [doctorNo, setDoctorNo] = useState(patientInfo?.doctorNo);//'2345'
    // toast
    const showToast = useToast()
    // 就醫資料
    const [medicalInformation, setMedicalInformation] = useState([])
    // 診斷記錄
    const [diagnosticRecords, setDiagnosticRecords] = useState([])
    // 醫囑清單
    const [dittoOrders, setDittoOrders] = useState([])
    // 是否勾選處方全選
    const [isAllOrder, setIsAllOrder] = useState(false)
    // 是否勾選"S"
    const [isSOrder, setIsSOrder] = useState(false)
    // 是否勾選"O"
    const [isOOrder, setIsOOrder] = useState(false)
    // 是否勾選ICD10
    const [isIcd10, setIsIcd10] = useState(false)
    // 選中某一筆就醫資訊
    const [selectedEncounterData, setSelectedEncounterData] = useState(null)
    // 選中的ditto
    const [selectedDittoOrderIds, setSelectedDittoOrderIds] = useState([])
    // 選中的ditto--藥品類(勾選)
    const [selectedMed, setSelectedMed] = useState([])
    // 選中的ditto--處置類(勾選)
    const [selectedDisPosal, setSelectedDisPosal] = useState([])
    // columns設置
    const orderColumns = [
        { field: 'orderName', headerName:'醫囑明細', width:300},
        { field: 'qty', headerName:'次量', width:100},
        { field: 'qtyUnit', headerName: '單位', width:100},
        { field: 'freqNo', headerName: '頻率', width:100},
        { field: 'bhpDivNo', headerName: '服用方法', width:100},
        { field: 'methodNo', headerName: '途徑', width:100},
        { field: 'day', headerName: '天數', width:100},
        { field: 'tqty', headerName: '總量', width:100 },
        { field: 'tqtyUnit', headerName: '單位', width:100 },
        { field: 'paidType', headerName: '自費', width:100 }
    ]



    /**
     * 取得門診急診住院 tab
     */
    const getEncounterTypeTab = ()=>{
        return [
            {id: 'OP',title: '門診'},{id: 'ED',title: '急診'},{id: 'IP',title: '住院'}
        ]
    }

    /**
     * 取得Tab內容
     * @return {Object[]}
     */
    const getTabContent = () => {
        return [
            {
                id: 'medRecord',
                title: '用藥記錄',
                content: (
                    <div className="w-full max-h-[560px] flex flex-row justify-start items-start gap-4 overflow-auto">
                        <div className="w-[232px] pt-2">
                            {/*搜尋條件-門急住切換*/}
                            <Tabs
                                variant={TabsEnum.WORKSPACE_TAB}
                                activeTabId={activeEncounterType}
                                items={getEncounterTypeTab()}
                                onTabChange={(tab)=>setActiveEncounterType(tab)}
                            />
                            {/*搜尋條件-科別，醫師下拉*/}
                            <div className="flex flex-row gap-2 mt-4">
                                <Select
                                    data={{
                                        label: "科別",
                                        options:
                                            !arrayIsEmpty(divList) ? divList?.map((item) => ({
                                                label: item.name,
                                                value: item.no,
                                            })) : [],
                                    }}
                                    value={divNo}
                                    onChange={(value)=>setDivNo(value)}
                                    notched={true}
                                    displayEmpty = {true}
                                />
                                <Select
                                    data={{
                                        label: "醫師",
                                        options:
                                            !arrayIsEmpty(doctorList) ? doctorList?.map((item) => ({
                                                label: item.name,
                                                value: item.no,
                                            })) : [],
                                    }}
                                    value={doctorNo}
                                    onChange={(value)=>setDoctorNo(value)}
                                    notched={true}
                                    displayEmpty = {true}
                                />
                            </div>
                            {/*就醫資訊*/}
                            <ul className="w-[100%]">
                                <li className="text-[18px] text-[#000000] font-bold py-1 px-2">就醫資訊</li>
                                {
                                    !arrayIsEmpty(medicalInformation) && medicalInformation.map((item)=> (
                                        <>
                                            <li className="cursor-pointer py-1 px-2"
                                                style={{background: (selectedEncounterData?.encounterId === item.encounterId)?
                                                        'rgba(49, 130, 206, 0.1)':'#FFFFFF'}}
                                                onClick={()=>handleSelectEncounterData(item)}>
                                                <div className="gap-2">
                                                    <span className="inline-block mr-2">{time2String(item?.encounterDate, DATE_FORMAT)}</span>
                                                    <span className="inline-block mr-2">{item?.divName || ''}</span>
                                                    <span className="inline-block mr-2">{item?.doctorName || ''}</span>
                                                </div>
                                                {/*該次就醫詳情,最多顯示3筆icd10*/}
                                                <div className="text-[#71717A] text-[14px] line-clamp-3">
                                                    {item?.encIcdCmParamList.map(icdItem => (<p>{`${icdItem?.icdCmCode} ${icdItem?.icd10NameEn}`}</p>))}
                                                </div>
                                            </li>
                                            <Divider/>
                                        </>
                                    ))
                                }
                            </ul>
                        </div>
                        <div className="w-[calc(100%-232px)] overflow-auto">
                            {/*checkbox選擇類別勾選醫囑*/}
                            <div className="flex flex-row gap-2">
                                <Checkbox label="處方全選" checked={isAllOrder} onChange={(e)=>{
                                    // 同步更新畫面狀態
                                    setIsAllOrder(e.target.checked)
                                    setIsOOrder(e.target.checked)
                                    setIsSOrder(e.target.checked)
                                    setIsIcd10(e.target.checked)
                                    setSelectedDittoOrderIds(e.target.checked ? dittoOrders.map(item => item.orderId):[])
                                }} />
                                <Checkbox label="S" checked={isSOrder} onChange={(e)=>setIsSOrder(e.target.checked)}/>
                                <Checkbox label="O" checked={isOOrder} onChange={(e)=>setIsOOrder(e.target.checked)}/>
                                <Checkbox label="ICD10" checked={isIcd10} onChange={(e)=>setIsIcd10(e.target.checked)}/>
                                <Checkbox label="藥品" checked={selectedMed.length >0}
                                          indeterminate={selectedMed.length>0 && selectedMed.length !== dittoOrders.filter(order => order.priceType === '1').length}
                                          onChange={(e)=>{
                                    if(e.target.checked) {
                                        //勾選藥品 將畫面上藥品選擇 (priceType === '1'該匹配條件後續會變更)
                                        setSelectedDittoOrderIds(dittoOrders.filter(order => order.priceType === '1').map(item => item.orderId))
                                    }
                                    else {
                                        setSelectedDittoOrderIds(selectedDittoOrderIds.filter(item =>
                                            dittoOrders.filter(order => order.priceType !== '1')
                                                .map(item => item.orderId).includes(item)) )
                                    }
                                }}/>
                                <Checkbox label="處置" checked={selectedDisPosal.length >0}
                                          indeterminate={selectedDisPosal.length>0 && selectedDisPosal.length !== dittoOrders.filter(order => order.priceType === '2').length}
                                          onChange={(e)=>{
                                    //勾選藥品 將畫面上處置選擇 (priceType === '2'該匹配條件後續會變更)
                                    if(e.target.checked) {
                                        //勾選藥品 將畫面上藥品選擇 (priceType === '1'該匹配條件後續會變更)
                                        setSelectedDittoOrderIds(dittoOrders.filter(order => order.priceType === '2').map(item => item.orderId))
                                    }
                                    else {
                                        setSelectedDittoOrderIds(selectedDittoOrderIds.filter(item =>
                                            dittoOrders.filter(order => order.priceType !== '2')
                                                .map(item => item.orderId).includes(item)) )
                                    }
                                }} />
                            </div>
                            {/*SOAP部分*/}
                            <div className="flex flex-row gap-2 mb-4 h-[168px] text-[#3F3F46] overflow-auto">
                                {
                                    objectIsEmpty(selectedEncounterData) &&
                                    (<span className="text-[#71717A]">
                                        未選擇一筆紀錄
                                        </span>
                                    )
                                }
                                {
                                    !objectIsEmpty(selectedEncounterData) && (
                                        <div>
                                            <div>{`<*S*>`}</div>
                                            <div>{selectedEncounterData.ocisEncSummary?.subjective || ''}</div>
                                            <div>{`<*O*>`}</div>
                                            <div>{selectedEncounterData.ocisEncSummary?.objective || ''}</div>
                                            <div>{`<*P*>`}</div>
                                            <div>{selectedEncounterData.ocisEncSummary?.previousRecord || ''}</div>
                                            <div>{`<*ICD10*>`}</div>
                                            {!arrayIsEmpty(selectedEncounterData.encIcdCmParamList) &&
                                                selectedEncounterData.encIcdCmParamList.map(icdItem => (
                                                    <div>{`${icdItem?.icdCmCode} ${icdItem?.icd10NameEn || ''}`}</div>
                                                ))
                                            }
                                        </div>

                                    )
                                }
                            </div>
                            {/*可勾選醫囑清單*/}
                            <div className="border-collapse w-full  overflow-y-auto min-h-[100px] " >
                                <AdvancedDataGrid
                                    rows={dittoOrders}
                                    columns={orderColumns}
                                    checkboxSelection={true}
                                    disableRowSelectionOnClick={false}
                                    disableColumnMenu={true}
                                    disableColumnSorting={true}
                                    height={'400px'}
                                    width={'100%'}
                                    getRowHeight={(params) => 40}
                                    getRowId={(row) => row.orderId}
                                    onRowSelectionModelChange={handleSelectionModelChange}
                                    rowSelectionModel={selectedDittoOrderIds}
                                />
                            </div>
                        </div>
                    </div>
                )
            },
            {
                id: 'commonUse',
                title: '個人常用',
                content: (
                    <div className="h-[400px] flex flex-row justify-start items-start gap-2">

                    </div>
                )
            },
            {
                id: 'division',
                title: '分科',
                content: (
                    <div className="h-[400px] flex flex-row justify-start items-start gap-2">
                    </div>
                )
            },
            {
                id: 'hospital',
                title: '全院',
                content: (
                    <div className="h-[400px] flex flex-row justify-start items-start gap-2">

                    </div>
                )
            }
        ]
    }

    /**
     * 取得dialog內容
     * @return {JSX.Element}
     */
    const getContent = () => (
        <>
            <div className="filter flex flex-row items-center justify-start space-x-2">
                <Tabs
                    variant={TabsEnum.INDICATOR_TAB}
                    activeTabId={activeTab}
                    items={getTabContent()}
                    onTabChange={(tab)=>setActiveTab(tab)}
                />
            </div>

        </>
    );

    /**
     * 選擇該筆就醫資訊
     * @param item {Object}
     * @return {void}
     */
    const handleSelectEncounterData = (item)=>{
        setSelectedEncounterData(item)
        setDittoOrders(item?.encounterOrderDittoParamList)
    }

    const handleSelectionModelChange = (newSelectionModel)=>{
        setSelectedDittoOrderIds(newSelectionModel)
    }

    /**
     * 清空畫面操作資料 如選擇某筆就醫資訊等
     */
    const clearData = ()=>{
        setSelectedEncounterData(null)
        setSelectedDittoOrderIds([])
        setDittoOrders([])
        setIsSOrder(false)
        setIsAllOrder(false)
        setIsIcd10(false)
        setIsOOrder(false)
    }

    /**
     * 點擊確認按鈕，新增order/處方到cpoe主畫面與emr主畫面
     */
    const handleConfirmPopup = ()=>{
        if(selectedEncounterData){
            // 已選擇的order
            const addedOrder = selectedDittoOrderIds.map(orderId => {
                return dittoOrders.find(item => item.orderId === orderId)
            })
            // 已選擇的處方
            onConfirm({
                addedOrder,
                encIcdCmParamList: (isIcd10 && selectedEncounterData?.encIcdCmParamList) || [],
                subjective: (isSOrder && selectedEncounterData.ocisEncSummary?.subjective) || '',
                objective: (isOOrder && selectedEncounterData.ocisEncSummary?.subjective) || '',
                previousRecord: (isAllOrder && selectedEncounterData.ocisEncSummary?.previousRecord) || '',
            })
            // 保存后清空畫面資料
            clearData()
        }

    }

    /**
     * 查詢Ditto的就診歷史清單
     * query傳參中--startDate/endDate 可不傳
     * @return {void}
     */
    const queryDittoOrders = ()=>{
        clearData()
        cpoeQueryDittoOrders({
            encounterType: activeEncounterType,
            divNo: divNo,
            doctorNo: doctorNo,
            patientId: patientInfo.patientId,
            encounterDate: patientInfo.encounterDate, //傳入patient的就診日期避免查詢到當日資料
        }).then(res =>{
            if (res.err === ApiErrorStatusEnum.Success) {
                setMedicalInformation(res.data)
            } else {
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 監聽勾選的order變更
     */
    useEffect(()=>{
        if(selectedDittoOrderIds.length > 0){
            //篩選勾選的order為藥品類
            // 暫時使用(priceType:1藥品 2處置 3材料後續若數據結構有變更再調整)

            let medOrder =  selectedDittoOrderIds.filter(item => dittoOrders.filter(order=> order.priceType === '1').map(item => item.orderId).includes(item))
            setSelectedMed(medOrder)
            let disposalOrder =  selectedDittoOrderIds.filter(item => dittoOrders.filter(order=> order.priceType === '2').map(item => item.orderId).includes(item))
            setSelectedDisPosal(disposalOrder)
        }else{
            setSelectedMed([])
            setSelectedDisPosal([])
        }

    },[selectedDittoOrderIds])

    /**
     * 初始化query,以及監聽到patient，科別有變化時
     */
    useEffect(() => {
        queryDittoOrders()
    }, [patientInfo.patientId, divNo, doctorNo,activeEncounterType]);

    return (
        <Dialog
            open={show}
            variant={DialogVariant.CONFIRM}
            content={getContent()}
            paperStyleProps={{width: DialogSizeEnums.XL}}
            title={"開單"}
            onClose={closePopupButtonOnClick}
            onConfirm={handleConfirmPopup}
            muiDialogContentProps={{sx: {overflowY: 'hidden'}}}
        />
    )
};
