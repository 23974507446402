import {
    Button,
    ButtonColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
} from 'edah-component'
import { t } from 'i18next'
import useICCard from '../../hooks/useICCard'

/**
 * 實體健保卡讀取按鈕
 * @param {string} title 按鈕文字
 * @param {ButtonVariantEnum} buttonVariant 按鈕樣式
 * @param {ButtonColorEnum} buttonColor 按鈕顏色
 * @return {JSX.Element}
 */
const PhysicalICCard = ({
    title = t('ICCard.readICCard'),
    buttonVariant = ButtonVariantEnum.Outlined,
    buttonColor = ButtonColorEnum.Secondary,
}) => {
    const { readPhysicalICCard } = useICCard()

    return (
        <Button
            color={buttonColor}
            size={ButtonSizeEnum.Medium}
            variant={buttonVariant}
            text={title}
            onClick={readPhysicalICCard}
        />
    )
}

export default PhysicalICCard
