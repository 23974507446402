import {configureStore} from "@reduxjs/toolkit"
import loadingPageSlice from "./Slice/LoadingPageSlice"
import toastMessageSlice from "./Slice/ToastMessageSlice"
import printSlice from "./Slice/printSlice"
import tabSlice from "./Slice/TabSlice";
import sideBarToggleSlice from "./Slice/SideBarToggleSlice";

/**
 * This is the store of the application
 */
export default configureStore({
    reducer: {
        loadingPage: loadingPageSlice,
        toastMessage: toastMessageSlice,
        print: printSlice,
        tab: tabSlice,
        sidebarToggle: sideBarToggleSlice,
    }
})
