import React, {useEffect, useState} from "react";
import {
    regsEditClinicList,
    regsMonthClinicBaseData,
    regsQueryByDateRangeApnDocDiv,
    regsCheckBeforeSaveClinic,
    regsCheckForClinicDocOrLocumDuplicate,
} from "../../api/v1/RegsMonth";

import {t} from "i18next";
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    fuzzySearchObjects,
    objectIsEmpty,
    stringIsEmpty,
    time2String,
    RegsWeekReservationLimitEnum,
    RegsWeekReservationMethodEnum,
} from "edah_utils/dist";
import {
    AlertTypeEnum,
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Card,
    ColorEnum,
    DateRangePicker,
    RadioGroup,
    Search,
    SizeEnum,
    AdvancedDataGrid,
    Pagination,
    Dialog,
    Field,
    TextField,
    Select,
    Checkbox,
    DialogSizeEnums,
    DialogVariant,
    IconEnum,
    TextArea,
} from "edah-component/dist";
import dayjs from "dayjs";

//引入班表批次異動表單
import ScheduleBatchesUpdateForm from "../../components/Regs/ScheduleBatchesUpdate/ScheduleBatchesUpdateForm";

// 引入Lynx自定義TableList樣式
import "../../components/TableList/tableList.scss";
import useToast from "../../hooks/useToast";
import {SearchTextField} from "../../components/SearchTextField/SearchTextField";

/**
 * 班表批次異動
 * @return {JSX.Element}
 */
const ScheduleBatchesUpdate = () => {
    //Toast Message Hooks
    const showToast = useToast();
    // 預設建立時間起迄:起-今日往前兩天~迄-今日日期
    const today = new Date();
    //時段列表
    const apnList = [
        {justnameNo: "1,2,3", justnameName: "全部"},
        {justnameNo: "1", justnameName: "早"},
        {justnameNo: "2", justnameName: "午"},
        {justnameNo: "3", justnameName: "晚"},
    ];
    //預設的查詢輸入欄位資料
    const defaultQueryInputData = {
        //搜尋醫師文字
        doctorText: "",
        //搜尋科別文字
        divText: "",
        //建立時間起迄
        dateStart: time2String(today, "yyyy-MM-DD"),
        //終止時間迄
        dateEnd: time2String(today, "yyyy-MM-DD"),
        // 時段 : 0(全部), 1(早), 2(午), 3(晚)
        apn: apnList[0].justnameNo,
    };
    // 查詢輸入欄位的資料 (顯示)
    const [displayQueryInputData, setDisplayQueryInputData] = useState(defaultQueryInputData);
    // 查詢輸入欄位的資料 (查詢後)
    const [queryInputData, setQueryInputData] = useState(defaultQueryInputData);
    //班表批次異動參數初始值
    const [infoData, setInfoData] = useState(null);
    // 配置下拉選單
    const [allDept, setAllDept] = useState([]);
    const [allDoctor, setAllDoctor] = useState([]);
    // 醫師下拉選項
    const [doctorOptionList, setDoctorOptionList] = useState([]);
    // 科別下拉選項
    const [departmentOptionList, setDepartmentOptionList] = useState([]);

    const [isSearch, setIsSearch] = useState(false);
    // 被選取的班表查詢資料
    const [selectedSchedules, setSelectedSchedules] = useState([]);
    // 被選取的班表查詢資料
    const [selectedTableData, setSelectedTableData] = useState([]);
    // 班表查詢資料清單
    const [filteredDataList, setFilteredDataList] = useState(null);
    // 班表進階搜尋清單
    const [advSearchDataList, setAdvSearchDataList] = useState([]);
    //顯示選取清單的現行資料
    const [currentData, setCurrentData] = useState(null);
    //進階搜尋文字
    const [advancedSearchText, setAdvancedSearchText] = useState("");
    //all checked狀態
    const [checkedAll, setCheckedAll] = useState(false);
    // 停代診明細 pagination控制變數
    const [paginationProps, setPaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    });
    // 是否開啟現行資料Dialog
    const [openScheduleList, setOpenScheduleList] = useState(false);
    // 顯示醫師報備稽訊息Popup
    const [showDoctorInformDetectMessagePopup, setShowDoctorInformDetectMessagePopup] = useState(false);
    // 儲存醫師報備稽核錯誤訊息
    const [doctorInformDetectMessage, setDoctorInformDetectMessage] = useState([]);
    // 存取變更資料
    const [updateData, setUpdateData] = useState([]);
    // 儲存醫師同時段時否有其它診資料
    const [duplicateDoctorSlot, setDuplicateDoctorSlot] = useState([]);
    // 顯示醫師同時段Popup
    const [showDoctorDuplicateClinicPopup, setShowDoctorDuplicateClinicPopup] = useState(false);
    /**
     * 清單選中時
     * @param {String} id Key
     */
    const handleListOnSelect = (id) => {
        const fieldData = filteredDataList.find((item) => item.key === id);
        setCurrentData(fieldData);
    };

    // 新增處理選取變更的函數
    const handleSelectionModelChange = (newSelection) => {
        setSelectedSchedules(newSelection);
        if (newSelection.length > 0) {
            const key = newSelection[newSelection.length - 1];
            handleListOnSelect(key);
        }
    };

    /**
     * 模式改變時
     * @param {String} apn
     */
    const handleApnModeOnChange = (apn) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            apn: apn,
        });
    };

    /**
     * 透過時段id取得時段字串
     * @param {Number} apn
     * @returns
     */
    const parseApnToString = (apn) => {
        switch (apn) {
            case 1:
                return "早";
            case 2:
                return "午";
            case 3:
                return "晚";
        }
        return "";
    };

    /**
     * 點選查詢按鈕事件
     */
    const handleSearchSubmit = () => {
        if (displayQueryInputData.dateEnd && displayQueryInputData.dateStart > displayQueryInputData.dateEnd) {
            showToast({
                message: "起始日期不可大於結束日期",
                type: AlertTypeEnum.Error,
            });
            return;
        }

        setIsSearch(true);
        // 只需要清空 selectedSchedules
        setSelectedSchedules([]);

        //查詢第一頁
        searchSchedule(displayQueryInputData, 1, paginationProps.pageSize);
    };

    /**
     * 查詢班表
     */
    const searchSchedule = (queryInputObj, page, pageSize) => {
        //檢查起始日期是否大於結束日期
        if (displayQueryInputData.dateEnd && displayQueryInputData.dateStart > displayQueryInputData.dateEnd) {
            showToast({
                message: "起始日期不可大於結束日期",
                type: AlertTypeEnum.Error,
            });
            return;
        }
        //把暫存的查詢條件寫入到真實的查詢條件
        setQueryInputData(queryInputObj);
        setCurrentData(null);

        let divNo = "";
        let doctorNo = "";
        let splitArray = queryInputObj.divText ? queryInputObj.divText.split(" ") : [];
        if (queryInputObj.divText && splitArray.length === 2) {
            divNo = splitArray[0];
        }
        splitArray = queryInputObj.doctorText ? queryInputObj.doctorText.split(" ") : [];
        if (queryInputObj.doctorText && splitArray.length === 2) {
            doctorNo = splitArray[0];
        }

        regsQueryByDateRangeApnDocDiv({
            //開始日期
            startDate: time2String(queryInputObj.dateStart, "YYYY-MM-DD 00:00:00"),
            //結束日期
            endDate: time2String(queryInputObj.dateEnd, "YYYY-MM-DD 00:00:00"),
            //科別
            divNo: divNo,
            //醫師
            doctorNo: doctorNo,
            //時段list
            apnList: queryInputObj.apn,
            pageNum: page, //第幾頁
            pageSize: pageSize, //一頁幾筆資料
        }).then((res) => {
            //執行成功
            if (res.err === ApiErrorStatusEnum.Success) {
                //在資料中加上key值
                const dataWithKeys = res.data.dataList
                    ? res.data.dataList.map((item, index) => {
                        let doctorName = "";
                        let divName = "";
                        if (stringIsEmpty(item.doctorName)) {
                            const findedDoctor = allDoctor.find((doctor) => doctor.userNo === item.doctorNo);
                            doctorName = !objectIsEmpty(findedDoctor) ? findedDoctor.userName : "";
                        } else {
                            doctorName = item.doctorName;
                        }

                        if (stringIsEmpty(item.divName)) {
                            const findedDiv = allDept.find((div) => div.divNo === item.divNo);
                            divName = !objectIsEmpty(findedDiv) ? findedDiv.divName : "";
                        } else {
                            divName = item.divName;
                        }

                        return {
                            ...item,
                            key: index,
                            //response資料沒有這3個。透過id來查找
                            apnName: stringIsEmpty(item.apnName) ? parseApnToString(item.apn) : item.apnName,
                            doctorName: doctorName,
                            divName: divName,

                            //加上勾選狀態
                            checked: false,
                            //收費別名稱資訊
                            cashName: getCashTypeString(item.cashType),
                        };
                    })
                    : [];

                setFilteredDataList(dataWithKeys);

                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                    currentPage: page,
                    pageSize: pageSize,
                });
            } else {
            }
        });
    };

    /**
     * 取得進階搜尋清單
     */
    const getAdvanceSearchData = () => {
        const tmpArray = arrayIsEmpty(filteredDataList)
            ? []
            : filteredDataList.map((item) => ({
                //key
                key: item.key, //這個不要過濾，不在顯示欄位內
                checked: item.checked, //這個不要過濾，用來判斷勾選狀態

                //只copy要進階搜尋的欄位
                //門診日期
                encounterDate: time2String(item.encounterDate, "YYYY-MM-DD"),
                //時段
                apnName: item.apnName,
                //週別
                weekName: item.weekName,
                //科別
                divName: item.divName,
                //醫師
                doctorName: item.doctorName,
                //診間
                clinicNo: item.clinicNo,
                //診室名稱
                clinicName: item.clinicName,
            }));
        //開始模糊搜尋
        const findedData = fuzzySearchObjects(tmpArray, advancedSearchText, ["key", "checked"]);
        setAdvSearchDataList(findedData);

        if (!objectIsEmpty(currentData)) {
            //查找已被selected的資料是否在模糊搜尋結果的資料列內
            const haveSelected = arrayIsEmpty(findedData)
                ? false
                : findedData.some((item) => item.key === currentData.key);
            if (!haveSelected) {
                //不存在的話將selected設為null
                setCurrentData(null);
            }
        }

        //判斷是否全勾選
        const allChecked = arrayIsEmpty(findedData) ? false : findedData.every((item) => item.checked === true);
        setCheckedAll(allChecked);
    };

    /**
     * 搜尋條件改變時
     * @event event {Event}
     */
    const handleAdvancedSearchOnInput = (event) => {
        //搜尋文字存在
        if (event.target.value) {
            setAdvancedSearchText(event.target.value);
        } else {
            //搜尋文字不存在
            setAdvancedSearchText("");
        }
    };

    /**
     * 醫生輸入搜尋條件改變時
     * @event {Event} event
     */
    const handleDoctorSearchOnInput = (event) => {
        //取得值
        const value = event.target.value;
        // 設定醫師選項
        setDisplayQueryInputData({
            ...displayQueryInputData,
            doctorText: value,
        });
    };

    /**
     * 科別輸入搜尋條件改變時
     * @event {Event} event
     */
    const handleDepartmentSearchOnInput = (event) => {
        //取得值
        const value = event.target.value;
        // 設定科別選項
        setDisplayQueryInputData({
            ...displayQueryInputData,
            divText: value,
        });
    };

    /**
     * 處理醫師選項點擊事件
     * @param option {Object} 醫師
     * @return {void}
     */
    const handleDoctorOptionOnClick = (option) => {
        // 醫師不為空
        if (option !== null) {
            // 醫師編號
            const no = option.userNo;
            // 醫師名稱
            const name = option.userName;
            // 設定醫師選項
            setDisplayQueryInputData({
                ...displayQueryInputData,
                doctorText: `${no} ${name}`,
            });
        }
    };

    /**
     * 處理科別選項點擊事件
     * @param option {Object} 科別
     * @return {void}
     */
    const handleDivptionOnClick = (option) => {
        // 科別不為空
        if (option !== null) {
            // 科別編號
            const no = option.divNo;
            // 科別名稱
            const name = option.divName;
            // 設定科別選項
            setDisplayQueryInputData({
                ...displayQueryInputData,
                divText: `${no} ${name}`,
            });
        }
    };

    /**
     * 醫師報備稽核Popup關閉事件
     * @param {void}
     * @return {void}
     */
    const handleDoctorInformDetectMessagePopupOnClose = () => {
        setShowDoctorInformDetectMessagePopup(false);
    };

    const handleDuplicateDoctorMessagePopupOnClose = () => {
        setShowDoctorDuplicateClinicPopup(false);
    };

    /**
     * 儲存檔案
     * @return {void}
     */
    const onSaveFile = () => {
        regsEditClinicList(updateData).then((res) => {
            //執行成功
            if (res.err === ApiErrorStatusEnum.Success) {
                //儲存成功
                showToast({message: "存檔成功", type: AlertTypeEnum.Success});

                // 清空選取資料
                setSelectedSchedules([]);
                setCheckedAll(false);

                //更新
                searchSchedule(queryInputData, 1, paginationProps.pageSize);
            } else {
                //儲存失敗
                const msg = `存檔失敗: ${res.msg}`;
                showToast({message: msg, type: AlertTypeEnum.Error});
            }
        });
    };

    /**
     * 確認醫師報備稽核Popup事件
     */
    const handleDuplicateDoctorMessagePopupOnSave = () => {
        onSaveFile();
        setShowDoctorDuplicateClinicPopup(false);
        setDuplicateDoctorSlot([]);
    };

    /**
     * 確認醫師報備稽核Popup事件
     * @param {void}
     * @return {void}
     */
    const handleDoctorInformDetectMessagePopupOnConfirm = () => {
        // 確認醫師同時段是否有重複診間
        doCheckDoctorDuplicateClinic(updateData);

        // // 檢查是否有重複診間資料
        // const hasDuplicateData = duplicateDoctorSlot.some((slot) => slot.data && slot.data !== "");

        // if (hasDuplicateData) {
        //     setShowDoctorDuplicateClinicPopup(true); // 顯示醫師重複詢問視窗
        // } else {
        //     onSaveFile();
        // }

        setShowDoctorInformDetectMessagePopup(false);
    };

    /**
     * 現行資料Dialog開啟事件
     * @param {void}
     * @return {void}
     */
    const handleScheduleListOpen = () => {
        setOpenScheduleList((prev) => !prev);
    };

    /**
     * 日期範圍變更事件
     * @param {Array} newValue 日期範圍 [開始日期, 結束日期]
     */
    const handleDateRangeOnChange = (newValue) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            dateStart: newValue[0] ? dayjs(newValue[0]).format("YYYY-MM-DD") : null,
            dateEnd: newValue[1] ? dayjs(newValue[1]).format("YYYY-MM-DD") : null,
        });
    };

    /**
     * 勾選全部的Checkbox 變更事件
     */
    const handleBatchedUpdateSelectAll = () => {
        const isChecked = !checkedAll;
        setCheckedAll(isChecked);

        const updateData = filteredDataList.map((item) => {
            // 在advSearchDataList中查找匹配的 key
            const matchedItem = advSearchDataList.find((advItem) => advItem.key === item.key);
            // 如果找到匹配的 key，則更新 checked
            if (matchedItem) {
                return {...item, checked: isChecked};
            }
            return item; // 否則保持原樣
        });
        setFilteredDataList(updateData);
    };

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     */
    const onPaginationPageOnChange = (page) => {
        // 清空選取資料
        setSelectedSchedules([]);
        setCheckedAll(false);

        // 搜尋頁碼對應的資料
        searchSchedule(queryInputData, page, paginationProps.pageSize);
    };

    /**
     * 月班前檢查
     * @param dataArray
     * @return {Promise<Awaited<unknown>[]>}
     */
    const doCheckDoctorInform = async (dataArray) => {
        const promises = dataArray.map((data) =>
            regsCheckBeforeSaveClinic([
                {
                    //診間key
                    clinicId: data.clinicId,
                    //就醫日期
                    encounterDate: data.encounterDate, // 修正：使用 encounterDate 而不是 date
                    //時段:1.早,2.午,3.晚
                    apn: data.apn, // 修正：直接使用 apn
                    //醫師代號
                    doctorNo: data.doctorNo, // 修正：使用 doctorNo 而不是 doctor
                    //診室名稱
                    clinicName: data.clinicName,
                    //診間(診室號)
                    clinicNo: data.clinicNo,
                    //科別代號
                    divNo: data.divNo, // 修正：使用 divNo 而不是 department
                    //預約最大看診人次
                    limit: data.limit,
                    //現場最大看診人次
                    onsiteLimit: data.onsiteLimit,
                    //看診時間(分/人)
                    slotMin: data.slotMin,
                    //給號方式:1.診號,2.時間
                    giveNumType: data.giveNumType,
                    //給號代碼
                    noType: data.noType,
                    //送病歷  Initial value: 0, 0.FALSE, 1.TRUE
                    sentChartFlag: data.sentChartFlag,
                    //預約限制:A.一般,B.限醫師,C.限醫師本人,D,限授權人員(含醫師本人)
                    reserveLimitType: data.reserveLimitType, // 修正：使用 reserveLimitType 而不是 reservationLimit
                    //診間類別  O:門診,T:外檢,P,預防保健
                    clinicType: data.clinicType,
                    //診間收費別
                    cashType: data.cashType,
                    //開放網掛/APP ,Initial value: 0 , 0.FALSE , 1.TRUE
                    webapptFlag: data.webapptFlag,
                    //網掛/App取消看診進度 Initial value: 0 , 0.FALSE , 1.TRUE
                    showProcessFlag: data.showProcessFlag,
                    //網掛預約期限(月)
                    webapptMonth: data.webapptMonth,
                    //週班注意事項
                    weekNotice: data.weekNotice,
                    //看診項目
                    clinicMarkNo: data.clinicMarkNo,
                    //假日展班, 0.FALSE , 1.TRUE
                    holidayexclFlag: data.holidayexclFlag,
                    //代班醫師
                    locumDoctor: data.locumDoctor,
                    //停診
                    stopClinicFlag: data.stopClinicFlag,
                    //停診原因
                    stopClinicReason: data.stopClinicReason,
                    //停代診公告
                    stopClinicBulletinFlag: data.stopClinicBulletinFlag,
                    //診間標語
                    clinicSlogan: data.clinicSlogan,
                    //診室地點
                    clinicLocation: data.clinicLocation,
                    //周班key，照來源傳入
                    weekId: data.weekId,
                    // 建立時間
                    createDatetime: data.createDatetime,
                    // 建立者
                    createUser: data.createUser,
                    // 修改時間
                    modifyDatetime: data.modifyDatetime,
                    // 修改者
                    modifyUser: data.modifyUser,
                    // lockVersion
                    lockVersion: data.lockVersion,
                },
            ])
        );

        const result = await Promise.all(promises);
        return result;
    };

    /**檢查是否有重複診間資料
     * @param {Array} dataArray
     * @return {Promise<void>}
     */
    const doCheckDoctorDuplicateClinic = async (dataArray) => {
        const promises = dataArray.map((data) => {
            return regsCheckForClinicDocOrLocumDuplicate({
                encounterDate: data.encounterDate,
                apn: data.apn,
                doctorNo: data.locumDoctor,
            });
        });

        const result = await Promise.all(promises);

        // 是否有重複
        const hasDuplicateData = result.some((slot) => slot.data && slot.data !== "");

        if (hasDuplicateData) {
            setDuplicateDoctorSlot(result);
            setShowDoctorDuplicateClinicPopup(true); // 顯示醫師重複詢問視窗
        } else {
            onSaveFile();
        }
    };

    /**
     * 儲存批次更新事件
     * @param {Object} scheduleInfo 要儲存的班表資訊
     */
    const handleSaveBatchUpdate = async (scheduleInfo) => {
        const update = selectedTableData.map((item) => {
            const selectedData = filteredDataList.find((data) => data.key === item.key);

            return {
                //診間key
                clinicId: selectedData.clinicId,
                //就醫日期
                encounterDate: selectedData.encounterDate,
                //時段:1.早,2.午,3.晚
                apn: selectedData.apn,
                //醫師代號
                doctorNo: selectedData.doctorNo,
                //診室名稱
                clinicName: selectedData.clinicName,
                //診間(診室號)
                clinicNo: selectedData.clinicNo,
                //科別代號
                divNo: selectedData.divNo,
                //預約最大看診人次
                limit: scheduleInfo.limit !== null ? scheduleInfo.limit : selectedData.limit,
                //現場最大看診人次
                onsiteLimit:
                    scheduleInfo.onsiteLimit !== null ? parseInt(scheduleInfo.onsiteLimit) : selectedData.onsiteLimit,
                //看診時間(分/人)
                slotMin: scheduleInfo.slotMin !== null ? scheduleInfo.slotMin : selectedData.slotMin,
                //預約方式:1.診號,2.時間
                giveNumType: scheduleInfo.giveNumType !== null ? scheduleInfo.giveNumType : selectedData.giveNumType,
                //給號代碼
                noType: selectedData.noType,
                //送病歷  Initial value: 0, 0.FALSE, 1.TRUE
                sentChartFlag: selectedData.sentChartFlag,
                //預約限制:A.一般,B.限醫師,C.限醫師本人,D,限授權人員(含醫師本人)
                reserveLimitType:
                    scheduleInfo.reserveLimitType !== null
                        ? scheduleInfo.reserveLimitType
                        : selectedData.reserveLimitType,
                //診間類別  O:門診,T:外檢,P,預防保健
                clinicType: selectedData.clinicType,
                ////診間收費別
                cashType: scheduleInfo.cashType !== null ? scheduleInfo.cashType : selectedData.cashType,
                ////開放網掛/APP ,Initial value: 0 , 0.FALSE , 1.TRUE
                webapptFlag: scheduleInfo.webapptFlag !== null ? scheduleInfo.webapptFlag : selectedData.webapptFlag,
                //網掛/App取消看診進度 Initial value: 0 , 0.FALSE , 1.TRUE
                showProcessFlag:
                    scheduleInfo.showProcessFlag !== null ? scheduleInfo.showProcessFlag : selectedData.showProcessFlag,
                //網掛預約期限(天)
                webapptMonth:
                    scheduleInfo.webapptMonth !== null ? scheduleInfo.webapptMonth : selectedData.webapptMonth,
                //週班注意事項
                weekNotice: scheduleInfo.weekNotice !== null ? scheduleInfo.weekNotice : selectedData.weekNotice,
                //看診項目
                clinicMarkNo: selectedData.clinicMarkNo,
                //假日展班, 0.FALSE , 1.TRUE
                holidayexclFlag: selectedData.holidayexclFlag,
                //代班醫師
                locumDoctor: scheduleInfo.locumDoctor !== null ? scheduleInfo.locumDoctor : selectedData.locumDoctor,
                //停診
                stopClinicFlag:
                    scheduleInfo.stopClinicFlag !== null ? scheduleInfo.stopClinicFlag : selectedData.stopClinicFlag,
                //停診原因
                stopClinicReason:
                    scheduleInfo.stopClinicReason !== null
                        ? scheduleInfo.stopClinicReason
                        : selectedData.stopClinicReason,
                // 停診原因說明
                stopClinicDesc:
                    scheduleInfo.stopClinicDesc !== null ? scheduleInfo.stopClinicDesc : selectedData.stopClinicDesc,
                //停代診公告
                stopClinicBulletinFlag:
                    scheduleInfo.stopClinicBulletinFlag !== null
                        ? scheduleInfo.stopClinicBulletinFlag
                        : selectedData.stopClinicBulletinFlag,
                //診間標語
                clinicSlogan:
                    scheduleInfo.clinicSlogan !== null ? scheduleInfo.clinicSlogan : selectedData.clinicSlogan,
                //nurse1-7無用，傳null
                nurse1: selectedData.nurse1,
                nurse2: selectedData.nurse2,
                nurse3: selectedData.nurse3,
                nurse4: selectedData.nurse4,
                nurse5: selectedData.nurse5,
                nurse6: selectedData.nurse6,
                nurse7: selectedData.nurse7,
                //診室地點
                clinicLocation:
                    scheduleInfo.clinicLocation !== null ? scheduleInfo.clinicLocation : selectedData.clinicLocation,
                createDatetime: selectedData.createDatetime,
                createUser: selectedData.createUser,
                modifyDatetime: null,
                modifyUser: null,
                lockVersion: selectedData.lockVersion,
                weekId: selectedData.weekId,
            };
        });

        const detectInformRet = await doCheckDoctorInform(update);
        if (detectInformRet === null) {
            showToast({
                type: AlertTypeEnum.Error,
                message: "醫師報備稽核錯誤",
            });
            return;
        }

        // 顯示錯誤訊息
        const someError = detectInformRet.some((item) => item.err === ApiErrorStatusEnum.Error);
        if (someError) {
            showToast({
                type: AlertTypeEnum.Error,
                message: detectInformRet.map((item) => item.msg).join(","),
            });
        }

        // 顯示詢問dialog
        const allSuccess = detectInformRet.every(
            (item) => item.err === ApiErrorStatusEnum.Success && !stringIsEmpty(item.data)
        );
        if (allSuccess) {
            setDoctorInformDetectMessage(detectInformRet.map((item) => item.data));
            setShowDoctorInformDetectMessagePopup(true);
            setUpdateData(update);
            return;
        }
    };

    /**
     * 檢查資料是否被選擇
     * @param {Object} data
     * @returns
     */
    const checkDataIsSelected = (data) => {
        if (objectIsEmpty(currentData)) {
            return false;
        }
        if (currentData.key === data.key) {
            return true;
        }
        return false;
    };

    /**
     * 取得科別選項列表
     * @return {Array}
     */
    const getDepartmentOptionList = () => {
        const searchText = displayQueryInputData?.divText || "";
        //搜尋文字為空時
        if (searchText === "") {
            return allDept;
        } else {
            return allDept?.filter((div) => {
                //是否有空白
                const hasSpace = searchText?.indexOf(" ") >= 0;
                return hasSpace
                    ? div.divNo.includes(searchText.split(" ")[0]) && div.divName.includes(searchText?.split(" ")[1])
                    : //無空白時
                    div.divNo.includes(searchText) || div.divName.includes(searchText);
            });
        }
    };

    /**
     * 取得醫師選項列表
     * @return {Array}
     */
    const getDoctorOptionList = () => {
        const searchText = displayQueryInputData?.doctorText || "";
        //搜尋文字為空時
        if (searchText === "") {
            return allDoctor;
        } else {
            return allDoctor.filter((doctor) => {
                //是否有空白
                const hasSpace = searchText.indexOf(" ") >= 0;
                return hasSpace
                    ? doctor.userNo.includes(searchText.split(" ")[0]) &&
                    doctor.userName.includes(searchText.split(" ")[1])
                    : //無空白時
                    doctor.userNo.includes(searchText) || doctor.userName.includes(searchText);
            });
        }
    };
    /**
     * 取得表格醫師欄位內容
     * @param scheduleData {Object} 班表資料
     * @returns {*|string}
     */
    const getTableDoctorFieldContent = (scheduleData) => {
        if (arrayIsEmpty(filteredDataList)) {
            return "";
        }
        const fundedData = filteredDataList.find((item) => item.key === scheduleData.key);
        if (objectIsEmpty(fundedData)) {
            return "";
        }
        let doctorFieldContent = "";
        if (!stringIsEmpty(fundedData.locumDoctor)) {
            //代班醫生號碼有值
            const locumDoctor = allDoctor.find((doctor) => doctor.userNo === fundedData.locumDoctor);
            doctorFieldContent = `${locumDoctor.userName}[代]`;
        } else {
            doctorFieldContent = scheduleData.doctorName;
            if (fundedData.stopClinicFlag !== null && fundedData.stopClinicFlag)
                doctorFieldContent = (
                    <>
                        {doctorFieldContent}
                        <span style={{color: "#C53030"}}>[停]</span>
                    </>
                );
        }
        return doctorFieldContent;
    };

    /**
     * 取得收費別字串
     * @param {String} type 收費別
     */
    const getCashTypeString = (type) => {
        const findedCashType = infoData.cashTypeList.find((item) => item.cashType === type);
        return objectIsEmpty(findedCashType) ? "" : findedCashType.cashName;
    };

    /**
     * 表格清單
     */
    const header = [
        {
            field: "singleDataView",
            headerName: "",
            minWidth: 90,
            renderCell: (params) => (
                <Button
                    icon={IconEnum.View}
                    variant={ButtonVariantEnum.Text}
                    onClick={handleScheduleListOpen}
                    // disabled={objectIsEmpty(currentData)}
                >
                    {t("general.view")}
                </Button>
            ),
        },
        {
            field: "encounterDate",
            headerName: t("Regs.general.clinicDate"),
            flex: 1,
            minWidth: 120,
        },
        {
            field: "apnName",
            headerName: t("Regs.general.timeSlot"),
            flex: 1,
            minWidth: 90,
        },
        {
            field: "weekName",
            headerName: t("Regs.general.weekName"),
            flex: 1,
            minWidth: 90,
        },
        {
            field: "divName",
            headerName: t("general.department"),
            flex: 1,
            minWidth: 150,
        },
        {
            field: "doctorName",
            headerName: t("general.doctor"),
            flex: 1,
            minWidth: 120,
            renderCell: (params) => {
                return getTableDoctorFieldContent(params.row);
            },
        },
        {
            field: "clinicNo",
            headerName: t("Regs.general.clinic"),
            flex: 1,
            minWidth: 150,
        },
        {
            field: "clinicName",
            headerName: t("Regs.general.clinicName"),
            flex: 1,
            minWidth: 150,
        },
    ];

    //第一次執行時先取得到資料
    useEffect(() => {
        //取得週班中查詢的相關參數初始值
        regsMonthClinicBaseData({}).then((res) => {
            //執行成功
            if (res.err === ApiErrorStatusEnum.Success && infoData === null) {
                //取得資料
                const data = res.data;
                //設定資料
                setInfoData(data);
                //預設將科別設定在下拉選單
                setAllDept(data.divisionList);
                setAllDoctor(data?.doctorList || []);
                //預設將醫生給予ListCard選項
                // setListCardOptions(data.doctorList)
            }
        });
    }, []);

    /**
     * 監聽advancedSearchText&filteredDataList的變化
     */
    useEffect(() => {
        //取得進階查詢的資料

        if (filteredDataList !== null) {
            getAdvanceSearchData();
        } else {
            setCurrentData(null);
            setCheckedAll(false);
        }
    }, [advancedSearchText, filteredDataList]);

    /**
     * 監聽 advSearchDataList 和 selectedSchedules 變化，更新選取的資料
     */
    useEffect(() => {
        if (!arrayIsEmpty(advSearchDataList)) {
            // 根據 selectedSchedules 找出對應的完整資料
            const selectedData = advSearchDataList.filter((item) =>
                selectedSchedules.includes(item.key)
            );
            setSelectedTableData(selectedData);

            // 如果有選取的資料，更新當前顯示的資料
            if (selectedData.length > 0) {
                handleListOnSelect(selectedData[selectedData.length - 1].key);
            }
        } else {
            setSelectedTableData([]);
        }
    }, [advSearchDataList, selectedSchedules]);

    /**
     * 監聽醫師代碼輸入框變化
     */
    useEffect(() => {
        setDoctorOptionList(getDoctorOptionList());
    }, [displayQueryInputData.doctorText, allDoctor]);

    /**
     * 監聽科別代碼輸入框變化
     */
    useEffect(() => {
        setDepartmentOptionList(getDepartmentOptionList());
    }, [displayQueryInputData.divText, allDept]);

    return (
        <>
            <div className="w-full">
                {/*頂部區塊*/}
                <div className="flex flex-row flex-wrap justify-start items-center w-full gap-4 px-4 py-2">
                    {/*日期*/}
                    <DateRangePicker
                        required
                        value={[
                            displayQueryInputData.dateStart ? dayjs(displayQueryInputData.dateStart) : null,
                            displayQueryInputData.dateEnd ? dayjs(displayQueryInputData.dateEnd) : null,
                        ]}
                        onChange={handleDateRangeOnChange}
                    />
                    {/*醫師*/}
                    {
                        <Search
                            inputLabel={t("general.doctor")}
                            onChange={(_e, value) => {
                                handleDoctorOptionOnClick(value);
                            }}
                            onInputChange={(_e, newInputValue) =>
                                handleDoctorSearchOnInput({
                                    target: {value: newInputValue},
                                })
                            }
                            disablePortal={false}
                            freeSolo
                            clearOnBlur
                            options={doctorOptionList}
                            getOptionLabel={(option) => `${option?.userNo} ${option?.userName}`}
                        />
                    }
                    {/*科別*/}
                    {
                        <Search
                            inputLabel={t("general.department")}
                            onChange={(_e, value) => {
                                handleDivptionOnClick(value);
                            }}
                            onInputChange={(_e, newInputValue) => {
                                handleDepartmentSearchOnInput({
                                    target: {value: newInputValue},
                                });
                            }}
                            disablePortal={false}
                            freeSolo
                            clearOnBlur
                            options={departmentOptionList}
                            getOptionLabel={(option) => `${option?.divNo} ${option?.divName}`}
                        />
                    }
                    {/*時段*/}
                    <div className="flex flex-row justify-center items-center gap-[12px] text-base font-normal">
                        <RadioGroup
                            row
                            labelProps={{text: t("Regs.general.timeSlot")}}
                            size={SizeEnum.Medium}
                            defaultValue={displayQueryInputData.apn}
                            value={displayQueryInputData.apn}
                            optionProps={{
                                options: apnList.map((item) => ({
                                    label: item.justnameName,
                                    value: item.justnameNo,
                                })),
                            }}
                            onChange={(e) => handleApnModeOnChange(e.target.value)}
                        />
                    </div>
                    {/*查詢按鈕*/}
                    <Button
                        color={ColorEnum.Primary}
                        variant={ButtonVariantEnum.Contained}
                        size={ButtonSizeEnum.Medium}
                        onClick={handleSearchSubmit}
                    >
                        {t("general.query")}
                    </Button>
                </div>

                {/*班表清單內容區塊*/}
                {isSearch && (
                    <div className="flex flex-row justify-center w-full gap-[12px] px-4 py-2 ">
                        {/*班表清單內容*/}
                        <div className="gap-[12px] border bg-white rounded-md border-gray-300 w-1/2">
                            <Card title={`${t("Schedule.scheduleList")}`}>
                                {/*班表清單標題、進階搜尋、現行資料*/}
                                <div className="flex flex-wrap justify-start items-center w-full  pb-2 text-start">
                                    <SearchTextField
                                        placeholder={t("general.advancedSearch")}
                                        value={advancedSearchText}
                                        onChange={(e) => handleAdvancedSearchOnInput(e)}
                                    />
                                </div>
                                {currentData !== null && (
                                    <Dialog
                                        open={openScheduleList}
                                        onClose={handleScheduleListOpen}
                                        title={t("Schedule.scheduleCurrentData")}
                                        variant={DialogVariant.CUSTOM}
                                        paperStyleProps={{
                                            width: DialogSizeEnums.SM,
                                        }}
                                        content={
                                            <div className="space-y-2">
                                                {/* 預約最大看診人次 */}
                                                <Field
                                                    labelWidth={170}
                                                    label={t("Regs.general.maximumNumberOfAppointments")}
                                                >
                                                    <TextField value={currentData.limit} disabled={true}/>
                                                </Field>
                                                {/* 現場最大看診人次 */}
                                                <Field labelWidth={170} label={t("Regs.general.maximumOnsitePatients")}>
                                                    <TextField value={currentData.onsiteLimit} disabled={true}/>
                                                </Field>
                                                {/* 看診時間(分/人) */}
                                                <Field labelWidth={170} label={t("Regs.general.slotTime")}>
                                                    <TextField value={currentData.slotMin} disabled={true}/>
                                                </Field>
                                                {/* 預約方式 */}
                                                <Field labelWidth={170} label={t("Regs.general.reservationMethod")}>
                                                    <div
                                                        className="w-[260px] flex flex-row items-center pl-4 space-x-4">
                                                        <RadioGroup
                                                            row={true}
                                                            size={SizeEnum.Medium}
                                                            defaultValue={currentData.giveNumType}
                                                            value={currentData.giveNumType}
                                                            optionProps={{
                                                                options: [
                                                                    // 診號
                                                                    {
                                                                        label: t(
                                                                            `Regs.RegsWeekReservationMethodEnum.${RegsWeekReservationMethodEnum.ClinicNumber}`
                                                                        ),
                                                                        value: RegsWeekReservationMethodEnum.ClinicNumber.toString(),
                                                                        disabled:
                                                                            currentData.giveNumType !==
                                                                            RegsWeekReservationMethodEnum.ClinicNumber.toString(),
                                                                    },
                                                                    // 時間
                                                                    {
                                                                        label: t(
                                                                            `Regs.RegsWeekReservationMethodEnum.${RegsWeekReservationMethodEnum.Time}`
                                                                        ),
                                                                        value: RegsWeekReservationMethodEnum.Time.toString(),
                                                                        disabled:
                                                                            currentData.giveNumType !==
                                                                            RegsWeekReservationMethodEnum.Time.toString(),
                                                                    },
                                                                ],
                                                            }}
                                                            onChange={undefined}
                                                        />
                                                    </div>
                                                </Field>
                                                {/* 預約限制 */}
                                                <Field
                                                    labelWidth={170}
                                                    label={t("Regs.general.reservationRestriction")}
                                                >
                                                    <div
                                                        className="w-[350px] flex flex-row items-center pl-4 space-x-4">
                                                        <RadioGroup
                                                            row={true}
                                                            size={SizeEnum.Medium}
                                                            defaultValue={currentData.reserveLimitType}
                                                            value={currentData.reserveLimitType}
                                                            optionProps={{
                                                                options: [
                                                                    // 一般
                                                                    {
                                                                        label: t(
                                                                            `Regs.RegsWeekReservationLimitEnum.${RegsWeekReservationLimitEnum.Normal}`
                                                                        ),
                                                                        value: RegsWeekReservationLimitEnum.Normal,
                                                                        disabled:
                                                                            currentData.reserveLimitType !==
                                                                            RegsWeekReservationLimitEnum.Normal,
                                                                    },
                                                                    // 限醫師
                                                                    {
                                                                        label: t(
                                                                            `Regs.RegsWeekReservationLimitEnum.${RegsWeekReservationLimitEnum.DoctorOnly}`
                                                                        ),
                                                                        value: RegsWeekReservationLimitEnum.DoctorOnly,
                                                                        disabled:
                                                                            currentData.reserveLimitType !==
                                                                            RegsWeekReservationLimitEnum.DoctorOnly,
                                                                    },
                                                                    // 限醫生本人
                                                                    {
                                                                        label: t(
                                                                            `Regs.RegsWeekReservationLimitEnum.${RegsWeekReservationLimitEnum.DoctorSelfOnly}`
                                                                        ),
                                                                        value: RegsWeekReservationLimitEnum.DoctorSelfOnly,
                                                                        disabled:
                                                                            currentData.reserveLimitType !==
                                                                            RegsWeekReservationLimitEnum.DoctorSelfOnly,
                                                                    },
                                                                ],
                                                            }}
                                                            onChange={undefined}
                                                        />
                                                    </div>
                                                </Field>
                                                {/* 收費別 */}
                                                <Field labelWidth={170} label={t("Regs.general.cashType")}>
                                                    <Select
                                                        data={{
                                                            label: "收費別",
                                                            options: [
                                                                {
                                                                    value: currentData.cashType,
                                                                    label: currentData.cashName,
                                                                },
                                                            ],
                                                        }}
                                                        value={currentData.cashType || ""}
                                                        disabled
                                                        showLabel={false}
                                                        readOnly={true}
                                                    />
                                                </Field>
                                                {/* 調病歷 */}
                                                <Field labelWidth={170} isShowColon={false}>
                                                    <Checkbox
                                                        label={t("Regs.general.reviewMedicalRecords")}
                                                        checked={currentData.sentChartFlag}
                                                        onChange={() => {
                                                        }}
                                                    />
                                                </Field>
                                                {/* 網掛/APP */}
                                                <Field labelWidth={170} isShowColon={false}>
                                                    <Checkbox
                                                        label={t("Regs.general.webAppointment")}
                                                        checked={currentData.webapptFlag}
                                                        onChange={() => {
                                                        }}
                                                    />
                                                </Field>
                                                {/* 網掛/APP取消看診進度 */}
                                                <Field labelWidth={170} isShowColon={false}>
                                                    <Checkbox
                                                        label={t("Regs.general.webAppointmentCancelProcess")}
                                                        checked={currentData.showProcessFlag}
                                                        onChange={() => {
                                                        }}
                                                    />
                                                </Field>
                                                {/* 網掛預約期限 */}
                                                <Field labelWidth={170} label={t("Regs.general.webAppointmentLimit")}>
                                                    <div className="flex items-center space-x-2">
                                                        <TextField
                                                            size={SizeEnum.Small}
                                                            inputWidth={SizeEnum.Small}
                                                            type="number"
                                                            value={currentData.webapptMonth}
                                                            disabled={true}
                                                        />
                                                        <span>{t("general.dateTime.month")}</span>
                                                    </div>
                                                </Field>
                                                {/* 診室地點 */}
                                                <Field labelWidth={170} label={t("Regs.general.clinicLocation")}>
                                                    <TextField
                                                        size={SizeEnum.Small}
                                                        inputWidth={SizeEnum.Large}
                                                        value={currentData.clinicLocation}
                                                        disabled={true}
                                                    />
                                                </Field>
                                                {/* 注意事項 */}
                                                <Field
                                                    sx={{display: "flex", alignItems: "start"}}
                                                    labelWidth={170}
                                                    label={t("Regs.general.notice")}
                                                >
                                                    <TextArea
                                                        value={currentData.weekNotice ? currentData.weekNotice : ""}
                                                        disabled
                                                    />
                                                </Field>
                                                {/* 代診醫生 */}
                                                <Field labelWidth={170} label={t("Regs.general.locumDoctor")}>
                                                    <TextField
                                                        value={allDoctor.find((doctor) => doctor.userNo === currentData.locumDoctor)?.userName || ''}
                                                    disabled={true}
                                                    />
                                                </Field>
                                                {/* 停診原因 */}
                                                <Field
                                                    labelWidth={170}
                                                    label={
                                                        <Checkbox
                                                            label={t("Regs.general.stopClinicReason")}
                                                            checked={
                                                                currentData.stopClinicFlag
                                                                    ? currentData.stopClinicFlag
                                                                    : false
                                                            }
                                                            onChange={() => {
                                                            }}
                                                        />
                                                    }
                                                >
                                                    <Select
                                                        data={{
                                                            label: "停診原因",
                                                            options: [
                                                                {
                                                                    value: currentData.stopClinicReason,
                                                                    label: currentData.stopClinicReasonText,
                                                                },
                                                            ],
                                                        }}
                                                        disabled={true}
                                                        value={currentData.stopClinicReason || ""}
                                                        showLabel={false}
                                                        readOnly={true}
                                                    />
                                                </Field>
                                                <Field labelWidth={170} isShowColon={false}>
                                                    <Checkbox
                                                        label={t("Regs.general.stopClinicBulletinForTheDay")}
                                                        checked={currentData.stopClinicBulletinFlag}
                                                        onChange={() => {
                                                        }}
                                                    />
                                                </Field>
                                                {/* 停診原因說明 */}
                                                <Field
                                                    sx={{display: "flex", alignItems: "start"}}
                                                    labelWidth={170}
                                                    label={t("Regs.general.stopClinicDesc")}
                                                >
                                                    <TextField
                                                        value={currentData.stopClinicDesc ? currentData.stopClinicDesc : ""}
                                                        disabled
                                                    />
                                                </Field>
                                            </div>
                                        }
                                    />
                                )}
                                <div className="w-full overflow-y-auto">
                                    <AdvancedDataGrid
                                        rows={advSearchDataList || []}
                                        columns={header}
                                        checkboxSelection={true}
                                        onRowSelectionModelChange={(newSelection) => {
                                            setSelectedSchedules(newSelection);
                                            if (newSelection.length > 0) {
                                                const key = newSelection[newSelection.length - 1];
                                                handleListOnSelect(key);
                                            }
                                        }}
                                        rowSelectionModel={selectedSchedules}
                                        disableColumnFilter
                                        disableColumnSelector
                                        disableColumnMenu
                                        disableDensitySelector
                                        disableRowSelectionOnClick={true}
                                        height={"100%"}
                                        getRowId={(row) => row.key}
                                        onRowClick={(info) => {
                                            handleListOnSelect(info.row.key);
                                        }}
                                        getRowHeight={(_params) => 52}
                                        autosizeOptions={{
                                            columns: ["encounterDate", "clinicNo", "clinicName"],
                                            includeOutliers: true,
                                            includeHeaders: true,
                                        }}
                                    />
                                </div>
                                <div className="flex justify-end w-full mt-2">
                                    <Pagination
                                        totalPageSize={paginationProps.totalPageSize}
                                        page={paginationProps.currentPage}
                                        pageSize={paginationProps.pageSize}
                                        totalSize={paginationProps.totalItemSize}
                                        onPageOnChange={(page, pageSize) => onPaginationPageOnChange(page, pageSize)}
                                        showFirstButton
                                        showLastButton
                                    />
                                </div>
                            </Card>
                        </div>
                        {/*更新清單表格內文*/}
                        <ScheduleBatchesUpdateForm
                            previewData={currentData}
                            baseData={infoData}
                            selectableData={selectedTableData}
                            onSave={handleSaveBatchUpdate}
                        />
                    </div>
                )}
            </div>
            {/* 醫師重複詢問 */}
            {showDoctorDuplicateClinicPopup && (
                <Dialog
                    open={showDoctorDuplicateClinicPopup}
                    title={"提醒"}
                    content={duplicateDoctorSlot[0].data}
                    variant={DialogVariant.DECISION}
                    paperStyleProps={{width: DialogSizeEnums.SM}}
                    onClose={handleDuplicateDoctorMessagePopupOnClose}
                    onConfirm={handleDuplicateDoctorMessagePopupOnSave}
                    muiDialogContentProps={{
                        sx: {padding: "4rem 1.5rem 4rem 1.5rem"},
                    }}
                />
            )}
            {/* 醫師報備詢問 */}
            {showDoctorInformDetectMessagePopup && (
                <Dialog
                    open={showDoctorInformDetectMessagePopup}
                    title={"提醒"}
                    content={
                        <div>
                            {doctorInformDetectMessage.map((message, index) => (
                                <p key={index} style={{marginBottom: "0.5rem"}}>
                                    {message}
                                </p>
                            ))}
                        </div>
                    }
                    variant={DialogVariant.DECISION}
                    paperStyleProps={{width: DialogSizeEnums.SM}}
                    onClose={handleDoctorInformDetectMessagePopupOnClose}
                    onConfirm={handleDoctorInformDetectMessagePopupOnConfirm}
                    muiDialogContentProps={{
                        sx: {padding: "4rem 1.5rem 4rem 1.5rem"},
                    }}
                />
            )}
        </>
    );
};

export default ScheduleBatchesUpdate;
