import SimpleCRUD from "../../components/CRUD/SimpleCRUD";
import React from "react";
import {TitleCard} from "edah-component";
/**
 *  CURD Setting Page
 *  @return {JSX.Element}
 */
const CRUDSettingPage = () => {
    // columns
    const aColumns = [
        { field: 'userNo', headerName: 'userNo', width: 200},
        { field: 'userName', headerName: '用戶名', width: 200},
        {field: 'userEmail', headerName: 'email', width: 200},
        { field: 'password', headerName: '密碼', width:80},
        {field: 'userMobile', headerName: '手機號碼', width: 200},
        {field: 'userActiveDate', headerName: 'userActiveDate', width: 200, editType:'date'},
        {field: 'userDeactiveDate', headerName: 'userDeactiveDate', width: 200,editType:'date'},
        {field: 'workPhoneCode', headerName: 'workPhoneCode', width: 200},
        {field: 'medicalStaff', headerName: 'medicalStaff-Search', width: 200, editType: 'select',
            valueOptions:[
                {value: '01',label: '01'},{value: '02',label: '02'},{value: '03',label: '03'}
            ]
        }
    ]
    const bColumns = [
        { field: 'userNo', headerName: 'userNo', width: 200},
        { field: 'userName', headerName: '用戶名', width: 200},
        {field: 'userEmail', headerName: 'email', width: 200},
        { field: 'password', headerName: '密碼', width:80},
        {field: 'userMobile', headerName: '手機號碼', width: 200},
        {field: 'userActiveDate', headerName: 'userActiveDate', width: 200, editType:'date'},
        {field: 'userDeactiveDate', headerName: 'userDeactiveDate', width: 200,editType:'date'},
        {field: 'workPhoneCode', headerName: 'workPhoneCode', width: 200},
        {field: 'medicalStaff', headerName: 'medicalStaff-Search', width: 200, editType: 'search',
            valueOptions:[
                {value: '01',label: '01'},{value: '02',label: '02'},{value: '03',label: '03'}
            ]
        },
    ]
    const cColumns = [
        { field: 'userNo', headerName: 'userNo', width: 200},
        { field: 'userName', headerName: '用戶名', width: 200},
        {field: 'userEmail', headerName: 'email', width: 200},
        { field: 'password', headerName: '密碼', width:80},
        {field: 'userMobile', headerName: '手機號碼', width: 200},
        {field: 'userActiveDate', headerName: 'userActiveDate', width: 200, editType:'date'},
        {field: 'userDeactiveDate', headerName: 'userDeactiveDate', width: 200,editType:'date'},
        {field: 'workPhoneCode', headerName: 'workPhoneCode', width: 200},
        {field: 'medicalStaff', headerName: 'medicalStaff-Search', width: 200, editType: 'radio',
            valueOptions:[
                {value: '01',label: '01'},{value: '02',label: '02'},{value: '03',label: '03'}
            ]
        },
    ]

    return (
        <div >
            <TitleCard title='基礎UserInfo' />
            <SimpleCRUD columns={aColumns} rowId="userNo" system="menu" tableName="UserInfo" />
            <TitleCard title='UserInfo--Search' />
            <SimpleCRUD columns={bColumns} rowId="userNo" system="menu" tableName="UserInfo" />
            <TitleCard title='UserInfo--radio' />
            <SimpleCRUD columns={cColumns} rowId="userNo" system="menu" tableName="UserInfo" />

        </div>

    )
}

export default CRUDSettingPage
