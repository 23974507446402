import React, {useEffect, useMemo, useState} from "react";
import InfoCard from "../../components/Appointment/InfoCard.jsx";
import CaseInformation from "../../components/Appointment/CaseInformation.jsx";
import MedicalInformation from "../../components/Appointment/MedicalInformation.jsx";
import AppointmentInfo from "../../components/Appointment/AppointmentInfo.jsx";
import {regsQueryBaseData, regsQueryMedicalInfo, regsQueryRegHistory,} from "../../api/v1/Regs";
import {
    addNewPatient,
    patientRegEditSave,
    queryPatientAndRegByIdNoAndBirthDate,
    queryPatientBySearch,
} from "../../api/v1/Mris.js";
import {queryAllDivision, queryAllDoctor} from "../../api/v1/Menu.js";
import {regsQueryFirstviewByField} from "../../api/v1/RegsFirstView.js";
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    objectIsEmpty,
    stringIsEmpty,
    time2String,
    TimeslotEnum
} from "edah_utils/dist";
import {PatientMedicalRecordsPopup} from "../../components/Appointment/Popup/PatientMedicalRecordsPopup";
import {t} from "i18next";
import {MultifactorSearchPopup} from "../../components/Appointment/Popup/MultifactorSearchPopup";
import FirstVisitPatientAppointmentListPopup
    from "../../components/Appointment/Popup/FirstVisitPatientAppointmentListPopup.jsx";
import PromptPopup from "../../components/Appointment/Popup/PromptPopup.jsx";
import FirstVisitPatientFormPopup from "../../components/Appointment/Popup/FirstVisitPatientFormPopup.jsx";
import "../../components/TableList/tableList.scss";
import CancelAppointmentMessagePopup from "../../components/Appointment/Popup/CancelAppointmentMessagePopup.jsx";
import VirtualHealthInsuranceCardPopup from "../../components/Appointment/Popup/VirtualHealthInsuranceCardPopup.jsx";
import IcCardViewPopup from "../../components/Appointment/Popup/IcCardViewPopup.jsx";
import NonFirstTimePatientEditPopup from "../../components/Appointment/Popup/NonFirstTimePatientEditPopup";
import {icHisGetRegisterBasic} from "../../api/v1/LocalService";
import useToast from "../../hooks/useToast";
import {
    AlertTypeEnum,
    Button,
    Divider,
    SizeEnum,
    TextField,
    Pagination,
    AdvancedDataGrid,
    Dialog,
    DialogVariant,
    DialogSizeEnums
} from "edah-component/dist";
import {SearchTextField} from "../../components/SearchTextField/SearchTextField";
import PatientInformation from "../../components/Appointment/PatientInformation";

/**
 * 掛號作業
 * @return {JSX.Element}
 */
const AppointmentLayout = () => {
    //病人資料
    const [patientData, setPatientData] = useState(null);
    // 看診資料
    const [appointmentData, setAppointmentData] = useState({
        //病歷ID
        patientId: !objectIsEmpty(patientData) ? patientData.patientId : null,
        //掛號別
        reserveType: "",
        //身份別
        ptType: "",
        //院區代碼
        zone: "",
        //門診日期
        encounterDate: time2String(new Date(), "yyyy-MM-DD"),
        //時段
        apn: "",
        //醫師代碼
        doctorNo: "",
        //科別代碼
        divNo: "",
        //診間號
        clinicNo: "",
        //診室名稱
        clinicName: "",
        //診室地點
        clinicLocation: "",
        //補輸
        fowardRegFlag: false,
        //電話預約
        phoneRegFlag: false,
        //月班識別碼
        clinicId: null,
        //給號方式
        giveNumType: "",
        //給號開始時間
        giveNumStartTime: null,
        //給號結束時間
        giveNumEndTime: null,
    });
    //基礎資料
    const [baseData, setBaseData] = useState(null);
    // 查詢文字 (送出給後端的文字)
    const [searchText, setSearchText] = useState("");
    // 查詢文字 (欄位渲染用)
    const [searchFieldValue, setSearchFieldValue] = useState("");
    // 健保IC卡資料
    const [icCardData, setIcCardData] = useState(null);
    // 是否顯示病人病歷資訊視窗
    const [showPatientMedicalRecordsPopup, setShowPatientMedicalRecordsPopup] =
        useState(false);
    // 是否顯示多因素搜尋視窗
    const [showMultifactorSearchPopup, setShowMultifactorSearchPopup] =
        useState(false);
    //病人醫療資訊字串
    const [patientMedicalInfoString, setPatientMedicalInfoString] =
        useState("");
    //是否顯示提示彈出框(找不到病人病歷)
    const [showPatientNotFoundPromptPopup, setShowPatientNotFoundPromptPopup] =
        useState(false);
    //是否顯示提示彈出框(初診病人且有預約)
    const [
        showFirstVistAppointmentPromptPopup,
        setShowFirstVistAppointmentPromptPopup,
    ] = useState(false);
    //是否顯示初診病人預約名單
    const [
        showFIrstVisitAppointmentListPopup,
        setShowFIrstVisitAppointmentListPopup,
    ] = useState(false);
    const [showFirstVisitPatientFormPopup, setShowFirstVisitPatientFormPopup] =
        useState(false);
    //是否為編輯模式
    const [isEditMode, setIsEditMode] = useState(false);
    // 是否顯示病人資料
    const [showUserData, setShowUserData] = useState(false);
    //預約資訊的搜尋文字
    const [appointmentSearchText, setAppointmentSearchText] = useState("");
    // 病人預約資訊清單
    const [appointmentTableData, setAppointmentTableData] = useState([]);
    // 病人預約資訊清單進階搜尋結果list
    const [advSearchAppointmentList, setAdvSearchAppointmentList] = useState(
        []
    );
    //科別列表
    const [departmentList, setDepartmentList] = useState([]);
    //醫生列表
    const [doctorList, setDoctorList] = useState([]);
    //預約資訊all checked狀態
    const [checkedAllAppointment, setCheckedAllAppointment] = useState(false);
    //是否顯示退掛確認彈窗
    const [showCancelAppointmentMessage, setShowCancelAppointmentMessage] =
        useState(false);
    //被選擇的預約資料
    const [selectedAppointmentDatas, setSelectedAppointmentDatas] =
        useState(null);
    //退掛按鈕disable狀態
    const [isCancelAppointmentDisabled, setIsCancelAppointmentDisabled] =
        useState(true);
    //初診病人資訊表格資料
    const [firstViewPatientFormData, setFirstViewPatientFormData] =
        useState(null);
    // 預約資訊 pagination控制變數
    const [appointmentPaginationProps, setAppointmentPaginationProps] =
        useState({
            //當前頁碼
            currentPage: 1,
            //每頁資料筆數
            pageSize: 10,
            //總資料筆數
            totalItemSize: 0,
            //總頁碼
            totalPageSize: 0,
        });
    //初診病人預約資料
    const [firstViewAppointmentData, setFirstViewAppointmentData] =
        useState(null);
    //病人病歷資料(要帶入病人病歷資料popup)
    const [patientMedicalData, setPatientMedicalData] = useState(null);
    //顯示虛擬IC popup
    const [showVHICPopup, setShowVHICPopup] = useState(false);
    //顯示IC卡檢視
    const [showIcCardViewPopup, setShowIcCardViewPopup] = useState(false);
    // 病人預約資訊點擊選擇
    const [selectedAppointments, setSelectedAppointments] = useState([]);
    // 是否清空多因素查詢的出生日期
    const [isCleanMultiFactorSearchBirthDate, setIsCleanMultiFactorSearchBirthDate] = useState(false)
    // 提示生日日期不可為未來日期彈窗
    const [showFutureDatePopup, setShowFutureDatePopup] = useState(false);
    // 多因素跳過稽核回傳訊息
    const [addPatientMessage, setAddPatientMessage] = useState(null);
    // 是否顯示非初診病人編輯彈窗
    const [showNonFirstTimePatientEditPopup, setShowNonFirstTimePatientEditPopup] = useState(false);
    //Toast Message Hooks
    const showToast = useToast();
    //紀錄該病歷資料建立時是否為電話預約 (true: 初診病人電話預約/ false: 一般初診病人掛號 /null: 一般掛號
    const [isPhoneFlagWhenMultifactorSkip, setIsPhoneFlagWhenMultifactorSkip] = useState(null)

    /**
     * 檢查病人姓名的第一個字符是否合法
     * @param name {string} 病人姓名
     * @return {boolean} - 如果合法返回 true，不合法返回 false
     */
    const checkPatientNameFirstChar = (name) => {
        const firstChar = name.charAt(0);
        if (/^[\d\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(firstChar)) {
            showToast({
                message: "病人姓名的第一個字不得為數字、空格或符號",
                type: AlertTypeEnum.Warning
            });
            return false;
        }
        return true;
    }

    /**
     * 查詢按鈕點擊事件
     */
    const handleSearchButtonOnClick = async () => {
        //文字為空不處理
        if (stringIsEmpty(searchText)) {
            return;
        }

        //IC卡資料清除
        setIcCardData(null);

        //關閉編輯模式
        setIsEditMode(false);

        // 清空病人預約資訊已選擇的項目狀態
        setSelectedAppointments([]);

        //清空查詢文字
        setSearchFieldValue('');

        //開始搜尋Patient資料
        await queryPatientBySearch({
            search: searchText.trim(),
            pageNum: 1,
            pageSize: 10,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {

                const foundedData = res.data;

                if (foundedData?.dataList.length > 0) {
                    //有匹配的病人病歷資訊結果
                    setPatientMedicalData({
                        dataList: foundedData?.dataList,
                        //每頁筆數
                        pageSize: foundedData?.pageSize,
                        //總頁數
                        totalPageSize: foundedData?.totalPageSize,
                        //總筆數
                        totalItemSize: foundedData?.totalItemSize,
                        //當前頁碼
                        currentPage: 1,
                        //搜尋的字串
                        searchText: searchText,
                    });
                    //顯示病人病歷資訊彈出視窗
                    setShowPatientMedicalRecordsPopup(true);
                } else {
                    //沒有匹配的病歷資料
                    //顯示 找不到病人病歷資料 提示
                    setShowPatientNotFoundPromptPopup(true);
                }
            }
        });


    };

    /**
     * 取得當前時段
     * @return {Number}
     */
    const getCurrentApn = () => {
        const now = new Date();
        const hours = now.getHours();
        const minutes = now.getMinutes();
        const seconds = now.getSeconds();
        const timeString = `${String(hours).padStart(2, "0")}${String(
            minutes
        ).padStart(2, "0")}${String(seconds).padStart(2, "0")}`;
        if (timeString >= "000000" && timeString <= "120000") {
            return TimeslotEnum.Morning;
        } else if (timeString > "120000" && timeString <= "170000") {
            return TimeslotEnum.Afternoon;
        } else {
            return TimeslotEnum.Night;
        }
    }

    /**
     * 重置預約資料
     * @param isAddReg {Boolean} 是否為給診號
     */
    const resetAppointmentData = (isAddReg = false) => {
        //重置資料
        setAppointmentData({
            //病歷ID
            patientId: patientData ? patientData.patientId : null,
            //掛號別
            reserveType: isAddReg ? appointmentData.reserveType : "1",
            //身份別
            ptType: "21",
            //院區代碼
            zone: "A",
            //門診日期
            encounterDate: time2String(new Date(), "yyyy-MM-DD"),
            //時段
            apn: `${getCurrentApn()}`,
            //醫師代碼
            doctorNo: "",
            //科別代碼
            divNo: "",
            //診間號
            clinicNo: "",
            //診室名稱
            clinicName: "",
            //診室地點
            clinicLocation: "",
            //補輸
            fowardRegFlag: false,
            //電話預約
            phoneRegFlag: isAddReg ? appointmentData.phoneRegFlag : false,
            //月班識別碼
            clinicId: null,
            //給號方式
            giveNumType: "",
            //給號開始時間
            giveNumStartTime: null,
            //給號結束時間
            giveNumEndTime: null,
        });
    };

    /**
     * 點選重新整理按鈕
     */
    const handleResetButtonOnClick = () => {
        resetToDefaultLayout(false);
        setSelectedAppointments([]); // 清空病人預約資訊已選擇的項目狀態
        setSearchFieldValue("");
    };

    /**
     * 恢復到預設畫面
     * @param isAddReg {Boolean} 是否為給診號
     */
    const resetToDefaultLayout = (isAddReg) => {
        //回復到預設畫面
        setSearchText("");
        setPatientData(null);
        setIcCardData(null);
        setPatientMedicalData(null);
        setAppointmentTableData([]);
        setAppointmentPaginationProps({
            //當前頁碼
            currentPage: 1,
            //每頁資料筆數
            pageSize: 10,
            //總資料筆數
            totalItemSize: 0,
            //總頁碼
            totalPageSize: 0,
        });

        setAppointmentSearchText("");
        setCheckedAllAppointment(false);
        setIsPhoneFlagWhenMultifactorSkip(null)

        resetAppointmentData(isAddReg);
    };

    /**
     * 檢查並轉換日期格式
     * @param {string} value - 輸入值
     * @returns {string|null} - 轉換後的日期格式，如果不是有效日期則返回 null
     */
    const convertDateFormat = (value) => {
        // 西元年格式 (YYYY-MM-DD)
        const westernDatePattern = /^(\d{4})-(\d{2})-(\d{2})$/;
        // 民國年格式 (YYY/MM/DD 或 YYY-MM-DD)
        const rocDatePattern = /^(\d{3})[/-](\d{2})[/-](\d{2})$/;

        let year, month, day;

        if (westernDatePattern.test(value)) {
            [, year, month, day] = value.match(westernDatePattern);
            // 檢查是否為有效日期
            const date = new Date(year, month - 1, day);
            if (date.getFullYear() !== parseInt(year) ||
                date.getMonth() !== parseInt(month) - 1 ||
                date.getDate() !== parseInt(day)) {
                return null;
            }
            return `${year}-${month}-${day}`;
        } else if (rocDatePattern.test(value)) {
            [, year, month, day] = value.match(rocDatePattern);
            // 民國年轉西元年
            const westernYear = parseInt(year) + 1911;
            // 檢查是否為有效日期
            const date = new Date(westernYear, month - 1, day);
            if (date.getFullYear() !== westernYear ||
                date.getMonth() !== parseInt(month) - 1 ||
                date.getDate() !== parseInt(day)) {
                return null;
            }
            return `${westernYear}-${month}-${day}`;
        }
        return null;
    };

    /**
     * 查詢文字改變事件
     * @param e {Event} 事件
     * @return {void}
     */

    const handleSearchOnChange = (e) => {
        const value = e.target.value;
        setSearchFieldValue(value);

        // 檢查並轉換日期格式
        const formattedDate = convertDateFormat(value);
        if (formattedDate) {
            // 如果是有效的日期格式，只更新搜尋文字，不自動開啟彈窗
            setIsCleanMultiFactorSearchBirthDate(false);
            setSearchText(formattedDate);
        } else {
            setSearchText(value);
        }
    };

    /**
     * 處理實體IC卡點擊事件
     */
    const handlePhysicalICCardOnClick = () => {
        //關閉編輯模式
        setIsEditMode(false);

        // 取得實體IC卡資料
        getIcHisGetRegisterBasic();
    };

    /**
     * 讀取IC卡點擊事件
     * @return {void}
     */
    const getIcHisGetRegisterBasic = () => {
        icHisGetRegisterBasic({}).then((res) => {
            // 取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                // 取得資料
                const data = res.data;
                // 資料為空
                if (objectIsEmpty(data)) {
                    // 停止執行
                    return;
                }
                successToGetIcCard(data);
            }
        });
    };

    /**
     * 成功取得ic卡資料事件
     * @param data {Object} ic卡資料
     * @param isPhysical {Boolean} 是否為實體卡
     * @returns
     */
    const successToGetIcCard = (data, isPhysical = true) => {
        if (objectIsEmpty(data)) return;

        //清空查詢輸入框
        setSearchText("");
        // 設定IC卡資料
        setIcCardData({...data, isPhysical: isPhysical});

        // 有身分證號和生日
        if (!stringIsEmpty(data.idNo) && !stringIsEmpty(data.birthDate)) {
            const birthDate = time2String(
                data.birthDate,
                "YYYY-MM-DD 00:00:00"
            );
            // 如果有性別，轉換成數字型態
            let genderName = null;
            if (!stringIsEmpty(data.gender)) {
                genderName = data.gender === "F" ? 2 : data.gender === "M" ? 1 : null;
            }
            getQueryPatientAndRegByIdNoAndBirthDate(data.idNo, birthDate, genderName, data.patientId);
        }
    };

    /**
     * 取得 查詢病歷 & 掛號紀錄
     * @param idNo 身分證號
     * @param birthDate 出生日期
     * @param genderName 性別名稱
     * @param patientId 病歷號
     */
    const getQueryPatientAndRegByIdNoAndBirthDate = (idNo, birthDate, genderName, patientId) => {
        queryPatientAndRegByIdNoAndBirthDate({idNo, birthDate}).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const data = res.data;

                // 若 API 回傳資料有值且包含 `patientId`
                if (!objectIsEmpty(data) && !stringIsEmpty(data.patientId)) {
                    if (data.patientId === patientId) {
                        // 回傳的 patientId 與 IC 卡的 patientId 一致，直接帶入病人資料
                        choicePatientDate({
                            ...data,
                            genderName: stringIsEmpty(genderName) ? data.genderName : genderName,
                        });
                    } else {
                        // 若 patientId 不一致，顯示病人資訊彈窗
                        setPatientMedicalData({
                            dataList: [data],
                            pageSize: 10,
                            totalPageSize: 1,
                            totalItemSize: 1,
                            currentPage: 1,
                            searchText: "",
                        });
                        setShowPatientMedicalRecordsPopup(true);
                    }
                } else {
                    // 回傳資料為空，但 IC 卡有 patientId，顯示彈窗
                    if (!stringIsEmpty(patientId)) {
                        setPatientMedicalData({
                            dataList: [
                                {
                                    patientId: patientId,
                                    idNo,
                                    birthDate,
                                    genderName,
                                },
                            ],
                            pageSize: 10,
                            totalPageSize: 1,
                            totalItemSize: 1,
                            currentPage: 1,
                            searchText: "",
                        });
                        setShowPatientMedicalRecordsPopup(true);
                    } else {
                        // 回傳資料為空且 IC 卡無 patientId，顯示找不到病歷提示
                        setShowPatientNotFoundPromptPopup(true);
                    }
                }
            } else {
                // API 查詢失敗處理
                let errMsg = "查詢病歷及掛號紀錄失敗";
                if (!stringIsEmpty(res.msg)) errMsg = `${errMsg}: ${res.msg}`;
                else if (res.statusCode !== null) errMsg = `${errMsg}: ${res.statusCode}`;
                showToast({message: errMsg, type: AlertTypeEnum.Error});
            }
        });
    };


    /**
     * 取得掛號基礎資料
     * @return {void}
     */
    const getRegsQueryBaseData = () => {
        regsQueryBaseData({}).then((res) => {
            //取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                //設定基礎資料
                setBaseData(res.data);
            }
        });
    };

    /**
     * 取得病人預約資訊
     * @param patientId {Number} 病歷號
     * @param page {Number} 查詢第幾頁
     * @param pageSize {Number} 每頁資料筆數
     */
    const getRegsQueryRegHistory = (patientId, page, pageSize) => {
        regsQueryRegHistory({
            patientId: patientId,
            pageNum: page,
            pageSize: pageSize,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const resultData =
                    !objectIsEmpty(res.data) && !arrayIsEmpty(res.data.dataList)
                        ? res.data.dataList.map((item) => ({
                            ...item,
                            checked: false,
                        }))
                        : [];
                setAppointmentTableData(resultData);
                setAppointmentPaginationProps({
                    ...appointmentPaginationProps,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                    currentPage: page,
                    pageSize: pageSize,
                });
            } else {
                setAppointmentTableData([]);
                setAppointmentPaginationProps({
                    ...appointmentPaginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageSize: pageSize,
                });
            }
        });
    };

    /**
     * 重新取得病人預約資訊
     * @param patientId {Number} 病歷號
     */
    const updateRegsQueryRegHistory = (patientId) => {
        getRegsQueryRegHistory(
            patientId,
            1,
            appointmentPaginationProps.pageSize
        );
    };

    /**
     * 取得病人醫療資訊
     * @param birthDate {String} 出生日期
     * @param gender {String} 性別
     * @param aborigineFlag {String} 是否為原住民
     */
    const getQueryMedicalInfo = (birthDate, gender, aborigineFlag) => {
        const birth = time2String(birthDate, "YYYY-MM-DD 00:00:00");
        regsQueryMedicalInfo({
            birthDate: birth,
            gender: gender,
            aborigineFlag: aborigineFlag
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setPatientMedicalInfoString(res.data);
            } else {
                setPatientMedicalInfoString("");
            }
        });
    };

    /**
     * 多因素頁面帶回資料
     * @param patient {Object} 病人病歷資訊
     */
    const handleMultifactorChoicePatientDate = (patient) => {
        choicePatientDate(patient);

        setShowMultifactorSearchPopup(false);
    };

    /**
     * 選擇病人病歷資訊
     * @param patient {Object} 病人病歷資訊
     */
    const choicePatientDate = (patient) => {
        //
        let genderName = patient.genderName
        let numericGender = patient.gender; // 保留原始的 gender 值
        //有IC卡資料且有性別時，以IC卡的為主
        if (!objectIsEmpty(icCardData) && !stringIsEmpty(icCardData.gender)) {
            if (icCardData.gender === 'F') {
                genderName = t('general.gender.female');
                numericGender = 2;
            } else if (icCardData.gender === 'M') {
                genderName = t('general.gender.male');
                numericGender = 1;
            }
        }
        //將病人資料設定
        setPatientData({...patient, genderName: genderName});
        //清空預約資訊的進階搜尋輸入框
        setAppointmentSearchText("");

        //清除給號開始、結束時間
        setAppointmentData({
            ...appointmentData,
            //給號開始時間
            giveNumStartTime: null,
            //給號結束時間
            giveNumEndTime: null,
        });

        if (!stringIsEmpty(patient.patientId)) {
            //查詢掛號紀錄
            getRegsQueryRegHistory(
                patient.patientId,
                1,
                appointmentPaginationProps.pageSize
            );
            //查詢就醫資訊
            getQueryMedicalInfo(patient.birthDate, numericGender, patient.aborigineFlag);
        }

        //查詢初診預約
        if (!stringIsEmpty(patient.idNo)) checkHaveFirstViewData(patient.idNo);

        //關閉病人病歷資料popup
        setShowPatientMedicalRecordsPopup(false);
        //關閉多因素查詢popup
        setShowMultifactorSearchPopup(false);
    };

    /**
     * 是否為身分證號
     * @param text {String}
     * @returns {boolean}
     */
    const isIdNoFormat = (text) => {
        //身分證規則:英文開頭 1碼 + 英文或數字 9碼
        const regxIdNo = /^[A-Za-z][A-Za-z0-9]{9}$/;
        return regxIdNo.test(text);
    };

    /**
     * 是否為手機號碼
     * @param text {String}
     * @returns {boolean}
     */
    const isMobileFormat = (text) => {
        //手機號碼:09開頭後面8個數字
        const regxMobile = /^09\d{8}$/;
        return regxMobile.test(text);
    };

    /**
     * 是否為日期格式
     * @param text {String}
     * @returns {boolean}
     */
    const isDateFormat = (text) => {
        //日期: yyyy-MM-dd 4碼-2碼-2碼 數字
        const regxDate = /^\d{4}-\d{2}-\d{2}$/;
        return regxDate.test(text);
    };

    /**
     * 初診沒預約 提示彈出框關閉事件
     */
    const handleFirstVistNoAppointmentPromptPopupOnClose = () => {
        //關閉提示
        setShowPatientNotFoundPromptPopup(false);
        // 不論是否勾選電話，皆開多因素查詢
        setShowMultifactorSearchPopup(true);
    };

    /**
     * 初診有預約 提示彈出框關閉事件
     */
    const handleFirstVistAppointmentPromptPopupOnClose = () => {
        setShowFirstVistAppointmentPromptPopup(false);
    };

    /**
     * 病人病歷資訊視窗關閉事件
     * @return void
     */
    const handlePatientMedicalRecordsPopupOnClose = () => {
        //關閉病歷資訊視窗關閉事件
        setShowPatientMedicalRecordsPopup(false);
        //表示非本人 就顯示多因素查詢
        setShowMultifactorSearchPopup(true);
    };

    /**
     * 多因素搜尋視窗關閉事件
     * @return void
     */
    const handleMultifactorSearchPopupOnClose = () => {
        setShowMultifactorSearchPopup(false);
        //IC卡資料清除
        setIcCardData(null);
    };

    /**
     * 初診病人預約清單視窗關閉事件
     * @returns void
     */
    const handleFirstVisitAppointmentListPopupOnClose = () =>
        setShowFIrstVisitAppointmentListPopup(false);

    /**
     * 初診報到按鈕點擊事件
     * @returns void
     */
    const handleFirstVisitCheckInOnClick = () => {
        setFirstViewAppointmentData({
            //看診日期
            encounterDate: appointmentData.encounterDate,
            //時段
            apn: appointmentData.apn,
        });
        setShowFIrstVisitAppointmentListPopup(true);
    };

    /**
     * 初診病人資料暫時存檔(還不需要建立病歷)
     * @param patientName {String} 病人姓名
     * @param birthDate {String} 病人生日
     * @param idNo {String} 病人身分證號
     * @param genderName {String} 病人性別名稱
     * @param homephonenumber {String} 病人電話
     * @param phonenumber {String} 病人手機號碼
     */
    const saveNewPatientTmp = async (
        patientName,
        birthDate,
        idNo,
        genderName,
        homephonenumber,
        phonenumber
    ) => {

        // 檢查病人姓名的第一個字符
        if (!checkPatientNameFirstChar(patientName)) return

        //已是新的病人資料
        //清除給號開始、結束時間
        setAppointmentData({
            ...appointmentData,
            //給號開始時間
            giveNumStartTime: null,
            //給號結束時間
            giveNumEndTime: null,
        });

        const newData = {
            patientId: "",
            patientName: patientName,
            genderName: genderName,
            birthDate: birthDate,
            ageYy: null,
            ageMm: null,
            idNo: idNo,
            sameIdNo: null,
            samePatientName: null,
            sameBirthDate: null,
            phoneNo: phonenumber,
            homePhoneNo: homephonenumber,
            aborigineFlag: null,
        };
        //設定病人資料
        setPatientData(newData);

        //關閉初診病人病歷填寫表單
        setShowFirstVisitPatientFormPopup(false);

        //關閉多因素查詢
        setShowMultifactorSearchPopup(false);

        //清空預約資訊Table的資料
        setAppointmentTableData([]);
        //清空醫療資訊
        setPatientMedicalInfoString("");
    };

    /**
     * 儲存編輯的病人資料
     * @param patientName {String} 病人姓名
     * @param birthDate {String} 病人生日
     * @param idNo {String} 病人身分證號
     * @param genderName {String} 性別
     * @param homePhoneNo {String} 電話號碼
     * @param phoneNo {String} 手機號碼
     * @param adrArea {String} 區域號碼
     * @param contactAddress {String} 聯絡地址
     * @param nationality {String} 國籍
     * @param aborigineFlag {Boolean} 是否為原住民
     * @param nativeptFlag {Boolean} 是否為原鄉人
     * @param idTypeNo {String} 證號類別
     * @returns {void}
     */
    const handleSaveEditPatient = async (
        {
            patientName,
            birthDate,
            idNo,
            genderName,
            homePhoneNo,
            phoneNo,
            adrArea,
            adrAreaName,
            contactAddress,
            nationality,
            aborigineFlag,
            nativeptFlag,
            idTypeNo
        }
    ) => {
        if (stringIsEmpty(patientName)) {
            //姓名欄位為空，跳出toast提示
            showToast({
                message: "姓名欄位不可為空",
                type: AlertTypeEnum.Warning,
            });
            return;
        }

        // 檢查病人姓名的第一個字符
        if (!checkPatientNameFirstChar(patientName)) return

        if (stringIsEmpty(patientData.patientId)) {
            //沒有病歷號
            setPatientData((prevData) => ({
                ...prevData,
                patientName: patientName,
                birthDate: birthDate,
                idNo: idNo,
                genderName: genderName,
                homePhoneNo: homePhoneNo,
                phoneNo: phoneNo,
                adrArea: adrArea,
                adrAreaName: adrAreaName,
                contactAddress: contactAddress,
                nationality: nationality,
                aborigineFlag: aborigineFlag,
                nativeptFlag: nativeptFlag,
                idTypeNo: idTypeNo,
            }));

            //關閉編輯模式
            setIsEditMode(false);
        } else {
            const genderObj = !objectIsEmpty(baseData) ? baseData.genderList.find(item => item.justnameName === genderName) : null;

            //有病歷號
            patientRegEditSave({
                patientId: patientData.patientId,
                //姓名
                patientName: patientName,
                //生日
                birthDate: birthDate,
                //身分證號
                idNo: idNo,
                //性別
                gender: !objectIsEmpty(genderObj) ? genderObj.justnameNo : null,
                //Integer
                lockVersion: patientData.lockVersion,
                //電話號碼
                homePhoneNo: homePhoneNo,
                //手機號碼
                phoneNo: phoneNo,
                //區域號碼
                adrArea: +adrArea,
                //地址
                contactAddress: contactAddress,
                //國籍
                nationality: nationality,
                //證號類別
                idTypeNo: idTypeNo,
                //是否為原住民
                aborigineFlag: aborigineFlag,
                //是否為原鄉人
                nativeptFlag: nativeptFlag
            }).then((res) => {
                if (res.err === ApiErrorStatusEnum.Success) {
                    setPatientData(res.data);
                    //顯示成功訊息
                    showToast({
                        message: `編輯 [${res.data.patientName}] 病歷成功`,
                        type: AlertTypeEnum.Success,
                    });

                    //關閉編輯模式
                    setIsEditMode(false);

                    //重新查詢掛號紀錄
                    getRegsQueryRegHistory(
                        patientData.patientId,
                        1,
                        appointmentPaginationProps.pageSize
                    );
                    //重新查詢就醫資訊
                    getQueryMedicalInfo(patientData.birthDate, patientData.gender, patientData.aborigineFlag);
                } else {
                    //失敗
                    let errMsg = "編輯失敗";
                    if (!stringIsEmpty(res.msg))
                        errMsg = errMsg + `: ${res.msg}`;
                    else if (res.statusCode !== null)
                        errMsg = errMsg + `: ${res.statusCode}`;
                    //顯示失敗訊息
                    showToast({message: errMsg, type: AlertTypeEnum.Error});
                }
            });
        }
    };

    /**
     * 開啟初診病人病歷資料填寫表單彈出框
     * @param patientName {String} 姓名
     * @param birthDate {String} 生日
     * @param idNo {String} 身分證號
     * @param homephonenumber {String} 家裡電話
     * @param mobilenumber {String} 手機電話
     */
    const openFirstVisitPatientFormPopup = (
        patientName,
        birthDate,
        idNo,
        homephonenumber,
        mobilenumber
    ) => {
        setFirstViewPatientFormData({
            patientName: patientName,
            birthDate: birthDate,
            idNo: idNo,
            homephonenumber: homephonenumber,
            mobilenumber: mobilenumber,
        });
        setShowFirstVisitPatientFormPopup(true);
    };

    /**
     * 關閉初診病人病歷資料填寫表單彈出框
     */
    const closeFirstVisitPatientFormPopup = () => {
        //IC卡資料清除
        setIcCardData(null);

        setShowFirstVisitPatientFormPopup(false);
    };

    /**
     * 點擊跳過稽核按鈕事件
     * @param patientName {String} 姓名
     * @param birthDate {String} 生日
     * @param idNo {String} 身分證號
     * @param homePhoneNo {String} 電話
     * @param phoneNo {String} 手機
     * @param adrArea {String} 郵遞區號
     * @param adrAreaName {String} 縣市
     * @param contactAddress {String} 通訊地址
     */
    const handleMultifactorSearchSkipOnClick = async (
        patientName,
        birthDate,
        idNo,
        homePhoneNo,
        phoneNo,
        adrArea,
        adrAreaName,
        contactAddress,
    ) => {
        //判斷電話有沒勾選
        if (appointmentData.phoneRegFlag) {
            //有勾選
            //開啟初診病人病歷資料填寫表單彈出框
            openFirstVisitPatientFormPopup(
                patientName,
                birthDate,
                idNo,
                homePhoneNo,
                ""
            );
        } else {
            setPatientData(prev => ({
                patientName: patientName,
                birthDate: birthDate,
                idNo: idNo,
                homePhoneNo: homePhoneNo,
                phoneNo: phoneNo,
                adrArea: adrArea,
                adrAreaName: adrAreaName,
                contactAddress: contactAddress,
            }))
            // 檢查是否有初診預約資料
            if (!stringIsEmpty(idNo)) {
                checkHaveFirstViewData(idNo);
            }
        }
        setShowMultifactorSearchPopup(false);
        // 記錄當下流程 電話預約 是否勾選，以便 給診號 時判斷是否需產生病歷號
        setIsPhoneFlagWhenMultifactorSkip(appointmentData.phoneRegFlag)
    };

    /**
     * 查詢是否有初診預約資料
     * @param idNo {String} 身分證號
     */
    const checkHaveFirstViewData = (idNo) => {
        regsQueryFirstviewByField({
            encounterDate: time2String(
                appointmentData.encounterDate,
                "YYYY-MM-DD 00:00:00"
            ),
            apn: appointmentData.apn.toString(),
            field: "ALL",
            search: idNo,
            pageSize: 10,
            pageNum: 1,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                if (
                    !objectIsEmpty(res.data) &&
                    !arrayIsEmpty(res.data.dataList)
                ) {
                    //設定要帶入初診病人預約名單的資料
                    setFirstViewAppointmentData({
                        dataList: res.data.dataList,
                        //每頁筆數
                        pageSize: res.data.pageSize,
                        //總頁數
                        totalPageSize: res.data.totalPageSize,
                        //總筆數
                        totalItemSize: res.data.totalItemSize,
                        //當前頁碼
                        currentPage: 1,
                        //搜尋的字串
                        searchText: idNo,
                        //搜尋的欄位
                        searchField: "ALL",
                        //看診日期
                        encounterDate: appointmentData.encounterDate,
                        //時段
                        apn: appointmentData.apn,
                    });
                    setShowFIrstVisitAppointmentListPopup(true);
                }
            } else {
            }
        });
    };

    /**
     * 預約資料勾選全部的Checkbox 變更事件
     */
    const handleCheckAllAppointmentOnChange = () => {
        const isChecked = !checkedAllAppointment;
        setCheckedAllAppointment(isChecked);

        const updateData = appointmentTableData.map((item) => {
            // 在advSearchAppointmentList中查找匹配的 regId
            const matchedItem = advSearchAppointmentList.find(
                (advItem) => advItem.regId === item.regId
            );
            // 如果找到匹配的 regId，則更新 checked
            if (matchedItem) {
                return {...item, checked: isChecked};
            }
            return item; // 否則保持原樣
        });
        setAppointmentTableData(updateData);
    };

    /**
     * 查詢所有醫生
     * @return {void}
     */
    const getQueryAllDoctor = () => {
        queryAllDoctor({}).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setDoctorList(res.data);
            }
        });
    };

    /**
     * 查詢所有科別
     * @return {void}
     */
    const getQueryAllDivision = () => {
        queryAllDivision({}).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setDepartmentList(res.data);
            }
        });
    };

    /**
     * 處理預約資訊查詢方框變更時
     * @param event {Event} event
     * @return void
     */
    const handleSearchAppointmentTextOnChange = (event) => {
        setAppointmentSearchText(event.target.value);
    };

    // 病人預約資訊表頭
    const columns = [
        {field: 'zoneName', headerName: t("Regs.general.campus"), width: 100},
        {
            field: 'encounterDate',
            headerName: t("Regs.general.clinicDate"),
            width: 120,
            renderCell: params => time2String(params.value, "YYYY-MM-DD")
        },
        {field: 'patientId', headerName: t("Regs.general.medicalRecordNumber"), width: 120},
        {field: 'patientName', headerName: t("general.username"), width: 100},
        {
            field: 'doctorName', headerName: t("general.doctor"), width: 100,
            renderCell: (params) => getAppointmentTableDoctorFieldContent(params.row)
        },
        {field: 'divName', headerName: t("general.department"), width: 100},
        {field: 'clinicName', headerName: t("Regs.general.clinicName"), width: 100},
        {field: 'clinicNo', headerName: t("Regs.general.clinic"), width: 80},
        {field: 'hiddenField', headerName: '', width: 0}
    ]

    /**
     * 控制表格點選事件
     */
    const handleSelectionModelChange = (newSelectionModel) => {
        setSelectedAppointments(newSelectionModel);
    };


    /**
     * 點選退掛按鈕事件
     */
    const handleCancelAppointmentOnClick = () => {
        const selectedData = advSearchAppointmentList.filter(item =>
            selectedAppointments.includes(item.regId)
        );
        setSelectedAppointmentDatas(selectedData);
        setShowCancelAppointmentMessage(true);
    };


    /**
     * 取得預約清單進階搜尋後的結果
     */
    const getAdvanceSearchAppointmentList = () => {
        //如果搜尋文字為空
        if (stringIsEmpty(appointmentSearchText)) {
            return appointmentTableData;
        } else {
            //如果搜尋文字不為空
            const lowerCaseSearchText = appointmentSearchText.toLowerCase();
            return appointmentTableData.filter((data) => {
                return (
                    (data.zoneName != null &&
                        data.zoneName
                            .toString()
                            .toLowerCase()
                            .includes(lowerCaseSearchText)) ||
                    (data.encounterDate !== null &&
                        time2String(data.encounterDate, "YYYY-MM-DD").includes(
                            lowerCaseSearchText
                        )) ||
                    (data.patientId != null &&
                        data.patientId
                            .toString()
                            .toLowerCase()
                            .includes(lowerCaseSearchText)) ||
                    (data.patientName != null &&
                        data.patientName
                            .toString()
                            .toLowerCase()
                            .includes(lowerCaseSearchText)) ||
                    (data.doctorName != null &&
                        data.doctorName
                            .toString()
                            .toLowerCase()
                            .includes(lowerCaseSearchText)) ||
                    (data.divName != null &&
                        data.divName
                            .toString()
                            .toLowerCase()
                            .includes(lowerCaseSearchText)) ||
                    (data.clinicName != null &&
                        data.clinicName
                            .toString()
                            .toLowerCase()
                            .includes(lowerCaseSearchText)) ||
                    (data.clinicNo != null &&
                        data.clinicNo
                            .toString()
                            .toLowerCase()
                            .includes(lowerCaseSearchText))
                );
            });
        }
    };

    /**
     * 預約資料 Table item Checkbox變更事件
     * @param regId {String} 預約ID
     */
    const handleAppointmentTableItemCheckOnChange = (regId) => {
        const updateData = appointmentTableData.map((item) =>
            item.regId === regId ? {...item, checked: !item.checked} : item
        );
        setAppointmentTableData(updateData);

        //檢查每一筆是否checked都為true
        setCheckedAllAppointment(updateData.every((item) => item.checked));
    };

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     * @param pageSize {Number} 每頁幾筆
     */
    const onAppointmentPaginationPageOnChange = (page, pageSize) => {
        getRegsQueryRegHistory(
            patientData.patientId,
            page,
            pageSize
        );
    };

    /**
     * 上一頁點擊事件
     */
    const onAppointmentPaginationPreviousOnClick = () => {
        const page =
            appointmentPaginationProps.currentPage - 1 > 1
                ? appointmentPaginationProps.currentPage - 1
                : 1;
        getRegsQueryRegHistory(
            patientData.patientId,
            page,
            appointmentPaginationProps.pageSize
        );
    };

    /**
     * 下一頁點擊事件
     */
    const onAppointmentPaginationNextOnClick = () => {
        const page =
            appointmentPaginationProps.currentPage + 1 <
            appointmentPaginationProps.totalPageSize
                ? appointmentPaginationProps.currentPage + 1
                : appointmentPaginationProps.totalPageSize;
        getRegsQueryRegHistory(
            patientData.patientId,
            page,
            appointmentPaginationProps.pageSize
        );
    };

    /**
     * 每頁資料筆數變更事件
     * @param e {Event} 事件
     */
    const onAppointmentPaginationPageSizeOnChange = (e) => {
        if (arrayIsEmpty(advSearchAppointmentList)) {
            setAppointmentPaginationProps({
                ...appointmentPaginationProps,
                pageSize: e.target.value,
            });
            return;
        }
        // 設定每頁資料筆數
        getRegsQueryRegHistory(patientData.patientId, 1, e.target.value);
    };

    /**
     * 關閉退掛Popup
     */
    const handleCloseCancelAppointmentPopup = () => {
        setShowCancelAppointmentMessage(false);
    };

    /**
     * 退掛結果事件
     * @param isSuccessed {Boolean} 退掛成功與否
     * @param msg {String} 退掛結果訊息
     */
    const handleCancelAppointmentResult = (isSuccessed, msg) => {
        setShowCancelAppointmentMessage(false);
        showToast({
            message: msg,
            type: isSuccessed ? AlertTypeEnum.Success : AlertTypeEnum.Warning,
        });
        //重新查詢掛號紀錄
        getRegsQueryRegHistory(
            patientData.patientId,
            1,
            appointmentPaginationProps.pageSize
        );
    };

    /**
     * 虛擬IC按鈕點擊事件
     */
    const handleVHICOnClick = () => {
        setShowVHICPopup(true);
    };

    /**
     * IC檢視按鈕點擊事件
     */
    const handleIcCardViewOnClick = () => {
        setShowIcCardViewPopup(true);
    };

    /**
     * 虛擬IC彈窗關閉事件
     */
    const handleCloseVHICPopup = () => {
        setShowVHICPopup(false);
    };

    /**
     * 取得預約資訊table的醫師欄位內容
     * @param appointmentData {Object} 預約資料
     */
    const getAppointmentTableDoctorFieldContent = (appointmentData) => {
        if (!stringIsEmpty(appointmentData.locumDoctorName)) {
            return `${appointmentData.locumDoctorName}[代]`;
        }
        return appointmentData.doctorName;
    };

    /**
     * 檢查字串的第一個字符是否為數字
     * @param str {String} 要檢查的輸入字串
     * @returns {boolean} 如果第一個字符是數字則返回true,否則返回false
     */
    const isFirstCharNumber = (str) => {
        return /^\d/.test(str);
    }

    /**
     * 關閉提醒生日不可晚於今日彈窗
     */
    const handleCloseFutureDatePopup = () => {
        //關閉清除生日日期事件
        setIsCleanMultiFactorSearchBirthDate(false);
        //關閉彈窗
        setShowFutureDatePopup(false);
    }

    /**
     *　第一次進來後
     */
    useMemo(() => {
        //取得所有醫生
        getQueryAllDoctor();
        //取得所有部門
        getQueryAllDivision();
        //取得基礎資料
        getRegsQueryBaseData();
    }, []);

    /**
     * 監聽PatientData變更
     */
    useEffect(() => {
        if (!objectIsEmpty(patientData)) {
            setShowUserData(true);

            if (!objectIsEmpty(patientData.patientName)) {
                //姓名不為空時
                //稽核病人姓名第一碼是否為數字
                if (isFirstCharNumber(patientData.patientName)) {
                    //是數字，跳toast提示
                    showToast({message: '姓名第一碼為數字', type: AlertTypeEnum.Warning});
                }
            }
        } else {
            setShowUserData(false);
        }

        //關閉編輯模式
        setIsEditMode(false);
    }, [patientData]);

    /**
     * 監聽appointmentTableData跟appointmentSearchText 變化
     */
    useEffect(() => {
        if (appointmentTableData !== null) {
            const list = getAdvanceSearchAppointmentList();
            setAdvSearchAppointmentList(list);
            //判斷退掛是否可點選
            const checked = list.some(
                (item) => item.checked !== null && item.checked === true
            );
            setIsCancelAppointmentDisabled(!checked);

            //判斷是否全勾選
            const allChecked = arrayIsEmpty(list)
                ? false
                : list.every((item) => item.checked === true);
            setCheckedAllAppointment(allChecked);
        } else {
            setAdvSearchAppointmentList(null);
            setIsCancelAppointmentDisabled(false);

            setCheckedAllAppointment(false);
        }
    }, [appointmentTableData, appointmentSearchText]);

    /**
     * 病人預約資訊觸發退掛按鈕事件
     */
    useEffect(() => {
        const isDisabled = selectedAppointments.length === 0;
        setIsCancelAppointmentDisabled(isDisabled);
        //console.log("Cancel button disabled:", isDisabled); // 添加這行來調試
    }, [selectedAppointments]);

    /**
     * 監聽電話預約變更
     * @return {void}
     */
    useEffect(() => {
        // 電話預約等於true時
        if (appointmentData.phoneRegFlag) {
            //更改掛號別為電話掛號
            setAppointmentData({...appointmentData, reserveType: "3"});
        }
    }, [appointmentData.phoneRegFlag]);

    return (
        <div className=" flex flex-col bg-[#FAFAFA] px-2 w-full">
            <div className="content flex flex-col space-y-2 pt-3 relative">
                <div className="flex flex-row space-x-12 justify-between">
                    <div className="flex flex-row space-x-2 items-center">
                        <TextField
                            label="病歷號碼/身分證號/手機號/生日(YYYY-MM-DD)"
                            value={searchFieldValue}
                            onChange={(e) => handleSearchOnChange(e)}
                            inputWidth={SizeEnum.XLarge}
                        />
                        <Button
                            color="primary"
                            variant="contained"
                            size="medium"
                            onClick={handleSearchButtonOnClick}
                        >
                            {t("general.query")}
                        </Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            size="medium"
                            sx={{minWidth: "fit-content"}}
                            onClick={handleResetButtonOnClick}
                        >
                            {"重新整理"}
                        </Button>
                    </div>
                    <div className="flex flex-row justify-end self-end space-x-2 pr-5">
                        <Button
                            color="secondary"
                            variant="outlined"
                            size="medium"
                            onClick={handlePhysicalICCardOnClick}
                        >
                            {"實體IC"}
                        </Button>
                        <Button
                            color="secondary"
                            variant="outlined"
                            size="medium"
                            onClick={handleVHICOnClick}
                        >
                            {"虛擬IC"}
                        </Button>
                        <Button
                            color="secondary"
                            variant="outlined"
                            size="medium"
                            onClick={handleIcCardViewOnClick}
                            disabled={objectIsEmpty(icCardData)}
                        >
                            {"IC檢視"}
                        </Button>
                        <Divider orientation="vertical"/>
                        <Button
                            color="secondary"
                            variant="outlined"
                            size="medium"
                            onClick={handleFirstVisitCheckInOnClick}
                            disabled={objectIsEmpty(patientData)}
                        >
                            {"初診報到"}
                        </Button>
                    </div>
                </div>
                <div className="flex flex-row w-full space-x-[8px]">
                    <div
                        className="flex flex-col min-w-[375px] xl:min-w-[375px] max-w-[375px] xl:max-w-[375px] space-y-[12px]">
                        {/*病歷資訊*/}
                        <CaseInformation
                            icCardData={patientData}
                            showData={showUserData}
                            saveEditPatient={handleSaveEditPatient}
                            isEditMode={isEditMode}
                            setIsEditMode={setIsEditMode}
                            baseData={baseData}
                            setShowNonFirstTimePatientEditPopup={setShowNonFirstTimePatientEditPopup}
                        />
                        <div className="min-h-[170px]">
                            {/*醫療資訊*/}
                            <MedicalInformation
                                medicalInfoString={patientMedicalInfoString}
                                showData={showUserData}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row items-stretch space-x-[8px] w-full justify-start">
                        <div className="flex flex-col min-w-[300px] xl:min-w-[358px] max-w-[300px] xl:max-w-[358px]]">
                            {/*看診資料*/}
                            <AppointmentInfo
                                patientData={patientData}
                                showData={showUserData}
                                baseData={baseData}
                                updateGetRegsQueryRegHistory={
                                    updateRegsQueryRegHistory
                                }
                                triggerResetDefault={resetToDefaultLayout}
                                appointmentData={appointmentData}
                                setAppointmentData={setAppointmentData}
                                resetAppointmentData={resetAppointmentData}
                                isPhoneFlagWhenMultifactorSkip={isPhoneFlagWhenMultifactorSkip}
                            />
                        </div>
                        <div className="flex flex-col max-w-[937px]">
                            {/*病人預約資訊*/}
                            <InfoCard titles="病人預約資訊">
                                <div className="max-h-[25rem] relative overflow-y-auto overflow-x-auto ">
                                    <div
                                        className="flex justify-between items-center w-[calc(100%-144px)] mb-4">
                                        <SearchTextField
                                            placeholder="進階搜尋"
                                            value={appointmentSearchText}
                                            onChange={(e) => handleSearchAppointmentTextOnChange(e)}
                                        />

                                        <Button
                                            color="secondary"
                                            variant="outlined"
                                            size="medium"
                                            onClick={handleCancelAppointmentOnClick}
                                            disabled={isCancelAppointmentDisabled}>
                                            {"退掛"}
                                        </Button>
                                    </div>
                                    {/*預約資訊data grid*/}
                                    <AdvancedDataGrid
                                        rows={showUserData ? advSearchAppointmentList : []}
                                        columns={columns}
                                        checkboxSelection={true}
                                        onRowSelectionModelChange={handleSelectionModelChange}
                                        rowSelectionModel={selectedAppointments}
                                        disableRowSelectionOnClick={false}
                                        disableColumnMenu={true}
                                        height={'340px'}
                                        getRowId={(row) => row.regId}
                                        getRowHeight={(params) => 56}
                                    />
                                </div>
                                {/*分頁*/}
                                <div className="flex justify-end items-end w-full">
                                    <Pagination
                                        totalPageSize={appointmentPaginationProps.totalPageSize}
                                        page={appointmentPaginationProps.currentPage}
                                        pageSize={appointmentPaginationProps.pageSize}
                                        totalSize={appointmentPaginationProps.totalItemSize}
                                        onPageOnChange={(page, pageSize) =>
                                            onAppointmentPaginationPageOnChange(page, pageSize)
                                        }
                                        showFirstButton
                                        showLastButton
                                    />
                                </div>
                            </InfoCard>
                            {/*病人排檢資訊*/}
                            <div className="max-h-[320px] mt-2">
                                <PatientInformation showData={showUserData}/>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    // 病人病歷資訊顯示視窗
                    <PatientMedicalRecordsPopup
                        closePopupButtonOnClick={
                            handlePatientMedicalRecordsPopupOnClose
                        }
                        show={showPatientMedicalRecordsPopup}
                        importFindedData={patientMedicalData}
                        choicePatientDate={choicePatientDate}
                        ptSearchOptionList={
                            objectIsEmpty(baseData)
                                ? null
                                : baseData.ptSearchOptionList
                        }
                    />
                }
                {
                    //找不到此病人資訊的提示(初診、未預約)
                    <PromptPopup
                        promptText={"找不到此病人資訊"}
                        show={showPatientNotFoundPromptPopup}
                        closePopupButtonOnClick={
                            handleFirstVistNoAppointmentPromptPopupOnClose
                        }
                    />
                }
                {
                    //多因素查詢
                    <MultifactorSearchPopup
                        show={showMultifactorSearchPopup}
                        icCardData={icCardData}
                        closePopupButtonOnClick={
                            handleMultifactorSearchPopupOnClose
                        }
                        skipAuthReasonList={
                            objectIsEmpty(baseData)
                                ? null
                                : baseData.skipAuthReasonList
                        }
                        confirmPatientData={handleMultifactorChoicePatientDate}
                        handleMultifactorSearchSkipOnClick={
                            handleMultifactorSearchSkipOnClick
                        }
                        searchText={searchText}
                        cleanMultiFactorSearchBirthDate={isCleanMultiFactorSearchBirthDate}
                    />
                }
                {
                    //找不到此病人病歷號的提示(初診、有預約)
                    <PromptPopup
                        promptText={"此病人今天有初診預約"}
                        show={showFirstVistAppointmentPromptPopup}
                        closePopupButtonOnClick={
                            handleFirstVistAppointmentPromptPopupOnClose
                        }
                    />
                }
                {
                    //初診預約清單視窗
                    <FirstVisitPatientAppointmentListPopup
                        show={showFIrstVisitAppointmentListPopup}
                        closePopupButtonOnClick={
                            handleFirstVisitAppointmentListPopupOnClose
                        }
                        fvSearchOptionList={
                            objectIsEmpty(baseData)
                                ? null
                                : baseData.fvSearchOptionList
                        }
                        apnList={
                            objectIsEmpty(baseData) ? null : baseData.apnList
                        }
                        patientId={
                            objectIsEmpty(patientData)
                                ? ""
                                : patientData.patientId
                        }
                        importAppointmentData={firstViewAppointmentData}
                        triggerResetAppointment={resetToDefaultLayout}
                        updateGetRegsQueryRegHistory={updateRegsQueryRegHistory}
                    />
                }
                {
                    //初診病人資訊 視窗
                    <FirstVisitPatientFormPopup
                        show={showFirstVisitPatientFormPopup}
                        data={firstViewPatientFormData}
                        onSave={saveNewPatientTmp}
                        closePopupButtonOnClick={
                            closeFirstVisitPatientFormPopup
                        }
                        futureDatePopup={setShowFutureDatePopup}
                    />
                }
                {
                    //退掛 視窗
                    <CancelAppointmentMessagePopup
                        show={showCancelAppointmentMessage}
                        closePopupButtonOnClick={
                            handleCloseCancelAppointmentPopup
                        }
                        candidateDatas={selectedAppointmentDatas}
                        handleCancelResult={handleCancelAppointmentResult}
                    />
                }
                {
                    //虛擬IC popup
                    <VirtualHealthInsuranceCardPopup
                        show={showVHICPopup}
                        closePopupButtonOnClick={handleCloseVHICPopup}
                        successToGetIcCard={successToGetIcCard}
                    />
                }
                {
                    // IC卡檢視 popup
                    <IcCardViewPopup
                        show={showIcCardViewPopup}
                        icCardData={icCardData}
                        closePopupButtonOnClick={() =>
                            setShowIcCardViewPopup(false)
                        }
                    />
                }
                {
                    // 非初診病人資訊編輯視窗
                    <NonFirstTimePatientEditPopup
                        show={showNonFirstTimePatientEditPopup}
                        setShowNonFirstTimePatientEditPopup={setShowNonFirstTimePatientEditPopup}
                        patientData={patientData}
                        saveEditPatient={handleSaveEditPatient}
                        baseData={baseData}
                    />
                }
                {
                    // 生日日期不可大於今日 Popup
                    <Dialog
                        open={showFutureDatePopup}
                        title={"提醒"}
                        content={addPatientMessage ? addPatientMessage.msg : '注意, 生日不可超過今日'}
                        variant={DialogVariant.REMIND}
                        paperStyleProps={{width: DialogSizeEnums.SM}}
                        onClose={handleCloseFutureDatePopup}
                        muiDialogContentProps={{sx: {padding: '4rem 1.5rem 4rem 1.5rem'}}}
                    />
                }
            </div>
        </div>
    );
};

export default AppointmentLayout;
