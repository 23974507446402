import React, {useEffect, useState} from "react"
import {Dialog, DialogSizeEnums, DialogVariant} from "edah-component/dist";

/**
 * 退掛確認彈窗
 * @param show {boolean} 是否顯示
 * @param closePopupButtonOnClick {function} 關閉按鈕點擊事件
 * @param docContent {json || object} json 或 pdf 內容
 * @param rowData {object} 資料
 * @param contentType {string} 資料類型
 * @returns
 */
const PdfJsonPopup = ({
   show = false,
   closePopupButtonOnClick,
    docContent,
    rowData,
    contentType
}) => {

    /**
     * 是否顯示彈窗
     */
    const [showPopup, setShowPopup] = useState(show)

    /**
     * 確定刪除點擊事件
     */
    const handleConfirmOnClick = () => {
        closePopupButtonOnClick()
    }

    /**
     * 取消刪除點擊事件
     */
    const handleCancelOnClick = () => {
        closePopupButtonOnClick()
    }

    /**
     * 取得內容
     * @return {JSX.Element}
     */
    const GetContent = () => (
        <>
            {/*如為PDF則顯示PDF*/}
            {/*如為JSON格式以表格方式呈現*/}
            {contentType === '0' ? <pre style={{
                backgroundColor: '#f5f5f5',
                padding: '15px',
                borderRadius: '4px',
                whiteSpace: 'pre-wrap'
            }}>
                {JSON.stringify(docContent, null, 2)}
            </pre>:<></>}
        </>
    )

    /**
     * 取得 Dialog Title
     * @return {string} Dialog 標題
     */
    const getTitle = () => {
        if (contentType === '0') {
            return 'JSON 內容';
        } else if (contentType === '1') {
            return 'PDF 內容';
        }
    };

    /**
     * 監聽是否顯示彈窗Pros變化
     */
    useEffect(() => {

        setShowPopup(show)
    }, [show])
    return showPopup ?
        <Dialog
            open={show}
            title={getTitle() || '顯示檔案'}
                variant={DialogVariant.CUSTOM}
                content={GetContent()}
                onClose={closePopupButtonOnClick}
                size={DialogSizeEnums.SM}
            />
        : <></>
}

export default PdfJsonPopup;