import {useEffect, useRef, useState} from "react"
import {regsQueryActiveByCategoriesAndSearch} from "../../api/v1/Regs"
import {arrayIsEmpty, objectIsEmpty, stringIsEmpty, time2String} from "edah_utils/dist"
import {t} from "i18next"
import {
    Button, Card, DatePicker, Field, SizeEnum, TextField,
    ColorEnum,
    ButtonSizeEnum,
    ButtonVariantEnum,
    IconEnum,
    AlertTypeEnum,
    Search,
    Checkbox,
    RadioGroup,
} from "edah-component/dist"
import dayjs from "dayjs";
import {checkGender} from "./utils";
import {ApiErrorStatusEnum} from "edah_utils/dist";
import useToast from "../../hooks/useToast";

/**
 * 病歷資訊
 * @param icCardData {Object} 健保IC卡資料
 * @param showData {Boolean} 是否顯示資料
 * @param saveEditPatient {Function} 儲存編輯的病人資料
 * @param isEditMode {Boolean} 是否為編輯模式
 * @param setIsEditMode {Function} 設定編輯模式
 * @param baseData {Object} 基本資料
 * @param setShowNonFirstTimePatientEditPopup {Function} 設定是否顯示非首次編輯彈窗
 */
const CaseInformation = ({icCardData, showData, saveEditPatient, isEditMode, setIsEditMode, baseData,setShowNonFirstTimePatientEditPopup}) => {

    const showToast = useToast()

    /**
     * 初始資料
     */
    const initialData = {
        patientName:'',
        birthDate:'',
        idNo:'',
        genderName:'',
        homePhoneNo:'',
        phoneNo:'',
        gender:'',
        idTypeNo:'',
        idTypeNoName:'',
        nationality:'',
        adrArea:'',
        adrAreaName:'',
        contactAddress:'',
        aborigineFlag: false,
        nativeptFlag: false,
        preferentialIdentityName:''
    }

    //編輯的資料
    const [editData, setEditData] = useState(initialData)
    //地區列表
    const [areaList, setAreaList] = useState([])
    const minguYearRef = useRef(null);
    const minguMonthRef = useRef(null);
    const minguDayRef = useRef(null);

    /**
     * 計算年齡
     * @param birthdate {String} 出生日期
     * @return {String} 年齡
     */
    const calculateDetailedAge = (birthdate) => {
        const birthDate = new Date(birthdate)
        const today = new Date()

        let ageYears = today.getFullYear() - birthDate.getFullYear()
        let ageMonths = today.getMonth() - birthDate.getMonth()
        let ageDays = today.getDate() - birthDate.getDate();

        // 調整月份和年份
        if (ageDays < 0) {
            // 如果天數負數，從上個月減去一個月，並從該月加上該月的總天數
            ageMonths--;
            const previousMonth = new Date(today.getFullYear(), today.getMonth(), 0); // 取得上個月最後一天
            ageDays += previousMonth.getDate(); // 加上上個月的天數
        }

        if (ageMonths < 0) {
            // 負數月份轉為正，並從年份中減去1
            ageYears--;
            ageMonths += 12;
        }

        return `${ageYears}y${ageMonths}m${ageDays}d`;
    };

    /**
     * 編輯按鈕事件
     * @return {void}
     */
    const handleEditPatientInformationOnClick = () => {
        let minguYears = 0;
        let birthDate = icCardData.birthDate;// === null ? new Date() : icCardData.birthDate
        minguYears = new Date(birthDate).getFullYear() - 1911

        setEditData({
            ...icCardData,
            birthDate: birthDate,
            birthMinguoYears: birthDate === null ? '' : `${String(minguYears).padStart(3, '0')}`,
            birthMinguoMonths: birthDate === null ? '' : `${String(new Date(birthDate).getMonth() + 1).padStart(2, '0')}`,
            birthMinguoDays: birthDate === null ? '' : `${String(new Date(birthDate).getDate()).padStart(2, '0')}`
        })

        // 設定編輯模式為 Inline 或是 Dialog
        if(!stringIsEmpty(icCardData?.patientId)){
            setShowNonFirstTimePatientEditPopup(true)
        }else{setIsEditMode(true)}

    }

    /**
     * 存檔按鈕事件
     */
    const handleSavePatientInformationOnClick = () => {

        // 檢查必填欄位
        if (stringIsEmpty(editData?.patientName)) {
            showToast({message: "姓名欄位為必填欄位，不得為空", type: AlertTypeEnum.Warning})
            return
        }

        if (stringIsEmpty(editData?.idNo)) {
            showToast({message: "身分證號欄位為必填欄位，不得為空", type: AlertTypeEnum.Warning})
            return
        }

        if (stringIsEmpty(editData?.birthDate)) {
            showToast({message: "出生日期欄位為必填欄位，不得為空", type: AlertTypeEnum.Warning})
            return
        }

        // 檢查是否為未來日期
        if (dayjs(editData.birthDate).isAfter(dayjs())) {
            showToast({message: "出生日期不可大於今日", type: AlertTypeEnum.Warning})
            return
        }

        // 檢查手機號碼或住家號碼是否有填寫其中一項
        if (stringIsEmpty(editData.homePhoneNo) && stringIsEmpty(editData.phoneNo)) {
            showToast({message: "住家號碼或手機號碼至少填寫一個", type: AlertTypeEnum.Warning})
            return
        }

        // 檢測手機號碼格式
        if (!stringIsEmpty(editData.phoneNo)) {
            //檢測手機號碼格式: 09開頭+後面8個數字
            const regex = /^09\d{8}$/;
            if (!regex.test(editData.phoneNo)) {
                showToast({message: "手機號碼格式不正確", type: AlertTypeEnum.Warning})
                return
            }
        }

        // 儲存編輯的病人資料
       /* saveEditPatient(
            editData.patientName,
            time2String(editData.birthDate, "YYYY-MM-DD 00:00:00"),
            editData.idNo,
            editData.genderName,
            editData.homePhoneNo,
            editData.phoneNo)*/

        // 呼叫父層的存檔事件並儲存編輯的病人資料
        saveEditPatient(
            {
                patientName:editData.patientName,
                birthDate:time2String(editData.birthDate, "YYYY-MM-DD 00:00:00"),
                idNo:editData.idNo,
                genderName:editData.genderName,
                homePhoneNo:editData.homePhoneNo,
                phoneNo:editData.phoneNo,
                adrArea:editData.adrArea,
                adrAreaName:editData.adrAreaName,
                contactAddress:editData.contactAddress,
                nationality:editData.nationality,
                aborigineFlag:editData.aborigineFlag||false,
                nativeptFlag:editData.nativeptFlag||false,
                idTypeNo:editData.idTypeNo
            }
        )
    }

    /**
     * 姓名輸入框變更事件
     * @param {Event} e
     */
    const handleInputNameTextOnChange = (e) => {
        setEditData(prev => ({
            ...prev,
            patientName: e.target.value
        }));
    }

    /**
     * 出生日期輸入框變更事件
     * @param {Dayjs | null} date
     */
    const handleInputBirthDateTextOnChange = (date) => {
        // 檢查是否為有效日期
        if (!date || !date.isValid()) {
            setEditData((prev) => ({
                ...prev,
                birthDate: null,
                birthMinguoYears: '',
                birthMinguoMonths: '',
                birthMinguoDays: ''
            }));
            return;
        }

        // 計算民國年，西元年減去1911
        const minguoYear = date.year() - 1911;

        setEditData((prev) => ({
            ...prev,
            birthDate: date.format("YYYY-MM-DD 00:00:00"), // 使用 dayjs 來格式化日期
            // 同步民國年、月、日
            birthMinguoYears: `${String(minguoYear).padStart(3, '0')}`, // 民國年填充為3位數
            birthMinguoMonths: `${String(date.month() + 1).padStart(2, '0')}`, // 月份從0開始，需要加1
            birthMinguoDays: `${String(date.date()).padStart(2, '0')}`, // 日期格式化為2位數
        }));
    };

    /**
     * 身分證號輸入框變更事件
     * @param {Event} e
     */
    const handleInputIdNoTextOnChange = (e) => {
        const idNumber = e.target.value
        const regxIdNo = /^[A-Za-z][A-Za-z0-9]{9}$/;
        if(regxIdNo.test(idNumber) && !objectIsEmpty(baseData) && !arrayIsEmpty(baseData.genderList)) {
            //是身分證格式()
            //判斷性別
            const genderName = checkGender(idNumber)
            const genderObj = baseData.genderList.find(item => item.justnameName === genderName);
            if(!objectIsEmpty(genderObj) && (genderObj.justnameNo === '1' || genderObj.justnameNo === '2')) {
                //判斷是男或是女時

                setEditData(prev => ({
                    ...prev,
                    genderName: genderObj.justnameName
                }));
            }
        }

        setEditData(prev => ({
            ...prev,
            idNo: e.target.value
        }));
    }

    /**
     * 電話輸入框變更事件
     * @param {Event} e
     */
    const handleInputhomePhoneNoTextOnChange = (e) => {
        setEditData(prev => ({
            ...prev,
            homePhoneNo: e.target.value
        }));
    }

    /**
     * 地區選單點擊事件
     * @param option {Object} 點擊的選項
     * @return {void}
     */
    const handleAreaOptionOnClick = (option) => {
        // 選項不為空
        if(option !== null){
            setEditData({
                ...editData,
                adrArea: option.justnamedNo,
                adrAreaName: option.justnamedName
            })
        }
    }

    /**
     * 通訊地址輸入框變更事件
     * @param e {Event} 事件物件
     * @return {void}
     */
    const handleInputAddressTextOnChange = (e) => {
        
        setEditData(prev => ({
            ...prev,
            contactAddress: e.target.value
        }));
    }

    /**
     * 國籍輸入框變更事件
     * @param e {Event}
     * @return {void}
     */
    const handleInputNationalityTextOnChange = (e) => {
        setEditData({
            ...editData,
            nationality: +e.target.value
        })
    }

    /**
     * 原住民變更事件
     * @param e {Event} 事件物件
     * @return {void}
     */
    const handleAborigineFlagOnChange = (e) => {
        setEditData({
            ...editData,
            aborigineFlag: e.target.checked
        })
    }

    /**
     * 是否為原鄉人變更事件
     * @param e {Object} 事件物件
     * @return {void}
     */
    const handleNativeptFlagOnChange = (e) => {
        setEditData({
            ...editData,
            nativeptFlag: e.target.checked
        });
    }

    const formatDateToMinguo = (date) => {
        const dateObj = new Date(date);
        const year = dateObj.getFullYear() - 1911;
        const month = dateObj.getMonth() + 1; // 月份從0開始，所以需要+1
        const day = dateObj.getDate();

        return `${String(year).padStart(3, '0')}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    }

    /**
     * 民國(年)輸入框Input事件
     * @param {Event} e
     */
    const handleInputMinguoYearsOnInput = (e) => {
        const value = e.target.value;
        // 移除所有非數字字符
        e.target.value = value.replace(/[^0-9]/g, '');
        // 確保值不超過3位數且大於0
        if (e.target.value.length > 3) {
            e.target.value = e.target.value.slice(0, 3);
        }

        if (e.target.value.length === 3 && minguMonthRef.current) {
            minguMonthRef.current.focus();
            minguMonthRef.current.select();
        }
        //if (e.target.value !== '' && parseInt(e.target.value, 10) < 1) {
        //    e.target.value = 1;
        //}
    }

    /**
     * 民國(月)輸入框Input事件
     * @param {Event} e
     */
    const handleInputMinguoMonthsOnInput = (e) => {
        const value = e.target.value;
        // 移除所有非數字字符
        e.target.value = value.replace(/[^0-9]/g, '');
        // 確保值不超過2位數且大於0
        if (e.target.value.length > 2) {
            e.target.value = e.target.value.slice(0, 2);
        }

        if (e.target.value.length === 2 && minguMonthRef.current) {
            minguDayRef.current.focus();
            minguDayRef.current.select();
        }
    }

    /**
     * 民國(日)輸入框Input事件
     * @param {Event} e
     */
    const handleInputMinguoDaysOnInput = (e) => {
        const value = e.target.value;
        // 移除所有非數字字符
        e.target.value = value.replace(/[^0-9]/g, '');
        // 確保值不超過2位數且大於0
        if (e.target.value.length > 2) {
            e.target.value = e.target.value.slice(0, 2);
        }

    }

    /**
     * 民國(年)輸入框變更事件
     * @param {Event} e
     */
    const handleInputMinguoYearsOnChange = (e) => {
        let date = new Date(editData.birthDate)
        date.setFullYear(Number(e.target.value) + 1911)

        setEditData(prev => ({
            ...prev,
            birthMinguoYears: e.target.value,
            //同步西元
            birthDate: time2String(date, "YYYY-MM-DD 00:00:00")
        }));
    }

    /**
     * 民國(月)輸入框變更事件
     * @param {Event} e
     */
    const handleInputMinguoMonthsOnChange = (e) => {
        let month = Number(e.target.value)
        let date = new Date(editData.birthDate)
        let year = date.getFullYear()
        let day = date.getDate()
        if (month < 1) {
            month = 1
        } else if (month > 12) {
            month = 12
        }
        const newDateString = `${year}-${String(month).padStart(2, '0')}-${day}`
        setEditData(prev => ({
            ...prev,
            birthMinguoMonths: e.target.value,
            //同步西元
            birthDate: time2String(newDateString, "YYYY-MM-DD 00:00:00")
        }));
    }

    /**
     * 民國(日)輸入框變更事件
     * @param {Event} e
     */
    const handleInputMinguoDaysOnChange = (e) => {
        let day = Number(e.target.value)
        let date = new Date(editData.birthDate)
        let year = date.getFullYear()
        let month = date.getMonth() + 1
        if (day < 1) {
            day = 1
        } else if (day > 31) {
            day = 31
        }
        const newDateString = `${year}-${String(month).padStart(2, '0')}-${day}`
        setEditData(prev => ({
            ...prev,
            birthMinguoDays: e.target.value,
            //同步西元
            birthDate: time2String(newDateString, "YYYY-MM-DD 00:00:00")
        }));
    }

    /**
     * 手機號碼輸入框變更事件
     * @param {Event} e
     */
    const handleInputphoneNoTextOnChange = (e) => {
        setEditData(prev => ({
            ...prev,
            phoneNo: e.target.value
        }));
    }

    /**
     * 取消編輯按鈕事件
     */
    const handleEditCancelOnClick = () => {
        setIsEditMode(false)

    }

    /**
     * 根據國籍代碼取得對應文字
     * @param nationality {number} 國籍代碼
     * @return {string} 國籍文字
     */
    const getNationalityText = (nationality) => {
        if (!nationality) return '';
        switch (nationality) {
            case 1:
                return '本國';
            case 2:
                return '外國';
            default:
                return '';
        }
    }

    /**
     * 取得選單地區值
     */
    const getAreaValue = () => {

        if(!editData.adrArea)return null;

        return {
            justnamedNo: editData.adrArea,
            justnamedName: editData.adrAreaName
        }
    }

    /**
     * 監聽編輯模式變化
     */
    useEffect(() => {
        if (!isEditMode) {
            setEditData(null)
        }
    }, [isEditMode])

    /**
     * 取得地區清單
     */
    useEffect(() => {
        regsQueryActiveByCategoriesAndSearch({
            categories: 'AREA',
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setAreaList(res.data)
            }else {
                showToast({message: res.msg, type: AlertTypeEnum.Error})
            }
        })
    }, []);

    return (
        <div>
            <Card title="病歷資訊" height="auto" className="">
                {/*沒有資料時*/}
                {
                    !showData && (
                        <p className="font-globalfont text-[20px] leading-[23px] font-normal text-[#9C9C9C] pb-3">請讀取健保IC卡或<br></br> 搜尋病人資訊
                        </p>)
                }
                {
                    showData && (
                        <>
                            {
                                isEditMode ? (
                                    <div className="flex justify-end space-x-2 -mt-10">
                                        <Button
                                            sx={{fontSize: '1rem', padding: '0', minWidth: 'fit-content'}}
                                            color={ColorEnum.Primary} variant={ButtonVariantEnum.Text}
                                            size={ButtonSizeEnum.Large} icon={IconEnum.Cancel}
                                            onClick={handleEditCancelOnClick}>
                                            {t('general.cancel')}
                                        </Button>
                                        <Button
                                            sx={{fontSize: '1rem', padding: '0', minWidth: 'fit-content'}}
                                            color={ColorEnum.Primary} variant={ButtonVariantEnum.Text}
                                            size={ButtonSizeEnum.Large} icon={IconEnum.Save}
                                            onClick={handleSavePatientInformationOnClick}>
                                            {t('general.saveFile')}
                                        </Button>
                                    </div>
                                ) : (
                                    <div className="flex justify-end -mt-10">
                                        <Button
                                            sx={{ fontSize: '1rem', padding: '0', minWidth: 'fit-content' }}
                                            color={ColorEnum.Primary} variant={ButtonVariantEnum.Text}
                                            size={ButtonSizeEnum.Large} icon={IconEnum.Edit}
                                            onClick={handleEditPatientInformationOnClick}>
                                            {t('general.edit')}
                                        </Button>
                                    </div>
                                )
                            }
                        </>
                    )
                }
                {/*有資料時*/}
                {
                    showData && (
                        <div className="w-full flex flex-col pt-10 space-y-[6px] w-3/4 text-[#18181b] text-[16px]">
                            {/**病歷號 */}
                            <Field labelWidth={80} label={t('Regs.general.medicalRecordNumber')}>
                                <p className="text-left">{(!objectIsEmpty(icCardData) && !stringIsEmpty(icCardData.patientId)) ? icCardData.patientId : ""}</p>
                            </Field>
                            {/**姓名 */}
                            <Field labelWidth={80} label={t('general.username')} isRequired={isEditMode}>
                                {isEditMode ? (
                                    <TextField
                                        size={SizeEnum.Small}
                                        inputWidth={SizeEnum.Fill}
                                        value={editData.patientName}
                                        onChange={handleInputNameTextOnChange}
                                        error={isEditMode && stringIsEmpty(editData?.patientName)}
                                    />
                                ) : (
                                    <p className="h-full flex items-center">
                                        {!objectIsEmpty(icCardData) &&
                                        !stringIsEmpty(icCardData.patientName)
                                            ? icCardData.patientName
                                            : ""}
                                    </p>
                                )}
                            </Field>
                            {/**性別 */}
                            <Field labelWidth={80} label={t('general.gender.name')}>
                                <p className="text-left">
                                    {isEditMode ? (
                                        !objectIsEmpty(editData) &&
                                        !stringIsEmpty(editData.genderName)
                                            ? editData.genderName
                                            : ""

                                    ): (
                                        !objectIsEmpty(icCardData) &&
                                        !stringIsEmpty(icCardData.genderName)
                                            ? icCardData.genderName
                                            : ""
                                    )}
                                </p>
                            </Field>
                            {/**出生日期 */}
                            <Field labelWidth={80} label={t("general.dateOfBirth")} isRequired={isEditMode} sx={{alignItems: "start"}}>
                                {isEditMode ? (
                                    <div className="space-y-1">
                                        <DatePicker
                                            size={SizeEnum.Small}
                                            label=""
                                            value={dayjs(editData.birthDate)}
                                            disableFuture
                                            onChange={handleInputBirthDateTextOnChange}
                                            error={isEditMode && stringIsEmpty(editData?.birthDate)}
                                        />
                                        {/*轉成民國年*/}
                                        <div className="flex items-center space-x-2">
                                            <p>{t("general.dateTime.mingu")}</p>
                                            <div className="flex items-center">
                                                <TextField
                                                    size={SizeEnum.Small}
                                                    inputWidth={SizeEnum.Tiny}
                                                    inputRef={minguYearRef}
                                                    type="number"
                                                    value={editData.birthMinguoYears}
                                                    onInput={handleInputMinguoYearsOnInput}
                                                    onChange={handleInputMinguoYearsOnChange}
                                                />
                                                <p>-</p>
                                                <TextField
                                                    size={SizeEnum.Small}
                                                    inputWidth={SizeEnum.Tiny}
                                                    inputRef={minguMonthRef}
                                                    type="number"
                                                    value={editData.birthMinguoMonths}
                                                    onInput={handleInputMinguoMonthsOnInput}
                                                    onChange={handleInputMinguoMonthsOnChange}
                                                />
                                                <p>-</p>
                                                <TextField
                                                    size={SizeEnum.Small}
                                                    inputWidth={SizeEnum.Tiny}
                                                    inputRef={minguDayRef}
                                                    type="number"
                                                    value={editData.birthMinguoDays}
                                                    onInput={handleInputMinguoDaysOnInput}
                                                    onChange={handleInputMinguoDaysOnChange}
                                                />
                                                {/*Input hide number arrow*/}
                                                <style>
                                                    {`
                                                input::-webkit-outer-spin-button,
                                                input::-webkit-inner-spin-button {
                                                    -webkit-appearance: none;
                                                    margin: 0;
                                                }
                                                input[type="number"] {
                                                    -moz-appearance: textfield;
                                                }
                                                `}
                                                </style>
                                            </div>
                                        </div>
                                    </div>
                                ) : (
                                    <p className="text-left">
                                        {icCardData === null || icCardData.birthDate === null || icCardData.birthDate === "" || icCardData.birthDate === undefined
                                            ? ""
                                            : `${time2String(icCardData.birthDate, "YYYY-MM-DD")} (民國${formatDateToMinguo(
                                                icCardData.birthDate
                                            )})`}
                                    </p>
                                )}
                            </Field>
                            {/**年齡 */}
                            <Field labelWidth={80} label={t('general.age')}>
                                <p className="text-left">
                                    {icCardData === null || icCardData.birthDate === null || icCardData.birthDate === "" || icCardData.birthDate === undefined
                                        ? ""
                                        : `${calculateDetailedAge(icCardData.birthDate)}`}
                                </p>
                            </Field>
                            {/**身分證號 */}
                            <Field labelWidth={80} label={t('general.idNumber')} isRequired={isEditMode}>
                                {
                                    isEditMode ?
                                        <TextField
                                            size={SizeEnum.Small}
                                            inputWidth={SizeEnum.Fill}
                                            value={editData.idNo || ''}
                                            onChange={handleInputIdNoTextOnChange}
                                            error={isEditMode && stringIsEmpty(editData?.idNo)}
                                        /> :
                                        <p className="h-full flex items-center">{(!objectIsEmpty(icCardData) && !stringIsEmpty(icCardData.idNo)) ? icCardData.idNo : ""}</p>
                                }
                            </Field>
                            {/**通訊地址 */}
                            <Field labelWidth={80} label={t('general.correspondenceAddress')}>
                                {
                                    isEditMode ?
                                    <>
                                        <Search
                                            value={getAreaValue()}
                                            options={areaList}
                                            getOptionLabel={(option) => `${option.justnamedNo} ${option.justnamedName}`}
                                            onChange={(_e,value)=>handleAreaOptionOnClick(value)}
                                        />
                                        <TextField
                                            size={SizeEnum.Small}
                                            inputWidth={SizeEnum.Fill}
                                            value={editData.contactAddress || ''}
                                            onChange={handleInputAddressTextOnChange}
                                        />
                                    </> 
                                        :
                                        <p className="h-full flex items-center">{(!objectIsEmpty(icCardData)) ? 
                                            `${icCardData.adrArea || ''} ${icCardData.adrAreaName || ''} ${icCardData.contactAddress || ''}` 
                                            : ""}</p>
                                }
                            </Field>
                            {/**電話 */}
                            <Field labelWidth={80} label={t('general.telephone')}>
                                {
                                    isEditMode ?
                                        <TextField
                                            size={SizeEnum.Small}
                                            inputWidth={SizeEnum.Fill}
                                            value={editData.homePhoneNo || ''}
                                            onChange={handleInputhomePhoneNoTextOnChange}
                                            error={isEditMode && stringIsEmpty(editData?.homePhoneNo) && stringIsEmpty(editData?.phoneNo)}
                                            /> :
                                        <p className="h-full flex items-center">{(!objectIsEmpty(icCardData) && !stringIsEmpty(icCardData.homePhoneNo)) ? icCardData.homePhoneNo : ""}</p>
                                }
                            </Field>
                            {/**手機 */}
                            <Field labelWidth={80} label={t('general.mobilePhone')}>
                                {
                                    isEditMode ?
                                        <TextField
                                            size={SizeEnum.Small}
                                            inputWidth={SizeEnum.Fill}
                                            value={editData.phoneNo || ''}
                                            onChange={handleInputphoneNoTextOnChange}
                                            error={isEditMode && stringIsEmpty(editData?.homePhoneNo) && stringIsEmpty(editData?.phoneNo)}

                                            /> :
                                        <p className="h-full flex items-center">{(!objectIsEmpty(icCardData) && !stringIsEmpty(icCardData.phoneNo)) ? icCardData.phoneNo : ""}</p>
                                }
                            </Field>
                            {/**優待身分 */}
                            <Field labelWidth={80} label="優待身分">
                                <div className="text-left">
                                    {/* 原住民 & 原鄉人暫時先放在這，等確定好再決定怎麼處理 */}
                                    {/* {isEditMode ? (
                                        <>
                                        <Checkbox label={'原住民'} checked={editData.aborigineFlag || false} onChange={handleAborigineFlagOnChange} />
                                        <Checkbox label={'原鄉人'} checked={editData.nativeptFlag || false} onChange={handleNativeptFlagOnChange} />
                                        </>
                                    ) : (
                                        <p className="h-full flex items-center">
                                            {!objectIsEmpty(icCardData) ? (
                                                [
                                                    icCardData.aborigineFlag && '原住民',
                                                    icCardData.nativeptFlag && '原鄉人'
                                                ].filter(Boolean).join('、')
                                            ) : ""}
                                        </p>
                                    )} */}
                                    {/**優待身分有就顯示資料，無就顯示空白 */}
                                    {
                                        icCardData?.preferentialIdentityName ? (
                                            <p className="h-full flex items-center">
                                                {icCardData?.preferentialIdentityName}
                                            </p>
                                    ) : (
                                        <p className="h-full flex items-center">
                                            {''}
                                        </p>
                                    )}
                                </div>
                            </Field>
                            {/**國籍 */}
                            <Field labelWidth={80} label="國籍">
                                <div className="text-left">
                                    {isEditMode ? (
                                        <RadioGroup row={true} size={SizeEnum.Medium}
                                        optionProps={{options:[{label:'本國',value:1},{label:'外國',value:2}]}}
                                        value={editData.nationality || ''}
                                        onChange={handleInputNationalityTextOnChange}
                            />
                                    ) : (
                                        <p className="h-full flex items-center">
                                            {!objectIsEmpty(icCardData) ? getNationalityText(icCardData.nationality) : ""}
                                        </p>
                                    )}
                                </div>
                            </Field>
                        </div>
                    )
                }
                {/*?????*/}
                {showData && <p></p>}


            </Card>
        </div>
    )
}
export default CaseInformation
