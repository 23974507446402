import React, {useEffect, useState} from "react"
import {ApiErrorStatusEnum, arrayIsEmpty, PopupModeEnum, ToastTypeEnum} from "edah_utils/dist"
import {t} from "i18next"
import {essiAddEmrType, essiEditEmrType} from "../../api/v1/ESSI"
import useToast from "../../hooks/useToast"
import {
    Field,
    TextField,
    Select,
    TextArea,
    Dialog,
    DialogSizeEnums,
    DialogVariant,
    SizeEnum,
    AlertTypeEnum,
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    ButtonColorEnum,
} from "edah-component/dist";

/**
 * 電子表單類別維護-新增 / 修改 彈窗
 * @param show 是否顯示
 * @param data {Object} 資料
 * @param mode {Number} 新增或編輯
 * @param parentNameData {Array<String>} 父層代號資料
 * @param closePopupOnClick {function} 關閉按鈕點擊事件
 * @return {JSX.Element}
 */
const AddEditEmrTypePopup = ({show = false, data, mode, parentNameData, closePopupOnClick}) => {
    /**
     * ShowToast
     */
    const showToast = useToast()

    /**
     * 預設資料
     */
    const defaultData = {
        // 表單類別代號
        "emrRptType": '',
        // 表單名稱
        "rptName": '',
        // 顯示順序
        "sortBy": '',
        // 有效否(0/1)
        "effFlag": true,
        // 備註
        "memo": '',
        // 父項類別代號
        "parentEmrRptType": ''
    }

    //是否為新增模式
    const [isAddMode, setIsAddMode] = useState(false)

    //是否顯示彈窗
    const [showPopup, setShowPopup] = useState(show)

    //新增或修改的資料
    const [newData, setNewData] = useState(mode === PopupModeEnum.Add ? defaultData : data)

    /**
     * 確定刪除點擊事件
     * @return {void}
     */
    const handleCloseButtonOnClick = () => {
        // 關閉彈窗
        setShowPopup(false)
        // 關閉彈窗後執行父層的關閉事件
        closePopupOnClick()
    }

    /**
     * 儲存或編輯按鈕被點擊時
     * @return {void}
     */
    const handleSaveOrEditOnClick = () => {
        if (isAddMode) {
            addEmrType()
        } else {
            editEmrType()
        }
    }

    /**
     * 排序欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleSortByOnChange = (e) => setNewData({...newData, sortBy: e.target.value})

    /**
     * 父層代號欄位改變時
     * @param value {String}
     * @return {void}
     */
    const handleParentEmrRptTypeOnChange = (value) => setNewData({...newData, parentEmrRptType: value})

    /**
     * 單據代號欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleEmrRptTypeOnChange = (e) => {
        // 如果不是新增模式
        if (!isAddMode) {
            // 不允許修改
            return
        }
        setNewData({...newData, emrRptType: e.target.value})
    }

    /**
     * 單據名稱欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleRptNameOnChange = (e) => setNewData({...newData, rptName: e.target.value})

    /**
     * 說明欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleMemoOnChange = (e) => setNewData({...newData, memo: e.target.value})

    /**
     * 有效否欄位改變時
     * @param e {Event}
     * @return {void}
     */
    const handleEffFlagOnChange = (e) => setNewData({...newData, effFlag: !newData.effFlag})

    /**
     * 新增電子表單類別
     * @return {void}
     */
    const addEmrType = () => {
        essiAddEmrType([newData]).then(handleApiResponse)
    }

    /**
     * 編輯電子表單類別
     * @return {void}
     */
    const editEmrType = () => {
        essiEditEmrType([newData]).then(handleApiResponse)
    }

    /**
     * 處理API回應
     * @param res {Object} API回應
     * @return {void}
     */
    const handleApiResponse = (res) => {
        // 取得API回應
        if (res.err === ApiErrorStatusEnum.Success) {
            // 顯示成功訊息
            showToast({message: res.msg})
            closePopupOnClick()
        } else { // 顯示錯誤訊息
            showToast({type: AlertTypeEnum.Error, message: res.msg})
        }
    }

    /**
     * 彈窗 Content
     * @return {React.JSX.Element}
     */
    const Content = () =>(<>
        <div className="flex flex-row p-[9px] space-x-[20px] text-[16px]">
            {/* 左欄 */}
            <div className="w-1/2 space-y-[4px]">
                {/*顯示順序*/}
                <Field label={"顯示順序"}>
                    <TextField size={SizeEnum.Medium} value={newData.sortBy} onChange={handleSortByOnChange}/>
                </Field>
                {/*父層代號*/}
                <Field label={'父層代號'}>
                    <Select
                        data={{
                            label: '父層代號',
                            options: parentNameData && parentNameData?.map((option=> ({
                                label:option,
                                value:option
                            }))) || []
                        }}
                        value={newData.parentEmrRptType}
                        onChange={handleParentEmrRptTypeOnChange}
                        showLabel={false}
                    />
                </Field>
                {/*單據代號*/}
                <Field label={'單據代號'}>
                    <TextField
                        size={SizeEnum.Medium}
                        disabled={!isAddMode}
                        value={newData.emrRptType}
                        onChange={handleEmrRptTypeOnChange} />

                </Field>
            </div>
            {/* 右欄 */}
            <div className="w-1/2 space-y-[4px]">
                {/* 單據是否有效 */}
                <Field label={'單據是否有效'}>
                    <label className='flex cursor-pointer select-none items-center h-10'>
                        <div className='relative'>
                            <input className='sr-only' type='checkbox' checked={newData.effFlag}
                                   onChange={handleEffFlagOnChange}/>
                            <div
                                className={`block h-8 w-14 rounded-full ${newData.effFlag ? 'bg-[#2B6CB0]' : 'bg-[#F4F4F5]'}`}>
                                <div
                                    className={`left-[4px] top-[4px] absolute w-6 h-6 bg-white rounded-full shadow transition-transform transform ${newData.effFlag ? 'translate-x-full' : ''}`}></div>
                            </div>
                        </div>
                    </label>
                </Field>
                {/*單據名稱*/}
                <Field label={'單據名稱'}>
                    <TextField
                        size={SizeEnum.Medium}
                        value={newData.rptName}
                        onChange={handleRptNameOnChange}/>
                </Field>
            </div>
        </div>
        <div className="w-full p-[9px] mb-2">
            <Field label={"說明"}>
                <TextArea
                    value={newData.memo}
                    onChange={handleMemoOnChange}
                    placeholder={"請輸入說明"}
                    sx={{width:'calc(100% - 53px)'
                    }}
                />
            </Field>
        </div>
    </>)

    const Footer = ()=>(<><Button
        color={ButtonColorEnum.Primary}
        size={ButtonSizeEnum.Medium}
        variant={ButtonVariantEnum.Contained}
        text={t('general.submit')}
        onClick={handleSaveOrEditOnClick}
    /></>)

    /**
     * 監聽是否顯示彈窗Pros變化
     * @return {void}
     */
    useEffect(() => {
        // 設定是否顯示彈窗
        setShowPopup(show)
        const isAddMode = mode === PopupModeEnum.Add
        // 設定是否為新增模式
        setIsAddMode(isAddMode)
        if (show) {
            // 如果是新增模式
            if (isAddMode) {
                setNewData(defaultData)
            } else {
                // 如果是編輯模式
                setNewData(data)
            }
        }
    }, [show])

    return (
        <Dialog
            open={show}
            title={"電子表單維護"}
            onClose={handleCloseButtonOnClick}
            variant={DialogVariant.CUSTOM}
            paperStyleProps={{width:DialogSizeEnums.MD}}
            content={Content()}
            footerContent={Footer()}
        />
    )

}
export default AddEditEmrTypePopup
