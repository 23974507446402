import {useEffect, useState} from "react";
import {t} from "i18next";
import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    fuzzySearchObjects,
    objectIsEmpty,
    RegsWeekModeEnum,
    stringIsEmpty,
    time2String,
} from "edah_utils/dist";
import CancelStopClinicPromptPopup from "../../components/Regs/StopClinic/CancelStopClinicPromptPopup";
import StopClinicListPromptPopup from "../../components/Regs/StopClinic/StopClinicListPromptPopup";
import {
    regsAddStopdregdAndCancelAndCompSave,
    regsCancelStopClinicAndLocum,
    regsQueryStopClinicBaseData,
    regsQueryStopClinicContactHistory,
    regsQueryStopClinicReg,
} from "../../api/v1/RegsStopClinic";
import {queryAllDivision, queryAllDoctor} from "../../api/v1/Menu";
import useToast from "../../hooks/useToast";
import {
    AlertTypeEnum,
    Button,
    ButtonSizeEnum,
    ButtonVariantEnum,
    Card,
    Checkbox,
    ColorEnum,
    DatePicker,
    DateRangePicker,
    Divider,
    DividerOrientationEnum,
    Field,
    RadioGroup,
    Search,
    Select,
    SizeEnum,
    TextArea,
    TextField,
    TimePicker,
    Pagination,
    AdvancedDataGrid, SelectWidthEnum
} from "edah-component/dist";
import dayjs from 'dayjs';
import {SearchTextField} from "../../components/SearchTextField/SearchTextField";

/**
 * 停代診作業
 * @return {JSX.Element}
 */
const StopClinic = () => {
    // 預設建立時間起迄:起-今日往前兩周~迄-今日日期
    const today = new Date();
    //預設的查詢輸入欄位資料
    const defaultQueryInputData = {
        //搜尋文字
        text: "",
        //建立時間起迄
        dateStart: time2String(today, "yyyy-MM-DD"),
        //終止時間迄
        dateEnd: time2String(today, "yyyy-MM-DD"),
        //聯絡狀態
        contactStatusMode: "0",
        // 醫師 or 科別 or 病歷號 過濾模式
        filterMode: RegsWeekModeEnum.Doctor,
    };
    // 查詢輸入欄位的資料 (顯示)
    const [displayQueryInputData, setDisplayQueryInputData] = useState(defaultQueryInputData);
    // 查詢輸入欄位的資料 (查詢後)
    const [queryInputData, setQueryInputData] = useState(defaultQueryInputData);
    //進階搜尋文字
    const [advSearchText, setAdvSearchText] = useState("");
    //簡訊文字
    const [messageText, setMessageText] = useState("");
    // Viewing Filter Option -> 醫師 list or 科別 list
    const [doctorOrDeptOptions, setDoctorOrDeptOptions] = useState(null);
    // 聯絡日期
    const [contactDate, setContactDate] = useState(today);
    // 聯絡時間
    const [contactTime, setContactTime] = useState(today);
    // 片語搜尋文字
    const [searchPhraseText, setSearchPhraseText] = useState("");
    // 聯絡內容文字
    const [contactContainText, setContactContainText] = useState("");
    // 選擇到的資料
    const [selectedData, setSelectedData] = useState(null);
    // 停代診明細查詢資料清單
    const [filteredDataList, setFilteredDataList] = useState(null);
    // 停代診明細進階搜尋清單
    const [advSearchDataList, setAdvSearchDataList] = useState([]);
    // 停代診基本資料
    const [baseData, setBaseData] = useState(null);
    // 選擇到停代診的資料
    const [selectedRows, setSelectedRows] = useState([]);
    // 是否有選擇資料
    const [haveSelectedData, setHaveSelectedData] = useState(false);
    //聯絡紀錄 清單
    const [contactHistoryList, setContactHistoryList] = useState(null);
    //片語資料
    const [phraseData, setPhraseData] = useState([
        {no: "1", name: "電話未接"}, {no: "2", name: "稍後再通知"}, {no: "3", name: "已通知"},
        {no: "4", name: "已改掛"}, {no: "5", name: "颱風天"}]);
    //時段列表
    const apnList = [{justnameNo: "", justnameName: "全部"}, {justnameNo: "1", justnameName: "早"}, {
        justnameNo: "2", justnameName: "午"
    }, {justnameNo: "3", justnameName: "晚"}]
    //停代診清單提示彈出窗顯示
    const [showStopClinicListPrompt, setShowStopClinicListPrompt] = useState(false);
    //取消停診提示彈出窗顯示
    const [showCancelStopClinicPrompt, setShowCancelStopClinicPrompt] = useState(false);
    //全部醫師list資料
    const [allDoctorList, setAllDoctorList] = useState([]);
    //全部科別list資料
    const [allDivList, setAllDivList] = useState([]);
    //退掛原因 代碼
    const [cancelRegReason, setCancelRegReason] = useState("");
    //是否完成聯絡
    const [isCompleteContact, setIsCompleteContact] = useState(false);
    // 停代診明細 pagination控制變數
    const [paginationProps, setPaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 10,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    });
    //Toast Message Hooks
    const showToast = useToast();

    /**
     * 日期範圍變更事件
     * @param  newValue {Array} 日期範圍 [開始日期, 結束日期]
     */
    const handleDateRangeOnChange = (newValue) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            dateStart: newValue[0] ? dayjs(newValue[0]).format('YYYY-MM-DD') : null,
            dateEnd: newValue[1] ? dayjs(newValue[1]).format('YYYY-MM-DD') : null,
        });
    };

    /**
     * 時段下拉選單選擇事件
     * @param value {String} 選單內容文字
     * @return {void}
     */
    const handleSelectApnOnChange = (value) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            apn: value
        })
    }

    /**
     * 聯絡狀態模式改變時
     * @param mode {Number} 聯絡狀態模式
     * @return {void}
     */
    const handleFilterContactStatusModeOnChange = (mode) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            contactStatusMode: mode,
        });
    };

    // 在選擇資料時更新聯絡狀態
    useEffect(() => {
        if (selectedData) {
            updateContactStatus(selectedData);
        }
    }, [selectedData]);

    /**
     * 監聽selectedData和selectedRows的變化
     * @return {void}
     */
    useEffect(() => {
        // 設定是否有選擇資料的狀態
        // 當有單筆選擇或多筆選擇時都要允許存檔
        const haveSelected = !objectIsEmpty(selectedData) || selectedRows.length > 0;
        //取得選擇明細的聯絡紀錄
        getSelectedContactHistory();
        //設定是否已有選擇資料
        setHaveSelectedData(haveSelected);
    }, [selectedData, selectedRows]);

    /**
     * 查詢別模式改變時
     * @param mode {String} 查詢別模式
     * @return {void}
     */
    const handleFilterModeOnChange = (mode) => {
        if (+mode === RegsWeekModeEnum.Department || +mode === RegsWeekModeEnum.Doctor) {
            const ele = document.querySelector('button.MuiAutocomplete-clearIndicator')
            if (ele) ele.click();
        }

        setDisplayQueryInputData({
            ...displayQueryInputData,
            filterMode: +mode || '',
            text: "",
        })

    }


    /**
     * 取得全部醫師或科別資料
     * @return {Array<Object>}
     */
    const getOptions = () => {
        switch (displayQueryInputData.filterMode) {
            case RegsWeekModeEnum.Department:
                return allDivList
            case RegsWeekModeEnum.Doctor:
                return allDoctorList
        }
    }

    /**
     * 取得全部醫師或科別資料
     * @param option {Object} 選項
     * @return {String}
     */
    const getOptionLabels = (option) => {
        switch (displayQueryInputData.filterMode) {
            case RegsWeekModeEnum.Department:
                return `${option.divNo} ${option.divName}`
            case RegsWeekModeEnum.Doctor:
                return `${option.userNo} ${option.userName}`
        }
    }

    /**
     * 搜尋文字被點擊時
     * @param option {Object} 選項
     * @return {void}
     */
    const handleSearchOnChange = (option) => {
        if (!option) {
            setDisplayQueryInputData({...displayQueryInputData, text: ''})
            return
        }

        if (displayQueryInputData.filterMode === RegsWeekModeEnum.Department) {
            setDisplayQueryInputData({...displayQueryInputData, text: `${option.divNo} ${option.divName}`})
        } else if (displayQueryInputData.filterMode === RegsWeekModeEnum.Doctor) {
            setDisplayQueryInputData({...displayQueryInputData, text: `${option.userNo} ${option.userName}`})
        }
    }

    /**
     * 搜尋文字改變時
     * @param value {String} 搜尋文字
     * @return {void}
     */
    const handleSearchTextOnChange = (value) => {
        setDisplayQueryInputData({
            ...displayQueryInputData,
            text: value,
        });
    };

    /**
     * 進階搜尋文字改變時
     * @param e {Event} 事件
     */
    const handleAdvanceSearchTextOnChange = (e) =>
        setAdvSearchText(e.target.value);

    /**
     * 簡訊文字改變時
     * @param e {Event} 事件
     */
    const handleMessageTextOnChange = (e) => setMessageText(e.target.value);

    /**
     * 聯絡日期輸入欄位值改變時
     * @param e {Event} 事件
     * @returns
     */
    const handleInputContactDateOnChange = (e) => {
        const formattedDate = dayjs(e.target.value).isValid()
            ? dayjs(e.target.value).format('YYYY-MM-DD') // 確認日期格式有效時進行格式化
            : null;

        setContactDate(formattedDate);
    };

    /**
     * 聯絡時間輸入欄位值改變時
     * @param e {Event} 事件
     * @returns
     */
    const handleInputContactTimeOnChange = (e) =>
        setContactTime(e.target.value);

    /**
     * 資料項目選擇事件
     * @param data {Object} 資料
     */
    const handleDataOnClick = (data) => {
        // 增加空值檢查
        if (!data || data.key === undefined) {
            return;
        }

        const findedData = filteredDataList.find(
            (item) => item.key === data.key
        );
        setSelectedData((prev) => findedData);

        //清空聯絡內容
        resetContactContain();
        //清空簡訊
        setMessageText("");
    };

    /**
     * 查詢按鈕點擊事件
     * @return {void}
     */
    const handleSearchOnClick = () => {
        if (
            displayQueryInputData.dateEnd &&
            displayQueryInputData.dateStart > displayQueryInputData.dateEnd
        ) {
            showToast({
                message: "起始日期不可大於結束日期",
                type: AlertTypeEnum.Error,
            });
            return;
        }

        // 清空選擇狀態
        setSelectedRows([]);
        setSelectedData(null);
        // 清空聯絡內容
        resetContactContain();
        // 清空簡訊
        setMessageText("");

        // 執行查詢
        queryStopClinicData(displayQueryInputData, 1, paginationProps.pageSize);
    };

    /**
     * 發簡訊按鈕點擊事件
     */
    const handleSendMessageOnClick = () => {
    };

    /**
     * 聯絡內容 存檔按鈕點擊事件
     * 處理多筆資料的儲存
     */
    const handleSaveContactDetailsOnClick = () => {
        // 驗證必填欄位
        if (!contactContainText.trim()) {
            showToast({
                message: "請填寫聯絡內容",
                type: AlertTypeEnum.Error,
            });
            return;
        }
        // 取得所有選中的掛號ID
        const regIdList = selectedRows
            .map(key => {
                const row = filteredDataList.find(item => item.key === key);
                // 判斷是否為初診預約或一般掛號
                return row ? (stringIsEmpty(row.regId) ? row.firstregId : row.regId) : null;
            })
            .filter(id => id !== null) // 過濾掉無效的ID
            .join(','); // 將ID用逗號串接

        // 驗證是否有選擇資料
        if (!regIdList) {
            showToast({
                message: "請選擇資料",
                type: AlertTypeEnum.Error,
            });
            return;
        }
        // 是否有選擇退掛原因
        const hasCancelReason = cancelRegReason !== "";
        // 儲存當前選中的資料，用於後續更新
        const currentSelectedData = selectedData;

        // 呼叫API儲存資料
        regsAddStopdregdAndCancelAndCompSave({
            regIdList: regIdList, // 傳入串接好的掛號ID清單
            contactStatus: contactContainText, // 聯絡狀況
            // 如果有退掛原因，設定為已完成聯絡
            completeFlag: hasCancelReason ? true : isCompleteContact, // 是否完成聯絡
            // 退掛原因代碼
            cancelRegReasoncode: cancelRegReason === "" ? null : cancelRegReason,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                // 根據是否有退掛原因顯示不同的成功訊息
                showToast({
                    message: hasCancelReason ? "退掛成功" : "儲存聯絡紀錄成功",
                    type: AlertTypeEnum.Success,
                });
                // 清空聯絡內容輸入框
                setContactContainText("");

                // 如果有選擇退掛原因，清空所有資料並重新查詢
                if (hasCancelReason) {
                    setSelectedRows([]);
                    setSelectedData(null);
                    resetContactContain();
                    setMessageText("");
                    // 重新取得停代診明細
                    queryStopClinicData(
                        queryInputData,
                        1,
                        paginationProps.pageSize
                    );
                } else {
                    // 沒有退掛原因時，保持選擇狀態並更新資料
                    queryStopClinicData(
                        queryInputData,
                        1,
                        paginationProps.pageSize
                    ).then((dataList) => {
                        if (currentSelectedData) {
                            const updatedRow = dataList.find(
                                item => (stringIsEmpty(item.regId) ? item.firstregId : item.regId) ===
                                    (stringIsEmpty(currentSelectedData.regId) ? currentSelectedData.firstregId : currentSelectedData.regId)
                            );
                            if (updatedRow) {
                                setSelectedData(updatedRow);
                                setSelectedRows([updatedRow.key]);
                            }
                        }
                        // 重新取得聯絡紀錄
                        if (currentSelectedData) {
                            regsQueryStopClinicContactHistory({
                                regId: !stringIsEmpty(currentSelectedData.regId) ? currentSelectedData.regId : "",
                                firstregId: stringIsEmpty(currentSelectedData.regId) ? currentSelectedData.firstregId : "",
                            }).then((historyRes) => {
                                if (historyRes.err === ApiErrorStatusEnum.Success) {
                                    setContactHistoryList(historyRes.data);
                                }
                            });
                        }
                    });
                }
            } else {
                const msg = `儲存聯絡紀錄失敗: ${res.msg}`;
                showToast({message: msg, type: AlertTypeEnum.Error});
            }
        });
    };

    /**
     * 取消停診按鈕點擊事件
     */
    const handlesetCancelStopClinicClick = () => {
        setShowCancelStopClinicPrompt(true);
    };

    /**
     * 停代診清單按鈕點擊事件
     */
    const handleStopClinicListOnClick = () => {
        setShowStopClinicListPrompt(true);
    };

    /**
     * 關閉取消停診Popup
     */
    const closeCancelStopClinicPromptPopup = () => {
        setShowCancelStopClinicPrompt(false);
    };

    /**
     * 關閉停代診明細Popup
     */
    const closeStopClinicListPromptPopup = () => {
        setShowStopClinicListPrompt(false);
    };

    /**
     * 確認取消停診
     * @param clinicId {String} 診間id
     */
    const confirmCancelStopClinic = (clinicId) => {
        regsCancelStopClinicAndLocum({
            clinicId: clinicId,
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                showToast({
                    message: "取消停診成功",
                    type: AlertTypeEnum.Success,
                });
                //重新取得停代診明細
                queryStopClinicData(
                    queryInputData,
                    1,
                    paginationProps.pageSize
                );
            } else {
                const msg = `取消停診失敗: ${res.msg}`;
                showToast({message: msg, type: AlertTypeEnum.Error});
            }
        });
        setShowCancelStopClinicPrompt(false);
    };

    /**
     * 片語搜尋輸入文字變化時
     * @param e {Event} 事件
     * @returns
     */
    const handleSearchPhraseTextOnChange = (e) =>
        setSearchPhraseText(e.target.value);

    /**
     * 聯絡資訊內容文字改變時
     * @param e {Event} 事件
     * @returns
     */
    const handleContactContainTextOnChange = (e) =>
        setContactContainText(e.target.value);

    /**
     * 退掛原因下拉選單改變時
     * @param value {String} 退掛原因代碼
     * @returns
     */
    const handleCancelRegReasonOnChange = (value) => {
        // 如果值小於 0 或無效，設為空字串
        const validValue = (!value || value < 0) ? '' : value;
        setCancelRegReason(validValue);
    };


    /**
     * 完成聯絡勾選狀態改變時
     * @returns
     */
    const handleIsCompleteContactOnChange = (checked) =>
        setIsCompleteContact(checked);

    // 更新聯絡狀態
    const updateContactStatus = (item) => {
        setIsCompleteContact(item?.contactCompFlag === 'Y');
    };

    /**
     * 片語Item點擊事件
     * @param phrase {String} 片語
     */
    const handlePhraseOnClick = (phrase) => {
        setContactContainText((prevText) => `${prevText}${phrase}`);
    };


    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     * @param pageSize {Number} 每頁資料筆數
     */
    const onPaginationPageOnChange = (page, pageSize) => {
        queryStopClinicData(queryInputData, page, pageSize);
        setPaginationProps({
            ...paginationProps,
            currentPage: page,
            pageSize: pageSize
        })
    };

    /**
     * 取得選擇明細的聯絡紀錄
     * @returns {Array<object>}
     */
    const getFuzzySearchPhraseList = () => {
        return fuzzySearchObjects(phraseData, searchPhraseText, ["no"]);
    };

    /**
     * 檢查資料是否被選擇
     * @param data {Object} 資料
     * @return {Boolean}
     */
    const checkDataIsSelected = (data) => {
        if (objectIsEmpty(selectedData)) {
            return false;
        }

        return selectedData.key === data.key;
    };

    /**
     * 停代診基本資料取得
     */
    const getStopClinicBasicData = () => {
        regsQueryStopClinicBaseData({}).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setBaseData(res.data);
            } else {
                console.error(res);
            }
        });
    };

    /**
     * 取得停代診明細
     * @param queryInputObj {Object} 查詢條件
     * @param page {Number} 頁碼
     * @param pageSize {Number} 每頁資料筆數
     * @return {void}
     */
    const queryStopClinicData = (queryInputObj, page, pageSize) => {
        //把暫存的查詢條件寫入到真實的查詢條件
        setQueryInputData(queryInputObj);
        let doctorNo = "";
        let divNo = "";
        let patientId = "";
        if (queryInputObj.filterMode === '') {
            patientId = queryInputObj.text;
        } else {
            //醫師或科別時
            //分割字串
            const splitArray = queryInputObj.text
                ? queryInputObj.text.split(" ")
                : [];
            if (queryInputObj.text) {
                if (queryInputObj.filterMode === RegsWeekModeEnum.Doctor) {
                    doctorNo = splitArray[0];
                } else {
                    divNo = splitArray[0];
                }
            }
        }
        // 回傳 Promise
        return regsQueryStopClinicReg({
            //完成 or 未完成 or 全部
            compFlag:
                queryInputObj.contactStatusMode === "0"
                    ? false
                    : queryInputObj.contactStatusMode === "1"
                        ? true
                        : null,
            //起始日期
            startDate: time2String(
                queryInputObj.dateStart,
                "YYYY-MM-DD 00:00:00"
            ),
            //結束日期
            endDate: time2String(queryInputObj.dateEnd, "YYYY-MM-DD 00:00:00"),
            // 時段
            apn: queryInputObj.apn || null,
            //醫師
            doctorNo: doctorNo,
            //科別
            divNo: divNo,
            //病歷號
            patientId: patientId,
            pageNum: page, //第幾頁
            pageSize: pageSize, //一頁幾筆資料
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                //在資料中加上key值
                const dataWithKeys = res.data.dataList
                    ? res.data.dataList.map((item, index) => {
                        return {...item, key: index};
                    })
                    : [];
                setFilteredDataList(dataWithKeys);
                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: res.data.totalItemSize,
                    totalPageSize: res.data.totalPageSize,
                    currentPage: page,
                    pageSize: pageSize,
                });
                return dataWithKeys; // 回傳處理後的資料
            } else {
                setFilteredDataList([]);
                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: 0,
                    totalPageSize: 0,
                    pageSize: pageSize,
                });
                const msg = `搜尋失敗: ${res.msg}`;
                showToast({message: msg, type: AlertTypeEnum.Error});
                return []; // 發生錯誤時回傳空陣列
            }
        });
    };

    /**
     * 清空聯絡內容
     * @return {void}
     */
    const resetContactContain = () => {
        setIsCompleteContact(false);
        //清空聯絡內容
        setContactContainText("");
        //清空退掛
        setCancelRegReason("");
    };

    /**
     * 取得進階搜尋停代診明細
     */
    const getAdvanceSearchStopClinicData = () => {
        const tmpArray = arrayIsEmpty(filteredDataList)
            ? []
            : filteredDataList.map((item) => ({
                //key
                key: item.key, //這個不要過濾，不在顯示欄位內

                //只copy要進階搜尋的欄位
                //完成與否
                contactCompFlag: item.contactCompFlag,
                //門診日期
                encounterDate: time2String(item.encounterDate, "YYYY-MM-DD"),
                //病歷號
                patientId: item.patientId,
                //病人姓名(聯絡次數)
                patientName: `${item.patientName}${item.regsStopregdCount ? ` (${item.regsStopregdCount})` : ''}`,
                //性別
                genderName: item.genderName,
                //出生日期
                birthDate: time2String(item.birthDate, "YYYY-MM-DD"),
                //手機
                phoneNo: item.phoneNo,
                //電話
                homePhoneNo: item.homePhoneNo,
                //看診號
                viewNo: item.viewNo,
                //醫師
                doctorName: item.doctorName,
                //科別
                divName: item.divName,
                //診室號
                clinicNo: item.clinicNo,
                //時段
                apnName: item.apnName,
                //停代診原因
                stopClinicReasonName: item.stopClinicReasonName,
                //代診醫師
                locumDoctorName: item.locumDoctorName,
                //退掛時間
                cancelRegDatetime: stringIsEmpty(item.cancelRegDatetime)
                    ? ""
                    : time2String(
                        item.cancelRegDatetime,
                        "YYYY-MM-DD HH:mm:ss"
                    ),
                //退掛原因
                cancelRegReasonName: item.cancelRegReasonName,
                //退掛人員
                cancelRegUserName: item.cancelRegUserName,
                //簡訊
            }));


        //開始模糊搜尋
        const findedData = fuzzySearchObjects(tmpArray, advSearchText, ["key"]);
        setAdvSearchDataList(findedData);

        if (!objectIsEmpty(selectedData)) {
            //查找已被selected的資料是否在模糊搜尋結果的資料列內
            const haveSelected = arrayIsEmpty(findedData)
                ? false
                : findedData.some((item) => item.key === selectedData.key);
            if (!haveSelected) {
                //不存在的話將selected設為null
                setSelectedData(null);
            }
        }
    };

    /**
     * 取得選擇明細資料的聯絡紀錄
     * @return {void}
     */
    const getSelectedContactHistory = () => {
        const data = selectedData;
        if (
            objectIsEmpty(data) ||
            (stringIsEmpty(data.regId) && stringIsEmpty(data.firstregId))
        ) {
            setContactHistoryList([]);
            return;
        }

        regsQueryStopClinicContactHistory({
            regId: !stringIsEmpty(data.regId) ? data.regId : "",
            firstregId: stringIsEmpty(data.regId) ? data.firstregId : "",
        }).then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                setContactHistoryList(res.data);
            } else {
                setContactHistoryList([]);
            }
        });
    };

    // 停代診明細表頭
    const header = [
        {field: 'contactCompFlag', headerName: t("general.done"), width: 60},
        {field: 'encounterDate', headerName: t("Regs.general.clinicDate"), width: 110},
        {field: 'patientId', headerName: t("Regs.general.medicalRecordNumber"), width: 130},
        {field: 'patientName', headerName: '病人姓名', width: 110},
        {field: 'genderName', headerName: t("general.gender.name"), width: 60},
        {field: 'birthDate', headerName: t("general.dateOfBirth"), width: 110},
        {field: 'phoneNo', headerName: '手機', width: 120},
        {field: 'homePhoneNo', headerName: '電話',},
        {field: 'viewNo', headerName: t("Regs.general.viewNumber"), width: 60},
        {field: 'doctorName', headerName: t("general.doctor"), width: 80},
        {field: 'divName', headerName: t("general.department"), width: 130},
        {field: 'clinicNo', headerName: '診室號', width: 70},
        {field: 'apnName', headerName: t("Regs.general.timeSlot"), width: 60},
        {field: 'stopClinicReasonName', headerName: '停代診原因', width: 100},
        {field: 'locumDoctorName', headerName: '代診醫師', width: 80},
        {field: 'cancelRegDatetime', headerName: '退掛時間', width: 170},
        {field: 'cancelRegReasonName', headerName: '退掛原因', width: 90},
        {field: 'cancelRegUserName', headerName: '退掛人員', width: 100},
        {field: 'message', headerName: '簡訊', width: 60},
    ]

    /**
     * 初始化
     * @return {void}
     */
    useEffect(() => {
        //取得停代診基本資料
        getStopClinicBasicData();
        //取得所有醫生
        queryAllDoctor().then((res) => {
            //取得成功
            if (res.err === ApiErrorStatusEnum.Success) {
                const list = res.data || [];
                //設定醫師選項
                setAllDoctorList(list);
                //預設filter醫師
                setDoctorOrDeptOptions(list);
            } else {
                setAllDoctorList([]);
            }
        });
        //取得所有科別
        queryAllDivision().then((res) => {
            if (res.err === ApiErrorStatusEnum.Success) {
                const list = res.data || [];
                setAllDivList(list);
            } else {
                setAllDivList([]);
            }
        });
        //
    }, []);

    /**
     * 監聽advSearchText&filteredDataList的變化
     * @return {void}
     */
    useEffect(() => {
        //取得進階查詢的資料
        getAdvanceSearchStopClinicData();
    }, [advSearchText, filteredDataList]);

    /**
     * 監聽selectedData的變化
     * @return {void}
     */
    useEffect(() => {
        //設定是否有選擇資料的狀態
        const haveSelected = !objectIsEmpty(selectedData);
        //取得選擇明細的聯絡紀錄
        getSelectedContactHistory();
        //設定是否已有選擇資料
        setHaveSelectedData(haveSelected);
    }, [selectedData]);

    /**
     * 監聽 selectedData 的變化
     * @return {void}
     */
    useEffect(() => {
        // 設定 contactDate 和 contactTime 為 contactCompDatetime
        if (selectedData && selectedData.contactCompFlag === 'Y' && selectedData.contactCompDatetime) {
            const contactCompDatetime = new Date(selectedData.contactCompDatetime);
            setContactDate(contactCompDatetime);
            setContactTime(contactCompDatetime);
        } else {
            // 如果 contactCompFlag 不是 'Y'，則設定為當前時間
            const now = new Date();
            setContactDate(now);
            setContactTime(now);
        }
    }, [selectedData]);

    return (
        <div className="w-full p-4 bg-[#FAFAFA] space-y-2.5 max-h-[calc(100vh-101px)] overflow-y-auto">
            {/*Filter區塊*/}
            <div className="filterGroup flex flex-row flex-wrap items-center justify-between mb-1">
                <div className="flex flex-wrap items-center space-x-2 text-left">
                    {/*日期起迄*/}
                    <DateRangePicker
                        required
                        value={[
                            displayQueryInputData.dateStart ? dayjs(displayQueryInputData.dateStart) : null,
                            displayQueryInputData.dateEnd ? dayjs(displayQueryInputData.dateEnd) : null
                        ]}
                        onChange={handleDateRangeOnChange}
                    />
                    {/* 選擇時段*/}
                    <Select
                        data={{
                            label: '時段',
                            options: apnList?.map((apn) => ({
                                label: apn.justnameName,
                                value: apn.justnameNo
                            })) || []
                        }}
                        onChange={handleSelectApnOnChange}
                        notched={true}
                        displayEmpty={true}
                        width={SelectWidthEnum.Small}
                        value={displayQueryInputData.apn || ""}
                    />
                    {/*聯絡狀態RadioButton*/}
                    <RadioGroup
                        row
                        labelProps={{text: "聯絡狀態"}}
                        defaultValue={displayQueryInputData.contactStatusMode}
                        value={displayQueryInputData.contactStatusMode}
                        size={SizeEnum.Medium}
                        optionProps={{
                            options: [
                                // 未完成
                                {label: '未完成', value: "0"},
                                // 已完成
                                {label: '已完成', value: "1"},
                                // 全部
                                {label: '全部', value: "2"},
                            ]
                        }}
                        onChange={(e) => handleFilterContactStatusModeOnChange(e.target.value)}/>

                    <Divider sx={{mx: 1}} orientation={DividerOrientationEnum.Vertical}/>
                    {/*查詢別*/}
                    <div className="flex flex-row mr-3.5 space-x-2">
                        <RadioGroup
                            row
                            labelProps={{text: "查詢別"}}
                            defaultValue={displayQueryInputData.filterMode}
                            value={displayQueryInputData.filterMode}
                            size={SizeEnum.Medium}
                            optionProps={{
                                options: [
                                    // 醫師
                                    {label: t("general.doctor"), value: RegsWeekModeEnum.Doctor},
                                    // 科別
                                    {label: t("general.department"), value: RegsWeekModeEnum.Department},
                                    // 病歷號
                                    {label: t("Regs.general.medicalRecordNumber"), value: ''},
                                ]
                            }}
                            onChange={(e) => handleFilterModeOnChange(e.target.value)}/>
                        {/*科別或醫師*/}
                        {
                            displayQueryInputData.filterMode !== null && (!arrayIsEmpty(allDoctorList) || !arrayIsEmpty(allDivList)) &&
                            (displayQueryInputData.filterMode === RegsWeekModeEnum.Doctor || displayQueryInputData.filterMode === RegsWeekModeEnum.Department) &&
                            (
                                <Search
                                    freeSolo
                                    inputLabel=''
                                    onChange={(e, value) => handleSearchOnChange(value)}
                                    onInputChange={(e, value) => handleSearchTextOnChange(value)}
                                    disablePortal={false}
                                    options={getOptions()}
                                    clearOnBlur
                                    getOptionLabel={(option) => getOptionLabels(option)}/>
                            )
                        }
                        {/*病歷號*/}
                        {
                            displayQueryInputData.filterMode === '' &&
                            (
                                <TextField
                                    helperText=""
                                    inputWidth="large"
                                    label=""
                                    placeholder="請輸入病歷號"
                                    size={SizeEnum.Medium}
                                    onChange={(e => handleSearchTextOnChange(e?.target?.value))}/>
                            )
                        }
                    </div>
                    {/*查詢按鈕*/}
                    <Button
                        color={ColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                        size={ButtonSizeEnum.Medium}
                        onClick={handleSearchOnClick}>
                        {t('general.query')}
                    </Button>
                </div>
            </div>
            {/*按鈕區塊*/}
            <div className="flex flex-row items-center justify-end mr-1 space-x-2">
                {/*列印停代診明細*/}
                <Button
                    color={ColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                    size={ButtonSizeEnum.Medium} onClick={handleStopClinicListOnClick}>
                    {'停代診清單'}
                </Button>
                {/*列印停代診明細*/}
                <Button
                    color={ColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                    size={ButtonSizeEnum.Medium}>
                    {'列印停代診明細'}
                </Button>
                <Button color={ColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                        size={ButtonSizeEnum.Medium} disabled={!haveSelectedData}
                        onClick={handlesetCancelStopClinicClick}>
                    {'取消停診'}
                </Button>
            </div>
            {/*停代診明細 table*/}
            <Card title='停代診明細' className="w-full">
                <div className="flex flex-row items-center justify-between mb-2">
                    {/*進階搜尋*/}
                    <SearchTextField
                        placeholder="進階搜尋"
                        value={advSearchText}
                        onChange={handleAdvanceSearchTextOnChange}
                    />
                </div>
                {/*停代診明細 Table*/}
                <AdvancedDataGrid
                    rows={advSearchDataList || []}
                    columns={header}
                    checkboxSelection={true}
                    disableColumnFilter
                    disableColumnSelector
                    disableColumnMenu
                    disableDensitySelector
                    disableRowSelectionOnClick={true}
                    height={'57vh'}
                    getRowId={(row) => row.key}
                    onRowSelectionModelChange={(newSelection) => {
                        // 更新選中的列
                        setSelectedRows(newSelection);
                        // 如果只選中一列，更新詳細資訊顯示
                        if (newSelection.length === 1) {
                            const selectedRow = filteredDataList.find(item => item.key === newSelection[0]);
                            if (selectedRow) {  // 增加空值檢查
                                handleDataOnClick(selectedRow);
                            }
                        } else {
                            // 當選擇多筆或取消選擇時，清空選擇的資料
                            setSelectedData(null);
                            resetContactContain();
                            setMessageText("");
                        }
                    }}
                    rowSelectionModel={selectedRows} // 控制選中的列
                    onCellClick={(params) => {
                        if (params && params.row) {  // 增加空值檢查
                            handleDataOnClick(params.row);
                        }
                    }}
                    getRowHeight={(params) => 52}
                />
                {/*分頁*/}
                <div className="flex justify-end w-full mt-1">
                    <Pagination
                        totalPageSize={paginationProps.totalPageSize}
                        totalSize={paginationProps.totalItemSize}
                        onPageOnChange={(page, pageSize) => onPaginationPageOnChange(page, pageSize)}
                        page={paginationProps.currentPage}
                        pageSize={paginationProps.pageSize}
                        showFirstButton
                        showLastButton
                    />
                </div>
            </Card>
            {/*簡訊*/
            }
            <Card title='簡訊'>
                <div className="w-full">
                    <div className="flex flex-row items-center">
                        {/*簡訊內容*/}
                        <TextField
                            sx={{mx: 2}}
                            inputWidth={SizeEnum.XLarge}
                            label="簡訊內容"
                            value={messageText}
                            onChange={handleMessageTextOnChange}
                        />
                        <Button
                            color={ColorEnum.Secondary} variant={ButtonVariantEnum.Outlined}
                            size={ButtonSizeEnum.Medium} disabled={!haveSelectedData}
                            onClick={handleSendMessageOnClick}>
                            {'發簡訊'}
                        </Button>
                    </div>
                </div>
            </Card>
            <div className="flex flex-nowrap items-center justify-start space-x-2">
                {/*聯絡內容*/}
                <div
                    className="w-[40%] overflow-auto">
                    <Card title='聯絡內容' sx={{height: '400px'}}>
                        <div className="flex flex-nowrap items-center justify-start space-x-2 mb-2">
                            <Field label="聯絡狀態" labelWidth={100}>
                                <Checkbox
                                    label="已完成"
                                    checked={isCompleteContact}
                                    onChange={(_, checked) => handleIsCompleteContactOnChange(checked)}
                                />
                            </Field>
                        </div>
                        {/*聯絡時間*/}
                        <div className="mb-2 space-y-2">
                            <Field label="聯絡時間" labelWidth={100}>
                                <div className="flex flex-row items-center space-x-2">
                                    <DatePicker
                                        label=""
                                        value={dayjs(contactDate)}
                                        disabled
                                        onChange={handleInputContactDateOnChange}
                                    />
                                    <TimePicker
                                        label=""
                                        value={dayjs(contactTime)}
                                        disabled
                                        showSeconds
                                        onChange={handleInputContactTimeOnChange}
                                    />
                                </div>
                            </Field>
                            {/*聯絡內容*/}
                            <Field
                                label="聯絡內容"
                                labelWidth={100}
                                isRequired
                                sx={{alignItems: "start"}}
                            >
                                <TextArea
                                    value={contactContainText}
                                    required
                                    onChange={handleContactContainTextOnChange}
                                    placeholder="請輸入聯絡內容"
                                ></TextArea>
                            </Field>
                            {/*退掛原因*/}
                            <Field label="退掛原因" labelWidth={100}>
                                <Select
                                    data={{
                                        label: "退掛原因",
                                        options:
                                            baseData?.cancelRegReasonList?.map(
                                                (reason) => ({
                                                    label: reason.justnameName,
                                                    value: reason.justnameNo,
                                                })
                                            ) || [],
                                    }}
                                    value={cancelRegReason || ''}  // 如果是 -1 或其他無效值，使用空字串
                                    onChange={handleCancelRegReasonOnChange}
                                    showLabel={false}
                                />
                            </Field>
                            {/*停代診原因*/}
                            <Field label="停代診原因" labelWidth={100}>
                                <p>{selectedData?.stopClinicReasonName || ''}</p>
                            </Field>
                        </div>


                        <div className="justify-end flex">
                            <Button
                                color={ColorEnum.Primary} variant={ButtonVariantEnum.Contained}
                                size={ButtonSizeEnum.Medium}
                                disabled={!haveSelectedData && selectedRows.length === 0}  // 修改判斷條件
                                onClick={() => handleSaveContactDetailsOnClick()}>
                                {t("general.saveFile")}
                            </Button>
                        </div>
                    </Card>
                </div>
                {/*片語*/}
                <div className="w-[20%] overflow-x-auto h-full">
                    <Card title='片語' sx={{height: '400px'}}>
                        <div className="flex items-center mb-2">
                            <TextField
                                value={searchPhraseText}
                                onChange={(e) => handleSearchPhraseTextOnChange(e)}
                            />
                        </div>
                        <div className="flex flex-wrap">
                            {
                                getFuzzySearchPhraseList().map((data) =>
                                    <Button sx={{marginRight: '0.5rem', marginBottom: '0.5rem'}}
                                            key={`phraseList_${data.no}`} color={ColorEnum.Secondary}
                                            variant={ButtonVariantEnum.Outlined} size={ButtonSizeEnum.Medium}
                                            onClick={() => handlePhraseOnClick(data.name)}>
                                        {data.name}
                                    </Button>
                                )
                            }
                        </div>
                    </Card>
                </div>
                {/*聯絡紀錄*/}
                <div className="w-[40%] overflow-x-auto">
                    <Card title='聯絡紀錄' sx={{height: '400px'}}>
                        <ul className=" overflow-y-auto h-[300px]">
                            {!arrayIsEmpty(contactHistoryList) &&
                                contactHistoryList.map((record, index) => (
                                    <div key={index}>
                                        <div>
                                            <div className="flex">
                                                <p>
                                                    {time2String(
                                                        record.contactDatetime,
                                                        "YYYY-MM-DD HH:mm:ss"
                                                    )}
                                                </p>
                                                <p>
                                                    {record.contactStatus
                                                        ? record.contactStatus
                                                        : ""}
                                                </p>
                                            </div>
                                            <div className="flex flex-wrap text-[#A1A1AA] leading-5 mb-3">
                                                <p>建立日期時間/人員：</p>
                                                <div className="flex leading-5">
                                                    <p>
                                                        {time2String(
                                                            record.createDatetime,
                                                            "YYYY-MM-DD HH:mm:ss")}
                                                    </p>
                                                    <p>/</p>
                                                    <p>
                                                        {record.createUserName
                                                            ? record.createUserName
                                                            : ""}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </ul>
                    </Card>
                </div>
            </div>

            {
                <StopClinicListPromptPopup
                    show={showStopClinicListPrompt}
                    closePopupButtonOnClick={closeStopClinicListPromptPopup}
                />
            }
            {
                <CancelStopClinicPromptPopup
                    show={showCancelStopClinicPrompt}
                    data={selectedData}
                    closePopupButtonOnClick={closeCancelStopClinicPromptPopup}
                    onConfirmClick={confirmCancelStopClinic}
                />
            }
        </div>
    )
        ;
};

export default StopClinic;
