import React, {useCallback, useMemo, useState} from 'react'
import {usePopup} from '../PopupProvider';
import {ApiErrorStatusEnum, fuzzySearchObjects, time2String} from "edah_utils/dist"
import {rsisAddMail, rsisDelMail, rsisEditMail, rsisQueryCodeValueCodeType, rsisQueryMail} from "../../../api/v1/RSIS";
import QueryCreateUserList from "../Modal/QueryCreateUserList";
import {t} from "i18next"
import {
    AlertTypeEnum,
    DatePicker, SizeEnum,
    InlineEditDataGrid, Search, TextField
} from "edah-component/dist"
import {SearchTextField} from "../../SearchTextField/SearchTextField";
import dayjs from "dayjs";


/**
 * 警示信箱發送
 * @return {JSX.Element}
 */
const MailListMaintain = () => {
    const [dataList, setDataList] = useState([])
    // 事件代號
    const [mailTypesList, setMailTypesList] = useState([])
    // 選中的 row id
    const [focusRowId, setFocusRowId] = useState(null)
    // 模糊搜尋的字串
    const [queryString, setQueryString] = useState('');
    // 未保存的資料集
    const [unSaveDataMap, setUnSaveDataMap] = useState({})
    const {
        setShowDeletePopup,
        showQueryCreateUserList,
        setShowQueryCreateUserList,
        setFocusItem,
        focusItem,
        showToast,
    } = usePopup()

    const [paginationProps, setPaginationProps] = useState({
        pageNum: 1,
        pageSize: 10,
        totalItemSize: 0,
        totalPageSize: 0,
        currentPageItemSize: 0,
    })

    /**
     * 當列表項目更新時
     * @param item {Object} 列表項目
     * @return {void}
     */
    const handleItemOnUpdate = (item) => {
        // 設定當前焦點項目
        setFocusItem(item)

        setUnSaveDataMap({...unSaveDataMap, [focusRowId]: {
                ...unSaveDataMap[focusRowId],
                userNo: item.userNo,
                userName: item.userName,
                fullName: `${item.userNo} ${item.userName}`
            }})
        // 關閉彈窗
        setShowQueryCreateUserList(false)
    }

    /**
     * 保存或編輯列表項目
     * @param item {Object} - 列表項目
     * @return {Promise<void>}
     */
    const handleSaveItemOnClick = async (item) => {
        // 如果是新增的列表項目，則執行onSave
        if (item.isNew) {
            rsisAddMail({
                mailListsUuid: '',
                mailType: item.mailType,
                codeValue1: item.codeValue1,
                userNo: item.userNo,
                userName: item.userName,
                effStartDatetime: `${item.effStartDatetime} 00:00:00`,
                effEndDatetime: `${item.effEndDatetime} 00:00:00`,
                memo: ''
            }).then(res => {
                const {err, data, msg} = res
                if (err === ApiErrorStatusEnum.Success) {
                    showToast({message: '保存成功', type: AlertTypeEnum.Success})
                    setDataList([])
                    getQueryMailUser()
                } else {
                    showToast({message: `保存失敗，${msg}`, type: AlertTypeEnum.Error})
                }
            })
        } else {// 如果是編輯的列表項目，則執行onSave成功後退回非編輯狀態
            const data = {
                mailListsUuid: item.mailListsUUid,
                mailType: item.mailType,
                userNo: item.userNo,
                effStartDatetime: `${item.effStartDatetime} 00:00:00`,
                effEndDatetime: `${item.effEndDatetime} 00:00:00`,
                memo: ''
            }
            rsisEditMail(data).then(res => {
                const {err, data, msg} = res
                if (err === ApiErrorStatusEnum.Success) {
                    showToast({message: '保存成功', type: AlertTypeEnum.Success})
                    setDataList([])
                    getQueryMailUser()
                } else {
                    showToast({message: `保存失敗，${msg}`, type: AlertTypeEnum.Error})
                }
            })
        }
    }

    /**
     * 刪除Mail發送人員名單
     * @param mailListsUUid {string} - Mail發送人員名單UUID
     * @return {void}
     */
    const handleListItemOnDelete = (mailListsUUid) => delMailUser(mailListsUUid)

    /**
     * 刪除Mail發送人員名單
     * @param mailListsUUid {string} - Mail發送人員名單UUID
     * @return {void}
     */
    const delMailUser = (mailListsUUid) => {
        rsisDelMail({mailListsUuid: mailListsUUid}).then(res => {
            const {err, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                setDataList(prevDataList => prevDataList.filter(item => item.mailListsUUid !== focusItem.mailListsUUid))
                showToast({message: '刪除成功', type: AlertTypeEnum.Success})
                setShowDeletePopup(false)
            } else {
                showToast({message: `刪除失敗，${msg}`, type: AlertTypeEnum.Error})
            }

        }).finally(() => {
            getQueryMailUser()
        })
    }
    /**
     * 員工代號輸入框點擊時
     * @param id {string} - row id
     * @return {void}
     */
    const handleQueryCreateUserOnClick = (id) => {
        setFocusRowId(id)
        setShowQueryCreateUserList(true)
    }
    /**
     * Mail發送人員名單維護--查詢
     * @return {void}
     */
    const getQueryMailUser = () => {
        rsisQueryMail({
            pageNum: paginationProps.pageNum,
            pageSize: paginationProps.pageSize
        }).then(res => {
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                setDataList(data)
                setPaginationProps(prev => ({
                    ...prev,
                    totalItemSize: data.length,
                    totalPageSize: 1,
                    currentPageItemSize: data.length
                }))
            } else {
                showToast({message: `取得失敗，${msg}`, type: AlertTypeEnum.Error})
            }
        })
    }

    /**
     * 關閉查詢新增使用者清單彈窗
     * @return {void}
     */
    const handleQueryCreateUserListOnClose = () => setShowQueryCreateUserList(false)

    /**
     * 取得所有基本資料設定
     * @return {void}
     */
    const getPurposeOptions = () => {
        rsisQueryCodeValueCodeType({
            codeType: 'EVENT_MAIL_LIST',
            effFlag: 'Y',
        }).then(res => {
            // 狀態 / 資料 / 訊息
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 設定支援目的選項
                setMailTypesList(data)
            } else {
                setMailTypesList([])
                showToast({message: msg, type: AlertTypeEnum.Error})
            }
        }).catch(error => {
            setMailTypesList([])
            showToast({message: '獲取數據失敗', type: AlertTypeEnum.Error})
        })
    }

    /**
     * 取得模糊匹配後的資料陣列
     * @return {Array<Object>}
     */
    const getFilterData = () => fuzzySearchObjects(dataList, queryString)
    /**
     * 搜尋字串改變時
     * @param event {Event} - 事件
     * @return {void}
     */
    const handleQueryOnChange = (event) => setQueryString(event.target.value);

    /**
     * 取得預設事件代號
     * @param id {string} - row id
     * @param defaultValue {string} - 預設值
     */
    const getDefaultMailEventValue = useCallback((id, defaultValue) => {
        const targetValue = unSaveDataMap[id]?.codeNo || defaultValue
        return mailTypesList?.find(item => item.codeNo === targetValue) || null
        }, [mailTypesList, unSaveDataMap]
    )

    /**
     * 保存列表項目
     * @param row {Object} - 列表項目
     * @return {void}
     */
    const handleOnSave = (row) => {
        const startDate = new Date(time2String(row?.effStartDatetime, 'YYYY-MM-DD'))
        const endDate = new Date(time2String(row?.effEndDatetime, 'YYYY-MM-DD'))
        // 驗證結束日期是否早於開始日期
        if (endDate < startDate) {
            showToast({message: "結束日期不能早於開始日期", type: AlertTypeEnum.Warning})
            return false
        }
        handleSaveItemOnClick({...row,
            mailType: row.codeNo || row.mailType,
            effStartDatetime: time2String(row?.effStartDatetime, 'YYYY-MM-DD'),
            effEndDatetime: time2String(row?.effEndDatetime, 'YYYY-MM-DD')
        })
    }

    /**
     * 警示信箱發送 - 表格用的 header 與設定資料
     */
    const header = [
        { field: 'mailType', headerName:'事件代號', flex: 1, minWidth: 100, renderCell: (params) => {
                // 是否為新項目
                const isEditable = params.isEditable
                return isEditable ?
                    <div className="flex flex-row mt-2 items-center">
                        <Search
                            freeSolo
                            key={params.id}
                            value={getDefaultMailEventValue(params.id, params.row?.mailType)}
                            onChange={(_e, value) => {
                                setUnSaveDataMap({...unSaveDataMap, [params.id]:{
                                        ...unSaveDataMap[params.id],
                                        codeNo: value?.codeNo,
                                        codeValue1: value?.codeValue1
                                    }
                                })                            }}
                            options={mailTypesList||[]}
                            getOptionLabel={(option) => `${option.codeNo}`}
                        />
                    </div>: <>{params.row.mailType}</>
            }
        },
        { field: 'codeValue1', headerName:'事件說明', flex: 1, minWidth: 250, renderCell: (params) => {
                const isNewOrEdit = params.row.isNew || params.isEditable
                return isNewOrEdit ? <>{unSaveDataMap[params.id]?.codeValue1 || params.row.codeValue1 }</> : <>{params.row.codeValue1}</>
            }
        },
        { field: 'userNo', headerName: '員工代號', flex: 1, editable: true, minWidth: 150, renderCell: (params) => {
                // 是否可編輯
                const isEditable = params.isEditable
                const value = params.row.isNew? '': `${params?.row?.userNo} ${params?.row?.userName}`
                return  isEditable ?
                    <div className="flex items-center h-[100%]">
                        <TextField
                            value={unSaveDataMap[params.id]?.fullName || value}
                            onClick={()=>handleQueryCreateUserOnClick(params.id)}
                        />
                    </div>: <>{params.row.userNo} {params.row.userName}</>
            }},
        { field: 'effStartDatetime', headerName:'開始日期', flex: 1, minWidth: 150, renderCell: (params) => {
                const isNewOrEdit = params.row.isNew || params.isEditable
                return isNewOrEdit ? <div className="flex items-center h-[100%]">
                    <DatePicker
                        label=''
                        size={SizeEnum.Small}
                        value={params.value ? dayjs(params.value) : null}
                        onChange={(newDate) => {
                            const date = newDate?.format('YYYY-MM-DD')
                            setUnSaveDataMap({
                                ...unSaveDataMap, [params.id]: {
                                    ...unSaveDataMap[params.id],
                                    effStartDatetime: date
                                }
                            })
                        }}
                    />
                </div> : <>{params.row.effStartDatetime?.split(' ')?.[0] || ''}</>
            }
        },
        {
            field: 'effEndDatetime', headerName: '結束日期', flex: 1, minWidth: 150, renderCell: (params) => {
                const isNewOrEdit = params.row.isNew || params.isEditable
                return isNewOrEdit ?
                    <div className="flex items-center h-[100%]">
                        <DatePicker
                            label=''
                            size={SizeEnum.Small}
                            value={params.value ? dayjs(params.value) : null}
                            onChange={(newDate) => {
                                const date = newDate?.format('YYYY-MM-DD')
                                setUnSaveDataMap({
                                    ...unSaveDataMap, [params.id]: {
                                        ...unSaveDataMap[params.id],
                                        effEndDatetime: date
                                    }
                                })
                            }}
                        />
                    </div> : <>{params.row.effEndDatetime?.split(' ')?.[0] || ''}</>
            }
        }
    ]

    // 第一次執行時
    useMemo(() => {
        // 取得所有基本資料設定
        getPurposeOptions()
        // 取得Mail發送人員名單
        getQueryMailUser()
    }, [])

    return (
        <div className='w-full px-6 py-2'>
            <div className="text-left my-2">
                <SearchTextField
                    value={queryString}
                    onChange={handleQueryOnChange}
                    placeholder={t('general.advancedSearch')}
                />
            </div>

            <InlineEditDataGrid
                rows={getFilterData() || []}
                columns={header}
                checkboxSelection={false}
                disableColumnFilter
                disableColumnSelector
                disableColumnMenu
                disableDensitySelector
                disableRowSelectionOnClick
                height={'700px'}
                onSave={(row) => {
                    const value = {...row, ...unSaveDataMap[row.id]}
                    return handleOnSave(value)
                }}
                onCancel={(row) => {
                    const rawTemp = {...unSaveDataMap}
                    delete rawTemp[row.id]
                    setUnSaveDataMap(rawTemp)
                }}
                onDeleteRow={(row) => {
                    handleListItemOnDelete(row?.mailListsUUid)
                }}
            />
            {
                showQueryCreateUserList && (
                    <QueryCreateUserList
                        handleOnClose={handleQueryCreateUserListOnClose}
                        handleOnUpdate={handleItemOnUpdate}/>
                )
            }
        </div>
    )
}

export default MailListMaintain
