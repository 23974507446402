import {
    ApiErrorStatusEnum,
    arrayIsEmpty,
    CampusEnum,
    fuzzySearchObjects,
    getLocalStorage,
    ToastTypeEnum
} from "edah_utils/dist"
import {
    Button,
    CheckboxGroup,
    DatePicker,
    TimePicker,
    Field,
    AdvancedDataGrid,
    Search,
    TextField,
    TextArea, SizeEnum, Card,
    Pagination
} from "edah-component/dist";
import React, {useEffect, useState} from "react"
import useToast from "../../hooks/useToast"
import {t} from "i18next"
import dayjs from "dayjs";
import {
    WdisServiceMonitorOperLogs, WdisServiceMonitorOperLogsInitial,
    WdisServiceMonitorTranstoPdf
} from "../../api/v1/WDIS"

// 服務LOG查詢頁面
const ServiceLog2Inquiry = () => {
    /**
     * Show Toast
     */
    const showToast = useToast()

    // 取得分院清單
    const campusList = Object.values(CampusEnum)

    //服務名稱
    const [logInfo, setLogInfo] = useState(null)

    //負責人代號
    const [ownerUser, setOwnerUser] = useState(null)


    //服務記錄起始日期
    const [logStartDate, setLogStartDate] = useState('')

    //服務記錄起始時間
    const [logStartTime, setLogStartTime] = useState('')

    //服務記錄結束日期
    const [logEndDate, setLogEndDate] = useState('')

    //服務記錄結束時間
    const [logEndTime, setLogEndTime] = useState('')

    //院區
    const [campus, setCampus] = useState([])

    //基礎資料
    const [baseData, setBaseData] = useState({})

    //log report print 資料
    const [dataList, setDataList] = useState([])
    // 分頁filter資料
    const [filterData, setFilterData] = useState([])

    //stackTrace
    const [stackTrace, setStackTrace] = useState('')

    //content
    const [content, setContent] = useState('')

    const [pdfBlob, setPdfBlob] = useState(null)
    //顯示PDF
    const [showPdf, setShowPdf] = useState(false)
    // 當前選擇的資料是否log
    const [isLog, setIsLog] = useState(true)
    // pagination控制變數
    const [paginationProps, setPaginationProps] = useState({
        //當前頁碼
        currentPage: 1,
        //每頁資料筆數
        pageSize: 50,
        //總資料筆數
        totalItemSize: 0,
        //總頁碼
        totalPageSize: 0,
    });

    //tabel column設置
    const tableColumns = [
        {
            field: 'meta.dataClassification', headerName: 'dataClassification', width: 100,
            valueGetter: (value, row) => row.meta.dataClassification || '',
        },
        {
            field: 'meta.executeUserId', headerName: 'executeUserId', width: 120,
            valueGetter: (value, row) => row.meta.executeUserId || '',
        },
        {
            field: 'meta.remoteIp', headerName: 'remoteIp', width: 130,
            valueGetter: (value, row) => row.meta.remoteIp || '',
        },
        {
            field: 'backend.executeDatetime', headerName: 'executeDatetime', width: 120,
            valueGetter: (value, row) => row.backend.executeDatetime || '',
        },
        // { field: 'id', headerName: 'id', width: 100},
    ]


    /**
     * 服務代號改變時
     * @param option {option} option
     * @return {void}
     */
    const handleServiceOptionOnChange = (option) => {
        const info = (option && option?.logId) ? option : null
        setLogInfo(info)
    }


    /**
     * 負責人代號改變時
     * @param e {Event} e
     * @return {void}
     */
    const handleOwnerUserOnChange = (e) => setOwnerUser(e.target.value)

    /**
     * 服務紀錄起始日期改變時
     * @param newDate {newDate} newDate
     * @return {void}
     */
    const handleLogStartDateOnChange = (newDate) => setLogStartDate(dayjs(newDate).format('YYYY-MM-DD'))

    /**
     * 服務紀錄起始時間改變時
     * @param newTime {String} newTime
     * @return {void}
     */
    const handleLogStartTimeOnChange = (newTime) => {
        const timeStr = dayjs(newTime).format('HH:mm:00')
        setLogStartTime(dayjs(newTime).format('HH:mm:00'))
    }

    /**
     * 服務紀錄結束日期改變時
     * @param newDate {event} event
     * @return {void}
     */
    const handleLogEndDateOnChange = (newDate) => setLogEndDate(dayjs(newDate).format('YYYY-MM-DD'))

    /**
     * 服務紀錄結束時間改變時
     * @param newTime {String} event
     * @return {void}
     */
    const handleLogEndTimeOnChange = (newTime) => setLogEndTime(dayjs(newTime).format('HH:mm:00'))


    /**
     * 院區改變時
     * @param index {number} index
     * @return {void}
     */
    const handleCampusOnChange = (index) => {
        // 取得當前院區
        const newCampus = [...campus]
        // 設定當前院區值
        newCampus[index] = {...newCampus[index], value: !newCampus[index].value}
        // 設定院區
        setCampus(newCampus)
    }

    /**
     * 查詢按鈕點擊時
     * @return {void}
     */
    const handleQueryOnClick = () => getQueryServiceMonitorOperlog()

    /**
     * 取得篩選服務代號資料
     * @return {Array<Object>}
     */
    const getFilterServiceData = () => {
        return fuzzySearchObjects(baseData?.serviceIdList, '') || []
    }

    /**
     * 獲取分頁的datagrid資料
     */
    const getFilterData = (page, pageSize) =>{
        const tempFilterData = dataList?.slice((page-1) * pageSize, page * pageSize ) || []
        return tempFilterData
    }

    /**
     * 頁碼變更事件
     * @param page {Number} 頁碼
     */
    const onPaginationPageOnChange = (page, pageSize) => {
        setPaginationProps({
            ...paginationProps,
            // totalItemSize: dataList?.length || 0,
            totalPageSize: Math.ceil(dataList?.length / pageSize),
            currentPage: page,
            pageSize: pageSize,
        });
        setFilterData(getFilterData(page, pageSize))
        // 清空右邊畫面資料
        setStackTrace('')
        setContent('')
        setPdfBlob(null)
        setShowPdf(false)
    };

    /**
     * 取得基礎資料
     * @return {void}
     */
    const getQueryBaseData = () => {
        WdisServiceMonitorOperLogsInitial({}).then(res => {
            const {err, data, msg} = res
            if (err === ApiErrorStatusEnum.Success) {
                // 設定基礎資料
                setBaseData(data)
                setLogStartDate(dayjs(data?.startDate).format('YYYY-MM-DD'))
                setLogStartTime(dayjs(data?.startDate).format('HH:mm:ss'))
                setLogEndDate(dayjs(data?.endDate).format('YYYY-MM-DD'))
                setLogEndTime(dayjs(data?.endDate).format('HH:mm:ss'))
            } else { // 取得失敗
                showToast({message: msg, type: ToastTypeEnum.Error})
            }
        })
    }

    /**
     * 服務Log查詢
     * @return {void}
     */
    const getQueryServiceMonitorOperlog = () => {
        setContent('')
        setStackTrace('')
        setPdfBlob(null)
        setShowPdf(false)
        // 取得分院
        const zone = campus.filter(item => item.value === true).map(item => item.key)
        const startDate = logStartDate + ' ' + logStartTime
        const endDate = logEndDate + ' ' + logEndTime
        WdisServiceMonitorOperLogs({
            //院區代號
            hospId: zone.join(','),
            logId: logInfo?.logId,
            executeId: ownerUser,
            //服務紀錄時間--起
            startDate: startDate,
            //服務紀錄時間--訖l
            endDate: endDate
        }).then(res => {
            // 取得狀態 / 資料 / 訊息
            const {err, data, msg} = res
            // 取得成功
            if (err === ApiErrorStatusEnum.Success) {
                // 設定資料
                setDataList(data)
                 //設定分頁資訊
                setPaginationProps({
                    ...paginationProps,
                    totalItemSize: data?.length || 0,
                    totalPageSize: Math.ceil(data?.length / paginationProps.pageSize)
                });
                
            } else { // 取得失敗
                showToast({message: msg, type: ToastTypeEnum.Error})
                // 清空資料
                setFilterData([])
                setDataList([])
            }
        })
    }

    /**
     * 轉成pdf檔案
     * @param formId {String}
     * @return {string}
     */
    const transToPdf = (formId) => {
        WdisServiceMonitorTranstoPdf({
            fromId: formId
        }).then(res => {
            if (res) {
                // 設定資料
                // debugger
                setPdfBlob(res)
                setShowPdf(true)
            } else { //取得失敗
                showToast({message: "轉換pdf資料失敗", type: ToastTypeEnum.Warning})
                //清除資料
                setPdfBlob(null)
                setShowPdf(false)
            }
        })
    }

    const handlerRowClick = (params) => {
        const contentStr = params?.row?.data?.content || ''
        const stackTrace = params?.row?.backend?.stackTrace
        setStackTrace(stackTrace)

        const type = params?.row?.meta?.dataClassification || ''
        if (type === 'LOG') {
            setContent(atob(contentStr) || '')
        }

        if (type === 'REPORT' || type === 'PRINT') {
            transToPdf(params?.row?.id)
        }
        setIsLog(type === 'LOG')
    }

    /**
     * 第一次進入頁面時
     * @return {void}
     */
    useEffect(() => {
        // 取得基礎資料
        getQueryBaseData()
        // 建立院區
        const createCampus = campusList.map(item => ({key: item, value: false}))
        // 取得當前角色
        const currentCampus = getLocalStorage('campus')
        const newCampus = createCampus.map(item => item.key === currentCampus ? {...item, value: true} : item)
        // 設定當前角色
        setCampus(newCampus)
    }, [])

    /**
     * 監聽dataList變化
     */
    useEffect(() => {
        if (dataList !== null) {
            setFilterData(getFilterData(paginationProps.currentPage, paginationProps.pageSize))
        } else {
            setFilterData(null);
        }
    }, [dataList]);

    return (
        <div className="w-full p-4 bg-[#FAFAFA] h-[calc(100vh-98px)]">
            {/*篩選清單*/}
            <div className="filterGroup mb-4 overflow-x-auto">
                <div className="mb-4">
                    <div className="flex flex-row items-center justify-start">
                        {/*Log代號*/}
                        <Field labelWidth={80} label="Log代號" sx={{width: 'auto', mr: 2}}>
                            <Search
                                freeSolo
                                value={logInfo}
                                clearOnBlur
                                onChange={(_e, value) => {
                                    handleServiceOptionOnChange(value)
                                }}
                                onInputChange={(e, value) => {
                                    handleServiceOptionOnChange(value)
                                }}
                                options={getFilterServiceData()}
                                getOptionLabel={(option) => `${option.logId} ${option.logName}`}/>
                        </Field>
                        {/*執行人員*/}
                        <Field labelWidth={80} label="執行人員" sx={{width: 'auto', mr: 2}}>
                            <TextField
                                label=""
                                value={ownerUser}
                                onChange={(e) => handleOwnerUserOnChange(e)}/>
                        </Field>
                        {/*Log記錄起迄時間*/}
                        <Field labelWidth={150} label="Log記錄起迄時間">
                            <div className="w-[610px] flex flex-row items-center justify-start gap-2">
                                <DatePicker disablePast={false}
                                            value={dayjs(logStartDate)}
                                            onChange={handleLogStartDateOnChange} // 移除事件，直接傳入日期
                                            label=""/>
                                <TimePicker label=""
                                            value={dayjs(`${logStartTime}`, 'HH:mm')}
                                            showSeconds
                                            onChange={handleLogStartTimeOnChange}/>
                                <p className="mx-2">~</p>
                                <DatePicker className="w-[166px]" disablePast={false}
                                            value={dayjs(logEndDate)} onChange={handleLogEndDateOnChange} // 移除事件，直接傳入日期
                                            label="" sx={{width: '200px', mr: 1}}/>
                                <TimePicker label=""
                                            value={dayjs(`${logEndTime}`, 'HH:mm')}
                                            showSeconds
                                            onChange={handleLogEndTimeOnChange}/>
                            </div>
                        </Field>
                    </div>
                </div>
                <div className="flex flex-row items-center justify-start">
                    {/* 院區多選 */}
                    {
                        !arrayIsEmpty(campus) && (
                            <CheckboxGroup
                                labelText="院區"
                                showLabel
                                required
                                row={true}
                                checkboxes={campus.map((item, index) => ({
                                    label: t(`campus.shortName.${item.key}`),
                                    checked: item.value,
                                    onChange: () => handleCampusOnChange(index),
                                }))}
                            />
                        )
                    }
                    {/*查詢按鈕*/}
                    <Button
                        color="primary"
                        variant="contained"
                        size="medium"
                        onClick={handleQueryOnClick}>
                        {t("general.query")}
                    </Button>
                </div>
            </div>
            {/*服務表格清單*/}
            <div className="maintainContainer grid grid-cols-3 gap-2 h-[calc(100%-114px)]">
                <div className="col-span-1 bg-white w-full  mr-4 h-full">
                    <AdvancedDataGrid columns={tableColumns} height='616px'  rows={filterData} themeMode="light"
                                      getRowId={(row) => row.id}
                                      getRowHeight={(params) => 56}
                                      onRowClick={handlerRowClick}/>
                    <div className="flex justify-end w-full overflow-auto">
                        <Pagination
                            totalPageSize={paginationProps.totalPageSize}
                            page={paginationProps.currentPage}
                            pageSize={paginationProps.pageSize}
                            totalSize={paginationProps.totalItemSize}
                            onPageOnChange={(page, pageSize) =>
                                onPaginationPageOnChange(page, pageSize)
                            }
                            
                        />
                    </div>
                </div>
                <div className="col-span-2 space-y-4">
                    <Card title="StackTrace" sx={{height: 'calc(32% - 8px)'}}>
                        <TextArea  inputSize={SizeEnum.Fill} value={stackTrace}/>
                    </Card>
                    <Card title={isLog ? 'Exception' : 'Report'} sx={{height: 'calc(68% - 8px)'}}>
                        {
                            !showPdf && (<TextArea inputSize={SizeEnum.Fill} value={content}/>)
                        }
                        {
                            showPdf &&
                            (
                                <div className="h-full bg-black overflow-auto">
                                    <iframe className="rounded-[6px]" src={URL.createObjectURL(pdfBlob)}
                                        width="100%"
                                        height="400px" />
                                </div>
                            )
                        }
                    </Card>
                </div>
            </div>
        </div>
    )
}
export default ServiceLog2Inquiry
